import React from "react";
import PropTypes from "prop-types";

import _ from "lodash";


import { useTranslation } from "react-i18next";

import { mapColumnsToGroupFields } from "../../../utils/mappers/groupSettings";

import {
    Input,
    Segment,
    Form,
    Grid,
    Button,
    Dropdown,
} from "semantic-ui-react";

import ADURangeSlider from "../ADURangeSlider/ADURangeSlider";
import SimpleRangeSlider from "../SimpleRangeSlider/SimpleRangeSlider";

import { round } from "../../../utils/format";

import {
    variabilityFactorMin,
    variabilityFactorMax,
    variabilityFactorStep,
    leadTimeFactorMin,
    leadTimeFactorMax,
    leadTimeFactorStep,
} from "../../../utils/constants";

import "./EditGroup.css";

const RulesSettings = ({
    selectedGroup,
    columns,
    items,
    ruleData,
    setRuleData,
    onCancel,
    rulesDisabled,
    materialMethodOptions,
}) => {
    const { t } = useTranslation();

    const groupFields = mapColumnsToGroupFields(t, columns, items);

    const def = {};
    for (let field of groupFields) {
        def[[field.accessor]] = {
            name: field.name,
            accessor: field.accessor,
            values: ["all"],
        };
    }

    const initialGroupData = _.isEmpty(selectedGroup)
        ? { ...def }
        : { ...selectedGroup };
    for (let field of groupFields) {
        if (_.isNil(initialGroupData[[field.accessor]])) {
            initialGroupData[[field.accessor]] = {
                name: field.name,
                accessor: field.accessor,
                values: ["all"],
            };
        }
    }

    const getVariabilityTag = (value) => {
        let text;
        if (value > 0.9) {
            text = t("groupSettings.rules.variability.tags.extremelyHigh");
        } else if (value > 0.7) {
            text = t("groupSettings.rules.variability.tags.veryHigh");
        } else if (value > 0.5) {
            text = t("groupSettings.rules.variability.tags.high");
        } else if (value > 0.3) {
            text = t("groupSettings.rules.variability.tags.medium");
        } else if (value > 0.1) {
            text = t("groupSettings.rules.variability.tags.low");
        } else {
            text = t("groupSettings.rules.variability.tags.veryLow");
        }
        return `${text} (${value})`;
    };

    const getLeadTimeTag = (value) => {
        let text;
        if (value > 0.9) {
            text = t("groupSettings.rules.leadTime.tags.extremelyShort");
        } else if (value > 0.7) {
            text = t("groupSettings.rules.leadTime.tags.veryShort");
        } else if (value > 0.5) {
            text = t("groupSettings.rules.leadTime.tags.short");
        } else if (value > 0.3) {
            text = t("groupSettings.rules.leadTime.tags.medium");
        } else if (value > 0.1) {
            text = t("groupSettings.rules.leadTime.tags.long");
        } else {
            text = t("groupSettings.rules.leadTime.tags.veryLong");
        }
        return `${text} (${value})`;
    };

    const incrementLeadTimeFactor = () => {
        const newValue = round(
            parseFloat(ruleData.leadTimeFactor) + leadTimeFactorStep,
            2
        );
        setRuleData({
            ...ruleData,
            leadTimeFactor: Math.min(newValue, leadTimeFactorMax),
        });
    };

    const decreaseLeadTimeFactor = () => {
        const newValue = round(
            parseFloat(ruleData.leadTimeFactor) - leadTimeFactorStep,
            2
        );
        setRuleData({
            ...ruleData,
            leadTimeFactor: Math.max(newValue, leadTimeFactorMin),
        });
    };

    const incrementVariabilityFactor = () => {
        const newValue = round(
            parseFloat(ruleData.variabilityFactor) + variabilityFactorStep,
            2
        );
        setRuleData({
            ...ruleData,
            variabilityFactor: Math.min(newValue, variabilityFactorMax),
        });
    };

    const decreaseVariabilityFactor = () => {
        const newValue = round(
            parseFloat(ruleData.variabilityFactor) - variabilityFactorStep,
            2
        );
        setRuleData({
            ...ruleData,
            variabilityFactor: Math.max(newValue, variabilityFactorMin),
        });
    };

    const initialMarked = {};
    groupFields.forEach((field) => (initialMarked[field.accessor] = true));

    return (

        <Segment className="rules-group">
            <Grid>
                <Grid.Row>
                    <Grid.Column>
                        <label className="rules-title">
                            {t("groupSettings.rules.title")}
                        </label>
                        <Form.Group widths={2}>
                            {!["buffered", "non_buffered"].includes(ruleData.materialMethod) ? null :
                                <Form.Field className="method-field">
                                    <label>
                                        {t("groupSettings.rules.materialMethod.title")}
                                    </label>
                                    <Dropdown
                                        className="method-dropdown"
                                        selection
                                        compact
                                        disabled={rulesDisabled}
                                        value={ruleData.materialMethod}
                                        options={materialMethodOptions}
                                        onChange={(e, data) =>
                                            setRuleData({
                                                ...ruleData,
                                                materialMethod: data.value,
                                            })
                                        }
                                    ></Dropdown>
                                </Form.Field>
                            }

                            <Form.Field className="adu-length">
                                <label>{t("groupSettings.rules.aduLength")}</label>
                                <ADURangeSlider
                                    startInitialValue={ruleData.aduMeasurementStart}
                                    endInitialValue={ruleData.aduMeasurementEnd}
                                    onChange={(low, high) => {
                                        setRuleData({
                                            ...ruleData,
                                            aduMeasurementStart: low,
                                            aduMeasurementEnd: high,
                                        });
                                    }}
                                    rulesDisabled={rulesDisabled}
                                />
                            </Form.Field>
                        </Form.Group>

                        <Form.Group widths={2}>
                            <Form.Field className="lead-time-slider">
                                <label>
                                    {t("groupSettings.rules.leadTime.title")}
                                </label>

                                <Grid>
                                    <Grid.Column width={16}>
                                        <SimpleRangeSlider
                                            step={leadTimeFactorStep}
                                            min={leadTimeFactorMin}
                                            max={leadTimeFactorMax}
                                            onChange={(value) => {
                                                setRuleData({
                                                    ...ruleData,
                                                    leadTimeFactor: round(parseFloat(value), 1),
                                                });
                                            }}
                                            initialValue={ruleData.leadTimeFactor}
                                            rulesDisabled={rulesDisabled}
                                        />
                                    </Grid.Column>
                                    <Grid.Column textAlign={"center"} width={16}>
                                        <Input
                                            className="lead-time-controls"
                                            value={getLeadTimeTag(ruleData.leadTimeFactor)}
                                            action
                                            disabled={rulesDisabled}
                                        >
                                            <Button
                                                positive
                                                icon="minus"
                                                size="mini"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    decreaseLeadTimeFactor();
                                                }}
                                                disabled={rulesDisabled}
                                            />
                                            <input type="text" />
                                            <Button
                                                positive
                                                icon="plus"
                                                size="mini"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    incrementLeadTimeFactor();
                                                }}
                                                disabled={rulesDisabled}
                                            />
                                        </Input>
                                    </Grid.Column>
                                </Grid>
                            </Form.Field>

                            <Form.Field className="variability-slider">
                                <label>
                                    {t("groupSettings.rules.variability.title")}
                                </label>
                                <Grid>
                                    <Grid.Column width={16}>
                                        <SimpleRangeSlider
                                            step={variabilityFactorStep}
                                            min={variabilityFactorMin}
                                            max={variabilityFactorMax}
                                            onChange={(value) => {
                                                setRuleData({
                                                    ...ruleData,
                                                    variabilityFactor: round(
                                                        parseFloat(value),
                                                        1
                                                    ),
                                                });
                                            }}
                                            initialValue={ruleData.variabilityFactor}
                                            rulesDisabled={rulesDisabled}
                                        />
                                    </Grid.Column>
                                    <Grid.Column textAlign={"center"} width={16}>
                                        <Input
                                            className="lead-time-controls"
                                            value={getVariabilityTag(
                                                ruleData.variabilityFactor
                                            )}
                                            action
                                            disabled={rulesDisabled}
                                        >
                                            <Button
                                                disabled={rulesDisabled}
                                                positive
                                                icon="minus"
                                                size="mini"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    decreaseVariabilityFactor();
                                                }}
                                            />
                                            <input type="text" />
                                            <Button
                                                disabled={rulesDisabled}
                                                positive
                                                icon="plus"
                                                size="mini"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    incrementVariabilityFactor();
                                                }}
                                            />
                                        </Input>
                                    </Grid.Column>
                                </Grid>
                            </Form.Field>
                        </Form.Group>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column>
                        {/* <Divider /> */}
                        <div className={"action-buttons"}>
                            <Button disabled={rulesDisabled} onClick={onCancel}>{t("cancel")}</Button>
                            <Button disabled={rulesDisabled} positive className="submit-group" type="submit">
                                {t("save")}
                            </Button>
                        </div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>
    );
};

RulesSettings.propTypes = {
    selectedGroup: PropTypes.object,
    columns: PropTypes.object,
    items: PropTypes.array,
    ruleData: PropTypes.object,
    setRuleData: PropTypes.func,
    onCancel: PropTypes.func,
    rulesDisabled: PropTypes.bool,
    materialMethodOptions: PropTypes.array,
    groupNames: PropTypes.arrayOf(PropTypes.string),
    loading: PropTypes.bool,
    groups: PropTypes.array,
};

RulesSettings.defaultProps = {
    selectedGroup: null,
    columns: [],
    items: [],
    groupNames: [],
    onSave: _.noop,
    onCancel: _.noop,
    loading: false,
    rulesDisabled: false,
    groups: [],
};

export default RulesSettings;