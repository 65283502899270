import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import _ from "lodash";

import { Button, Input, Dropdown } from "semantic-ui-react";

import { debounce } from "throttle-debounce";

import "./PageSelector.css";

import { useTranslation } from "react-i18next";

const PageSelector = ({
  pageIndex,
  pageSize,
  canPreviousPage,
  canNextPage,
  pageOptions,
  pageCount,
  gotoPage,
  nextPage,
  previousPage,
  setPageSize,
}) => {
  const { t } = useTranslation();

  const [pageInput, setPageInput] = useState(pageIndex + 1);

  const [debouncedUpdate] = useState(() =>
    debounce(1000, (page) => {
      gotoPage(page);
    })
  );

  const sizes = [10, 20, 30, 40, 50, 100].map((size) => ({
    key: size,
    text: t("ordersTable.show") + " " + size,
    value: size,
  }));

  return (
    <div className="page-selector">
      <Button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
        {"<<"}
      </Button>{" "}
      <Button onClick={previousPage} disabled={!canPreviousPage}>
        {"<"}
      </Button>{" "}
      <Button onClick={nextPage} disabled={!canNextPage}>
        {">"}
      </Button>{" "}
      <Button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
        {">>"}
      </Button>{" "}
      <span className="current-page-indicator">
        <strong>
          {t("ordersTable.page", {
            current: pageIndex + 1,
            total: pageOptions.length,
          })}
        </strong>
      </span>
      {"|"}
      <span className="go-to-page">
        {t("ordersTable.goToPage")}:{" "}
        <Input
          className="page-input"
          type="number"
          value={pageInput}
          onChange={(e) => {
            let page = e.target.value ? Number(e.target.value) - 1 : 0;
            if (page < 0) {
              page = 0;
            }
            setPageInput(page + 1);

            debouncedUpdate(page > pageCount - 1 ? pageCount - 1 : page);
          }}
          style={{ width: "100px" }}
        />
      </span>{" "}
      <Dropdown
        value={pageSize}
        onChange={(e, data) => {
          setPageSize(Number(data.value));
        }}
        selection
        options={sizes}
      ></Dropdown>
    </div>
  );
};

PageSelector.propTypes = {
  pageIndex: PropTypes.number,
  pageSize: PropTypes.number,
  canPreviousPage: PropTypes.bool,
  canNextPage: PropTypes.bool,
  pageOptions: PropTypes.array,
  pageCount: PropTypes.number,
  gotoPage: PropTypes.func,
  nextPage: PropTypes.func,
  previousPage: PropTypes.func,
  setPageSize: PropTypes.func,
};

PageSelector.defaultProps = {
  pageIndex: 0,
  pageSize: 0,
  canPreviousPage: false,
  canNextPage: false,
  pageOptions: [],
  pageCount: 0,
  gotoPage: _.noop,
  nextPage: _.noop,
  previousPage: _.noop,
  setPageSize: _.noop,
};

const mapStateToProps = (state) => {
  return {
    pageIndex: state.items.pageIndex,
    pageSize: state.items.pageSize,
  };
};

export default connect(mapStateToProps)(PageSelector);
