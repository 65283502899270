import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link, useLocation, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import { useTranslation } from "react-i18next";
import { changeInputType } from "../../actions/auth";

import {
  Loader,
  Button,
  Form,
  Grid,
  Header,
  Image,
  Segment,
  Label
} from "semantic-ui-react";

import { login } from "../../actions/auth";

import { 
  ORDER_SCREEN, 
  REGISTER, 
  ACCOUNT_RECOVERY 
  } from "../Navbar/routes";

import Error from "../Error/Error";

import "./Login.css";
import paqtanaLogo from "../../img/Paqtana-Portrait-Logo.svg";

export const Login = ({
  login,
  isAuthenticated,
  loading,
  inputType,
  changeInputType,
}) => {
  const { t } = useTranslation();
  const location = useLocation();

  const { from } = location.state || { from: { pathname: ORDER_SCREEN } };

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const { email, password } = formData;

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      onSubmit(e);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    login(formData.email, formData.password);
  };

  if (loading) {
    return <Loader active size="large" />;
  }

  if (isAuthenticated) {
    return <Redirect to={from || ORDER_SCREEN} />;
  }

  return (
    <Grid
      textAlign="center"
      className="login-container"
    >
      <Grid.Column className="login-column">
        <Grid.Row>
          <Image src={paqtanaLogo} size='small' centered/>
          <Header as="h2" textAlign="center" className="login-message">
            {t("account.logInMessage")}
          </Header>

          <Form size="huge" onSubmit={onSubmit} onKeyPress={handleKeyPress}>
            <Segment stacked>
              <Form.Input
                type="email"
                name="email"
                fluid
                required
                icon="mail"
                iconPosition="left"
                placeholder={t("account.emailAddress")}
                value={email}
                onChange={(e) => onChange(e)}
              />
              <Form.Input
                fluid
                required
                icon="lock"
                iconPosition="left"
                type={inputType.type}
                name="password"
                action={<Button 
                          icon={inputType.iconName} 
                          basic 
                          className="actionEyeButton" 
                          onClick={(e)=>{changeInputType(inputType); 
                                          e.preventDefault()}}/>}
                placeholder={t("account.password")}
                value={password}
                onChange={(e) => onChange(e)}
              />

              <Error />

              <Button primary fluid size="large" type="submit">
                {t("account.logIn")}
              </Button>
            </Segment>

          </Form>
          
          <Link to={REGISTER}>
            <Button fluid size="large" className="register-button">
              {t("account.register")}
            </Button>
          </Link>

          <Label className="forget-password">
              <Link to={ACCOUNT_RECOVERY}>
                {t("account.forgetPassword")}
              </Link>
          </Label>
            
        </Grid.Row>
      </Grid.Column>
    </Grid>
  );
};

Login.propTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  inputType: PropTypes.object,
  changeInputType: PropTypes.func
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.auth.loading,
  inputType: state.auth.inputType
});

export default connect(mapStateToProps, { login, changeInputType })(Login);
