import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useTable } from 'react-table'
import PropTypes from 'prop-types'
import Table from '../../../../../DataImport/Table/Table'
import { mapColumnHeaderToReactTableFormat } from '../../../../../../utils/mappers/analytics'
import ModifyActionCell from './ModifyActionCell/ModifyActionCell'

const GroupSettingTable = ({groupSetting, materialName})=> {
    const { t } = useTranslation()
    const translateMapper = useMemo(()=>
                                  {
                                    return({
                                        //Start Date is unnecessary for the group setting table, so it is removed from the mapper
                                        // adu_measurement_start: t("simulation.aduMeasuramentLength"),
                                        material_name: t("simulation.materialTitle"),
                                        adu_measurement_end: t("simulation.aduMeasuramentLength"),
                                        lead_time_factor: t("detailsPanel.numericalDetails.bufferParameters.lead_time_factor"),
                                        variability_factor: t("detailsPanel.numericalDetails.bufferParameters.variability_factor"),
                                    })
                                  }, [t])

    const columns = useMemo(()=>{
        if(Object.keys(groupSetting).length){
            //TODO: check if is neccessary to remove adu_measurement_start from the mapper
            const newGroupSettingOrder = {adu_measurement_end: "", lead_time_factor: "", variability_factor: ""}
            const columns = mapColumnHeaderToReactTableFormat(newGroupSettingOrder, translateMapper, "adu_measurement_start")
            const nameHeader = [{Header: translateMapper.material_name, accessor: "material_name",}]
            return nameHeader.concat(columns)
        }
        return []
    }, [groupSetting, translateMapper])

    const data = useMemo(()=>{
        if(Object.keys(groupSetting).length){
            const { adu_measurement_end, lead_time_factor, variability_factor } = groupSetting
            return [{material_name:materialName, 
                    adu_measurement_end, 
                    lead_time_factor, 
                    variability_factor,
                    modify: <ModifyActionCell
                                data={groupSetting}
                                type="GroupSettingsTable"
                                />
                }]
        }
        return []
    }, [groupSetting, materialName])

    //It's impossible to have different names of colums and data because of the way react-table works
    const groupSettingTable = useTable({columns, data})

    return(
        <Table instance={groupSettingTable}/>
    )
}

GroupSettingTable.propTypes = {
    groupSetting: PropTypes.object,
    materialName: PropTypes.string
}

export default GroupSettingTable;