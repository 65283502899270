import React from "react";
import _ from "lodash";

import axiosWrapper from "../utils/axiosWrapper";
import axios from "axios";

import {
  AUTH_ERROR,
  ADMIN_CONSOLE_ACCESS_GRANTED,
  GET_ORGANIZATION,
  UPDATE_ORGANIZATION,
  EDIT_WORKSPACE,
  DELETE_WORKSPACE,
  SET_WORKSPACE_TO_DELETE,
  SESSION_REFRESH_REQUIRED,
  GET_ROLES,
  ADD_ROLES,
  REMOVE_ROLES,
  DELETE_USER,
  INVITE_LINK_GENERATED,
  BEGIN_ORGANIZATION_FETCH,
  ABORT_ORGANIZATION_FETCH,
  BEGIN_INVITE_LINK_FETCH,
  ABORT_INVITE_LINK_FETCH,
  BEGIN_WORKSPACE_REQUEST,
  ABORT_WORKSPACE_REQUEST,
  CREATE_AUTO_UPLOAD_CONFIG_SUCCESS,
  CREATE_AUTO_UPLOAD_CONFIG_FAIL,
  CREATE_AUTO_UPLOAD_CONFIG,  
  GET_BUCKET_NAME,
  GET_NEW_KEY_SUCCESS,
  GET_NEW_KEY_FAIL,
  GET_NEW_KEY,
  FLUSH_KEY,
  CREATING_NEW_INT_API_TOKEN, 
  CREATING_NEW_INT_API_TOKEN_SUCCESS,  
  CREATING_NEW_INT_API_TOKEN_FAIL,
  INT_API_TOKENS_INFO,
  GET_ADVANCED_SETTINGS,
} from "./types";

import { toast } from "react-toastify";

import ToastI18nWrapper from "../components/ToastI18nWrapper/ToastI18nWrapper";

import { getWorkspaces, setCurrentWorkspace } from "./workspace";

export const checkAdminConsoleAccess = () => async (dispatch) => {
  try {
    await axiosWrapper.get("/admin");
    dispatch({ type: ADMIN_CONSOLE_ACCESS_GRANTED });
  } catch (error) {
    dispatch({ type: AUTH_ERROR });
    if (!_.isNil(error.response)) {
      if (error.response.status === 403 &&
        !_.isEmpty(error.response.data) &&
        error.response.data[0].message &&
        error.response.data[0].message === "missing_permissions"
      ) {
        toast.error(
          <ToastI18nWrapper
            translateKey={"backendMessages.missing_permissions"}
          />,
          {
            toastId: "missing_permissions",
          }
        );
      }
    } else {
      toast.error(<ToastI18nWrapper translateKey={"genericError"} />, {
        toastId: "genericError",
      });
    }
  }
};

export const getOrganization = () => async (dispatch) => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  try {
    dispatch({ type: BEGIN_ORGANIZATION_FETCH });
    const response = await axiosWrapper.get("/organization", {
      cancelToken: source.token,
    });
    dispatch({ type: GET_ORGANIZATION, payload: response.data });
  } catch (error) {
    dispatch({ type: ABORT_ORGANIZATION_FETCH });

    if (!_.isNil(error.response)) {
      if (error.response.status === 401) {
        dispatch({ type: SESSION_REFRESH_REQUIRED });
        dispatch({ type: AUTH_ERROR });
      } else if (error.response.status === 403) {
        dispatch({ type: AUTH_ERROR });
      }
    } else {
      toast.error(<ToastI18nWrapper translateKey={"genericError"} />, {
        toastId: "genericError",
      });
    }
  }
  return () => {
    source.cancel();
  };
};

export const createWorkspace = (workspace, fillWithDemoData=false) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = JSON.stringify(workspace);
  try {
    dispatch({ type: BEGIN_WORKSPACE_REQUEST });
    const response = await axiosWrapper.post("/workspaces", body, config);
    await dispatch(getWorkspaces());
    if (fillWithDemoData) {
      const body2 = JSON.stringify({workspace_id: response.data.id});
      await axiosWrapper.post("/demo-data", body2, config);
    }
    return dispatch(setCurrentWorkspace(response.data.id));
  } catch (error) {
    dispatch({ type: ABORT_WORKSPACE_REQUEST });
    toast.error(<ToastI18nWrapper translateKey={"genericError"} />, {
      toastId: "genericError",
    });
  }
};

export const editWorkspace = (workspace) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = JSON.stringify(workspace);
  try {
    dispatch({ type: BEGIN_WORKSPACE_REQUEST });
    const response = await axiosWrapper.put("/workspaces", body, config);
    dispatch({ type: EDIT_WORKSPACE, payload: response.data });
  } catch (error) {
    dispatch({ type: ABORT_WORKSPACE_REQUEST });
    toast.error(<ToastI18nWrapper translateKey={"genericError"} />, {
      toastId: "genericError",
    });
  }
};

export const deleteWorkspace = (id) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify({ id: id }),
  };
  try {
    // double check if session expired or permission was revoked while modal was open
    dispatch({ type: BEGIN_WORKSPACE_REQUEST });
    await dispatch(checkDeleteWorkspacePermission());
    const response = await axiosWrapper.delete("/workspaces", config);
    dispatch({ type: DELETE_WORKSPACE, payload: response.data });
  } catch (error) {
    dispatch({ type: ABORT_WORKSPACE_REQUEST });
    if (!_.isNil(error.response)) {
      if (error.response.status === 401) {
        dispatch({ type: SESSION_REFRESH_REQUIRED });
        dispatch({ type: AUTH_ERROR });
      }
    } else {
      toast.error(<ToastI18nWrapper translateKey={"genericError"} />, {
        toastId: "genericError",
      });
    }
  }
};

export const checkDeleteWorkspacePermission = () => async (dispatch) => {
  try {
    await axiosWrapper.get("/workspaces/delete-permission");
    dispatch({ type: DELETE_WORKSPACE });
    dispatch(getWorkspaces());
  } catch (error) {
    if (!_.isNil(error.response)) {
      if (error.response.status === 401) {
        dispatch({ type: SESSION_REFRESH_REQUIRED });
        dispatch({ type: AUTH_ERROR });
      }
    } else {
      toast.error(<ToastI18nWrapper translateKey={"genericError"} />, {
        toastId: "genericError",
      });
    }
  }
};

export const setWorkspaceToDelete = (workspace) => async (dispatch) => {
  dispatch({ type: SET_WORKSPACE_TO_DELETE, payload: workspace });
};

export const getRoles = () => async (dispatch) => {
  try {
    const response = await axiosWrapper.get("/roles");
    dispatch({ type: GET_ROLES, payload: response.data });
  } catch (error) {
    if (!_.isNil(error.response)) {
      if (error.response.status === 401) {
        dispatch({ type: SESSION_REFRESH_REQUIRED });
        dispatch({ type: AUTH_ERROR });
      }
    } else {
      toast.error(<ToastI18nWrapper translateKey={"genericError"} />, {
        toastId: "genericError",
      });
    }
  }
};

export const addRolesToUser = (userId, assignments) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({
    user_id: userId,
    role_assignment_list: assignments,
  });

  try {
    const response = await axiosWrapper.post("/user-roles", body, config);
    dispatch(getOrganization());
    dispatch({ type: ADD_ROLES, payload: response.data });
  } catch (error) {
    if (!_.isNil(error.response)) {
      if (error.response.status === 401) {
        dispatch({ type: SESSION_REFRESH_REQUIRED });
        dispatch({ type: AUTH_ERROR });
      }
    } else {
      toast.error(<ToastI18nWrapper translateKey={"genericError"} />, {
        toastId: "genericError",
      });
    }
  }
};

export const removeRolesFromUser = (userId, assignments) => async (
  dispatch
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify({
      user_id: userId,
      role_assignment_list: assignments,
    }),
  };

  try {
    const response = await axiosWrapper.delete("/user-roles", config);
    dispatch(getOrganization());
    dispatch({ type: REMOVE_ROLES, payload: response.data });
  } catch (error) {
    if (!_.isNil(error.response)) {
      if (error.response.status === 401) {
        dispatch({ type: SESSION_REFRESH_REQUIRED });
        dispatch({ type: AUTH_ERROR });
      }
    } else {
      toast.error(<ToastI18nWrapper translateKey={"genericError"} />, {
        toastId: "genericError",
      });
    }
  }
};

export const setOpenCalendars = (open_calendars) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = JSON.stringify({
    open_calendars: open_calendars,
  });

  try {
    const response = await axiosWrapper.put("/organization", body, config);
    dispatch({ type: UPDATE_ORGANIZATION, payload: response.data });
  } catch (error) {
    if (!_.isNil(error.response)) {
      if (error.response.status === 401) {
        dispatch({ type: SESSION_REFRESH_REQUIRED });
        dispatch({ type: AUTH_ERROR });
      }
    } else {
      toast.error(<ToastI18nWrapper translateKey={"genericError"} />, {
        toastId: "genericError",
      });
    }
  }
};

export const deleteUser = (id) => async (dispatch) => {
  try {
    // double check if session expired or permission was revoked while modal was open ?
    const response = await axiosWrapper.delete(`/user/${id}`);
    dispatch({ type: DELETE_USER, payload: response.data });
    dispatch(getOrganization());
  } catch (error) {
    if (!_.isNil(error.response)) {
      if (error.response.status === 401) {
        dispatch({ type: SESSION_REFRESH_REQUIRED });
        dispatch({ type: AUTH_ERROR });
      }
    } else {
      toast.error(<ToastI18nWrapper translateKey={"genericError"} />, {
        toastId: "genericError",
      });
    }
  }
};

export const getInviteLink = () => async (dispatch) => {
  try {
    dispatch({ type: BEGIN_INVITE_LINK_FETCH });
    // double check if session expired or permission was revoked while modal was open ?
    const response = await axiosWrapper.get("/organization/invite-link");
    dispatch({
      type: INVITE_LINK_GENERATED,
      payload: response.data.invite_parameters,
    });
  } catch (error) {
    dispatch({ type: ABORT_INVITE_LINK_FETCH });

    if (!_.isNil(error.response)) {
      if (error.response.status === 401) {
        dispatch({ type: SESSION_REFRESH_REQUIRED });
        dispatch({ type: AUTH_ERROR });
      } else if (error.response.status === 412) {
        toast.error(
          <ToastI18nWrapper
            translateKey={"account.adminConsole.userLimitReached"}
          />
        );
      }
    } else {
      toast.error(<ToastI18nWrapper translateKey={"genericError"} />, {
        toastId: "genericError",
      });
    }
  }
};

export const generateAutoUploadConfig = (
  currentWorkspaceId
  
) => async (dispatch) => {

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = JSON.stringify({
      current_workspace_id: currentWorkspaceId      
    });
    dispatch({
      type: CREATE_AUTO_UPLOAD_CONFIG,
    })

    try{
      const autoUploadConfigResponse = await axiosWrapper.post(
          "/auto-upload-config",
          body,
          config); 

      dispatch({      
          type: CREATE_AUTO_UPLOAD_CONFIG_SUCCESS,
          payload: {
            json_key: autoUploadConfigResponse.data.service_account_key,
            key_name: autoUploadConfigResponse.data.key_name,                      
          },
        });
      
        await dispatch(getWorkspaces());

    } 
    catch (error) {
      dispatch({
        type: CREATE_AUTO_UPLOAD_CONFIG_FAIL,
      });

    }    
  }

export const getBucketName = (  
  
) => async (dispatch) => {

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
        
    const bucketInfoResponse = await axiosWrapper.get(
        "/bucket-info",        
        config);
      
    dispatch({
      type: GET_BUCKET_NAME,
      payload: {
        bucket_name: bucketInfoResponse.data.bucket_name,        
      },
    });    
  }

export const generateNewJsonKey = (  

  ) => async (dispatch) => {
  
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      dispatch({
        type: GET_NEW_KEY
      })
      try {
        const autoUploadNewKeyResponse = await axiosWrapper.post(
            "/auto-upload-new-key",          
            config);          
        
        dispatch({
            type: GET_NEW_KEY_SUCCESS,
            payload: {
              json_key: autoUploadNewKeyResponse.data.new_jsonkey,
              key_name: autoUploadNewKeyResponse.data.key_name,        
            },
          });
      }
      catch (error){
        dispatch({
          type: GET_NEW_KEY_FAIL
        })
      }            
    }

  export const flushKey = (

  ) => async (dispatch) => {
    dispatch({
      type: FLUSH_KEY,
    })
  }

  export const updateWorkspaceToAutoConfig = (
    
    currentWorkspaceId
      
    ) => async () => {

        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        const body = JSON.stringify({
          current_workspace_id: currentWorkspaceId      
        });

        try{
          await axiosWrapper.put(
            "/auto-upload-config",
            body,
            config);          
        }
        catch(error){
          // console.log(error)

        }       
    }
  
export const createIntAPIToken = (
    
    ) => async (dispatch) => {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        dispatch(
          {type: CREATING_NEW_INT_API_TOKEN, 
            })
        try{
          const intAPIToken = await axiosWrapper.post(
            "/integration-api-token",
            config);
            dispatch(
              {type: CREATING_NEW_INT_API_TOKEN_SUCCESS, 
                payload: {newToken: intAPIToken.data.new_token}})
        }
        catch{
          dispatch(
            {type: CREATING_NEW_INT_API_TOKEN_FAIL, 
              })

        }
    }
  export const getIntAPITokensInfo = (
          
      ) => async (dispatch) => {
  
          const config = {
            headers: {
              "Content-Type": "application/json",
            },
          };
          const intAPITokensInfo = await axiosWrapper.get(
              "/integration-api-token",
              config);
          
              dispatch(
            {type: INT_API_TOKENS_INFO,
              payload: {tokenInfo: intAPITokensInfo.data.organization_tokens} 
              })
      }

  export const deactivateAPIToken = (tokenId
              
      ) => async (dispatch) => {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        const body = JSON.stringify({id: tokenId})
        await axiosWrapper.put(
          "/integration-api-token",
          body,
          config);
        try{
          const intAPITokensInfo = await axiosWrapper.get(
            "/integration-api-token",
            config);
        
            dispatch(
          {type: INT_API_TOKENS_INFO,
            payload: {tokenInfo: intAPITokensInfo.data.organization_tokens} 
            })
        }
        catch{
          dispatch(
            {type: INT_API_TOKENS_INFO,
              payload: {tokenInfo: []} 
              })
        }
    }
    
  export const getAdvancedSettings = (
          
      ) => async (dispatch) => {
  
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        const advancedSettings = await axiosWrapper.get(
            "/advanced-settings",
            config);
        if(advancedSettings.status === 200){
          dispatch(
            {type: GET_ADVANCED_SETTINGS,
              payload: {advancedSettings: advancedSettings.data} 
              })
        }
        
      }

  export const updateAdvancedSettings = (newAdvancedSettings

    ) => async (dispatch) => {
    
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const body = JSON.stringify({
        advanced_settings: newAdvancedSettings      
      });
      const advancedSettings =  await axiosWrapper.put(
          
          "/advanced-settings",
          body,
          config);
      if(advancedSettings.status === 200){
        await dispatch(getAdvancedSettings())
      }
      toast.success(
        <ToastI18nWrapper translateKey={"account.adminConsole.advancedSettingsUpdated"} />,
        { timeout: 1000 }
      );
    }
  
  
  