import React, { useMemo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import {
  getUploadHistory,
  getFileTypeLastUpdated,
  uploadFile,
} from "../../actions/files";

import { Loader, Grid, Segment, Modal, Button } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useTable } from "react-table";
import { fileAccessors } from "../../utils/constants";
import { mapAccessorsToColumns, isProcessingFileData, checkMasterInLogEntries } from "../../utils/mappers/dataImport";
import { mapFileDataToStatus } from "../../utils/mappers/fileStatus";
import { getSafeSelectedWorkspace } from "../../utils/mappers/user";
import { resolveEditPermission, getWorkspaceTimezone } from "../../utils/mappers/workspace";
import { isFileValid, isFileTooLarge } from "../../utils/files";
import { warmupHighMemService, warmupHighMemParsingService } from "../../actions/warmup";
import Table from "./Table/Table";
import UploadErrorsModal from "./UploadErrorsModal/UploadErrorsModal";
import DownloadTemplatesModal from "./DownloadTemplatesModal/DownloadTemplatesModal";
import UploadButtonContainer from "./UploadButtonContainer";

import {
  checkProcessingTimeouts,
  FILE_PROCESSING_TIMEOUT_MS,
} from "../../actions/files";

import "./DataImport.css";

export const DataImport = ({
  currentWorkspaceId,
  canEditCurrentWorkspace,
  dataImportStatus,
  logEntries,
  uploadFile,
  loadingHistory,
  loadingFileTypeInfo,
  hasMasterData,
  checkProcessingTimeouts,
  warmupHighMemParsingService,
  warmupHighMemService,
  workspaceTimezone,
  isProcessingMasterData,
  isProcessingHistoricalDemandData,
  isProcessingInventoryData
}) => {

  const { t, i18n } = useTranslation();
  const [showFileErrorModal, setShowFileErrorModal] = useState(false);
  const [fileError, setFileError] = useState({});

  useEffect(() => {
    warmupHighMemParsingService();
    warmupHighMemService();
  }, [warmupHighMemParsingService, warmupHighMemService]);

  useEffect(() => {
    const timer = setInterval(() => {
      checkProcessingTimeouts();
    }, FILE_PROCESSING_TIMEOUT_MS);
    return () => clearInterval(timer);
  }, [logEntries, checkProcessingTimeouts]);


  const columns = useMemo(
    () =>
      mapAccessorsToColumns(
        t,
        Object.keys(fileAccessors),
        (filename, errors) => {
          if (!_.isEmpty(errors)) {
            return (
              <div className="view-errors">
                <UploadErrorsModal filename={filename} errors={errors} />
              </div>
            );
          }
          return "";
        },
        workspaceTimezone
      ),
    [t, workspaceTimezone]
  );

  const instance = useTable({
    debug: true,
    columns,
    data: logEntries,
  });

  const onFileAdded = (e, type) => {
    const file = extractFile(e.target.files);

    if (_.isNil(file)) {
      return;
    }

    if (!isFileValid(file)) {
      setShowFileErrorModal(true);
      setFileError({
        header: t("dataImport.fileNotValid.header"),
        content: t("dataImport.fileNotValid.content"),
      });
    } else if (isFileTooLarge(file)) {
      setShowFileErrorModal(true);
      setFileError({
        header: t("dataImport.fileTooLarge.header"),
        content: t("dataImport.fileTooLarge.content"),
      });
    } else {
      sendUploadRequest(file, type);
    }
  };

  const extractFile = (fileList) => {
    return fileList.item(0);
  };

  const sendUploadRequest = (file, type) => {
    const formData = new FormData();
    formData.append("file", file, file.name);
    formData.append("type", type);
    uploadFile(currentWorkspaceId, formData, i18n.language);
  };

  if (!currentWorkspaceId) {
    return (
      <div className="ui container container-space">
        <Segment placeholder style={{ minHeight: "350px" }}>
          <h2>{t("workspaceRequired")}</h2>
        </Segment>
      </div>
    );
  }
  return (
    <Grid stackable className="data-import relaxed">
      <Modal
        open={showFileErrorModal}
        header={fileError.header}
        content={fileError.content}
        actions={["OK"]}
        onClose={() => setShowFileErrorModal(false)}
      />
      <Grid.Column width={6}>
        <Segment className="upload-buttons">
          <UploadButtonContainer
            dataImportStatus={dataImportStatus}
            loadingFileTypeInfo={loadingFileTypeInfo}
            workspaceTimezone={workspaceTimezone}
            hasMasterData={hasMasterData}
            canEditCurrentWorkspace={canEditCurrentWorkspace}
            isProcessingMasterData={isProcessingMasterData}
            isProcessingHistoricalDemandData={isProcessingHistoricalDemandData}
            isProcessingInventoryData={isProcessingInventoryData}
            onFileAdded={(e, key) => {
              onFileAdded(e, key);
            }}/>
          <Grid className="bottom-buttons" stackable>
            <Grid.Row>
              {/*<HelpModal />*/}
              <Segment basic floated="right">
                <Button
                  as="a"
                  href={
                    // eslint-disable-next-line no-undef
                    `${process.env.PUBLIC_URL}/data_import_help/${
                    i18n.language
                    }/${t("dataImport.helpFilename")}.xlsx`
                  }
                  download
                >
                  {t("dataImport.help")}
                </Button>

                <DownloadTemplatesModal />
              </Segment>
            </Grid.Row>
          </Grid>
        </Segment>
      </Grid.Column>
      {loadingHistory ? (
        <Grid.Column width={10}>
          <Loader active size="large" />
        </Grid.Column>
      ) : _.isEmpty(logEntries) ? (
        <Grid.Column width={10}>
          <Segment placeholder>
            <h3>{t("dataImport.emptyUploadLog")}</h3>
          </Segment>
        </Grid.Column>
      ) : (
        <Grid.Column width={10} className="upload-log-column">
          <Table instance={instance} />
        </Grid.Column>
      )}
    </Grid>
  );
};

DataImport.propTypes = {
  currentWorkspaceId: PropTypes.number,
  canEditCurrentWorkspace: PropTypes.bool,
  dataImportStatus: PropTypes.object,
  logEntries: PropTypes.arrayOf(PropTypes.object),
  getUploadHistory: PropTypes.func,
  getFileTypeLastUpdated: PropTypes.func,
  uploadFile: PropTypes.func,
  loadingHistory: PropTypes.bool,
  loadingFileTypeInfo: PropTypes.bool,
  hasMasterData: PropTypes.bool,
  checkProcessingTimeouts: PropTypes.func,
  warmupHighMemParsingService: PropTypes.func,
  warmupHighMemService: PropTypes.func,
  workspaceTimezone: PropTypes.string,
  isProcessingMasterData: PropTypes.bool,
  isProcessingHistoricalDemandData: PropTypes.bool,
  isProcessingInventoryData: PropTypes.bool
};

DataImport.defaultProps = {
  currentWorkspaceId: 1,
  canEditCurrentWorkspace: true,
  dataImportStatus: {},
  logEntries: [{}],
  getUploadHistory: _.noop,
  getFileTypeLastUpdated: _.noop,
  uploadFile: _.noop,
  loadingHistory: false,
  loadingFileTypeInfo: false,
  warmupHighMemParsingService: _.noop,
  warmupHighMemService: _.noop,
};

const mapStateToProps = (state) => ({
  currentWorkspaceId: getSafeSelectedWorkspace(state),
  canEditCurrentWorkspace:
    state.auth.isConnected &&
    resolveEditPermission(state.auth.user, getSafeSelectedWorkspace(state)),
  dataImportStatus: mapFileDataToStatus(state.files.fileTypeInfo, state.files.logEntries),
  logEntries: state.files.logEntries,
  loadingHistory: state.files.loadingHistory,
  loadingFileTypeInfo: state.files.loadingFileTypeInfo,
  hasMasterData: checkMasterInLogEntries(state),
  isProcessingMasterData: isProcessingFileData(state, "master"),
  isProcessingHistoricalDemandData: isProcessingFileData(state, "historical_demand"),
  isProcessingInventoryData: isProcessingFileData(state, "inventory"),
  workspaceTimezone: getWorkspaceTimezone(state)
});

export default connect(mapStateToProps, {
  getUploadHistory,
  getFileTypeLastUpdated,
  uploadFile,
  checkProcessingTimeouts,
  warmupHighMemParsingService,
  warmupHighMemService
})(DataImport);
