import React, { useState } from "react";

import PropTypes from "prop-types";

import _ from "lodash";
import { Dropdown, Grid } from "semantic-ui-react";

import { useTranslation } from "react-i18next";

import "./WorkspacesDropdown.css";

const WorkspacesDropdown = ({
  markedWorkspaces,
  workspaces,
  onChange,
  disabled
}) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState({}); //TODO CHECK THIS INIITIAL VALUE

  const updateChecked = (id, value) => {
    setChecked({ ...checked, [id]: value });
  };
  return (
    <Dropdown
      upward={false}
      disabled={disabled}
      text={
        _.isEmpty(workspaces)
          ? t("account.adminConsole.noWorkspaceFoundShort")
          : t("account.adminConsole.selectWorkspace")
      }
      onClick={() => setOpen(true)}
      onBlur={() => {
        setOpen(false);
      }}
      closeOnChange={false}
      open={open}
    >
      {_.isEmpty(workspaces) ? null : (
        <Dropdown.Menu className="user-access-workspaces-dropdown">
          {workspaces.map(workspace => (
            <Dropdown.Item
              key={workspace.id}
              className="user-access-workspaces-dropdown-item"
            >
              <Grid>
                <Grid.Row>
                  <div className="ui item checkbox">
                    <input
                      type="checkbox"
                      name={workspace.name}
                      onClick={e => {
                        //console.log(e.target.checked);
                        updateChecked(workspace.id, e.target.checked);
                        onChange(workspace.id, e.target.checked);
                      }}
                      value={checked[workspace.id]}
                      defaultChecked={_.includes(
                        markedWorkspaces,
                        workspace.id.toString()
                      )}
                    />
                    <label>{workspace.name}</label>
                  </div>
                </Grid.Row>
              </Grid>
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      )}
    </Dropdown>
  );
};

WorkspacesDropdown.propTypes = {
  markedWorkspaces: PropTypes.arrayOf(PropTypes.string),
  workspaces: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
  disabled: PropTypes.bool
};

WorkspacesDropdown.defaultProps = {
  markedWorkspaces: [],
  workspaces: [],
  onChange: _.noop,
  disabled: false
};

export default WorkspacesDropdown;
