import React, { useEffect } from "react";

import _ from "lodash";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Tab, Loader, Dimmer } from "semantic-ui-react";

import {
  checkAdminConsoleAccess,
  getOrganization,
  getAdvancedSettings,
} from "../../actions/adminConsole";

import WorkspaceEditor from "./WorkspaceEditor/WorkspaceEditor";
import UserAccessControl from "./UserAccessControl/UserAccessControl";
import AutoUploadConfig from "./DataImportConfig/AutoUploadConfig";
import IntegrationAPIConfig from "./IntegrationApiConfig/IntegrationAPIConfig";
import AdvancedSettings from "./AdvancedSettings/AdvancedSettings";

import { useTranslation } from "react-i18next";

import "./AdminConsole.css";

export const AdminConsole = ({
  loadingPermission,
  loadingOrganization,
  checkAdminConsoleAccess,
  getOrganization,
  organizationId,
  organizationCode,
  loadingWorkspaceRequest,
  currentUserRoles,
  getAdvancedSettings,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    checkAdminConsoleAccess();
    getOrganization();
    getAdvancedSettings();
  }, [checkAdminConsoleAccess, getOrganization, getAdvancedSettings]);
  const V1Active = _.isNil(window.location.search.substring(1)) ? false
    : window.location.search.substring(1) === 'activate_v1=true';

  const panes = [
    {
      menuItem: t("account.adminConsole.workspaceEditor"),
      // eslint-disable-next-line react/display-name
      render: () => (
        <Tab.Pane className="pane">
          <Dimmer.Dimmable dimmed={loadingWorkspaceRequest}>
            <Dimmer inverted active={loadingWorkspaceRequest}>
              {loadingWorkspaceRequest && (
                <div className="ui container">
                  <Loader active size="large" />
                </div>
              )}
            </Dimmer>
            <WorkspaceEditor organizationCode={organizationCode} />
          </Dimmer.Dimmable>
        </Tab.Pane>
      ),
    },
    {
      menuItem: t("account.adminConsole.userAccess"),
      // eslint-disable-next-line react/display-name
      render: () => (
        <Tab.Pane className="pane">
          <UserAccessControl />
        </Tab.Pane>
      ),
    },
    {
      menuItem: t("account.adminConsole.advancedSettings"),
      // eslint-disable-next-line react/display-name
      render: () => (
        <Tab.Pane className="pane">
          <AdvancedSettings />
        </Tab.Pane>
      ),
    },

    {
      menuItem: t("account.autoUploadConfig.autoUploadConfig"),
      // eslint-disable-next-line react/display-name
      render: () => (
        <Tab.Pane className="pane">
          <AutoUploadConfig />
        </Tab.Pane>
      ),

    },
    {
      menuItem: t("account.adminConsole.integrationAPIConfig"),
      // eslint-disable-next-line react/display-name
      render: () => (
        <Tab.Pane className="pane">
          <IntegrationAPIConfig />
        </Tab.Pane>
      ),
    },
  ];

  if (loadingPermission || loadingOrganization) {
    return <Loader active size="large" />;
  }

  if (_.isNil(currentUserRoles) || !_.has(currentUserRoles, null)) {
    return null;
  }
  const PisaCondition = organizationId === 38
  const filteredPanes = panes.filter(
    tab => !(
      tab.menuItem === t("account.autoUploadConfig.autoUploadConfig") && !((organizationId < 28) || V1Active || PisaCondition)))
  return (
    <div className="admin-console-container">
      <div className="ui container">
        <h1 className="title">{t("account.adminConsole.title")}</h1>
        <Tab className="ui container admin-console-panes" panes={filteredPanes} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loadingPermission: state.adminConsole.loadingPermission,
  loadingOrganization: _.isEmpty(state.adminConsole.organization),
  organizationCode: _.isNil(state.adminConsole.organization) ? null : state.adminConsole.organization.name_slug,
  organizationId: _.isNil(state.adminConsole.organization) ? null : state.adminConsole.organization.id,
  needsRefresh: state.adminConsole.needsRefresh,
  loadingWorkspaceRequest: state.adminConsole.loadingWorkspaceRequest,
  currentUserRoles: _.isNil(state.auth.user) ? null : state.auth.user.roles,
});

AdminConsole.propTypes = {
  checkAdminConsoleAccess: PropTypes.func.isRequired,
  getOrganization: PropTypes.func.isRequired,
  organizationCode: PropTypes.string,
  organizationId: PropTypes.number,
  loadingPermission: PropTypes.bool.isRequired,
  loadingOrganization: PropTypes.bool.isRequired,
  loadingWorkspaceRequest: PropTypes.bool.isRequired,
  currentUserRoles: PropTypes.object,
  getAdvancedSettings: PropTypes.func,
};

export default connect(mapStateToProps, {
  checkAdminConsoleAccess,
  getOrganization,
  getAdvancedSettings,
})(AdminConsole);
