import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import Header from "./Header/Header";
import Row from "./Row/Row";
import ErrorBoundary from "../../ErrorBoundary/ErrorBoundary"
import { connect } from "react-redux";
import { changeOrderTableWidth } from "../../../actions/ordersTable";
import { itemAccessors } from "../../../utils/constants";
import classNames from "classnames";
import "./Table.css";

const parsePseudoNull = (rowValue) => {
  if (rowValue === '-'){
    rowValue = 0;
  }
  return rowValue;
}

const calculateColumnSums = (rows, columns) => {
  const sums = columns.map(column => {
    const isNumericColumn = rows.every(row => !isNaN(parseFloat(parsePseudoNull(row.values[column.id]))));
    const excludedColumns = ['net_flow_priority', 'current_on_hand_alert', 'material_code', 'location', 'description', 'location_code_concatenation'];
    const isExcludedColumn = excludedColumns.includes(column.id);
    if (isNumericColumn && !isExcludedColumn) {
      var sumTotal = rows.reduce((sum, row) => sum + parseFloat(parsePseudoNull(row.values[column.id])), 0);
      sumTotal = sumTotal.toFixed(2);
      return new Intl.NumberFormat("en-US", {minimumFractionDigits:2, maximumFractionDigits:2}).format(sumTotal)
    }
    return null;
  });
  return sums;
};

const updateSubheaderPosition = (refContainer) => {
  const headerRow = refContainer.current.querySelector('.order-table-header');
  const subheaderCells = refContainer.current.querySelectorAll('.subheader th');
  if (headerRow && subheaderCells.length > 0) {
    const headerHeight = headerRow.offsetHeight;
    subheaderCells.forEach(cell => {
      cell.style.top = `${headerHeight}px`;
    });
  }
};

const Table = ({
  getTableProps,
  getTableBodyProps,
  headerGroups,
  filterRows,
  rows,
  allRows,
  prepareRow,
  onSelectRow,
  editable,
  ordersCalculationId,
  selectedRow,
  setFilter,
  changeOrderTableWidth
}) => {

  const refContainer = useRef(null);
  useEffect(() => {
    if (!refContainer.current) return;

    updateSubheaderPosition(refContainer);

    // resize observer help us to know when order table container width has changed
    // this action hep us interact with the horizontal scroll bar in the order table
    //  check doc: https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserver
    const resizeObserver = new ResizeObserver(() => {
      // max scroll left is like maxScrollLeft in firefox but this property does not exist in chrome
      var maxScrollLeft = refContainer.current.scrollWidth - refContainer.current.clientWidth;
      changeOrderTableWidth(maxScrollLeft);
      updateSubheaderPosition(refContainer);
    });
    resizeObserver.observe(refContainer.current);
    return () => resizeObserver.disconnect(); // clean up 
  }, [changeOrderTableWidth, headerGroups, filterRows, rows]);

  const columnSums = calculateColumnSums(allRows, headerGroups[0].headers);

  return (
    
    <div className="container data-table" id="container-data-table" ref={refContainer}>
      <ErrorBoundary>
        <table className="ui  celled table" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <React.Fragment key={headerGroup.id}>
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <Header
                      setFilter={setFilter}
                      tableWideFilteredRows={filterRows}
                      key={column.id}
                      column={column}
                    ></Header>
                  ))}
                </tr>
                <tr className="subheader">
                  {headerGroup.headers.map((column, index) => (
                    <th
                      key={column.id}
                      style={{ textAlign: 'center' }}
                      className={classNames({
                        location: column.id === itemAccessors.location,
                        code: column.id === itemAccessors.code,
                        description: column.id === itemAccessors.description,
                      })}
                    >
                      {columnSums[index] !== null ? columnSums[index] : ''}
                    </th>
                  ))}
                </tr>
              </React.Fragment>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => (
              <Row
                ordersCalculationId={ordersCalculationId}
                key={row.id}
                prepareRow={prepareRow}
                onSelectRow={onSelectRow}
                editable={editable}
                row={row}
                selected={selectedRow && selectedRow.id === row.id}
              />
            ))}
          </tbody>
        </table>
      </ErrorBoundary>
    </div >
  );
};

Table.propTypes = {
  onSelectRow: PropTypes.func,
  editable: PropTypes.bool,
  getTableProps: PropTypes.func,
  getTableBodyProps: PropTypes.func,
  headerGroups: PropTypes.array,
  filterRows: PropTypes.array,
  rows: PropTypes.array,
  allRows: PropTypes.array,
  prepareRow: PropTypes.func,
  ordersCalculationId: PropTypes.number,
  selectedRow: PropTypes.object,
  setFilter: PropTypes.func,
  changeOrderTableWidth: PropTypes.func
};

Table.defaultProps = {
  onSelectRow: _.noop,
  editable: true,
};

// export default Table;
export default connect(null, { changeOrderTableWidth })(Table);
