import _ from "lodash";
import { getClientTimezone } from "../format";

export const mapCurrentUserToWorkspaceOptions = (workspaces, currentUser) => {
  const mapToWorkspaceOption = (workspace) => ({
    key: workspace.id,
    text: workspace.name,
    value: workspace.id,
  });

  if (_.isNil(currentUser) || _.isNil(currentUser.roles)) {
    return [];
  }

  // any permissions in the "null" workspace means this user is admin; so show all workspaces
  if (_.has(currentUser.roles, "null")) {
    return workspaces.map(mapToWorkspaceOption);
  }

  const visibleWorkspaces = Object.keys(currentUser.roles).map((id) =>
    String(id)
  );
  const workspaceOptions = workspaces
    .filter((workspace) => _.includes(visibleWorkspaces, String(workspace.id)))
    .map(mapToWorkspaceOption);

  return workspaceOptions;
};

export const mapUserRolesToEditableWorkspaceIds = (userRoles) => {
  const workspaceIds = Object.keys(userRoles).filter((workspaceId) => {
    const rolesInWorkspace = userRoles[workspaceId];
    const rolesWithEditPermission = rolesInWorkspace.filter((role) => {
      return _.includes(
        role.permissions.map((permission) => permission.codename),
        "edit"
      );
    });
    return !_.isEmpty(rolesWithEditPermission);
  });

  return workspaceIds;
};

export const resolveEditPermission = (user, currentWorkspace) => {
  if (
    _.isNil(currentWorkspace) ||
    _.isNil(user) ||
    _.isNil(user.editableWorkspaces)
  ) {
    return false;
  }

  if (user.isAdmin) {
    return true;
  }

  return _.includes(user.editableWorkspaces, String(currentWorkspace));
};

export const getBatchInventoryModeValue = (workspaces, currentWorkspaceId) => {
  if (_.isNil(currentWorkspaceId) || _.isEmpty(workspaces)) {
    return false;
  }

  const current = _.find(
    workspaces,
    (workspace) => workspace.id === currentWorkspaceId
  );
  return current && current.batch_inventory_mode;
};


export const getWorkspaceTimezone = (state) => {

  const workspaces = state.workspace.workspaces
  const currentWorkspaceId = state.workspace.currentWorkspace

  if (_.isNil(currentWorkspaceId) || _.isEmpty(workspaces)) {
    return getClientTimezone();
  }

  const current = _.find(
    workspaces,
    (workspace) => workspace.id === currentWorkspaceId
  );
  if (_.isNil(current)) {
    return getClientTimezone();
  }
  return current.timezone

};
