import _ from "lodash";
import { GET_DATASET, ABORT_DATASET_FETCH } from "../actions/types";

const initialState = {
  loading: true,
  columns: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_DATASET:
      if (_.isNil(payload)) {
        return { ...initialState, loading: false };
      }
      return {
        ...state,
        columns: _.isNil(payload.columns) ? [] : payload.columns,
        loading: false,
      };

    case ABORT_DATASET_FETCH:
      return {
        ...state,
        loading: false,
        columns: {},
      };
    default:
      return state;
  }
}
