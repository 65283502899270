import _ from "lodash";
import moment from "moment-timezone";
import 'moment/locale/es'
import 'moment/locale/it'

export const abbreviateNumber = (value) => {
  const roundValueDown = Math.floor(value, 1);
  return (value - roundValueDown) >= 0.5 ? roundValueDown + 1 : roundValueDown;  
};

export const shortenNumber = (value, longNumber = 10)=>{
  const stringValue = value.toLocaleString()
  return stringValue.length <= longNumber ? stringValue : stringValue.slice(0, longNumber) + '...'
}

export const toSnakeCase = (str) => {
  return str
    .replace(/([A-Z])([A-Z])/g, "$1_$2")
    .replace(/([a-z])([A-Z])/g, "$1_$2")
    .replace(/[\s_]+/g, "_")
    .replace(/[-_]+/g, "_")
    .toLowerCase();
};

export const dateInputFormat = (date) => {
  if (!_.isObject(date)) {
    return null;
  }

  return moment(date).format("YYYY-MM-DD")
};

export const calendarDateInputFormat = (date) => {
  return _.isNull(date) ? null : moment(date).format('YYYY-MM-DD');
}

export const toSentenceCaseFromSnakeCase = (str) => {
  const words = str.toLowerCase().split("_");

  return words
    .map((word) => {
      const firstChar = word.charAt(0).toUpperCase();

      if (word.length > 1) {
        return firstChar + word.substring(1, word.length);
      }
      return firstChar;
    })
    .join(" ");
};

export const round = (value, precision = 0) => {
  const multiplier = Math.pow(10, precision);
  return Math.round(value * multiplier) / multiplier;
};

export const toExcelNumberFormatWithDecimals = (value, opts={currency: "USD"}) => {
  return new Intl.NumberFormat("en-US", {...opts, minimumFractionDigits:2, maximumFractionDigits:2}).format(value)
}

export const toExcelNumberFormatWithoutDecimals = (value, opts={currency: "USD"}) => {
  return new Intl.NumberFormat("en-US", {...opts, minimumFractionDigits:0, maximumFractionDigits:0}).format(value)
}

export const maskTextToExtractFloat = (text) => {
  return text.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
};

export const maskTextToExtractInt = (text)=>{
  return text.replace(/[^0-9]/g, "").replace(/(\..*)\./g, "$1");
} 

export const toClientTimezone = (
  UTCDatetime,
  format = "HH:MM - DD/MM/YYYY"
) => {
  const clientTimeZone = getClientTimezone();
  return moment.utc(UTCDatetime).tz(clientTimeZone).format(format);
};


export const toWorkspaceTimezone = (
  UTCDatetime,
  workspaceTimezone,
  dateFormat = "HH:MM - DD/MM/YYYY"
) => {

  if (_.isNil(moment.utc(UTCDatetime).tz(workspaceTimezone))) {
    return toClientTimezone(UTCDatetime, dateFormat);
  }
  return moment.utc(UTCDatetime).tz(workspaceTimezone).format(dateFormat);
};

export const getClientTimezone = () => {
  return moment.tz.guess()
}

export const nanToNum = (val) => (_.isNumber(val) ? val : 0);

export const sortAnyTypeAsc = (a, b) => {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
};

export const sortDataImportLogDesc = (a, b) => {
  try {
    const dateA = new Date(a.created_at);
    const dateB = new Date(b.created_at);
    if (dateA < dateB) {
      return 1;
    }
    if (dateA > dateB) {
      return -1;
    }
    return 0;
  }
  catch (error) {
    return 0
  }
};

export const toISOStringWithoutTimezone = (date, isEnd = false) => {
  const dt = isEnd ? moment(date).endOf('day') :
    // always set 12 noon for the start date/time to fall within the calendar day
    moment(date).set({hour: 12, minute: 0, second: 0, millisecond: 0});
  return dt.utc(true).toISOString();
}

export const dateFormatSimulationChart = (date, language)=>{
  moment.locale(language)
  const currentDate = moment(date, 'DD-MM-YYYY')
  return currentDate.format("DD/MMM/Y").replace('.', '')
}