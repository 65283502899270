import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Modal, Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import './GroupsWarning.css';

const GroupsWarning = ({
  isWarningActive,
  closeWarning,
  
}) => {

  const { t } = useTranslation();

  return (
    <Modal
      className='groupWarning'
      open={isWarningActive}>
      <Modal.Header>{t('dataImport.warning')}</Modal.Header>
      <Modal.Content className='ui warning message groupWarning'>{t('groupSettings.groupsWarning')}</Modal.Content>
      <Modal.Actions>
        <Button
          className='groupWarningContinueButton'
          active={true}
          onClick={closeWarning}>
          {t('confirm')}
        </Button>        
      </Modal.Actions>
    </Modal>

  );
};

GroupsWarning.propTypes = {
  isWarningActive: PropTypes.bool,
  closeWarning: PropTypes.func,
  uploadNewMaster: PropTypes.func
};

GroupsWarning.defaultProps = {
  isWarningActive: false,
  closeWarning: _.noop,
  uploadNewMaster: _.noop
};

export default GroupsWarning;
