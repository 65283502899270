import React from "react"
import { Segment, Grid, Button } from "semantic-ui-react"
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"
import { changeToggleOption } from "../../../../actions/analytics"
import PropTypes from "prop-types"
import { WasAggregateSelected, WasCurrencyUnitsSelected } from "../../../../utils/constants"
import "./ToggleSection.css"

const ToggleSection = 
({ analyticsToggleOptions, 
    changeToggleOption, 
    disableAnalyticsContentInteraction 
})=>{

    const { t } = useTranslation()

    const toggleOptionsHandler = (buttonName)=>{
        const isAggregatedSelected = buttonName === t("analytics.aggregated") || 
                                        buttonName === t("analytics.singleItem")
                                                ? WasAggregateSelected
                                                : WasCurrencyUnitsSelected

        const [ toggleKey, value ] = Object
                                        .entries(analyticsToggleOptions)
                                        .filter((obj)=>obj[0]===isAggregatedSelected)[0]

        changeToggleOption({...analyticsToggleOptions, [toggleKey]: !value})
    }

    return(
        <Segment className="side-button">
            <label>{t("analytics.options")}</label>    
            <Grid.Row>
                <Button.Group fluid>
                    <Button 
                        toggle 
                        active={analyticsToggleOptions[WasAggregateSelected]}
                        onClick={(e, data)=>toggleOptionsHandler(data.children)}
                        disabled={disableAnalyticsContentInteraction}>
                        {t("analytics.aggregated")}
                    </Button>
                    <Button 
                        toggle 
                        active={!analyticsToggleOptions[WasAggregateSelected]}
                        onClick={(e, data)=>toggleOptionsHandler(data.children)}
                        disabled={disableAnalyticsContentInteraction}
                        >
                        {t("analytics.singleItem")}
                    </Button>
                </Button.Group>
            </Grid.Row>

            <label className="space-title">{t("simulation.chartType")}</label>
            <Grid.Row>
                <Button.Group fluid>
                    <Button 
                        toggle 
                        active={analyticsToggleOptions[WasCurrencyUnitsSelected]} 
                        onClick={(e, data)=>toggleOptionsHandler(data.children)}
                        disabled={disableAnalyticsContentInteraction}>
                            {t("analytics.currencyButton")}
                    </Button>
                    <Button 
                        toggle 
                        active={!analyticsToggleOptions[WasCurrencyUnitsSelected]} 
                        onClick={(e, data)=>toggleOptionsHandler(data.children)}
                        disabled={disableAnalyticsContentInteraction}>
                            {t("analytics.countButton")}
                    </Button>
                </Button.Group>
            </Grid.Row>
        </Segment>
    )
}

ToggleSection.propTypes = {
    analyticsToggleOptions: PropTypes.object,
    changeToggleOption: PropTypes.func,
    disableAnalyticsContentInteraction: PropTypes.bool
}

const mapStateToProps = (state)=>({
    analyticsToggleOptions: state.analytics.analyticsToggleOptions,
    disableAnalyticsContentInteraction: state.analytics.disableAnalyticsContentInteraction
})

export default connect(mapStateToProps, { changeToggleOption })(ToggleSection)