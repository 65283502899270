import { WasSingleWorkspaceSelected, WasAggregateSelected, WasCurrencyUnitsSelected } from "../../../utils/constants"
import _ from "lodash"
import { calculateCustomMaxYAxis, calculateCustomMinYAxis } from "../../../utils/mappers/analytics";
import { CHART_OPTIONS_GLOBAL, 
        COMBO_CHART_ORDER_COLORS,
         CHART_ONE_ORDER_COLORS, 
         CHART_TWO_ORDER_COLORS } from "../../../utils/constants";
import i18n from "../../../i18n";
import { createZeroArray } from "../../../utils/dataGenerator";
import { SYMBOLS } from "../../../utils/constants";
import { toExcelNumberFormatWithoutDecimals } from "../../../utils/format";

export const ANALYTICS_CHART_OPTIONS =  {
    ...CHART_OPTIONS_GLOBAL,
    noData: {text: i18n.t("simulation.noDataChart"), 
    style: { fontSize: '18px' }},
}

export const SINGLE_SKU_CHART_OPTIONS = {
    ...ANALYTICS_CHART_OPTIONS,
    stroke: { width: 2 },
    markers: { size: .001 }, //markers solve problem of color in points of apex chart
}

const checkIfDataExist = (chartData)=>{
    return _.isEmpty(chartData) ? [] : chartData
}


export const getCurrentAnalyticsTypeChart = (analyticsChartDataFormatObject, analyticsToggleOptions)=>{
    const {analyticsChartData, analyticsByMaterialChartData, analyticsAllWorkspacesChartData} = analyticsChartDataFormatObject
    const isDefaultAnalyticsChart = analyticsToggleOptions[WasAggregateSelected] && analyticsToggleOptions[WasSingleWorkspaceSelected]
    const isAllWorkspacesChart = analyticsToggleOptions[WasAggregateSelected] && !analyticsToggleOptions[WasSingleWorkspaceSelected]
    const isSingleSkuChartOptionOne = !analyticsToggleOptions[WasAggregateSelected] && !analyticsToggleOptions[WasSingleWorkspaceSelected]
    const isSingleSkuChartOptionTwo = !analyticsToggleOptions[WasAggregateSelected] && analyticsToggleOptions[WasSingleWorkspaceSelected]
    if(isDefaultAnalyticsChart){
        return checkIfDataExist(analyticsChartData)
    }

    if(isAllWorkspacesChart){
        return checkIfDataExist(analyticsAllWorkspacesChartData)
    }

    if(isSingleSkuChartOptionOne || isSingleSkuChartOptionTwo){
        return checkIfDataExist(analyticsByMaterialChartData)
    }
}

export const isCurrentChartOneOrChartTwo = (analyticsChartDataFormatObject, analyticsToggleOptions)=>{
    const currentAnalyticsChartData = getCurrentAnalyticsTypeChart(analyticsChartDataFormatObject, analyticsToggleOptions)
    if(_.isEmpty(currentAnalyticsChartData)){
        return []
    }
    if(analyticsToggleOptions[WasCurrencyUnitsSelected]){
        return currentAnalyticsChartData['chartOneData'] || currentAnalyticsChartData['chartOne']
    }
    if(!analyticsToggleOptions[WasCurrencyUnitsSelected]){
        return currentAnalyticsChartData['chartTwoData'] || currentAnalyticsChartData['chartTwo']
    }
}

const convertToMaxYAxisCalculatorFormat = (chartType) => {
    if(!_.isEmpty(chartType)){
        const newChartType = _.cloneDeep(chartType)
        Object
            .keys(newChartType)
            .forEach((key)=>{
                newChartType[key] = newChartType[key].map((arrayPoint)=>(arrayPoint[1]))
            })
    
        return newChartType
    }
}

// nfe = net flow equation
// ohs = on hand stock
// chart type cloud be chart one or chart two
const fullFillNfeOrOhsObject = (chartType)=>{
    if(!_.isEmpty(chartType)){
        const newChartType = _.cloneDeep(chartType)
        const lengthOfData = newChartType["GREEN"].length

        const arrayOfZeros = createZeroArray(lengthOfData)

        const objectHasNFE = Object.prototype.hasOwnProperty.call(newChartType, "NFE")
        const objectHasOHS = Object.prototype.hasOwnProperty.call(newChartType, "OHS")
        if(!objectHasNFE){
            newChartType["NFE"] = arrayOfZeros
        }
        if(!objectHasOHS){
            newChartType["OHS"] = arrayOfZeros
        }
        return newChartType
    }
}

export const getGraphOptionsByChart = ( analyticCharts, analyticsChartDataFormatObject,analyticsToggleOptions)=>{
    const currentAnalyticsChartData = isCurrentChartOneOrChartTwo(analyticCharts, analyticsToggleOptions)
    const { PERCENTAGE, USD } = SYMBOLS

    const maxYAxis = Object
                        .keys(currentAnalyticsChartData)
                        .length 
                        ? calculateCustomMaxYAxis(fullFillNfeOrOhsObject(convertToMaxYAxisCalculatorFormat(currentAnalyticsChartData))) 
                        : 0

    const chartOneAllWorkspacesAndAggregateCurrentWorkspace = (analyticsToggleOptions[WasAggregateSelected] 
                                                                || !analyticsToggleOptions[WasSingleWorkspaceSelected]) && analyticsToggleOptions[WasCurrencyUnitsSelected]
    const chartTwoAllWorkspacesAndAggregateCurrentWorkspace = (analyticsToggleOptions[WasAggregateSelected] 
                                                                || !analyticsToggleOptions[WasSingleWorkspaceSelected]) && !analyticsToggleOptions[WasCurrencyUnitsSelected]
                         


    if(!analyticsToggleOptions[WasAggregateSelected]){
        // single-item chart
        const typeOfValue = analyticsToggleOptions[WasCurrencyUnitsSelected] ? USD : " "
        const currentAnalyticsChartData2 = isCurrentChartOneOrChartTwo(analyticsChartDataFormatObject, analyticsToggleOptions)

        const currentOptions = {...SINGLE_SKU_CHART_OPTIONS,
                                // the first color is set to white, so that the placeholder series for the fix does not render
                                colors: ['#FFFFFF', ...COMBO_CHART_ORDER_COLORS],
                                yaxis: {
                                            min:  calculateCustomMinYAxis(currentAnalyticsChartData2),
                                            max:  maxYAxis,
                                            labels: {formatter: (value) => { return toExcelNumberFormatWithoutDecimals(value) }}
                                        },
                                tooltip: {
                                            ...ANALYTICS_CHART_OPTIONS.tooltip,
                                            y: {
                                                formatter: function(value, { seriesIndex }) {
                                                    return seriesIndex === 0 ? "" : `${typeOfValue}  ${toExcelNumberFormatWithoutDecimals(value)}`
                                                },
                                                title: {
                                                    // prevent displaying the placeholder line "series" in the tooltip
                                                    formatter: (seriesName) => {
                                                        return seriesName === "line" ? "" : seriesName
                                                    },
                                                },
                                            },
                                }
        }

        return currentOptions
    }

    if(chartOneAllWorkspacesAndAggregateCurrentWorkspace){
        const typeOfValue = analyticsToggleOptions[WasCurrencyUnitsSelected] ? USD : " "
        const currentAnalyticsChartData2 = isCurrentChartOneOrChartTwo(analyticsChartDataFormatObject, analyticsToggleOptions)
        const currentOptions = {...SINGLE_SKU_CHART_OPTIONS,
                                colors: CHART_ONE_ORDER_COLORS,
                                yaxis: {
                                            min:  calculateCustomMinYAxis(currentAnalyticsChartData2),
                                            max:  maxYAxis,
                                            labels: {formatter: (value) => { return toExcelNumberFormatWithoutDecimals(value) }}
                                        },
                                tooltip: {
                                            ...SINGLE_SKU_CHART_OPTIONS["tooltip"],
                                            y: { formatter: function(value){ return `${typeOfValue}  ${toExcelNumberFormatWithoutDecimals(value)}`}}
                                }
        }

        return currentOptions
    }

    if(chartTwoAllWorkspacesAndAggregateCurrentWorkspace){
        const currentOptions = {options: ANALYTICS_CHART_OPTIONS,   
            colors: CHART_TWO_ORDER_COLORS,
            yaxis: { min: 0, 
                    max: 100,
                    labels: {formatter: (value) => { return toExcelNumberFormatWithoutDecimals(value) }} 
                },
            tooltip: {
                ...ANALYTICS_CHART_OPTIONS["tooltip"],
                y: { formatter: function(value){return `${toExcelNumberFormatWithoutDecimals(value)} ${PERCENTAGE}`}}
            }
        }
        return currentOptions
    }

}

export const transformComboChartDataWithLineFix = (currentChartData, analyticsToggleOptions) => {
    const isSingleItemChart = !analyticsToggleOptions[WasAggregateSelected];
    if (!isSingleItemChart) {
        // early return here, because we only need to do the transformation for single items
        return currentChartData;
    }

    let zeroData = [];
    for (let i = 0; i < currentChartData[0].data.length; i++) {
        zeroData.push([currentChartData[0].data[i][0], 0]);
    }

    // Similar to simulation, add the white line as the first item to fix the OHS line
    currentChartData.unshift({
        name: 'line',
        type: 'area',
        data: zeroData,
    });

    return currentChartData;
}