import {
  ADMIN_CONSOLE_ACCESS_GRANTED,
  SESSION_REFRESH_REQUIRED,
  GET_ORGANIZATION,
  UPDATE_ORGANIZATION,
  CREATE_WORKSPACE,
  EDIT_WORKSPACE,
  DELETE_WORKSPACE,
  GET_WORKSPACES,
  SET_WORKSPACE_TO_DELETE,
  GET_ROLES,
  DELETE_USER,
  INVITE_LINK_GENERATED,
  BEGIN_ORGANIZATION_FETCH,
  ABORT_ORGANIZATION_FETCH,
  BEGIN_INVITE_LINK_FETCH,
  ABORT_INVITE_LINK_FETCH,
  BEGIN_WORKSPACE_REQUEST,
  ABORT_WORKSPACE_REQUEST,
  CREATE_AUTO_UPLOAD_CONFIG_SUCCESS,
  CREATE_AUTO_UPLOAD_CONFIG_FAIL,
  CREATE_AUTO_UPLOAD_CONFIG,  
  GET_BUCKET_NAME,
  GET_NEW_KEY_SUCCESS,
  GET_NEW_KEY_FAIL,
  GET_NEW_KEY,
  FLUSH_KEY,
  CREATING_NEW_INT_API_TOKEN,
  CREATING_NEW_INT_API_TOKEN_SUCCESS,
  CREATING_NEW_INT_API_TOKEN_FAIL,
  INT_API_TOKENS_INFO,
  GET_ADVANCED_SETTINGS,
} from "../actions/types";

const initialState = {
  loadingPermission: true,
  needsRefresh: true,
  checkingWorkspaceDeleteFreshness: true,
  needsWorkspaceDeleteRefresh: true,
  organization: null,
  workspaceToDelete: null,
  loadingRoles: true,
  inviteParameters: null,
  roles: [],
  loadingOrganization: true,
  loadingInviteLink: true,
  loadingWorkspaceRequest: true,
  configProcessing: false,
  jsonKey: {},
  keyName: null,  
  bucketName: null,
  creatingAPIToken: false,
  apiToken: null,
  tokensInfo: [],
  advancedSettings: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case BEGIN_WORKSPACE_REQUEST:
      return { ...state, loadingWorkspaceRequest: true };
    case ABORT_WORKSPACE_REQUEST:
      return {
        ...state,
        loadingWorkspaceRequest: false,
      };
    case SESSION_REFRESH_REQUIRED:
      return {
        ...state,
        needsRefresh: true,
        needsWorkspaceDeleteRefresh: true,
        loadingPermission: false,
        checkingWorkspaceDeleteFreshness: false,
        loadingOrganization: false,
      };
    case ADMIN_CONSOLE_ACCESS_GRANTED:
      return {
        ...state,
        needsRefresh: false,
        needsWorkspaceDeleteRefresh: false,
        loadingPermission: false,
      };
    case GET_ORGANIZATION:
      return {
        ...state,
        organization: payload,
        needsRefresh: false,
        loadingPermission: false,
        loadingOrganization: false,
      };
    case GET_WORKSPACES:
      return {
        ...state,
        organization: {
          ...state.organization,
          workspaces: payload,
        },
        loadingWorkspaceRequest: false,
        needsRefresh: false,
        loadingPermission: false,
      };
    case CREATE_WORKSPACE:
      return {
        ...state,
        organization: {
          ...state.organization,
          workspaces: [...state.organization.workspaces, payload],
        },
        needsRefresh: false,
        loadingPermission: false,
        loadingWorkspaceRequest: false,
      };
    case EDIT_WORKSPACE:
      return {
        ...state,
        organization: {
          ...state.organization,
          workspaces: state.organization.workspaces.map((workspace) =>
            workspace.id === payload.id ? payload : workspace
          ),
        },
        needsRefresh: false,
        loadingPermission: false,
        loadingWorkspaceRequest: false,
      };
    case SET_WORKSPACE_TO_DELETE:
      return {
        ...state,
        workspaceToDelete: payload,
        checkingWorkspaceDeleteFreshness: true,
      };
    case DELETE_WORKSPACE:
      if (!payload) {
        return {
          ...state,
          needsWorkspaceDeleteRefresh: false,
          checkingWorkspaceDeleteFreshness: false,
        };
      }

      return {
        ...state,
        loadingWorkspaceRequest: false,
        workspaceToDelete: null,
        organization: {
          ...state.organization,
          workspaces: [
            ...state.organization.workspaces.filter(
              (workspace) => workspace.id !== payload.id
            ),
          ],
        },
        checkingWorkspaceDeleteFreshness: false,
      };
    case GET_ROLES:
      return { ...state, loadingRoles: false, roles: payload };
    case UPDATE_ORGANIZATION:
      return {
        ...state,
        organization: payload,
        needsRefresh: false,
        loadingPermission: false,
      };
    case DELETE_USER:
      return {
        ...state,
        organization: {
          ...state.organization,
          users: state.organization.users.filter(
            (user) => user.id !== payload.id
          ),
        },
        needsRefresh: false,
        loadingPermission: false,
      };
    case INVITE_LINK_GENERATED:
      return { ...state, inviteParameters: payload, loadingInviteLink: false };
    case BEGIN_ORGANIZATION_FETCH:
      return { ...state, loadingOrganization: true };
    case ABORT_ORGANIZATION_FETCH:
      return { ...state, loadingOrganization: false };
    case BEGIN_INVITE_LINK_FETCH:
      return { ...state, loadingInviteLink: true };
    case ABORT_INVITE_LINK_FETCH:
      return { ...state, loadingInviteLink: false, inviteParameters: null };
    case CREATE_AUTO_UPLOAD_CONFIG:
      return{...state, configProcessing: true};
    case CREATE_AUTO_UPLOAD_CONFIG_FAIL:
      return{...state};
    case CREATE_AUTO_UPLOAD_CONFIG_SUCCESS:
      return {
        ...state,
        jsonKey: payload.json_key,
        keyName: payload.key_name,
        configProcessing: false,                
      };    
    case GET_BUCKET_NAME:
      return{ ...state, bucketName: payload.bucket_name};
    case GET_NEW_KEY:
      return{ ...state}    
    case GET_NEW_KEY_SUCCESS:
      return{...state, jsonKey: payload.json_key, keyName: payload.key_name,};
    case GET_NEW_KEY_FAIL:
      return{ ...state}
    case FLUSH_KEY:
      return{...state, jsonKey: {}}
    case CREATING_NEW_INT_API_TOKEN:
      return{...state, creatingAPIToken: true}
    case CREATING_NEW_INT_API_TOKEN_SUCCESS:
      return{...state, creatingAPIToken: false, apiToken: payload.newToken}
    case CREATING_NEW_INT_API_TOKEN_FAIL:
      return{...state, creatingAPIToken: false}
    case INT_API_TOKENS_INFO:
      return{...state, tokensInfo: payload.tokenInfo}
    case GET_ADVANCED_SETTINGS:
      return{...state, advancedSettings: payload}
    default:
      return state;
  }
}
