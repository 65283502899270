export const ORDER_SCREEN = "/orders";
export const DATA_IMPORT = "/data-import";
export const BUFFER_SETTINGS = "/buffer-settings";
export const CALENDAR = "/calendar";
export const ANALYTICS = "/analytics";
export const USERS = "/users";
export const LOGIN = "/";
export const REGISTER = "/register-admin";
export const REGISTER_NON_ADMIN = "/register";
export const LOGOUT = "/logout";
export const ADMIN_CONSOLE = "/admin-console";
export const ACCOUNT_RECOVERY = "/user-account-recovery";
export const PASSWORD_RECOVERY = "/user-password-recovery";
