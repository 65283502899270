import React from "react";
import PropTypes from "prop-types";

import _ from "lodash";
import { useTranslation } from "react-i18next";


import {
  Button,
  
} from "semantic-ui-react";
import "./SubGroupButtons.css"

const SubGroupButtons = (
    goBack,
) => {
  const { t } = useTranslation();

    return(
      <div>
            <Button
                className="subgroup-buttons"
                onClick={goBack.goBack}
                icon='angle left'
            />
            <Button
                className="subgroup-buttons-add"
                onClick={goBack.newSubgroup}
                >{t("groupSettings.addNewEventGroup")}</Button>                
        </div>
        
    )
}
SubGroupButtons.propTypes = {
    goBack: PropTypes.func,
    newSubgroup: PropTypes.func,
    
  };
  
SubGroupButtons.defaultProps = {
    goBack: _.noop,
    newSubgroup: _.noop,    
  };
  
export default SubGroupButtons;