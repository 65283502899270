import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Loader, Grid, Segment } from 'semantic-ui-react';
import MasterUploadButton from './UploadButton/MasterUpload';
import InventoryUploadButton from './UploadButton/InventoryUpload';
import HistoricalUploadButton from './UploadButton/HistoricalUpload';
import ImportDates from './UploadTimeInformation/ImportDates';

const UploadButtonContainer = ({
  dataImportStatus,
  loadingFileTypeInfo,
  workspaceTimezone,
  hasMasterData,
  canEditCurrentWorkspace,
  isProcessingMasterData,
  onFileAdded,
  isProcessingHistoricalDemandData,
  isProcessingInventoryData
}) => {
  const { t } = useTranslation();

  const isProcessingSomeData = isProcessingInventoryData || isProcessingHistoricalDemandData || isProcessingMasterData

  const uploadButtonChooser = (fileType) => {
    if (fileType === 'master'){
      return <MasterUploadButton
        fileType={fileType}
        hasMasterData={hasMasterData}
        canEditCurrentWorkspace={canEditCurrentWorkspace}
        isProcessingFileData={isProcessingSomeData}
        onFileAdded={(e) => onFileAdded(e, fileType)}/>;
    }
    if (fileType === 'inventory'){
      return <InventoryUploadButton
        fileType={fileType}
        hasMasterData={hasMasterData}
        canEditCurrentWorkspace={canEditCurrentWorkspace}
        isProcessingFileData={isProcessingSomeData}
        onFileAdded={(e) => onFileAdded(e, fileType)}/>;
    }
    if (fileType === 'historical_demand'){
      return <HistoricalUploadButton
        fileType={fileType}
        hasMasterData={hasMasterData}
        canEditCurrentWorkspace={canEditCurrentWorkspace}
        isProcessingFileData={isProcessingSomeData}
        onFileAdded={(e) => onFileAdded(e, fileType)}/>;
    }
  };
  const uploadTimeInformation = (key) =>{
    if (loadingFileTypeInfo){
      return (<Loader active inline size="mini" />);
    }
    else{
      return(
        <ImportDates
          dataImportStatus={dataImportStatus}
          workspaceTimezone={workspaceTimezone}
          fileType={key}
        />
      );}
  };

  return Object.keys(dataImportStatus).map((key) => {
    return (
      <Segment key={key} className="file-upload">
        <Grid>
          <Grid.Column width={10}>
            {t(dataImportStatus[key].translateKey)}
            <br />
            <span className="secondary">
              {uploadTimeInformation(key)}
            </span>
          </Grid.Column>
          <Grid.Column width={6}>
            {uploadButtonChooser(key)}
          </Grid.Column>
        </Grid>
      </Segment>
    );
  });
};


UploadButtonContainer.propTypes = {
  dataImportStatus: PropTypes.object,
  loadingFileTypeInfo: PropTypes.bool,
  workspaceTimezone: PropTypes.string
};

export default UploadButtonContainer;




