import _ from 'lodash';
import moment from 'moment';

export const mapCalendarEvent = rawEventData => ({
  ...rawEventData,
  title: rawEventData.name,
  allDay: true,
  editable: true
  //backgroundColor: "#8e7fef"
});

export const mapToEventsArray = (
  eventsGroupedByWorkspace,
  currentWorkspaceId
) => {
  const events = [];

  const workspaceKeys = Object.keys(eventsGroupedByWorkspace);

  for (const key of workspaceKeys) {
    events.push(
      ...eventsGroupedByWorkspace[key].map(rawEventData => ({
        ...rawEventData,
        title: rawEventData.name,
        allDay: true,
        editable: key === currentWorkspaceId.toString(),
        backgroundColor:
          key === currentWorkspaceId.toString() ? "#1678c2" : "#6b6b6b"
      }))
    );
  }

  return events;
};

export const mapBufferSettingsGroupsToOptions = groups => {
  return groups.map(group => ({
    key: group.id,
    text: group.name || group.description,
    value: group.id
  }));
};

/**
 * Set the event start date format to be just the date part only, and append the 'T23:59:59' suffix to the
 * end date format.
 *
 * This mapping also explicity overrides the allDay property by setting it to false due to how FullCalendar
 * handles allDay events combined with nextDayThreshold. See https://fullcalendar.io/docs/nextDayThreshold
 * for more info.
 *
 * @param {Array} events list of events
 * @returns the transformed list of events with start, end and allDay properties modified
 */
export const mapCalendarEventsDaysOnly = events => {
  return events.map(event => ({
      ..._.omit(event, ['start', 'end', 'allDay']),
      allDay: false,
      start: moment.utc(event.start).format('YYYY-MM-DD'),
      end: moment.utc(event.end).format('YYYY-MM-DD') + 'T23:59:59'
  }));
}
