import React from "react";
import _ from "lodash";

import axiosWrapper from "../utils/axiosWrapper";

import { toast } from "react-toastify";

import { clear } from "idb-keyval";

import ToastI18nWrapper from "../components/ToastI18nWrapper/ToastI18nWrapper";

import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  AUTH_ERROR,
  USER_LOADED,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  SET_CONNECTION_STATUS,
  SET_PASSWORD_ICON,
  BEGIN_AUTH_RECOVER_PASS_TOKEN,
  END_AUTH_RECOVER_PASS_TOKEN,
  RECOVER_PASS_MESSAGE
} from "./types";

import { setErrors } from "./error";

export const register = ({
  registration_code,
  name,
  email,
  password,
  role,
  organization_name,
  language,
}) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = JSON.stringify({
    registration_code,
    name,
    email,
    password,
    role,
    organization_name,
    language,
  });
  try {
    const res = await axiosWrapper.post("/register", body, config);
    // TODO: show some register success toast..
    dispatch({
      type: REGISTER_SUCCESS,
      payload: res.data,
    });
    dispatch(login(email, password));
  } catch (error) {
    if (!_.isNil(error.response)) {
      const errors = error.response.data;
      if (errors) {
        dispatch(setErrors(errors));
      }
    } else {
      toast.error(<ToastI18nWrapper translateKey={"genericError"} />, {
        toastId: "genericError",
      });
    }
    dispatch({
      type: REGISTER_FAIL,
    });
  }
};

export const registerNonAdmin = ({
  name,
  email,
  password,
  signature,
  organization_id,
  language,
}) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = JSON.stringify({
    name,
    email,
    password,
    signature,
    organization_id,
    language,
  });
  try {
    const res = await axiosWrapper.post("/organization/new-user", body, config);
    // TODO: show some register success toast..
    dispatch({
      type: REGISTER_SUCCESS,
      payload: res.data,
    });
    dispatch(login(email, password));
  } catch (error) {
    if (!_.isNil(error.response)) {
      const errors = error.response.data;
      if (errors) {
        dispatch(setErrors(errors));
      }
    } else {
      toast.error(<ToastI18nWrapper translateKey={"genericError"} />, {
        toastId: "genericError",
      });
    }
    dispatch({
      type: REGISTER_FAIL,
    });
  }
};

export const login = (email, password) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = JSON.stringify({ email, password });

  try {
    const res = await axiosWrapper.post("/login", body, config);
    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data, // {session_id}
    });
    dispatch(loadUser());
  } catch (error) {
    if (!_.isNil(error.response)) {
      const errors = error.response.data;
      if (errors) {
        dispatch(setErrors(errors));
      }
    } else {
      toast.error(<ToastI18nWrapper translateKey={"genericError"} />, {
        toastId: "genericError",
      });
    }

    dispatch({
      type: LOGIN_FAIL,
    });
  }
};

// hits the /user endpoint to get back the user object
export const loadUser = () => async (dispatch) => {
  try {
    const res = await axiosWrapper.get("/user");
    dispatch({ type: USER_LOADED, payload: res.data });
  } catch (error) {
    dispatch({ type: AUTH_ERROR });
    if (!_.isNil(error.response) && error.response.status === 401) {
      return;
    }
    toast.error(<ToastI18nWrapper translateKey={"genericError"} />, {
      toastId: "genericError",
    });
  }
};

export const logout = () => async (dispatch) => {
  try {
    await axiosWrapper.post("/logout");
    await clear().catch(_.noop);

    dispatch({ type: LOGOUT });
  } catch (error) {
    dispatch({ type: AUTH_ERROR });
  }
};

export const setConnectionStatus = (value) => (dispatch) => {
  dispatch({
    type: SET_CONNECTION_STATUS,
    payload: {
      isConnected: value === "connected",
      connectionStatusMessage: value,
    },
  });
};

export const recoverPassword = (password, signature) => async () => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = JSON.stringify({password, signature });

  try {
    const res = await axiosWrapper.post("/user-password-recovery", body, config);

    const message = res.data[0]["message"]

    // TODO: change message to javascript format later
    const keyTranslate = "backendMessages." + message

    if(message === "password_updated_succesfully"){
      toast.success(
        <ToastI18nWrapper translateKey={keyTranslate}/>,
        { timeout: 300 }
      );
    }

    if(message !== "password_updated_succesfully"){
      toast.warn(
        <ToastI18nWrapper 
            translateKey={keyTranslate} 
            />,
        { timeout: 300 }
        );
    }

  } catch (error) {
    toast.error(<ToastI18nWrapper translateKey={"genericError"} />, {
      autoClose: 3000,
      toastId: "genericError"
    })
  }
};

export const changeInputType = (typeInput)=> async(dispatch)=>{
  const { type, iconName } = typeInput
  const newType = type === "password" ? "text" : "password"
  const newIconName = iconName === "eye" ? "eye slash" : "eye"
  dispatch({
    type: SET_PASSWORD_ICON,
    payload: {type: newType, iconName: newIconName}
  })
}

export const accountRecovery = (email, userLanguage) => async()=>{
  try{
    await axiosWrapper.post(`/password-recovery?user_email=${email}&language=${userLanguage}`)
    toast.success(
      <ToastI18nWrapper translateKey={`account.emailWasSent`}/>,
      { timeout: 300 }
    );
  }catch(error){
    toast.error(<ToastI18nWrapper translateKey={"genericError"} />, {
      toastId: "genericError",
    });
  }
}

export const checkJWToken = (token) => async(dispatch)=>{
  try{
    dispatch({
      type: BEGIN_AUTH_RECOVER_PASS_TOKEN
    })
    
    const res = await axiosWrapper.post(`/account-recovery-token-manager?user_token=${token}`)
    
    const message_res = res.data[0]['message']

    dispatch({
      type: RECOVER_PASS_MESSAGE,
      payload: message_res
    })

    dispatch({
      type: END_AUTH_RECOVER_PASS_TOKEN
    })
  }catch(error){
    toast.error(<ToastI18nWrapper translateKey={"genericError"}/>, {
      toastId: "genericError"
    })
  }
}