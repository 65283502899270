import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import { getInviteLink } from "../../../actions/adminConsole";
import { connect } from "react-redux";

import { REGISTER_NON_ADMIN } from "../..//Navbar/routes";

import { Button, Modal, Input, Loader } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

import "./UserInviteModal.css";

const UserInviteModal = ({ inviteParameters, onClose, loadingInviteLink }) => {
  const { t, i18n } = useTranslation();
  const textAreaRef = useRef(null);

  const urlValue = `${window.location.host}${REGISTER_NON_ADMIN}?lang=${i18n.language}&${inviteParameters}`;

  const [copySuccess, setCopySuccess] = useState(false);

  const copyToClipboard = () => {
    textAreaRef.current.select();
    document.execCommand("copy");
    showCopySuccess();
  };

  const showCopySuccess = () => {
    setCopySuccess(true);
    setTimeout(() => {
      setCopySuccess(false);
    }, 3000);
  };

  if (loadingInviteLink) {
    return (
      <Modal basic open>
        <Loader indeterminate>
          {t("account.adminConsole.inviteModalPreparingLink")}
        </Loader>
      </Modal>
    );
  } else if (_.isNil(inviteParameters)) {
    return null;
  }

  return (
    <Modal open>
      <Modal.Header>{t("account.adminConsole.inviteUserHeader")}</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <p>{t("account.adminConsole.inviteUserDescription")}</p>

          <Input
            fluid
            action={{
              color: "teal",
              labelPosition: "right",
              icon: "copy",
              content: t("account.adminConsole.inviteModalCopyAction"),
              onClick: () => copyToClipboard(),
            }}
            value={urlValue}
            ref={textAreaRef}
          ></Input>

          {copySuccess && (
            <p className="link-copy-success">
              {t("account.adminConsole.copySuccess")}
            </p>
          )}
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose}>{t("confirm")}</Button>
      </Modal.Actions>
    </Modal>
  );
};

UserInviteModal.propTypes = {
  inviteParameters: PropTypes.string,
  loadingInviteLink: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
};

UserInviteModal.defaultProps = {
  onClose: _.noop,
};

const mapStateToProps = (state) => ({
  inviteParameters: state.adminConsole.inviteParameters,
  loadingInviteLink: state.adminConsole.loadingInviteLink,
});

export default connect(mapStateToProps, { getInviteLink })(UserInviteModal);
