import React from "react";
import PropTypes from "prop-types";

const FileStatusIndicator = ({ failed, text }) => {
  const divStyle = failed
    ? "failed"
    : null;
  return <span className={divStyle}>{text}</span>;
};

FileStatusIndicator.propTypes = {
  failed: PropTypes.bool,
  text: PropTypes.string
};

FileStatusIndicator.defaultProps = {
  failed: false,
  text: ""
};

export default FileStatusIndicator;
