import React, { useState, useEffect } from 'react'
import { Grid, Checkbox} from 'semantic-ui-react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { mapDaytagToNum } from '../../../../utils/mappers/analytics'
import { changeSimOrdDays, changeLoadSimulationData } from '../../../../actions/analytics'
import { getSafeSelectedWorkspace } from '../../../../utils/mappers/user'

const OrdDaysSelector = 
({
    disabled,
    simOrdDays,
    changeSimOrdDays,
    loadSimulationData,
    simulationMaterialData,
    currentWorkspaceId,
    changeLoadSimulationData
})=>{
    
    const { t } = useTranslation();
    const { loaded_results="", sim_inputs="" } = loadSimulationData[0] || {}
    const revertDaytranslation = (dayTranslation) =>{
        const tmap = {
            'Mon': t("simulation.Mon"),
            'Tue': t("simulation.Tue"),
            'Wed': t("simulation.Wed"),
            'Thu': t("simulation.Thu"),
            'Fri': t("simulation.Fri"),
            'Sat': t("simulation.Sat"),
            'Sun': t("simulation.Sun")
        }
        const rootDayTag = Object.entries(tmap).filter(entry => entry.includes(dayTranslation))[0][0]
        return rootDayTag 
    } 

    const initOrdDays = {
        'Mon': true,
        'Tue': true, 
        'Wed': true, 
        'Thu': true, 
        'Fri': true, 
        'Sat': true, 
        'Sun': true
        }
    const currentNonOrdDays = Object.keys(initOrdDays).filter(day => !simOrdDays.includes(mapDaytagToNum(day)))
    currentNonOrdDays.forEach(day => {
        initOrdDays[day] = false        
    })
    
    const [ordDays, updateOrdDays] = useState(initOrdDays)
    const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']

    useEffect(()=>{
        if(loadSimulationData.length){
            const newInitOrdDays = {}
            const numLoadedOrdDays = sim_inputs.ordering_days
            days.forEach((dayName, index)=> {
                newInitOrdDays[dayName] = numLoadedOrdDays.includes(index)
            })
            updateOrdDays(newInitOrdDays)
        }
        // Disabled because days is const
        // eslint-disable-next-line
    }, [ loadSimulationData ])

    useEffect(()=>{
        if(simulationMaterialData.data){
            //TODO: refactor this if it is possible, because DRY (initOrdDays repeat)
            updateOrdDays({
                'Mon': true,
                'Tue': true, 
                'Wed': true, 
                'Thu': true, 
                'Fri': true, 
                'Sat': true, 
                'Sun': true
                })
        }
    }, [simulationMaterialData, currentWorkspaceId])


    const minDays = () =>{
        const ordDaysCount = Object.values(ordDays).filter(isOrderingD => isOrderingD )
        return  ordDaysCount.length > 1 
    }

    const editOrdDays = (e, data) =>{
        const rootDay = revertDaytranslation(data.label)
        if(disabled || (!minDays() && ordDays[rootDay]) ){
            return
        }
        ordDays[rootDay] = !ordDays[rootDay]
        updateOrdDays({...ordDays})
        const newOrdDays = []
        days.map(day =>{
            if(ordDays[day]){
                newOrdDays.push(mapDaytagToNum(day))
            }
            return null
        })
        if(loadSimulationData.length){
            changeLoadSimulationData([{loaded_results,
                                       sim_inputs: {...sim_inputs, ordering_days: newOrdDays}}])
            return
        }
        changeSimOrdDays(newOrdDays)
    }

    const daySelector = (day) => {
        return(
            <Grid.Column key={day}>
                <Checkbox
                    label={t("simulation." + day)}
                    disabled={disabled}
                    checked={ordDays[day]}
                    onClick={(e, data) => editOrdDays(e, data)}
                />
            </Grid.Column>
        )
    }
    
    return(
    <Grid.Column mobile={8} widescreen={11} tablet={11}>
        <Grid.Row>
            <label 
                className="field-title">
                    {t("simulation.ordDays")}
            </label>
        </Grid.Row>
        <Grid columns={7} stackable>
            
            <Grid.Row>
                {days.map(day => daySelector(day))}
            </Grid.Row>
        </Grid>
    </Grid.Column>
    )
}

OrdDaysSelector.propTypes = {
    disabled: PropTypes.bool,
    simOrdDays: PropTypes.array,
    changeSimOrdDays: PropTypes.func,
    loadSimulationData: PropTypes.array,
    simulationMaterialData: PropTypes.object,
    currentWorkspaceId: PropTypes.number,
    changeLoadSimulationData: PropTypes.func,
}

const mapStateToProps = (state)=>({
    simOrdDays: state.analytics.simOrdDays,
    loadSimulationData: state.analytics.loadSimulationData,
    simulationMaterialData: state.analytics.simulationMaterialData,
    currentWorkspaceId: getSafeSelectedWorkspace(state),
})

export default connect(mapStateToProps,{ changeSimOrdDays, changeLoadSimulationData })(OrdDaysSelector)