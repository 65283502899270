import React, { useState } from "react";

import _ from "lodash";

import PropTypes from "prop-types";

import { Accordion, Icon, Table } from "semantic-ui-react";

import OnHandStockBatches from "./OnHandStockBatches/OnHandStockBatches";

import { useTranslation } from "react-i18next";

import "./BufferNumericalDetails.css";

import { percentageQuantities } from "../../../utils/mappers/detailsPanel";

import classNames from "classnames"; 
import { toExcelNumberFormatWithDecimals } from "../../../utils/format";

const BufferNumericalDetails = ({ bufferData }) => {
  const { t } = useTranslation();

  const [activeIndexes, setActiveIndexes] = useState([0, 4]);

  const handleClick = (e, titleProps) => {
    const { index, active } = titleProps;

    let newIndexes = [];
    if (active) {
      newIndexes = activeIndexes.filter((value) => value !== index);
    } else {
      newIndexes = [...activeIndexes, index];
    }

    setActiveIndexes(newIndexes);
  };

  const filterNaN = (value) => {
    return !_.isNaN(value) && _.isNumber(value) ? toExcelNumberFormatWithDecimals(value) : "-";
  };

  return (
    <Accordion>
      <Accordion.Title
        active={_.includes(activeIndexes, 0)}
        index={0}
        onClick={handleClick}
      >
        <Icon name="dropdown" />
        {t("detailsPanel.numericalDetails.bufferParameters.title")}
      </Accordion.Title>
      <Accordion.Content active={_.includes(activeIndexes, 0)}>
        <Table definition>
          <Table.Body>
            {Object.keys(bufferData.bufferParameters).map((key) => (
              <Table.Row key={key}>
                <Table.Cell
                  className={`numerical-details-buffer-parameters-${key} left-round`}
                >
                  {t(`detailsPanel.numericalDetails.bufferParameters.${key}`)}
                </Table.Cell>
                <Table.Cell>
                  {filterNaN(bufferData.bufferParameters[key])}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Accordion.Content>

      <Accordion.Title
        active={_.includes(activeIndexes, 1)}
        index={1}
        onClick={handleClick}
      >
        <Icon name="dropdown" />
        {t("detailsPanel.numericalDetails.bufferZones.title")}
      </Accordion.Title>
      <Accordion.Content active={_.includes(activeIndexes, 1)}>
        <Table definition>
          <Table.Body>
            {Object.keys(bufferData.zones).map((key) => (
              <Table.Row key={key}>
                <Table.Cell
                  className={`numerical-details-buffer-zones-${key} left-round`}
                >
                  {t(`detailsPanel.numericalDetails.bufferZones.${key}`)}
                </Table.Cell>
                <Table.Cell>
                  {filterNaN(bufferData.zones[key].units)}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Accordion.Content>

      <Accordion.Title
        active={_.includes(activeIndexes, 2)}
        index={2}
        onClick={handleClick}
      >
        <Icon name="dropdown" />
        {t("detailsPanel.numericalDetails.stockParameters.title")}
      </Accordion.Title>
      <Accordion.Content active={_.includes(activeIndexes, 2)}>
        <Table definition>
          <Table.Body>
            {Object.keys(bufferData.netFlow).map((key) => (
              <Table.Row key={key}>
                <Table.Cell
                  className={`numerical-details-net-flow-${key} left-round`}
                >
                  {/* Old key (net_flow_eq) changed to availableStock*/}
                  {
                    key === "net_flow_eq"
                      ? t("detailsPanel.numericalDetails.stockParameters.availableStock")
                      : t(`detailsPanel.numericalDetails.stockParameters.${key}`)
                  }
                </Table.Cell>
                <Table.Cell>{filterNaN(bufferData.netFlow[key])}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Accordion.Content>

      <Accordion.Title
        active={_.includes(activeIndexes, 3)}
        index={3}
        onClick={handleClick}
      >
        <Icon name="dropdown" />
        {t("detailsPanel.numericalDetails.analytics.title")}
      </Accordion.Title>
      <Accordion.Content active={_.includes(activeIndexes, 3)}>
        <Table definition>
          <Table.Body>
            {Object.keys(bufferData.analytics).map((key) => (
              <Table.Row key={key}>
                <Table.Cell className="left-round">
                  {t(`detailsPanel.numericalDetails.analytics.${key}`)}
                </Table.Cell>
                <Table.Cell
                  className={classNames(`numerical-details-analytics-${key}`, {
                    "quantity-in-black-zone":
                      bufferData.coloredCells[key] &&
                      bufferData.coloredCells[key] === "black",
                    "quantity-in-grey-zone":
                      bufferData.coloredCells[key] &&
                      bufferData.coloredCells[key] === "grey",
                    "quantity-in-red-zone":
                      bufferData.coloredCells[key] &&
                      bufferData.coloredCells[key] === "red",
                    "quantity-in-yellow-zone":
                      bufferData.coloredCells[key] &&
                      bufferData.coloredCells[key] === "yellow",
                    "quantity-in-green-zone":
                      bufferData.coloredCells[key] &&
                      bufferData.coloredCells[key] === "green",
                    "quantity-in-blue-zone":
                      bufferData.coloredCells[key] &&
                      bufferData.coloredCells[key] === "blue",
                  })}
                >
                  {`${filterNaN(bufferData.analytics[key])} ${
                    _.includes(percentageQuantities, key) ? "%" : ""
                    }`}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Accordion.Content>

      <OnHandStockBatches
        active={_.includes(activeIndexes, 4)}
        index={4}
        onClick={handleClick}
      />
    </Accordion>
  );
};

BufferNumericalDetails.propTypes = {
  bufferData: PropTypes.object.isRequired,
};

export default BufferNumericalDetails;
