import v8n from 'v8n'
import moment from "moment-timezone";

const dateToValidCalendarFormat = (date)=>{
    return date ? date.split('/').reverse().join('-') : ""
}

export const allowAddQuantityModifier = (dateAndMultiplier, setErrorFields, setDateAndMultiplier, setAction, action)=>{

    const checkAllDataEntered = v8n()
                                    .schema({
                                            start_date: v8n().string().minLength(1),
                                            end_date: v8n().string().minLength(1),
                                            multiplier: v8n().string().minLength(1)
                                    }).test(dateAndMultiplier)        
                    
    if(checkAllDataEntered){
        const startSelectedDate = moment(dateToValidCalendarFormat(dateAndMultiplier.start_date), 'YYYY-MM-DD').valueOf()
        const endSelectedDate = moment(dateToValidCalendarFormat(dateAndMultiplier.end_date), 'YYYY-MM-DD').valueOf()
        if(startSelectedDate === endSelectedDate || startSelectedDate > endSelectedDate){
            setErrorFields({startDate: true, endDate: true,  multiplier: false})
            return false
        }

        setDateAndMultiplier({start_date: "", end_date: "", multiplier: ""})
        setErrorFields({startDate: false, endDate: false,  multiplier: false})
        setAction(action ? false : true)
        return true
    }
    setErrorFields({
                    startDate: v8n().string().maxLength(0).test(dateAndMultiplier.start_date), 
                    endDate: v8n().string().maxLength(0).test(dateAndMultiplier.end_date),  
                    multiplier: v8n().string().maxLength(0).test(dateAndMultiplier.multiplier)
                })
    return false
}

