import React, { useState } from "react";
import PropTypes from "prop-types";

import _ from "lodash";

import { useTranslation } from "react-i18next";

import { Input, Button, Grid, List } from "semantic-ui-react";
import "./SubGroupMaterials.css"

const SubgroupDropdown = ({ options, defaultValues, onChange }) => {
    const { t } = useTranslation();
    const eventOptions = [...options]
    const [filterInput, setFilterInput] = useState("");
    const [filteredOptions, setFilteredOptions] = useState([...options]);

    const [values, setValues] = useState([...defaultValues]);

    const handleFilterChange = (e, options) => {
        const value = e.target.value || undefined;
        setFilterInput(value)
        if (_.isNil(value)) {
            setFilteredOptions([...options])
            return
        }
        setFilteredOptions([...options.filter(
            option => (option.text).toLowerCase().includes(value.toLowerCase()))])
    }

    const className = "subgroup"

    const dropdownItem = (option) => {
        return (<List.Item className={className} key={option.key}>
            <div className="ui checkbox">
                <input
                    id={option.key}
                    disabled={false}
                    checked={values.includes(option.value)}
                    type="checkbox"
                    onChange={(e) => {
                        if (e.target.checked) {
                            setValues([...values, option.value]);
                            onChange([...values, option.value]);
                        }
                        if (!(e.target.checked)) {
                            setValues(values.filter(value => value !== option.value));
                            onChange(values.filter(value => value !== option.value));
                        }
                    }}
                />
                {" "}
                <label htmlFor={option.key}>{option.text}</label>
            </div>
        </List.Item>)
    }

    return (
        <div>{
            <Grid.Row verticalAlign="middle">
                <Button
                    className="dropdown-filters"
                    disabled={false}
                    onClick={(e) => {
                        e.preventDefault()
                        setValues([...eventOptions.map(option => option.value)]);
                        onChange([...eventOptions.map(option => option.value)]);
                    }}
                >
                    {t("groupSettings.selectAll")}
                </Button>
                <Button
                    className="dropdown-filters"
                    disabled={false}
                    onClick={(e) => {
                        e.preventDefault()
                        setValues([]);
                    }}
                >
                    {t("groupSettings.unselectAll")}
                </Button>
            </Grid.Row>
        }
            <Grid.Row verticalAlign="top" stretched>
                <Input
                    className="list-searchbar"
                    icon="search"
                    value={filterInput}
                    onChange={(e) => handleFilterChange(e, options)}
                    placeholder={t("ordersTable.search")}
                    size="small"
                />
                <List className="scrollable-list">
                    {filteredOptions.map(option => (dropdownItem(option)))}
                </List>
            </Grid.Row>
        </div>
    )
};

SubgroupDropdown.propTypes = {
    options: PropTypes.array,
    defaultValues: PropTypes.array,
    onChange: PropTypes.func,
    groupType: PropTypes.string,
};

SubgroupDropdown.defaultProps = {
    options: [],
    defaultValues: [],
    onChange: _.noop,
    required: false,
    groupType: ""
};

export default SubgroupDropdown;
