import { useEffect, useState } from "react";

const useComponentSize = ( componentRef ) => {
    const [ windowWidth, setWindowWidth ] = useState(0);
    const [ componentWidth , setComponentWidth ] = useState(0);

    useEffect(() => {
        window.addEventListener('resize', () => {
            setWindowWidth(window.innerWidth);
        })
    }, [])

    useEffect(()=>{
        if(componentRef.current){
            setComponentWidth(componentRef.current.offsetWidth)
        }
    }, [windowWidth, componentRef])

    return { windowWidth, componentWidth };
}

export default useComponentSize;