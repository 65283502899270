import React, { useState } from "react";
import PropTypes from "prop-types";
//import { Slider } from "react-semantic-ui-range";
import { Input, Grid } from "semantic-ui-react";
import { maskTextToExtractFloat } from "../../../utils/format";

import { Slider, Rail, Handles, Tracks, Ticks } from "react-compound-slider";
import { SliderRail, Handle, Track, Tick } from "../Slider/SliderComponents"; // example render components - source below
import { maskTextToExtractInt } from "../../../utils/format";
import { useTranslation } from "react-i18next";

import "./ADURangeSlider.css";

const sliderStyle = {
  position: "relative",
  width: "100%",
};

const domain = [0, 365];

const ADURangeSlider = ({ startInitialValue, endInitialValue, onChange, rulesDisabled }) => {
  const [start, setStart] = useState(startInitialValue);
  const [end, setEnd] = useState(endInitialValue);
  const settings = {
    min: domain[0],
    max: domain[1],
    step: 5,
  };

  const [startTemp, setStartTemp] = useState(start);
  const [endTemp, setEndTemp] = useState(end);

  const defaultValues = [start, end];
  const onUpdate = (values) => {
    setStart(values[0]);
    setEnd(values[1]);
  };

  const { t } = useTranslation();

  const handleStartChange = (e) => {
    let newStartValue = parseInt(maskTextToExtractFloat(e.target.value));
    if (!newStartValue || newStartValue < settings.min) {
      setStart(settings.min);
      setStartTemp(settings.min);
      return;
    } else if (Math.abs(newStartValue) > Math.abs(end)) {
      setStart(end - settings.step);
      setStartTemp(end - settings.step);
      return;
    }
    onChange(newStartValue, endTemp);
    setStart(newStartValue);
    setStartTemp(newStartValue);
  };

  const handleEndChange = (e) => {
    let newEndValue = parseInt(maskTextToExtractFloat(e.target.value));
    if (!newEndValue || newEndValue > settings.max) {
      setEnd(settings.max);
      setEndTemp(settings.max);
      return;
    } else if (Math.abs(start) > Math.abs(newEndValue)) {
      setEnd(start + settings.step);
      setEndTemp(start + settings.step);
      return;
    }
    onChange(startTemp, newEndValue);
    setEnd(newEndValue);
    setEndTemp(newEndValue);
  };
  const className = rulesDisabled ? "disabled-slider" : "slider-wrapper";

  return (
    <Grid>
      <Grid.Column width={16}>
        <div className={className}>
          <Slider
            mode={2}
            step={settings.step}
            domain={domain}
            rootStyle={sliderStyle}
            onUpdate={onUpdate}
            onChange={(values) => {
              onChange(values[0], values[1]);
              setStartTemp(values[0]);
              setEndTemp(values[1]);
            }}
            values={defaultValues}
            disabled={rulesDisabled}
          >
            <Rail>
              {({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}
            </Rail>
            <Handles>
              {({ handles, getHandleProps }) => (
                <div className="slider-handles">
                  {handles.map((handle) => (
                    <Handle
                      key={handle.id}
                      handle={handle}
                      domain={domain}
                      getHandleProps={getHandleProps}
                    />
                  ))}
                </div>
              )}
            </Handles>
            <Tracks left={false} right={false}>
              {({ tracks, getTrackProps }) => (
                <div className="slider-tracks">
                  {tracks.map(({ id, source, target }) => (
                    <Track
                      key={id}
                      source={source}
                      target={target}
                      getTrackProps={getTrackProps}
                    />
                  ))}
                </div>
              )}
            </Tracks>
            <Ticks count={5}>
              {({ ticks }) => (
                <div className="slider-ticks">
                  {ticks.map((tick) => (
                    <Tick key={tick.id} tick={tick} count={ticks.length} />
                  ))}
                </div>
              )}
            </Ticks>
          </Slider>
        </div>
      </Grid.Column>
      <Grid.Column width={8} className="column thin-column">
        <Input
          label={t("groupSettings.rules.aduMeasurementStart")}
          type="text"
          className="ui input adu-measurement"
          value={startTemp}
          onChange={(e) => setStartTemp(maskTextToExtractInt(e.target.value))}
          onBlur={handleStartChange}
          fluid
          disabled={rulesDisabled}
        />
        <label>{t("groupSettings.rules.aduMeasurementUnit")}</label>
      </Grid.Column>
      <Grid.Column width={8} className="column thin-column">
        <Input
          label={t("groupSettings.rules.aduMeasurementEnd")}
          type="text"
          className="ui input adu-measurement"
          value={endTemp}
          onChange={(e) => setEndTemp(maskTextToExtractInt(e.target.value))}
          onBlur={handleEndChange}
          fluid
          disabled={rulesDisabled}
        />
        <label>{t("groupSettings.rules.aduMeasurementUnit")}</label>
      </Grid.Column>
    </Grid>
  );
};

ADURangeSlider.propTypes = {
  startInitialValue: PropTypes.number.isRequired,
  endInitialValue: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  rulesDisabled: PropTypes.bool,
};

export default ADURangeSlider;
