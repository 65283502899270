import React, { useState } from "react";
import PropTypes from "prop-types";
import { match as matchShape } from "react-router-prop-types";

import _ from "lodash";

import queryString from "query-string";

import { Link, Redirect } from "react-router-dom";
import { register, registerNonAdmin } from "../../actions/auth";
import { connect } from "react-redux";

import { useTranslation } from "react-i18next";

import { LOGIN, ORDER_SCREEN } from "../Navbar/routes";

import Error from "../Error/Error";

import {
  Button,
  Form,
  Grid,
  Header,
  Message,
  Segment,
  Image,
} from "semantic-ui-react";

import "./Register.css";
import paqtanaLogo from "../../img/Paqtana-Portrait-Logo.svg";

export const Register = ({
  register,
  registerNonAdmin,
  isAuthenticated,
  ...props
}) => {
  const { t, i18n } = useTranslation();

  const [formData, setFormData] = useState({
    // eslint-disable-next-line react/prop-types
    registration_code: _.isEmpty(props.match)
      ? ""
      : // eslint-disable-next-line react/prop-types
        props.match.params.registrationCode,
    name: "",
    email: "",
    password: "",
    password2: "",
    policy_terms: false,
    organization_name: "",
    role: "admin",
  });

  const [formErrors, setFormErrors] = useState([]);

  const {
    registration_code= "",
    name,
    email,
    password,
    password2,
    organization_name,
  } = formData;

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]:
        e.target.name === "termscheckbox" ? e.target.checked : e.target.value,
    });
  };

  const isInvited = () => {
    // eslint-disable-next-line react/prop-types
    if (_.isNil(props.location)) {
      return false;
    }
    // eslint-disable-next-line react/prop-types
    const values = queryString.parse(props.location.search);
    return _.has(values, "org") && _.has(values, "code");
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const errors = getSignupFormErrors();
    setFormErrors(errors);
    if (!_.isEmpty(errors)) {
      return;
    }
    if (isInvited()) {
      // eslint-disable-next-line react/prop-types
      const values = queryString.parse(props.location.search);
      const invitedUserdata = {
        ..._.pick(formData, "name", "email", "password"),
        organization_id: values.org,
        signature: values.code,
        language: i18n.language,
      };
      registerNonAdmin(invitedUserdata);
    } else {
      register({ ...formData, language: i18n.language });
    }
  };

  const getSignupFormErrors = () => {
    const errors = [];
    if (password.length < 8) {
      errors.push({
        header: "account.passwordTooShort.header",
        content: "account.passwordTooShort.content",
      });
    }

    if (password !== password2) {
      errors.push({
        header: "account.passwordsDoNotMatch.header",
        content: "account.passwordsDoNotMatch.content",
      });
    }

    if (organization_name.length > 60) {
      errors.push({
        header: "account.organizationNameTooLong.header",
        content: "account.organizationNameTooLong.content",
      });
    }
    return errors;
  };

  if (isAuthenticated) {
    return <Redirect to={ORDER_SCREEN}></Redirect>;
  }

  return (
    <Grid
      textAlign="center"
      container
      verticalAlign="middle"
      className="register-container"
    >
      <Grid.Column className="register-column">
        <Image src={paqtanaLogo} size='small' centered />
        <Header as="h2" textAlign="center">
          {t("account.registerMessage")}
        </Header>
        <Form size="large" onSubmit={onSubmit} error={!_.isEmpty(formErrors)}>
          <Segment stacked>
            {!isInvited() && (
              <>
                <Form.Input
                  name="registration_code"
                  fluid
                  required
                  placeholder={t("account.registrationCode")}
                  label={t("account.registrationCode")}
                  value={registration_code}
                  onChange={(e) => onChange(e)}
                />
                <Form.Input
                  name="organization_name"
                  fluid
                  required
                  placeholder={t("account.organizationName")}
                  label={t("account.organizationName")}
                  value={organization_name}
                  onChange={(e) => onChange(e)}
                />
              </>
            )}
            <Form.Input
              name="name"
              fluid
              required
              placeholder={t("account.name")}
              label={t("account.name")}
              value={name}
              onChange={(e) => onChange(e)}
            />
            <Form.Input
              type="email"
              name="email"
              fluid
              required
              placeholder={t("account.emailAddress")}
              label={t("account.emailAddress")}
              value={email}
              onChange={(e) => onChange(e)}
            />
            <Form.Input
              fluid
              required
              type="password"
              name="password"
              placeholder={t("account.password")}
              label={t("account.password")}
              value={password}
              onChange={(e) => onChange(e)}
            />
            <Form.Input
              fluid
              required
              type="password"
              name="password2"
              placeholder={t("account.passwordConfirm")}
              label={t("account.passwordConfirm")}
              value={password2}
              onChange={(e) => onChange(e)}
            />
            <Form.Input
              required
              className="formFeildWrapper"
              type="checkbox"
              name="termscheckbox"
              label={
                <label className="policy-label">
                  {t("account.readAndAgree")}{" "}
                  <a
                    href="https://app.termly.io/document/terms-of-service/1c302266-4cdc-41b8-a349-79af8f58d3d0"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t("account.termsOfService")}
                  </a>{" "}
                  {t("account.and")}{" "}
                  <a
                    href="https://app.termly.io/document/privacy-policy/c9584122-76ba-4531-bb44-b9fbbf56e5a5"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t("account.privacyPolicy")}
                  </a>
                </label>
              }
              onChange={(e) => onChange(e)}
            />

            {formErrors.map((error) => (
              <Message
                key={error.header}
                error
                header={t(error.header)}
                content={t(error.content)}
              />
            ))}

            <Error />

            <Button primary fluid size="large" type="submit">
              {t("account.register")}
            </Button>
          </Segment>
        </Form>

        <Message className="bottom-message">
          <strong>{t("account.existingUserPrompt")}</strong>
          <br />
          <Link to={LOGIN}>{t("account.logIn")}</Link>
        </Message>
      </Grid.Column>
    </Grid>
  );
};

Register.propTypes = {
  register: PropTypes.func.isRequired,
  registerNonAdmin: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  props: PropTypes.objectOf(matchShape),
};

Register.defaultProps = {
  props: null,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { register, registerNonAdmin })(
  Register
);
