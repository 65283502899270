/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { NavLink, Link } from "react-router-dom";

import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { logout } from "../../actions/auth";
import {
  getWorkspaces,
  resetCurrentWorkspace,
  setBatchInventoryMode,
} from "../../actions/workspace";
import { checkAdminConsoleAccess } from "../../utils/mappers/user";
import { setLanguage } from "../../actions/language";

import {
  Menu,
  Popup,
  Icon,
  Modal,
  Button,
  Dropdown,
  Grid,
  GridColumn,
  Label,
  Image,
  Header,
} from "semantic-ui-react";

import WorkspaceSelector from "../WorkspaceSelector/WorkspaceSelector";
import WorkspaceSettings from "./WorkspaceSettings/WorkspaceSettings";

import {
  ORDER_SCREEN,
  DATA_IMPORT,
  BUFFER_SETTINGS,
  CALENDAR,
  ANALYTICS,
  ADMIN_CONSOLE,
} from "./routes";

import orders from "../../img/orders.svg";
import dataImport from "../../img/dataImport.svg";
import bufferSettings from "../../img/bufferSettings.svg";
import calendar from "../../img/calendar.svg";
import analytics from "../../img/analytics.svg";

import "./Navbar.css";
import paqtanaLogo from "../../img/Paqtana-Logo-Solo.svg";

export const Navbar = ({
  auth: { isAuthenticated, loading, user },
  hasAdminConsoleAccess,
  logout,
  getWorkspaces,
  resetCurrentWorkspace,
  workspaces,
  currentWorkspaceNotSet,
  setLanguage,
  currentWorkspaceId,
  isConnected,
  connectionStatusMessage,
}) => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (!_.isNil(user)) {
      if (_.includes(i18n.languages, user.language)) {
        i18n.changeLanguage(user.language);
      } else {
        // user was created with a language not supported.
        // solve inconsistent state by updating user's language in db to match i18n language
        setLanguage(i18n.language);
      }
    }
  }, [user, i18n, setLanguage]);

  useEffect(() => {
    if (!loading && isAuthenticated) {
      getWorkspaces();
    }
  }, [loading, isAuthenticated, getWorkspaces]);

  useEffect(() => {
    if (user && user.isAdmin && !currentWorkspaceNotSet) {
      return;
    }

    if (
      !_.isEmpty(workspaces) &&
      (currentWorkspaceNotSet ||
        (!_.isNil(user) &&
          !_.isNil(user.roles) &&
          !_.has(user.roles, currentWorkspaceId)))
    ) {
      resetCurrentWorkspace();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaces, currentWorkspaceId, user]);

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    setLanguage(language);
  };

  const [showSupportModal, setShowSupportModal] = useState(false);
  const [showWorkspaceSettings, setShowWorkspaceSettingsModal] = useState(
    false
  );

  const renderLink = (to, translateKey, iconClassName, iconSrc) => (
    <NavLink
      exact
      activeClassName="selected"
      className="item"
      to={to}
      key={translateKey}
    >
      <img
        className={classNames(iconClassName, "icon", "large")}
        src={iconSrc}
        alt="Orders"
      />
      {t(translateKey)}
    </NavLink>
  );

  const authLinks = [
    [ORDER_SCREEN, "orderScreen.title", "orders", orders],
    [
      DATA_IMPORT,
      "dataImport.title",
      classNames("cloud", "upload"),
      dataImport,
    ],
    [
      BUFFER_SETTINGS,
      "groupSettings.title",
      classNames("sliders", "horizontal"),
      bufferSettings,
    ],
    [
      CALENDAR,
      "calendar.title",
      classNames("calendar", "alternate", "outline"),
      calendar,
    ],
    [ANALYTICS, "analytics.title", classNames("chart", "bar"), analytics],
  ];

  return !loading && isAuthenticated ? (
    <Grid stackable>
      <GridColumn
        verticalAlign="middle"
        className="no-padding-top no-padding-right logo-section"
        largeScreen={2}
        widescreen={2}
      >

      <Header as="h2">
        <Image className="svg-logo" src={paqtanaLogo} centered />
          Paqtana
        </Header>
      </GridColumn>
      <GridColumn className="no-padding-top" largeScreen={12} widescreen={12}>
        <Menu
          widths={authLinks.length + 1}
          icon="labeled"
          className="top-nav"
          stackable
        >
          <Modal open={showSupportModal}>
            <Modal.Header>{t("account.support")}</Modal.Header>
            <Modal.Content>
              <Modal.Description>
                <p>{t("account.supportText")}</p>
                <p>{t("supportEmailAddress")}</p>
                <p>
                  <a
                    href="https://app.termly.io/document/terms-of-service/1c302266-4cdc-41b8-a349-79af8f58d3d0"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t("account.termsOfService")}
                  </a>
                  {" "}{t("account.and")}{" "}
                  <a
                    href="https://app.termly.io/document/privacy-policy/c9584122-76ba-4531-bb44-b9fbbf56e5a5"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t("account.privacyPolicy")}
                  </a>
                </p>
              </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
              <Button onClick={() => setShowSupportModal(false)}>
                {t("close")}
              </Button>
            </Modal.Actions>
          </Modal>
          <WorkspaceSettings
            open={showWorkspaceSettings}
            onClose={() => setShowWorkspaceSettingsModal(false)}
          />

          <div className="item">
            <WorkspaceSelector />
          </div>
          {authLinks.map((linkData) => renderLink(...linkData))}
        </Menu>
      </GridColumn>
      <GridColumn className="no-padding-top" width={2}>
        <Menu
          icon
          vertical
          secondary
          compact
          className="item settings-logo-menu"
        >
          <Popup
            disabled={showSupportModal}
            position="bottom center"
            trigger={
              <Menu.Item
                className={classNames({
                  "settings-icon": isConnected,
                  "settings-icon-moved": !isConnected,
                })}
              >
                <Icon className="icon" size="big" name="cog" />
              </Menu.Item>
            }
            hideOnScroll={true}
            on="click"
            className="account account-popup"
          >
            <Menu vertical secondary compact>
              {hasAdminConsoleAccess && (
                <Menu.Item as={Link} to={ADMIN_CONSOLE} disabled={!isConnected}>
                  {t("account.adminConsole.title")}
                </Menu.Item>
              )}

              <Menu.Item onClick={() => setShowWorkspaceSettingsModal(true)}>
                {t("account.workspace.settings")}
              </Menu.Item>

              <Menu.Item onClick={() => setShowSupportModal(true)}>
                {t("account.support")}
              </Menu.Item>

              <Menu.Item>
                <Dropdown text={t("languages.title")}>
                  <Dropdown.Menu>
                    {i18n.languages.map((language) => (
                      <Dropdown.Item
                        key={language}
                        text={t(`languages.${language}`)}
                        onClick={() => changeLanguage(language)}
                      />
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </Menu.Item>
              <Menu.Item onClick={logout}>{t("account.logout")}</Menu.Item>
            </Menu>
          </Popup>
        </Menu>
        <br />
        {!isConnected && (
          <Label
            className="offline-label"
            basic
            color={
              connectionStatusMessage === "connected"
                ? "green"
                : connectionStatusMessage === "connecting"
                  ? "yellow"
                  : "red"
            }
          >
            {/*t("offline")*/}
            {t(`pusherStates.${connectionStatusMessage}`)}
          </Label>
        )}
      </GridColumn>
    </Grid>
  ) : null;
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  hasAdminConsoleAccess: checkAdminConsoleAccess(state.auth.user),
  workspaces: state.workspace.workspaces,
  currentWorkspaceNotSet: state.workspace.currentWorkspace === null,
  currentWorkspaceId: state.workspace.currentWorkspace,
  isConnected: state.auth.isConnected,
  connectionStatusMessage: state.auth.connectionStatusMessage,
});

Navbar.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  hasAdminConsoleAccess: PropTypes.bool.isRequired,
  getWorkspaces: PropTypes.func.isRequired,
  resetCurrentWorkspace: PropTypes.func.isRequired,
  workspaces: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentWorkspaceNotSet: PropTypes.bool.isRequired,
  setLanguage: PropTypes.func.isRequired,
  currentWorkspaceId: PropTypes.number,
  setBatchInventoryMode: PropTypes.func.isRequired,
  isConnected: PropTypes.bool,
  connectionStatusMessage: PropTypes.string,
};

export default connect(mapStateToProps, {
  logout,
  getWorkspaces,
  resetCurrentWorkspace,
  setLanguage,
  setBatchInventoryMode,
})(Navbar);
