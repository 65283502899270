import React from 'react'
import { Popup } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import { abbreviateNumber } from '../../utils/format'
import classNames from 'classnames'
import "./LargetextPopup.css"

const LargeTextPopup = (props)=>{
    const { 
            key="temporal-key", 
            content="temporal-content", 
            containerWidth=150,
            wordLength=0.5,
            textStyle="label", //TODO: apply SOLID principle later
        } = props

    const indexToCut = abbreviateNumber(containerWidth * wordLength)
    const shortContent = content.slice(0, indexToCut)+'...'

    return(
        <>
            {
                content.length > indexToCut
                    ? <Popup
                        key={key} 
                        on='hover' 
                        basic
                        //popperModifiers allow you to modify popup placement when you have near components
                        popperModifiers={{ preventOverflow: { padding: {top: -100, right: -1000}} }}
                        content={content}
                        trigger={<label className={classNames({[textStyle]:true})}>{shortContent}</label>} 
                        />
                    : <label className={classNames({[textStyle]:true})}>{content}</label>
            }
        </>
    )
}

LargeTextPopup.propTypes = {
    key: PropTypes.string,
    content: PropTypes.string,
    wordLength: PropTypes.number,
    containerWidth: PropTypes.number,
    textStyle: PropTypes.string,
}

export default LargeTextPopup