import { DATA_IMPORT_MAX_FILE_SIZE } from "../utils/constants";

export const isFileTooLarge = (file) => {
  const { size } = file;
  return size > DATA_IMPORT_MAX_FILE_SIZE;
};

export const isFileValid = (file) => {
  try {
    const { name } = file;
    const extensionRegex = new RegExp("^(.*)+.+(xls|csv|xlsx)$", "gm");

    return extensionRegex.test(name.toLowerCase());
  } catch (error) {
    return false
  }
};

export const formatBytes = (bytes, decimals = 0) => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};
