import React, { useState } from "react";
import PropTypes from "prop-types";

import _ from "lodash";

import { debounce } from "throttle-debounce";

import { useTranslation } from "react-i18next";

import { mapColumnsToGroupFields, fatherItems } from "../../../utils/mappers/groupSettings";

import {
  Segment,
  Form,
  Grid,
  Loader,
  Dimmer,
  GridColumn,
} from "semantic-ui-react";

import MultipleDropdown from "../MultipleDropdown/MultipleDropdown";
import FiltersDropdown from "../FiltersDropdown/FiltersDropdown";
import SideEffectsModal from "../SubGroupsEffectsWarning/SideEffectsModal";
import RulesSettings from "../RulesSettings/RulesSettings";

import "./EditGroup.css";

const EditGroup = ({
  groupNames,
  selectedGroup,
  columns,
  items,
  onSave,
  onCancel,
  loading,
  groups,
}) => {
  const { t } = useTranslation();

  const groupFields = mapColumnsToGroupFields(t, columns, items);

  const def = {};
  for (let field of groupFields) {
    def[[field.accessor]] = {
      name: field.name,
      accessor: field.accessor,
      values: ["all"],
    };
  }

  const [duplicateNameError, setDuplicateNameError] = useState(null);
  const [showWarning, setShowWarning] = useState(false)
  const [warningShowed, setWarningShowed] = useState(false)

  const initialGroupData = _.isEmpty(selectedGroup)
    ? { ...def }
    : { ...selectedGroup };
  for (let field of groupFields) {
    if (_.isNil(initialGroupData[[field.accessor]])) {
      initialGroupData[[field.accessor]] = {
        name: field.name,
        accessor: field.accessor,
        values: ["all"],
      };
    }
  }

  const [newGroupData, setNewGroupData] = useState(initialGroupData);

  const initialRuleData = _.isEmpty(initialGroupData.rules)
    ? {
      materialMethod: "buffered",
      aduMeasurementStart: 0,
      aduMeasurementEnd: 90,
      variabilityFactor: 0.5,
      leadTimeFactor: 0.5,
    }
    : { ...initialGroupData.rules };
  const [ruleData, setRuleData] = useState(initialRuleData);

  const debouncedGroupData = debounce(500, (fieldData) => {
    updateNewGroupData({ ...newGroupData, ...fieldData });
  });

  const updateNewGroupData = (fieldData) => {
    setNewGroupData({ ...newGroupData, ...fieldData });
  };

  const materialMethodOptions = [
    {
      text: t(
        "groupSettings.rules.materialMethod.buffered"
      ),
      value: "buffered",
    },
    {
      text: t(
        "groupSettings.rules.materialMethod.nonBuffered"
      ),
      value: "non_buffered",
    },
  ]
  const initialMarked = {};
  groupFields.forEach((field) => (initialMarked[field.accessor] = true));
  const [markedFields, setMarkedFields] = useState(initialMarked);

  const updateMarkedFields = (accessor, value) => {
    setMarkedFields({ ...markedFields, [accessor]: value });
  };

  const sideEffectWarning = (groupData, groups, columns) => {
    if (groupData.id === undefined) {
      return null
    }
    const childGroups = groups.filter(
      group => group.rules.materialMethod.includes('event')).filter(
        group => group.rules.materialMethod.split('-')[1] === (groupData.id).toString())
    let showWarning = false
    childGroups.map(childGroup => {

      let fatherMaterials = []

      fatherItems(groupData, items, columns).forEach(
        row => fatherMaterials.push(row.location_code_concatenation))

      childGroup.location_code_concatenation.values.forEach(materialId => {
        if (!fatherMaterials.includes(materialId)) {
          showWarning = true
        }
        return null
      })
      return null
    })
    return showWarning
  }



  return (
    <Segment basic className={"edit-group"}>
      <Dimmer.Dimmable dimmed={loading}>
        <Dimmer inverted active={loading}>
          {loading && (
            <div className="ui container">
              <Loader active size="large" />
            </div>
          )}
        </Dimmer>

        <Form
          autoComplete="off"
          onSubmit={() => {
            const filtered = {
              ...newGroupData,
              rules: { ...ruleData },
            };

            // Cleanup before dispatch
            Object.keys(filtered).forEach(
              (key) => filtered[key] === undefined && delete filtered[key]
            );

            if (
              !_.has(newGroupData, "id") &&
              _.includes(groupNames, newGroupData.name.trim())
            ) {
              setDuplicateNameError({
                content: t("groupSettings.duplicateGroup"),
                pointing: "above",
              });
              return;
            }
            if (sideEffectWarning(filtered, groups, columns) && !warningShowed) {
              setShowWarning(true)
              return;
            }

            setDuplicateNameError(null);
            setWarningShowed(false)
            onSave(filtered);
          }}
        >
          <SideEffectsModal
            isWarningActive={showWarning}
            closeWarning={() => {
              setShowWarning(false)
              setWarningShowed(true)
            }}
          />
          <Grid columns={2} stackable>
            <GridColumn stretched mobile={16} largeScreen={8} widescreen={8} className="temporal-container-simple">
              <Segment className="name-filters">
                <Form.Group className="top-labels" widths={2}>
                  <Form.Input
                    className="group-name-input"
                    required
                    error={duplicateNameError}
                    placeholder="Add a name to this group"
                    name="name"
                    defaultValue={_.get(selectedGroup, "name", "")}
                    onChange={(e) => {
                      debouncedGroupData({ name: e.target.value.trim() });
                    }}
                    label={t("groupSettings.nameLabel")}
                  />
                  <Form.Field>
                    <label>{t("groupSettings.filters")}</label>
                    <FiltersDropdown
                      groupFields={groupFields}
                      markedGroupFields={groupFields
                        .filter(
                          (field) => markedFields[field.accessor] === true
                        )
                        .map((field) => field.accessor)}
                      onChange={updateMarkedFields}
                    ></FiltersDropdown>
                  </Form.Field>
                </Form.Group>

                <Grid stackable className="group-filters-container">
                  {groupFields
                    .filter((field) => markedFields[field.accessor] === true)
                    .map((field) => (
                      <Grid.Column
                        stretched
                        width={4}
                        key={field.accessor}
                        className={"group-field-dropdown"}
                      >
                        <label className="dropdown-group-fields">{field.name}</label>
                        <MultipleDropdown
                          options={field.options}
                          defaultValues={
                            _.get(selectedGroup, field.accessor, {}).values ||
                            _.get(newGroupData, field.accessor, {}).values
                          }
                          onChange={(options) =>
                            updateNewGroupData({
                              [field.accessor]: _.isEmpty(options)
                                ? {
                                  name: field.name,
                                  values: ["all"],
                                }
                                : {
                                  name: field.name,
                                  values: options,
                                },
                            })
                          }
                          groupType={""}
                        />
                      </Grid.Column>
                    ))}
                </Grid>
              </Segment>
            </GridColumn>
            <GridColumn mobile={16} largeScreen={8} widescreen={8}>
              <RulesSettings
                selectedGroup={selectedGroup}
                columns={columns}
                items={items}
                ruleData={ruleData}
                setRuleData={setRuleData}
                onCancel={onCancel}
                rulesDisabled={false}
                materialMethodOptions={materialMethodOptions}
              />
            </GridColumn>
          </Grid>
        </Form>
      </Dimmer.Dimmable>
    </Segment>
  );
};

EditGroup.propTypes = {
  selectedGroup: PropTypes.object,
  columns: PropTypes.object,
  items: PropTypes.array,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  groupNames: PropTypes.arrayOf(PropTypes.string),
  loading: PropTypes.bool,
  groups: PropTypes.array,
};

EditGroup.defaultProps = {
  selectedGroup: null,
  columns: [],
  items: [],
  groupNames: [],
  onSave: _.noop,
  onCancel: _.noop,
  loading: false,
  groups: [],
};

export default EditGroup;
