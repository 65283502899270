import React, { useState, useEffect } from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from "@fullcalendar/interaction";
import { Form, Popup, Button } from 'semantic-ui-react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import allLocales from "@fullcalendar/core/locales-all";
import PropTypes from 'prop-types'
import moment from "moment-timezone";
import { CustomCalendarStyleWrapper } from './CustomCalendarStyleWrapper';

import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import './CustomCalendarInput.css'
import { dateFormatSimulationChart } from '../../utils/format';


const CustomCalendarInput = (props)=>{
    const { title='Date Label', 
            required=false, 
            error=false , 
            disabled=false, 
            onDateClick, 
            action=false,
            validRange={startDateRange: "2000-01-01", endDateRange: "2050-01-01" },
            //TODO: check if this is correct in different time zones
            currentDate=moment().format('YYYY-MM-DD'),
            placeholder = ""
        } = props
    const { t, i18n } = useTranslation()
    const [ selectedDate, setSelectedDate ] = useState("")

    useEffect(()=>{
        setSelectedDate("")
    }, [action])

    const changeDateFormat = (date)=>{
        return date.dateStr.split('-').reverse().join('/')
    }

    return(
        <Form.Field required={required} error={error} disabled={disabled} className="From-Field">
            <label className='field-title'>{title}</label>
            <Popup
                basic
                on="click"
                size="small"
                wide
                disabled={disabled}
                trigger={   
                    <Button
                        basic
                        color={error ? 'red' : 'grey'}
                        fluid
                        >
                            <p className={classNames({'button-text': selectedDate ? true : false,
                                                     "button-with-text": placeholder})}>
                            {   selectedDate 
                                ? dateFormatSimulationChart(selectedDate, i18n.language)
                                : placeholder
                                    ? placeholder
                                    : t("customCalendar.placeholder")
                            }
                            </p>
                    </Button>
                }
                >
                <CustomCalendarStyleWrapper>
                    <FullCalendar
                        className='custom-calendar'
                        plugins={[dayGridPlugin, interactionPlugin]}
                        defaultDate={currentDate}
                        dateClick={(dateSelected)=>{setSelectedDate(changeDateFormat(dateSelected));
                                                    onDateClick(changeDateFormat(dateSelected))
                        }}
                        locales={allLocales}
                        locale={i18n.language}
                        header={{
                            left: "prev",
                            center: "title",
                            right: " ,next",
                        }}
                        //In some cases you need to add one day because Fullcalendar doesn't work well with the range of dates
                        validRange={{
                            start: validRange.startDateRange,
                            end: moment(validRange.endDateRange)    
                                        .add(1, 'days').format("Y-MM-DD")
                        }}
                    />
                </CustomCalendarStyleWrapper>
            </Popup>
        </Form.Field>
        
    )
}

CustomCalendarInput.propTypes = {
    title: PropTypes.string,
    required: PropTypes.bool,
    error: PropTypes.bool,
    disabled: PropTypes.bool,
    onDateClick: PropTypes.func,
    action: PropTypes.bool,
    validRange: PropTypes.object,
    currentDate: PropTypes.string,
    placeholder: PropTypes.string
}

export default CustomCalendarInput