import React from "react";
import _ from "lodash";

import { GET_DATASET, ABORT_DATASET_FETCH } from "./types";

import axiosWrapper from "../utils/axiosWrapper";
import { toast } from "react-toastify";

import ToastI18nWrapper from "../components/ToastI18nWrapper/ToastI18nWrapper";

export const getDataset = (current_workspace_id) => async (dispatch) => {
  try {
    const response = await axiosWrapper.get(
      `/datasets?current_workspace_id=${current_workspace_id}`
    );
    const datasetInvalidColumns = !_.isNil(response.data) ? Object.keys(response.data).filter(column => response.data[column].length === 0) : [];
    const filteredDatasetColumns = _.omit(response.data, datasetInvalidColumns);
    dispatch({
      type: GET_DATASET,
      // payload: response.data,
      payload: filteredDatasetColumns,
    });
  } catch (error) {
    dispatch({ type: ABORT_DATASET_FETCH });

    if (!_.isNil(error.response) && error.response.status === 404) {
      return;
    } else {
      toast.error(<ToastI18nWrapper translateKey={"genericError"} />, {
        autoClose: 2500,
        toastId: "genericError",
      });
    }
  }
};
