import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { useTranslation } from "react-i18next";

import { Dropdown } from "semantic-ui-react";
import { toExcelNumberFormatWithDecimals } from "../../../utils/format";
import "./VarianceCell.css";

const VarianceCell = ({
  cell: { value: initialValue },
  row: { index },
  column: { id },
  updateTableData, // This is a custom function that we supplied to our table instance
}) => {
  const { t } = useTranslation();
  const standardReasonCodes = [
    {
      key: "supply",
      text: t("ordersTable.reasonCodes.supply"),
      value: "supply",
    },
    {
      key: "demand",
      text: t("ordersTable.reasonCodes.demand"),
      value: "demand",
    },
    {
      key: "internal",
      text: t("ordersTable.reasonCodes.internal"),
      value: "internal",
    },
    {
      key: "other",
      text: t("ordersTable.reasonCodes.other"),
      value: "other",
    },
  ]
  const isStandardReasonCode = (reasonCode) => {
  let isStandard = false
  if (_.isNil(reasonCode)){
    return isStandard
  }
  standardReasonCodes.forEach((reason) => {if (reason.key === reasonCode){isStandard = true}})
  return isStandard
  }

  // We need to keep and update the state of the cell normally
  const [reasonCode, setReasonCode] = React.useState(
    initialValue.reasonCode || ""
  );
  const addToCustomReasonCodes = (newCodeVal) => {
    if( newCodeVal.lenght === 0){
      return false
    }
    let isNewCode = true
    memoizedOptions.forEach((customCode) => {
        if( (customCode.value === newCodeVal) || (customCode.key === newCodeVal)){
        isNewCode = false
        }
      })
    return isNewCode
  }
  const [customReasonCodes] = useState(
    (_.isNil(initialValue.reasonCode) || isStandardReasonCode(initialValue.reasonCode) ?  [] : [
      {key: initialValue.reasonCode, text: initialValue.reasonCode, value:initialValue.reasonCode}])
      );
  const onChange = (e, data) => {
      if (addToCustomReasonCodes(data.value) ){
    customReasonCodes.push({key: data.value, text: data.value, value: data.value })
    }
    setReasonCode(data.value);
    updateTableData(
      index,
      id,
      {
        delta: initialValue.delta,
        reasonCode: data.value,
      },
      true
    );
  };
  const memoizedOptions = useMemo(
    () => {
    
     return [...standardReasonCodes, ...customReasonCodes] 
    
    },
    [standardReasonCodes, customReasonCodes]
  );
  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setReasonCode(initialValue.reasonCode);
  }, [initialValue]);
  
  const getDropdownText = (reasonCode) => {
    if (_.isNil(reasonCode)){
      return ""
    }
    if (reasonCode.length === 0){
      return reasonCode
    }
    if (isStandardReasonCode(reasonCode)){
      return t(`ordersTable.reasonCodes.${reasonCode}`)
    }
    return reasonCode
  }
  const renderInitialValue = (className, initialValue)=>{
    return(
      <span className={className}>
        {initialValue.delta > 0 && <span>+</span>}
        {toExcelNumberFormatWithDecimals(initialValue.delta)}
      </span>
    )
  }

  return (
    <div className="variance-cell-wrapper">
        {initialValue.delta !== 0 
        ? (
            <span className="variance-dropdown">
              <Dropdown
                disabled={initialValue.delta === 0}
                placeholder={t("ordersTable.reasonCodes.placeholder")}
                className="dropdown-text"
                selection
                search
                text={getDropdownText(reasonCode)}
                value={reasonCode}
                onChange={onChange}
                options={memoizedOptions}
                allowAdditions
              ></Dropdown>
              {renderInitialValue("dropdown-var", initialValue)}
            </span>
          )
        : renderInitialValue("variance-value", initialValue)
      }
    </div>
  );
};

VarianceCell.propTypes = {
  cell: PropTypes.object,
  row: PropTypes.object,
  column: PropTypes.object,
  updateTableData: PropTypes.func,
};

export default VarianceCell;
