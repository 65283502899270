import React from "react";
import PropTypes from "prop-types";

import moment from "moment-timezone";

import { connect } from "react-redux";

import _ from "lodash";

import { useTranslation } from "react-i18next";

import { getEvents } from "../../actions/calendar";

import { getSafeSelectedWorkspace } from "../../utils/mappers/user";

import { Segment, Button, Grid, Popup } from "semantic-ui-react";

import { setSelectedRow } from "../../actions/ordersTable";
import { mapItemToBufferData } from "../../utils/mappers/detailsPanel";

import OrderCard from "./OrderCard/OrderCard";
import SvgBufferDetailsChart from "./SvgBufferDetailsChart/SvgBufferDetailsChart";

import BufferNumericalDetails from "./BufferNumericalDetails/BufferNumericalDetails";

import "./DetailsPanel.css";
import { getWorkspaceTimezone } from "../../utils/mappers/workspace";

export const DetailsPanel = ({
  selectedItem,
  hasSelectedRow,
  setSelectedRow,
  events,
  workspaceTimezone,
  advancedSettings
}) => {
  const { t } = useTranslation();

  const bufferData = mapItemToBufferData(selectedItem, advancedSettings);

  const findEvents = (item, events) => {
    const matchingEvents = [];

    const dateStringInWorkspaceTimezone = moment.tz(workspaceTimezone).format("YYYY-MM-DD")
    const momentObjectInWorkspaceTimezone = moment(dateStringInWorkspaceTimezone).utc(true)

    for (const event of events) {
      if (
        _.includes(
          event.groups.map((group) => group.name),
          item.group
        ) &&
        momentObjectInWorkspaceTimezone.isBetween(
          moment(event.start).utc(true),
          moment(event.end).utc(true),
          "day",
          '[)'
        )
      ) {
        matchingEvents.push(event.name);
      }
    }
    return matchingEvents;
  };

  return (
    <div className="details-panel ui one column grid">
      {hasSelectedRow && (
        <div className="row close-details">
          <Popup
            position="left center"
            content="Close Details Panel"
            trigger={
              <Button
                basic
                floated="right"
                icon="close"
                size="medium"
                onClick={() => setSelectedRow(null)}
              />
            }
          />
        </div>
      )}
      <div className="row ">
        <Segment className="details-top-section">
          {!hasSelectedRow ? (
            <div className="empty-state">{t("detailsPanel.chart.empty")}</div>
          ) : (
              <>
                <OrderCard
                  item={{
                    ...selectedItem,
                    events: findEvents(selectedItem, events),
                  }}
                />

                {selectedItem.material_method === "buffered" && (
                  <SvgBufferDetailsChart
                    bufferData={{
                      ...bufferData,
                      partId: selectedItem.location_code_concatenation,
                    }}
                  />
                )}
              </>
            )}
        </Segment>
      </div>
      <div className="row">

        {!hasSelectedRow ? (
          <span></span>
        ) : (
            <Segment
              textAlign={!hasSelectedRow ? "center" : "left"}
              className="details-bottom-section"
            >
              <Grid className="details-bottom-section" container>
                <Grid.Column>
                  <BufferNumericalDetails bufferData={bufferData} />
                </Grid.Column>
              </Grid>
            </Segment>
          )}
      </div>
    </div>
  );
};

DetailsPanel.propTypes = {
  selectedItem: PropTypes.object,
  hasSelectedRow: PropTypes.bool,
  setSelectedRow: PropTypes.func,
  events: PropTypes.array,
  getEvents: PropTypes.func,
  currentWorkspaceId: PropTypes.number,
  workspaceTimezone: PropTypes.string,
  advancedSettings: PropTypes.object,
};

DetailsPanel.defaultProps = {
  selectedItem: {},
  hasSelectedRow: false,
  setSelectedRow: _.noop,
  getEvents: _.noop,
  events: [],
  currentWorkspaceId: null,
  advancedSettings: {},
};

const mapStateToProps = (state) => ({
  selectedItem: _.find(
    state.items.items,
    (item) =>
      item.location_code_concatenation ===
      _.get(
        state,
        "ordersTable.selectedRow.values.location_code_concatenation",
        {}
      )
  ),
  events: state.calendar.events,
  currentWorkspaceId: getSafeSelectedWorkspace(state),
  hasSelectedRow: !_.isNil(state.ordersTable.selectedRow),
  workspaceTimezone: getWorkspaceTimezone(state),
  advancedSettings: state.adminConsole.advancedSettings,
});

export default connect(mapStateToProps, {
  setSelectedRow,
  getEvents,
})(DetailsPanel);
