import _ from "lodash"
import axiosWrapper from "../utils/axiosWrapper";

export const warmupHighMemService = () => () => {
  axiosWrapper.get("/activate-high-mem").catch(_.noop);
};

export const warmupHighMemParsingService = () => () => {
  axiosWrapper.get("/activate-high-mem-parse").catch(_.noop);
};
