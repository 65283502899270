import _ from "lodash";

import {
  VIEW_PERMISSION,
  EDIT_PERMISSION,
  VIEWER_ROLE,
  EDITOR_ROLE,
  ADMIN_ROLE
} from "../constants";

export const mapRolesToColumProperties = roles => {
  const columnProperties = roles
    .map(role => {
      if (role.title === ADMIN_ROLE) {
        return {
          role,
          titleKey: "account.adminConsole.adminColumnName",
          type: "checkbox"
        };
      } else if (role.title === VIEWER_ROLE) {
        return {
          role,
          titleKey: "account.adminConsole.viewWorkspaceColumnName",
          type: "dropdown",
          permission: VIEW_PERMISSION
        };
      } else if (role.title === EDITOR_ROLE) {
        return {
          role,
          titleKey: "account.adminConsole.editWorkspaceColumnName",
          type: "dropdown",
          permission: EDIT_PERMISSION
        };
      }
      return null;
    })
    .filter(props => props !== null);

  return _.sortBy(columnProperties, prop => prop.role.title);
};
