import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";

import _ from "lodash";

import classNames from "classnames";

import "./Table.css";

const Table = ({ instance, onSelectRow, selectedRowId, allowSelectRow=false }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = instance;


  const [ selectedRowIdNum, setSelectedRowIdNum ] = useState()

  //This code is used to change row color status when user click in a row
  const wasSelectedThisRow = useMemo(()=>{
    if(allowSelectRow){
      const rowStatusArray = new Array(rows.length).fill(false)

      if(selectedRowIdNum){
        return rowStatusArray.map((_, index)=> index === Number(selectedRowIdNum) ? true : false)
      }

      return rowStatusArray
    }

    return []
  }, [ selectedRowIdNum, allowSelectRow, rows])


  return (
    <div className="data-import-table">
      <table className="ui basic table" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            // eslint-disable-next-line react/jsx-key
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // eslint-disable-next-line react/jsx-key
                <th 
                  {...column.getHeaderProps()}
                  // To customize head modify by this css class
                  // column.id is the accessor name
                  className={`th-class-column ${column.id}`}>
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              // eslint-disable-next-line react/jsx-key
              <tr
                {...row.getRowProps()}
                onClick={() => {
                  if(allowSelectRow){
                    onSelectRow(row)
                    setSelectedRowIdNum(row.id)
                  }
                }}
                className={classNames("collapsing", 
                                      "upload-log-entry", 
                                      {active: selectedRowId === row.id}, 
                                      {hoverRow: allowSelectRow},
                                      {selectRow: wasSelectedThisRow.length ? wasSelectedThisRow[i] : false}
                                      )}
              >
                {row.cells.map((cell) => {
                  return (
                    // eslint-disable-next-line react/jsx-key
                    <td
                      key={cell.value}
                      className={classNames({
                        centered:
                          cell.column.id === "errors" &&
                          !_.isEmpty(_.get(row, "values.errors", [])),
                        [`td-class-column ${cell.column.id}`]: true
                      })}
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

Table.propTypes = {
  instance: PropTypes.object.isRequired,
  onSelectRow: PropTypes.func,
  selectedRowId: PropTypes.string,
  allowSelectRow: PropTypes.bool,
  selectRowStatus: PropTypes.array,
};

Table.defaultProps = {
  onSelectRow: _.noop,
  selectedRowId: "-1",
};

export default Table;
