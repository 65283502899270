import _ from "lodash";
import {
    BEGIN_SINGLE_CALCULATION,
    FAILED_SINGLE_CALCULATION,
    END_SINGLE_CALCULATION,
    CLEAR_SINGLE_CALCULATION,
    BEGIN_SINGLE_CALCULATION_FOR_TABLE_CELL,
    END_SINGLE_CALCULATION_FOR_TABLE_CELL,
    BEGIN_LOAD_DYNAMIC_FIELDS_METADATA,
    END_LOAD_DYNAMIC_FIELDS_METADATA,
    FAILED_LOAD_DYNAMIC_FIELDS_METADATA,
    BEGIN_DYNAMIC_FIELDS_REQUEST,
    FAILED_DYNAMIC_FIELDS_REQUEST,
    BEGIN_GET_DYNAMIC_FIELDS,
    FAILED_GET_DYNAMIC_FIELDS,
    GET_DYNAMIC_FIELDS,
    SAVE_DYNAMIC_FIELD,
    EDIT_DYNAMIC_FIELD,
    DELETE_DYNAMIC_FIELD,
    RESET_DRAFT_DYNAMIC_FIELD,
    UPDATE_DRAFT_DYNAMIC_FIELD,
    SET_DRAFT_DYNAMIC_FIELD,
} from '../actions/types';

const initialState = {
    crudOperationError: false,
    draftDynamicField: {},
    dynamicFieldItems: [],
    loadingCrudOperation: false,
    loadingDynamicFields: false,
    loadingMetadata: false,
    loadingSingleCalcResult: false,
    singleCalcResult: '',
    singleCalcError: null,
    singleCalcTableCellResults: [],

    // metadata
    additionalFieldNames: [],
    allFieldNames: [],
    dynamicFieldNames: [],
    dynamicFieldNamesTitlesMap: {},
    dynamicFieldsWithActualOrder: {},
}

export default function (state = initialState, action) {
    const { type, payload } = action;
    let newSingleCalcTableCellResults;

    switch (type) {
        case BEGIN_SINGLE_CALCULATION:
            return {
                ...state,
                loadingSingleCalcResult: true,
                singleCalcResult: ''
            };

        case END_SINGLE_CALCULATION:
            return {
                ...state,
                loadingSingleCalcResult: false,
                singleCalcResult: payload
            };

        case FAILED_SINGLE_CALCULATION:
            return {
                ...state,
                loadingSingleCalcResult: false,
                singleCalcResult: '',
                singleCalcError: ''//payload.error
            };

        case BEGIN_SINGLE_CALCULATION_FOR_TABLE_CELL:
            newSingleCalcTableCellResults = state.singleCalcTableCellResults;
            if (!newSingleCalcTableCellResults[payload.rowIndex]) {
                newSingleCalcTableCellResults[payload.rowIndex] = {};
            }
            newSingleCalcTableCellResults[payload.rowIndex][payload.columnId] = '';
            return {
                ...state,
                singleCalcTableCellResults: [...newSingleCalcTableCellResults]
            };

        case END_SINGLE_CALCULATION_FOR_TABLE_CELL:
            newSingleCalcTableCellResults = state.singleCalcTableCellResults;
            if (!newSingleCalcTableCellResults[payload.rowIndex]) {
                newSingleCalcTableCellResults[payload.rowIndex] = {};
            }
            newSingleCalcTableCellResults[payload.rowIndex][payload.columnId] = payload.result;
            return {
                ...state,
                singleCalcTableCellResults: [...newSingleCalcTableCellResults]
            };


        case CLEAR_SINGLE_CALCULATION:
            return {
                ...state,
                loadingSingleCalcResult: false,
                singleCalcResult: '',
                singleCalcError: ''
            };

        case BEGIN_LOAD_DYNAMIC_FIELDS_METADATA:
            return {
                ...state,
                loadingMetadata: true,
                allFieldNames: [],
                dynamicFieldNames: [],
                additionalFieldNames: [],
                dynamicFieldNamesTitlesMap: {},
                dynamicFieldsWithActualOrder: {},
            };

        case END_LOAD_DYNAMIC_FIELDS_METADATA:
            return {
                ...state,
                loadingMetadata: false,
                allFieldNames: payload.all_fields,
                dynamicFieldNames: payload.dynamic_fields,
                additionalFieldNames: payload.additional_fields,
                dynamicFieldNamesTitlesMap: payload.dynamic_fields_names_titles,
                dynamicFieldsWithActualOrder: payload.actual_order_dynamic_fields,
            };

        case FAILED_LOAD_DYNAMIC_FIELDS_METADATA:
            return {
                ...state,
                loadingMetadata: false,
            };

        case BEGIN_GET_DYNAMIC_FIELDS:
            return {
                ...state,
                loadingDynamicFields: true,
                dynamicFieldItems: []
            };

        case GET_DYNAMIC_FIELDS:
            return {
                ...state,
                loadingDynamicFields: false,
                dynamicFieldItems: payload
            };

        case FAILED_GET_DYNAMIC_FIELDS:
            return {
                ...state,
                loadingDynamicFields: false,
                dynamicFieldItems: []
            };

        case BEGIN_DYNAMIC_FIELDS_REQUEST:
            return {
                ...state,
                crudOperationError: false,
                loadingCrudOperation: true,
            };

        case FAILED_DYNAMIC_FIELDS_REQUEST:
            return {
                ...state,
                crudOperationError: true,
                loadingCrudOperation: false,
            };

        case SAVE_DYNAMIC_FIELD:
            return {
                ...state,
                draftDynamicField: {},
                crudOperationError: false,
                loadingCrudOperation: false,
            };

        case EDIT_DYNAMIC_FIELD:
            return {
                ...state,
                draftDynamicField: {},
                dynamicFieldItems: [
                    ...state.dynamicFieldItems.filter(item => item.id !== payload.dynamic_field_id),
                    _.extend(
                        _.mapKeys(payload, (value, key) => (key === 'dynamic_field_id' ? 'id' : key)),
                        {key: payload.dynamic_field_id}
                    )
                ],
                crudOperationError: false,
                loadingCrudOperation: false,
            };

        case DELETE_DYNAMIC_FIELD:
            return {
                ...state,
                draftDynamicField: {},
                dynamicFieldItems: payload ?
                    state.dynamicFieldItems.filter(item => item.id !== payload.id) :
                    state.dynamicFieldItems,
                crudOperationError: false,
                loadingCrudOperation: false,
            };

        case SET_DRAFT_DYNAMIC_FIELD:
            return {
                ...state,
                draftDynamicField: payload,
            };

        case RESET_DRAFT_DYNAMIC_FIELD:
            return {
                ...state,
                draftDynamicField: {}
            };

        case UPDATE_DRAFT_DYNAMIC_FIELD:
            return {
                ...state,
                draftDynamicField: _.assign(state.draftDynamicField, {[payload.field]: payload.value})
            };
            
        default:
            return state;
    }
}
