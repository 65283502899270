import {
  GET_EVENTS,
  ADD_EVENT,
  EDIT_EVENT,
  REMOVE_EVENT,
  BEGIN_EVENT_FETCH,
  ABORT_EVENTS_REQUEST,
  BEGIN_EVENT_REQUEST,
  GET_WORKSPACES,
  SELECT_VISIBLE_WORKSPACE_EVENTS
} from "../actions/types";

//import { events } from "../utils/mocks/calendar";

/*
event structure :
{ 
	id: title: "Event 1", 
	start: new Date(), 
	end: new Date(),
	allDay: true, 
	editable: true
}
*/

const initialState = {
  events: [],
  loadingPutPost: true,
  loadingPutPostFetch: true,
  selectedWorkspaceIds: []
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_WORKSPACES:
      return {
        ...state,
        selectedWorkspaceIds: payload.map((workspace) => workspace.id)
      }
    case SELECT_VISIBLE_WORKSPACE_EVENTS:
      return {
        ...state,
        selectedWorkspaceIds: payload
      }
    case GET_EVENTS:
      return {
        ...state,
        loadingPutPost: false,
        loadingFetch: false,
        events: payload,
      };
    case ADD_EVENT:
      return {
        ...state,
        loadingPutPost: false,
        events: [
          ...state.events,
          { ...payload, title: payload.name, editable: true },
        ],
      };
    case EDIT_EVENT:
      return {
        ...state,
        loadingPutPost: false,
        events: [
          ...state.events.filter((event) => event.id !== payload.id),
          { ...payload },
        ],
      };
    case REMOVE_EVENT:
      return {
        ...state,
        loadingPutPost: false,
        loadingFetch: false,
        events: state.events.filter((event) => event.id !== payload),
      };
    case BEGIN_EVENT_FETCH:
      return { ...state, loadingFetch: true };
    case BEGIN_EVENT_REQUEST:
      return { ...state, loadingPutPost: true };
    case ABORT_EVENTS_REQUEST:
      return { ...state, loadingPutPost: false, loadingFetch: false };
    default:
      return state;
  }
}
