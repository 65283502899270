import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  SET_CONNECTION_STATUS,
  SET_PASSWORD_ICON,
  BEGIN_AUTH_RECOVER_PASS_TOKEN,
  END_AUTH_RECOVER_PASS_TOKEN,
  RECOVER_PASS_MESSAGE
} from "../actions/types";

import _ from "lodash";

import { mapUserRolesToEditableWorkspaceIds } from "../utils/mappers/workspace";

const initialState = {
  session_id: localStorage.getItem("session_id"),
  isAuthenticated: false, // to toggle stuffonly logged in users can see
  loading: true, // make sure we've already made a request to backend and got a response
  user: null,
  isConnected: true,
  connectionStatusMessage: "connected",
  inputType: {type: "password", iconName: "eye"},
  messageRecoverPassword: ""
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_CONNECTION_STATUS:
      return {
        ...state,
        isConnected: payload.isConnected,
        connectionStatusMessage: payload.connectionStatusMessage,
      };
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        user: {
          ...payload,
          isAdmin: payload.roles && _.has(payload.roles, null),
          editableWorkspaces: mapUserRolesToEditableWorkspaceIds(payload.roles),
        },
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        isAuthenticated: false,
        loading: false,
      };
    case LOGIN_SUCCESS:
      // TODO: search better storage method
      // TODO: confirm what else should be stored about session
      localStorage.setItem("session_id", payload.session_id);
      return {
        ...state,
        session_id: payload.session_id,
        isAuthenticated: true,
        loading: false,
      };
    case LOGIN_FAIL:
    case LOGOUT:
    case REGISTER_FAIL:
    case AUTH_ERROR:
      // so that we never store a token that's not valid in local storage
      localStorage.removeItem("session_id");
      return {
        ...state,
        session_id: null,
        isAuthenticated: false,
        loading: false,
      };
    case SET_PASSWORD_ICON:
      return{
        ...state,
        inputType: payload
      }
    case BEGIN_AUTH_RECOVER_PASS_TOKEN:
      return{
        ...state,
        loading: true
      }
    case END_AUTH_RECOVER_PASS_TOKEN:
      return{
        ...state,
        loading: false
      }
    case RECOVER_PASS_MESSAGE:
      return{
        ...state,
        messageRecoverPassword: payload
      }
    default:
      return state;
  }
}
