import Pusher from 'pusher-js';

/*Pusher.log = function (message) {
  if (window.console && window.console.log) { window.console.log(message); }
};*/

// eslint-disable-next-line no-undef
export const pusherInstance = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
  // eslint-disable-next-line no-undef
  cluster: process.env.REACT_APP_PUSHER_CLUSTER,
  activityTimeout: 5000,
  pongTimeout: 30000,
  forceTLS: true,
});
