import _ from "lodash";

export const visibleToken = (token) => {
    if (_.isNil(token)) {return null}
    const lenToken = token.length
    const lineLength = 100
    let startCount = 0
    let endcount = lineLength
    let isAllTokenScanned = false
    const dividedToken = []
    while(!isAllTokenScanned){
        dividedToken.push(token.substring(startCount, endcount))
        startCount = endcount
        endcount = endcount + lineLength

        isAllTokenScanned = dividedToken.join('').length === lenToken
    }
    return dividedToken
}

export const formatIsoDate = (isoDate) => {
    const formattedDate =  new Date(isoDate.concat('Z') )
    return formattedDate.toLocaleString()
 }

