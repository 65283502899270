import React from "react";
import _ from "lodash";

import moment from "moment";

import {
  GET_UPLOAD_HISTORY,
  GET_FILETYPE_LAST_UPDATED,
  BEGIN_FILETYPE_LAST_UPDATED_FETCH,
  ABORT_FILETYPE_LAST_UPDATED_FETCH,
  UPLOAD_FILE,
  UPDATE_FILE_STATUS,
  ABORT_FILE_UPLOAD,
  BEGIN_UPLOAD_HISTORY_FETCH,
  ABORT_UPLOAD_HISTORY_FETCH,
} from "../actions/types";

import { PROCESSING_STATUS, UPLOADING_STATUS } from "../utils/constants";

import axiosWrapper from "../utils/axiosWrapper";

import axios from "axios";

import uuid from "uuid";

import { toast } from "react-toastify";

import ToastI18nWrapper from "../components/ToastI18nWrapper/ToastI18nWrapper";

import { getBatchInventoryModeValue } from "../utils/mappers/workspace";

export const FILE_PROCESSING_TIMEOUT_MS = 900000;
export const checkProcessingTimeouts = () => async (dispatch, getState) => {
  let entries = getState().files.logEntries;

  if (_.isEmpty(entries)) {
    return;
  }

  try {
    let timeout = false;

    entries = entries.map((entry) => {
      if (entry.status === "processing") {
        let created = moment(entry.created_at);
        let diff = moment(Date.now()).diff(created);
        if (diff >= FILE_PROCESSING_TIMEOUT_MS) {
          timeout = true;
          return {
            ...entry,
            status: "failed",
            error_log: { errors: [{ error_type: "backend_error" }] },
          };
        }
      }
      return entry;
    });

    if (timeout) {
      dispatch({ type: GET_UPLOAD_HISTORY, payload: entries });
    }
  } catch (e) {
    // console.log(e);
  }
};

export const updateUploadHistory = (updatedEntry) => async (
  dispatch,
  getState
) => {
  let payload = getState().files.logEntries;

  if (_.some(payload, ["signed_url_id", String(updatedEntry.signed_url_id)])) {
    payload = payload.map((entry) =>
      String(entry.signed_url_id) === String(updatedEntry.signed_url_id)
        ? { ...updatedEntry }
        : entry
    );
  } else {
    payload = [...payload, updatedEntry];
  }

  dispatch({ type: GET_UPLOAD_HISTORY, payload });
};

export const getUploadHistory = (
  currentWorkspaceId,
  inBackground = false
) => async (dispatch) => {
  try {
    if (!inBackground) {
      dispatch({ type: BEGIN_UPLOAD_HISTORY_FETCH });
    }

    const response = await axiosWrapper.get(
      `/data-import-log?current_workspace_id=${currentWorkspaceId}`
    );
    const uploads = response.data[0].message.map((entry) => entry.log_instance);
    dispatch({ type: GET_UPLOAD_HISTORY, payload: uploads });
  } catch (error) {
    dispatch({ type: ABORT_UPLOAD_HISTORY_FETCH });
    toast.error(<ToastI18nWrapper translateKey={"genericError"} />, {
      autoClose: 3000,
      toastId: "genericError",
    });
  }
};

export const getFileTypeLastUpdated = (currentWorkspaceId) => async (
  dispatch
) => {
  try {
    dispatch({ type: BEGIN_FILETYPE_LAST_UPDATED_FETCH });

    const response = await axiosWrapper.get(
      `/data-import-latest?current_workspace_id=${currentWorkspaceId}`
    );
    const responseData = response.data
    
    // This condition only happend when response code is 204
    if(_.isEmpty(responseData)){
      dispatch({
        type: GET_FILETYPE_LAST_UPDATED,
        payload: { master: null, inventory: null, historical_demand: null },
      });
      return
    }

    const fileDates = {};
    for (const key of Object.keys(responseData)) {
      let mappedKey;
      switch (key) {
        case "master_file":
          mappedKey = "master";
          break;
        case "inventory_id":
          mappedKey = "inventory";
          break;
        case "historical_demand_id":
          mappedKey = "historical_demand";
          break;
        default:
          continue;
      }
      fileDates[mappedKey] = responseData[key].created_at;
    }

    dispatch({ type: GET_FILETYPE_LAST_UPDATED, payload: fileDates });
  } catch (error) {

    dispatch({ type: ABORT_FILETYPE_LAST_UPDATED_FETCH });
    toast.error(<ToastI18nWrapper translateKey={"genericError"} />, {
      toastId: "genericError",
    });

  }
};

export const uploadFile = (
  currentWorkspaceId,
  formData,
  currentLanguage
) => async (dispatch, getState) => {
  // TODO: request for signed url
  const file = formData.get("file");
  const typeFile = formData.get("type");
  const { name } = file;

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  
  const body = JSON.stringify({
    current_workspace_id: currentWorkspaceId,
    file_section: formData.get("type"),
    file_type: name
      .split("")
      .splice(name.lastIndexOf(".") + 1)
      .join(""),
    original_filename: name,
    lang: currentLanguage,
    has_batch_data: getBatchInventoryModeValue(
      getState().workspace.workspaces,
      currentWorkspaceId
    ),
  });

  const temp_id = uuid.v4();

  try {
    dispatch({
      type: UPLOAD_FILE,
      payload: {
        id: temp_id,
        original_filename: name,
        created_at: "...",
        status: UPLOADING_STATUS,
        file_section: typeFile,
      },
    });

    const signedUrlResponse = await axiosWrapper.post(
      "/signed-url",
      body,
      config
    );

    await axios.put(signedUrlResponse.data.signed_url, file, {
      headers: {
        "Content-Type": "application/octet-stream",
      },
    });

    dispatch({
      type: UPDATE_FILE_STATUS,
      payload: {
        id: temp_id,
        signed_url_id: signedUrlResponse.data.file_name.split("-")[0],
        status: PROCESSING_STATUS,
        created_at: Date.now(),
      },
    });

    const hostName = window.location.hostname
    // eslint-disable-next-line no-undef
    if(hostName.includes("localhost") || hostName.includes("127.0.0.1") ){
      // For dev testing:
      // To automatically hit the parsing endpoint after upload is done

      const encoded_data = JSON.stringify(btoa(JSON.stringify({
        serialized_file_name: signedUrlResponse.data.file_name,
        download_file_name: null,
        bucket_name: null,
        // eslint-disable-next-line no-undef
        cf_key: process.env.REACT_APP_CF_KEY,
      })))

      const body = {
        "message":{
          "data": encoded_data,
        }
      }

      await axiosWrapper.post(
        "/parse-file",
        body,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    }

  } catch (error) {
    if (!_.isNil(error.response) && error.response.status === 400) {
      dispatch(getUploadHistory(currentWorkspaceId, true));
      dispatch(getFileTypeLastUpdated(currentWorkspaceId));
      dispatch({ type: ABORT_FILE_UPLOAD });
      if (!_.isNil(error.response.data)) {
        error.response.data.forEach((e) =>
          toast.error(
            <ToastI18nWrapper
              translateKey={`dataImport.backendValidationErrors.${e.message}`}
            />,
            {
              autoClose: 2500,
            }
          )
        );
      }
    } else {
      toast.error(<ToastI18nWrapper translateKey={"genericError"} />, {
        autoClose: 2500,
        toastId: "genericError",
      });
    }
  }
};
