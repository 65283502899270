import React from "react";

import {
  SAVE_GROUP,
  GET_GROUPS,
  GET_FATHER_GROUPS,
  EDIT_GROUP,
  REMOVE_GROUP,
  AUTH_ERROR,
  BEGIN_GROUPS_REQUEST,
  ABORT_GROUPS_REQUEST,
  BEGIN_GROUP_FETCH,
} from "./types";

import axiosWrapper from "../utils/axiosWrapper";

import { mapBufferSettingGroup } from "../utils/mappers/groupSettings";

import _ from "lodash";

import { toast } from "react-toastify";

import ToastI18nWrapper from "../components/ToastI18nWrapper/ToastI18nWrapper";

export const getGroups = (currentWorkspaceId, inBackground = false) => async (
  dispatch
) => {
  if (!currentWorkspaceId) {
    dispatch({
      type: GET_GROUPS,
      payload: [],
    });
    return;
  }
  try {
    if (!inBackground) {
      dispatch({ type: BEGIN_GROUP_FETCH });
    }
    const response = await axiosWrapper.get(
      `/buffer-settings?current_workspace_id=${currentWorkspaceId}`
    );
    dispatch({
      type: GET_GROUPS,
      payload: response.data.map(mapBufferSettingGroup),
    });
    dispatch(getFatherGroups(currentWorkspaceId))
  } catch (error) {
    if (!_.isNil(error.response) && error.response.status === 401) {
      dispatch({ type: AUTH_ERROR });
    }
    dispatch({ type: ABORT_GROUPS_REQUEST });
  }
};

export const saveGroup = (
  currentWorkspaceId,
  group,
  isCurrentOrderTableCommitted
) => async (dispatch, getState) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const filters = _.pick(group, Object.keys(getState().dataset.columns));

  const rawBody = {
    current_workspace_id: currentWorkspaceId,
    name: group.name,
    description: group.description || "",
    filters: filters,
    material_method: group.rules.materialMethod,
    adu_measurement_start: group.rules.aduMeasurementStart,
    adu_measurement_end: group.rules.aduMeasurementEnd,
    lead_time_factor: group.rules.leadTimeFactor,
    variability_factor: group.rules.variabilityFactor,
    calculation_required: !isCurrentOrderTableCommitted
  };

  const body = JSON.stringify(rawBody);

  try {
    dispatch({
      type: BEGIN_GROUPS_REQUEST,
    });
    await axiosWrapper.post("/buffer-settings", body, config);
    dispatch({
      type: SAVE_GROUP,
    });
  } catch (error) {
    dispatch({ type: ABORT_GROUPS_REQUEST });
    dispatch(getGroups(currentWorkspaceId));

    if (!_.isNil(error.response)) {
      switch (error.response.status) {
        case 401:
          dispatch({ type: AUTH_ERROR });
          break;
        case 412:
          toast.error(
            <ToastI18nWrapper
              translateKey={"groupSettings.groupCollision"}
              data={{
                conflicting_group_names: Object.keys(
                  error.response.data[0].colliding_materials
                ).join(", "),
              }}
            />,
            { toastId: "groupCollision" }
          );
          break;
        default:
          toast.error(<ToastI18nWrapper translateKey={"genericError"} />, {
            toastId: "genericError",
          });
          break;
      }
    } else {
      toast.error(<ToastI18nWrapper translateKey={"genericError"} />, {
        toastId: "genericError",
      });
    }
  }
};

export const editGroup = (
  currentWorkspaceId,
  group,
  isCurrentOrderTableCommitted
) => async (dispatch, getState) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const filters = _.pick(group, Object.keys(getState().dataset.columns));

  const rawBody = {
    current_workspace_id: currentWorkspaceId,
    group_id: group.id,
    name: group.name,
    description: group.description || "",
    filters: filters,
    material_method: group.rules.materialMethod,
    adu_measurement_start: group.rules.aduMeasurementStart,
    adu_measurement_end: group.rules.aduMeasurementEnd,
    lead_time_factor: group.rules.leadTimeFactor,
    variability_factor: group.rules.variabilityFactor,
    calculation_required: !isCurrentOrderTableCommitted
  };
  const body = JSON.stringify(rawBody);

  try {
    dispatch({
      type: BEGIN_GROUPS_REQUEST,
    });
    const response = await axiosWrapper.put("/buffer-settings", body, config);
    const group = response.data;
    dispatch({
      type: EDIT_GROUP,
      payload: mapBufferSettingGroup(group),
    });
    getGroups(currentWorkspaceId)

  } catch (error) {
    dispatch({ type: ABORT_GROUPS_REQUEST });
    dispatch(getGroups(currentWorkspaceId));

    if (!_.isNil(error.response)) {
      switch (error.response.status) {
        case 401:
          dispatch({ type: AUTH_ERROR });
          break;
        case 412:
          toast.error(
            <ToastI18nWrapper
              translateKey={"groupSettings.groupCollision"}
              data={{
                conflicting_group_names: Object.keys(
                  error.response.data[0].colliding_materials
                ).join(", "),
              }}
            />,
            { toastId: "groupCollision" }
          );
          break;
        default:
          toast.error(<ToastI18nWrapper translateKey={"genericError"} />, {
            toastId: "genericError",
          });
          break;
      }
    } else {
      toast.error(<ToastI18nWrapper translateKey={"genericError"} />, {
        toastId: "genericError",
      });
    }
  }
};

export const removeGroup = (
  currentWorkspaceId,
  groupId,
  isCurrentOrderTableCommitted
) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify({
      group_id: groupId,
      current_workspace_id: currentWorkspaceId,
      calculation_required: !isCurrentOrderTableCommitted
    }),
  };
  try {
    dispatch({
      type: BEGIN_GROUP_FETCH,
    });
    const response = await axiosWrapper.delete("/buffer-settings", config);
    dispatch({
      type: REMOVE_GROUP,
      payload: response.data.id,
    });
    dispatch(getFatherGroups(currentWorkspaceId))

  } catch (error) {
    dispatch({ type: ABORT_GROUPS_REQUEST });
    if (!_.isNil(error.response)) {
      if (error.response.status === 401) {
        dispatch({ type: AUTH_ERROR });
      } else if (error.response.status === 412) {
        toast.error(
          <ToastI18nWrapper translateKey={"groupSettings.groupRemoveError"} />,
          { toastId: "groupRemoveError" }
        );
      }
    } else {
      toast.error(<ToastI18nWrapper translateKey={"genericError"} />, {
        toastId: "genericError",
      });
    }
  }
};

export const getFatherGroups = (
  currentWorkspaceId

) => async (dispatch) => {
  if (currentWorkspaceId == null) {
    return
  }

  try {
    const response = await axiosWrapper.get(
      `/singular-calendar-settings?current_workspace_id=${currentWorkspaceId}`
    );
    dispatch({
      type: GET_FATHER_GROUPS,
      payload: response.data,
    });
  } catch (error) {
    if (!_.isNil(error.response) && error.response.status === 401) {
      dispatch({ type: AUTH_ERROR });
    }
    dispatch({ type: ABORT_GROUPS_REQUEST });
  }
};
export const saveEventGroup = (
  currentWorkspaceId,
  group,
) => async (dispatch, getState) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const filters = _.pick(group, Object.keys(getState().dataset.columns));

  const rawBody = {
    current_workspace_id: currentWorkspaceId,
    name: group.name,
    description: group.description || "",
    filters: filters,
    material_method: group.rules.materialMethod,
    adu_measurement_start: group.rules.aduMeasurementStart,
    adu_measurement_end: group.rules.aduMeasurementEnd,
    lead_time_factor: group.rules.leadTimeFactor,
    variability_factor: group.rules.variabilityFactor,
  }

  const body = JSON.stringify(rawBody);

  try {
    dispatch({
      type: BEGIN_GROUPS_REQUEST,
    });
    await axiosWrapper.post("/singular-calendar-settings", body, config);
    dispatch({
      type: SAVE_GROUP,
    });
    dispatch(getFatherGroups(currentWorkspaceId))
  } catch (error) {
    dispatch({ type: ABORT_GROUPS_REQUEST });
    dispatch(getGroups(currentWorkspaceId));

    if (!_.isNil(error.response)) {
      switch (error.response.status) {
        case 401:
          dispatch({ type: AUTH_ERROR });
          break;
        case 412:
          toast.error(
            <ToastI18nWrapper
              translateKey={"groupSettings.subgroupMaterialsConflict"}
              data={{
                conflicting_group_names: Object.keys(
                  error.response.data[0].colliding_materials
                ).join(", "),
              }}
            />,
            { toastId: "groupCollision" }
          );
          break;
        default:
          toast.error(<ToastI18nWrapper translateKey={"genericError"} />, {
            toastId: "genericError",
          });
          break;
      }
    } else {
      toast.error(<ToastI18nWrapper translateKey={"genericError"} />, {
        toastId: "genericError",
      });
    }
  }
};

export const editEventGroup = (
  currentWorkspaceId,
  group,
  isCurrentOrderTableCommitted
) => async (dispatch, getState) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const filters = _.pick(group, Object.keys(getState().dataset.columns));
  const rawBody = {
    current_workspace_id: currentWorkspaceId,
    group_id: group.id,
    name: group.name,
    description: group.description || "",
    filters: filters,
    material_method: group.rules.materialMethod,
    adu_measurement_start: group.rules.aduMeasurementStart,
    adu_measurement_end: group.rules.aduMeasurementEnd,
    lead_time_factor: group.rules.leadTimeFactor,
    variability_factor: group.rules.variabilityFactor,
    mirror_event_subgroup: group.mirrorEventSubgroup,
    calculation_required: !isCurrentOrderTableCommitted
  };
  const body = JSON.stringify(rawBody);

  try {
    dispatch({
      type: BEGIN_GROUPS_REQUEST,
    });
    const response = await axiosWrapper.put("/singular-calendar-settings", body, config);
    const group = response.data;
    dispatch({
      type: EDIT_GROUP,
      payload: mapBufferSettingGroup(group),
    });
    getGroups(currentWorkspaceId)

  } catch (error) {
    dispatch({ type: ABORT_GROUPS_REQUEST });
    dispatch(getGroups(currentWorkspaceId));

    if (!_.isNil(error.response)) {
      switch (error.response.status) {
        case 401:
          dispatch({ type: AUTH_ERROR });
          break;
        case 412:
          toast.error(
            <ToastI18nWrapper
              translateKey={"groupSettings.subgroupMaterialsConflict"}
              data={{
                conflicting_group_names: 'no group coincidence'
              }}
            />,
            { toastId: "groupCollision" }
          );
          break;
        default:
          toast.error(<ToastI18nWrapper translateKey={"genericError"} />, {
            toastId: "genericError",
          });
          break;
      }
    } else {
      toast.error(<ToastI18nWrapper translateKey={"genericError"} />, {
        toastId: "genericError",
      });
    }
  }
};