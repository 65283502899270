import React from 'react'
import { Grid, Loader, Segment, Dimmer } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _ from "lodash";
import moment from "moment";
import { editEvent } from '../../../actions/calendar';
import { getSafeSelectedWorkspace } from '../../../utils/mappers/user';
import { resolveEditPermission } from '../../../utils/mappers/workspace';
import { dateInputFormat, calendarDateInputFormat } from '../../../utils/format';
import { mapCalendarEventsDaysOnly } from "../../../utils/mappers/calendar";

import FullCalendar from "@fullcalendar/react";
import allLocales from "@fullcalendar/core/locales-all";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import './EventCalendar.css'
import styled from '@emotion/styled';

const defaultWorkingDays = [2, 3, 4, 5];
const defaultOrderingDays = [1, 6];

export const CalendarStyleWrapper = styled.div`
  .fc-widget-content{
    padding: 0.4em 0.1em !important;
  }  

  .fc.table{
    border-spacing: 0.4em !important;
  }
`

const EventCalendar = ({
    loadingFetch,
    loadingPutPost,
    selectedWorkspaceIds,
    events,
    canEditCurrentWorkspace,
    currentWorkspaceId,
    isOrdersTableCommitted,
    clickDate,
    openNewEventModal,
    clickEvent,
    openEditEventModal
    }) => {

    const { t, i18n } = useTranslation();

    const filterEvents = () => {
        //console.log('selectedWorkspaceIds: ', selectedWorkspaceIds);
        const filtered = [];
        selectedWorkspaceIds.forEach((id) => {
          filtered.push(...events.filter((event) => event.workspace_id === id))
        });
        return mapCalendarEventsDaysOnly(filtered);
    };

    const getFixedEnd = (start, end) => {
      if (_.isObject(end)) {
        return moment(
          dateInputFormat(end),
          "YYYY-MM-DD"
        ).format();
      }
  
      return moment(dateInputFormat(start), "YYYY-MM-DD")
        .add(1, "days")
        .format();
    };

    const customDayRender = (dayRenderInfo) => {
      if (_.includes(defaultWorkingDays, dayRenderInfo.date.getDay())) {
        dayRenderInfo.el.classList.add("working-day");
      } else if (_.includes(defaultOrderingDays, dayRenderInfo.date.getDay())) {
        dayRenderInfo.el.classList.add("ordering-day");
      }
    };
    
    return (
        <Grid.Column className='event-calendar-container'>
            <Grid.Row>
              {loadingFetch ? (
                <div className="ui container">
                  <Segment style={{ minHeight: "600px" }}>
                    <Loader active size="large" />
                  </Segment>
                </div>
              ) : (
                  <Dimmer.Dimmable
                    className="full-calendar"
                    dimmed={loadingPutPost}
                  >
                    <Dimmer inverted active={loadingPutPost}>
                      {loadingPutPost && (
                        <div className="ui container">
                          <Loader active size="large">
                            {t("calendar.updating")}
                          </Loader>
                        </div>
                      )}
                    </Dimmer>
                    
                    <CalendarStyleWrapper>
                      <FullCalendar
                        height={700}
                        defaultView="dayGridMonth"
                        header={{
                          left: "prev,next,today",
                          center: "title",
                          right: "dayGridMonth, dayGridWeek",
                        }}
                        displayEventTime={false}
                        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                        locales={allLocales}
                        locale={i18n.language}
                        events={filterEvents(events).map((event) => ({
                          ...event,
                          editable: canEditCurrentWorkspace
                        }))}
                        dateClick={(dateClickInfo) => {
                          if (!canEditCurrentWorkspace) {
                            return;
                          }
                          clickDate(dateClickInfo.date)
                          openNewEventModal(canEditCurrentWorkspace && true);
                          //setClickedDate(dateClickInfo.date);
                          //setOpenNewEventModal(canEditCurrentWorkspace && true);
                        }}
                        eventDrop={(info) => {
                          const { title, start, end } = info.event;

                          editEvent(
                            currentWorkspaceId,
                            info.event,
                            title,
                            calendarDateInputFormat(start),
                            getFixedEnd(start, end),
                            _.get(
                              info.event,
                              "extendedProps.quantity_per_day",
                              null
                            ),
                            _.get(info.event, "extendedProps.adus_per_day", null),
                            _.get(info.event, "extendedProps.groups", []).map(
                              (group) => group.id
                            ),
                            isOrdersTableCommitted
                          );
                        }}
                        eventResize={(info) => {
                          const { title, start, end } = info.event;

                          editEvent(
                            currentWorkspaceId,
                            info.event,
                            title,
                            calendarDateInputFormat(start),
                            getFixedEnd(start, end),
                            _.get(
                              info.event,
                              "extendedProps.quantity_per_day",
                              null
                            ),
                            _.get(info.event, "extendedProps.adus_per_day", null),
                            _.get(info.event, "extendedProps.groups", []).map(
                              (group) => group.id
                            ),
                            isOrdersTableCommitted
                          );
                        }}
                        eventClick={(info) => {
                          if (
                            info.event.extendedProps.workspace_id ===
                            currentWorkspaceId
                          ) {
                            clickEvent(info.event);
                            openEditEventModal(true);
                            //setClickedEvent(info.event);
                            //setOpenEditEventModal(true);
                          }
                        }}
                        dayRender={customDayRender}
                      />
                    </CalendarStyleWrapper>
                  </Dimmer.Dimmable>
                )}
            </Grid.Row>
        </Grid.Column>
    )
}

EventCalendar.propTypes = {
    selectedWorkspaceIds: PropTypes.array,
    loadingFetch: PropTypes.bool,
    loadingPutPost: PropTypes.bool,
    events: PropTypes.array,
    canEditCurrentWorkspace: PropTypes.bool,
    currentWorkspaceId: PropTypes.number,
    isOrdersTableCommitted: PropTypes.bool,
    clickDate: PropTypes.func,
    openNewEventModal: PropTypes.func,
    clickEvent: PropTypes.func,
    openEditEventModal: PropTypes.func
}

EventCalendar.defaultProps = {
    events: [],
    selectedWorkspaceIds: [],
    loadingFetch: false,
    loadingPutPost: false,
    canEditCurrentWorkspace: false,
}

const mapStateToProps = (state) => ({
    currentWorkspaceId: getSafeSelectedWorkspace(state),
    selectedWorkspaceIds: state.calendar.selectedWorkspaceIds,
    loadingFetch: state.calendar.loadingFetch,
    loadingPutPost: state.calendar.loadingPutPost,
    events: state.calendar.events,
    canEditCurrentWorkspace:
        state.auth.isConnected &&
        resolveEditPermission(state.auth.user, getSafeSelectedWorkspace(state)),
    isOrdersTableCommitted: state.ordersTable.isCommitted,
})

export default connect( mapStateToProps, null )(EventCalendar)