import React from "react";
import PropTypes from "prop-types";

import { Slider, Rail, Handles, Tracks, Ticks } from "react-compound-slider";
import { SliderRail, Handle, Track, Tick } from "../Slider/SliderComponents"; // example render components - source below

import "./SimpleRangeSlider.css";

const sliderStyle = {
  position: "relative",
  width: "100%"
};

const SimpleRangeSlider = ({ initialValue, step, min, max, onChange, rulesDisabled }) => {
  const domain = [min, max];

  const className = rulesDisabled ? "disabled-slider" : "slider-wrapper";

  return (
    <div className={className}>
      <Slider
        step={step}
        domain={domain}
        rootStyle={sliderStyle}
        disabled={rulesDisabled}
        onChange={values => {
          onChange(values[0]);
        }}
        values={[initialValue]}
      >
        <Rail>
          {({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}
        </Rail>
        <Handles>
          {({ handles, getHandleProps }) => (
            <div className="slider-handles">
              {handles.map(handle => (
                <Handle
                  key={handle.id}
                  handle={handle}
                  domain={domain}
                  getHandleProps={getHandleProps}
                />
              ))}
            </div>
          )}
        </Handles>
        <Tracks left={false} right={false}>
          {({ tracks, getTrackProps }) => (
            <div className="slider-tracks">
              {tracks.map(({ id, source, target }) => (
                <Track
                  key={id}
                  source={source}
                  target={target}
                  getTrackProps={getTrackProps}
                />
              ))}
            </div>
          )}
        </Tracks>
        <Ticks count={5}>
          {({ ticks }) => (
            <div className="slider-ticks">
              {ticks.map(tick => (
                <Tick key={tick.id} tick={tick} count={ticks.length} />
              ))}
            </div>
          )}
        </Ticks>
      </Slider>
    </div>
  );
};

SimpleRangeSlider.propTypes = {
  initialValue: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  rulesDisabled: PropTypes.bool
};

export default SimpleRangeSlider;
