import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Table } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import DeactivateTokenButton from "./DeactivateTokenButton";
import  { formatIsoDate }  from "./utils";

export const TokensInfoContainer = ({
    tokensInfo, 
    deactivateToken,
    getTokensInfo, 
}) => {
    const { t } = useTranslation();
    const tokenNumHeader = "Token Number";
    const creartedAtHeader = t("account.adminConsole.createdAt"); 
    const deprecatedAtHeader = t("account.adminConsole.revokedAt");
    let tokenNum = 0;
    const revokeButton = (tokenInfo) => {
        return (<DeactivateTokenButton
        deactivateToken={deactivateToken}
        tokenId={tokenInfo.id}
        isTokenDeactivated={ _.isNil(tokenInfo.deprecated_at)}
        getTokensInfo={getTokensInfo}/>)
    }
    const formatTokenInfo = (tokenInfo) => {
        return {
            "id": tokenInfo.id,
            "tokenNum": ++tokenNum, 
            'created_at': formatIsoDate(tokenInfo.created_at),
            'deprecated_at': _.isNil(tokenInfo.deprecated_at) ? revokeButton(tokenInfo) : formatIsoDate(tokenInfo.deprecated_at)
        }
    } 
    
    const formatedTokensInfo = tokensInfo.map(tokenInfo => (
        formatTokenInfo(tokenInfo)
    ))
    const tokensKeyMarker = formatedTokensInfo.length
    const generateChildKey = (tokenInfo,  keyAdder) => {
        if (_.isNil(tokenInfo.id)) {
            return '0'
        }
        return (tokenInfo.id + keyAdder).toString()
    } 
    const tokenCells = (formatedTokensInfo) => {
        if (formatedTokensInfo.length === 0) {
            return
        }
        return (
        <Table.Body>
                {formatedTokensInfo
                    .map(   tokenInfo => {
                        return <Table.Row key={generateChildKey(tokenInfo, 0)}>
                                <Table.Cell key={generateChildKey(tokenInfo, tokensKeyMarker)}>{tokenInfo.tokenNum}</Table.Cell>
                                <Table.Cell key={generateChildKey(tokenInfo, tokensKeyMarker*10) }>{tokenInfo.created_at}</Table.Cell>
                                <Table.Cell key={generateChildKey(tokenInfo, tokensKeyMarker*100)}>{tokenInfo.deprecated_at}</Table.Cell>
                            </Table.Row>
                })}               
                
            </Table.Body>
        )
    }       
    return(
        <Table celled>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>{tokenNumHeader}</Table.HeaderCell>
                    <Table.HeaderCell>{creartedAtHeader}</Table.HeaderCell>
                    <Table.HeaderCell>{deprecatedAtHeader}</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            {tokenCells(formatedTokensInfo)}
        </Table>
    )  
}

TokensInfoContainer.propTypes = {    
    tokensInfo: PropTypes.array,
    deactivateToken: PropTypes.func,
    getTokensInfo: PropTypes.func,   
  };

TokensInfoContainer.defaultProps = {
    deactivateToken: _.noop,
    getTokensInfo: _.noop,    
};

export default TokensInfoContainer;