import React from 'react'
import { shortenNumber } from '../../../../utils/format';
import { Popup } from 'semantic-ui-react';
import { topOfZoneLineYOffset,
        topOfZoneLineXOffset} from '../../../../utils/constants';
import "./BarChart.css"

export const TopOfZone = ( dataTopZone ) => {
    const { drawData, shortNumberLength, topOfZoneTextXCoordinate } = dataTopZone
    const { relativeOffset, topOf, width, x, y } = drawData

    const topZoneValueSplit = topOf.split(": ")
    const contentValue = topZoneValueSplit[0] + ': ' + parseFloat(topZoneValueSplit[1]).toLocaleString()

    const renderGContainer = ( TopOfZoneValue )=> {
      return(
        <g>
          <line
            className={"topOfZoneLine"}
            x1={x + width}
            y1={y + topOfZoneLineYOffset}
            x2={topOfZoneTextXCoordinate - topOfZoneLineXOffset}
            y2={y + topOfZoneLineYOffset + relativeOffset}
          />
          <text
            x={topOfZoneTextXCoordinate}
            y={y + 5 + relativeOffset}
            z={1}
            className={"topOfZone"}
          >
            { TopOfZoneValue }
          </text>
        </g>
      )
    }

    return(
      topZoneValueSplit[1].length >= shortNumberLength
      ? <Popup
          on='hover'
          position="left center"
          content={contentValue}
          trigger={ renderGContainer(topZoneValueSplit[0] + ': ' + shortenNumber(parseFloat(topZoneValueSplit[1]), shortNumberLength+0.5)) }
          />
        : (renderGContainer(contentValue))
          
    )
  };