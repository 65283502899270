import { toast } from "react-toastify";
import { NullTransition } from "../components/ToastI18nWrapper/NullTransition/NullTransition";

export const baseOptions = {
  transition: NullTransition,
  autoClose: 4000,
  hideProgressBar: true,
  position: toast.POSITION.BOTTOM_RIGHT,
  draggable: false,
};
