import {
  ORGANIZATION_LEVEL_PERMISSIONS_KEY,
  ACCESS_ADMIN_CONSOLE_PERMISSION,
  ADMIN_ROLE,
} from "../constants";
import _ from "lodash";

export const mapUserToPermissions = (user) => {
  const roles = user.roles;
  const mappedRoles = {};
  Object.keys(roles).forEach((workspace) => {
    mappedRoles[workspace] = roles[workspace].reduce((prev, role) => {
      return role.permissions.map((permission) => permission.codename);
    }, []);
  });

  return mappedRoles;
};

export const checkAdminConsoleAccess = (user) =>
  !_.isEmpty(user) &&
  _.includes(
    mapUserToPermissions(user)[ORGANIZATION_LEVEL_PERMISSIONS_KEY],
    ACCESS_ADMIN_CONSOLE_PERMISSION
  );

export const getMarkedWorkspaces = (user_roles, role) => {
  return Object.keys(user_roles)
    .map((workspaceId) => {
      const rolesInWorkspace = user_roles[workspaceId];
      if (_.find(rolesInWorkspace, role)) {
        return workspaceId;
      } else {
        return null;
      }
    })
    .filter((id) => id !== null);
};

export const hasAdminRole = (user) => {
  if (!_.has(user.roles, null)) {
    return false;
  }

  return _.includes(
    user.roles[null].map((role) => role.title),
    ADMIN_ROLE
  );
};

export const getFirstVisibleWorkspace = (user, workspaces) => {
  // look for first workspace where user has access
  if (_.isNil(user) || _.isEmpty(workspaces)) {
    return null;
  }

  if (user.isAdmin) {
    return workspaces[0].id;
  }

  const visibleWorkspaces = Object.keys(user.roles).map((id) => String(id));

  if (_.isEmpty(visibleWorkspaces)) {
    return null;
  }

  const firstVisible = _.find(workspaces, {
    id: Number(visibleWorkspaces[0]),
  });

  return firstVisible ? firstVisible.id : null;
};

export const getSafeSelectedWorkspace = (state) => {
  const { user } = state.auth;
  if (_.isNil(user)) {
    return null;
  }
  return state.workspace.currentWorkspace;
};

export const getCurrentWorkspaceName = (t, workspaceState, workspaceId)=>{
  if(_.isEmpty(workspaceState)){
    return t("account.adminConsole.noWorkspaces")
  }
  const currentWorkspaceObject = workspaceState.filter(workspace=>workspace.id === workspaceId)[0]
  const workspaceName = currentWorkspaceObject["name"]
  return workspaceName
}
