import React from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { Loader } from "semantic-ui-react";

import { useTranslation } from "react-i18next";

import "./ProgressIndicator.css";

const ProgressIndicator = ({ loadingUpdate }) => {
  const { t } = useTranslation();

  if (loadingUpdate) {
    return (
      <span className="table-upload-indicator">
        <Loader size={"small"} active inline></Loader>
        <span className="progress-message">{t("ordersTable.syncing")}</span>
      </span>
    );
  }
  return null;
};

ProgressIndicator.propTypes = {
  loadingUpdate: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  loadingUpdate: state.ordersTable.loadingUpdate,
});

export default connect(mapStateToProps)(ProgressIndicator);
