import React from "react"
import _ from "lodash"
import { Button, Dropdown, Icon } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types"
import { permanentColumns } from "../../../utils/constants";
import "./OrderColumns.css"
const OrderColumns = 
({  
    currentLayout, 
    savedLayouts, 
    allColumns, 
    onLayoutSelected, 
    setShowOverwriteLayoutConfirm,
    tableInstance,
    setColumnsConfig,
    setShowDeleteLayoutConfirm,
    onColumnToggle,
    setShowNewLayoutModal,
    editingLayout
})=>{
    const { t } = useTranslation()
    const currentLayoutName = _.isEmpty(currentLayout.translationKey)
                                            ? currentLayout.name
                                            : t(currentLayout.translationKey);
    const shouldDisableDeleteButton = () => (!currentLayout.isDeletable);

    const renderItem = (layout)=>{
        
        return(
            <div className="saved-layout-data">
                {layout.name} 
                <div>
                    {
                    currentLayout.name === layout.name 
                                && (
                                    <div>
                                        <Icon name="write" 
                                            onClick={() => {
                                                setShowOverwriteLayoutConfirm(true)
                                                setColumnsConfig(tableInstance.state)
                                            }}
                                            className="layout-write-icon"/>
                                        <Icon name="trash" 
                                            disabled={shouldDisableDeleteButton()}
                                            onClick={() => setShowDeleteLayoutConfirm(true)}
                                            className="layout-trash-icon"/>
                                    </div>
                                    )
                    }
                </div>
            </div>
        )
    }

    return(
        <Button.Group>
            <Dropdown
                icon={"align left"}
                text={currentLayoutName}
                floating
                labeled
                button
                // Button-content from suggested order filter css 
                className="icon suggested-order-filter button-content"
                scrolling
            >
                <Dropdown.Menu>
                <Dropdown.Header>{t("ordersTable.saved")}</Dropdown.Header>
                <Dropdown.Divider/>
                {savedLayouts
                    .map((layout) => {
                        return (
                        <Dropdown.Item
                            key={layout.id}
                            onClick={() => onLayoutSelected(layout.id)}>
                            { 
                                _.isEmpty(layout.translationKey)
                                    ? renderItem(layout)
                                    : t(layout.translationKey)
                            }
                        </Dropdown.Item>
                        );
                })}
                <Dropdown.Divider/>
                <Dropdown.Header>{t("ordersTable.columnsName")}</Dropdown.Header>
                <Dropdown.Divider/>

                {allColumns.map(column => {
                    return (
                    <Dropdown.Item key={column.id}>
                        <div className="ui checkbox">
                        <input
                            disabled={_.includes(permanentColumns, column.id)}
                            type="checkbox"
                            onClick={() => onColumnToggle(column.id)}
                            {...column.getToggleHiddenProps()}
                        />{" "}
                        <label>{column.Header}</label>
                        </div>
                    </Dropdown.Item>
                    );
                })}
                </Dropdown.Menu>
            </Dropdown>
            {
                editingLayout && <Button icon="save" className="order-column-button" onClick={()=>setShowNewLayoutModal(true)}/>
            }
        </Button.Group>
    )
}

OrderColumns.propTypes = {
    currentLayout: PropTypes.object,
    savedLayouts: PropTypes.array,
    allColumns: PropTypes.array,
    onLayoutSelected: PropTypes.func,
    setShowOverwriteLayoutConfirm: PropTypes.func,
    tableInstance: PropTypes.object,
    setColumnsConfig: PropTypes.func,
    setShowDeleteLayoutConfirm: PropTypes.func,
    onColumnToggle: PropTypes.func,
    setShowNewLayoutModal: PropTypes.func,
    editingLayout: PropTypes.bool
}

export default OrderColumns