import React, { useEffect, useState, useCallback } from "react";
import { Menu, Segment, Grid } from 'semantic-ui-react';
import "./Analytics.css"
import Simulation from './Simulation/Simulation';
import AnalyticsSection from "./AnalyticsSection/AnalyticsSection";
import { connect } from "react-redux";
import PropTypes from 'prop-types'
import { queryReport } from "../../actions/analytics";
import { getSafeSelectedWorkspace } from "../../utils/mappers/user";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { getAllSavedSimulations, getSimulationAllowedStatus, getAnalyticsStatus } from "../../actions/analytics";
// import { TIME_FILTER_OPTIONS } from "../../utils/constants";
// import { getUserSelectedTimeFilterByCase } from "./utils";
import _ from "lodash";

const Analytics = 
({  
    queryReport, 
    currentWorkspaceId, 
    loadingSimulationActions, 
    getAllSavedSimulations,
    items,
    getSimulationAllowedStatus,
    getAnalyticsStatus,
    analyticsUserSelectedTimeFilter,
    analyticsChartData
}) => {

    const { t } = useTranslation()
    
    const menuItemNames = { firstItem: "historical", secondItem: "simulation" }
    const [ activeItem, setActiveItem ] = useState(menuItemNames.firstItem);
    // const { CURRENT_WORKSPACE } = TIME_FILTER_OPTIONS;

    // Load necessary data to allow and load simulation. 
    useEffect(() => {
        if( currentWorkspaceId ){
            getSimulationAllowedStatus(currentWorkspaceId)
        }
    }, [ currentWorkspaceId, getSimulationAllowedStatus ])
    
    const getSimulationData = useCallback(() => {
        if(currentWorkspaceId){
            getAllSavedSimulations(currentWorkspaceId)
        }
    }, [ currentWorkspaceId, getAllSavedSimulations ]);

    useEffect(()=>{
        getSimulationData();
    }, [ getSimulationData, loadingSimulationActions ])


    useEffect(()=>{
        if(currentWorkspaceId){
            getAnalyticsStatus(currentWorkspaceId)
        }
    }, [currentWorkspaceId, getAnalyticsStatus])

    // Load all data for analytics section.
    useEffect(() => {
        // This query report are going to be executed only first time, user change workspace or order table is updated
        if(currentWorkspaceId && _.isEmpty(analyticsChartData)){
            // const currentSelectedTimeFilter = getUserSelectedTimeFilterByCase(analyticsUserSelectedTimeFilter, CURRENT_WORKSPACE)
            // const isFirstDataFetch = _.isEmpty(currentSelectedTimeFilter) ? true : false
            // // console.log('executing useEffect query report')
            // console.log('executing useEffect query report')
            queryReport({currentWorkspaceId, 
                        t, 
                        fieldFilters: { }, //Empty because this useEffect is executed only first time
                        timeFilter: { }, 
                        isFirstDataFetch: true})
        }
        // This line is used because of newGroupData
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentWorkspaceId, items, analyticsUserSelectedTimeFilter]);

    if (!currentWorkspaceId) {
        return (
          <div className="ui container container-space">
            <Segment placeholder style={{ minHeight: "350px" }}>
              <h2>{t("workspaceRequired")}</h2>
            </Segment>
          </div>
        );
    }

    const segmentClassName = classNames({  
                                            'analytics-menu-container': true, 
                                            "analytics-height": activeItem === menuItemNames.firstItem, 
                                            "simulation-height": activeItem === menuItemNames.secondItem
                                        })


    return(
        <Segment secondary basic className={segmentClassName}>
            <Grid>
                <Grid.Row centered>
                    <Grid.Column widescreen={13}>
                        <Grid.Row centered className="sub-menu-container">
                            <Menu compact fluid widths={2}>
                                <Menu.Item
                                    disabled={false}
                                    active={activeItem === menuItemNames.firstItem}
                                    onClick={()=>setActiveItem(menuItemNames.firstItem)}
                                >
                                    {t("analytics.historicTitle")}
                                </Menu.Item>
                                
                                <Menu.Item
                                    disabled={false}
                                    active={activeItem === menuItemNames.secondItem}
                                    onClick={()=>setActiveItem(menuItemNames.secondItem)}
                                >
                                    {t("simulation.title")}
                                </Menu.Item>
                            </Menu>
                        </Grid.Row>
                        <Grid.Row centered>
                            <Grid.Column >
                                {
                                    activeItem === menuItemNames.firstItem
                                        ? <AnalyticsSection/>
                                        : <Simulation/>
                                }
                            </Grid.Column>
                        </Grid.Row>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
      </Segment>
    )
}

Analytics.propTypes = {
    queryReport: PropTypes.func,
    currentWorkspaceId: PropTypes.number,
    loadingSimulationActions: PropTypes.bool,
    getAllSavedSimulations: PropTypes.func,
    items: PropTypes.array,
    getSimulationAllowedStatus: PropTypes.func,
    getAnalyticsStatus: PropTypes.func,
    analyticsUserSelectedTimeFilter: PropTypes.object,
    analyticsChartData: PropTypes.object,
}

const mapStateToProps = (state) => ({
    currentWorkspaceId: getSafeSelectedWorkspace(state),
    loadingSimulationActions: state.analytics.loadingSimulationActions,
    items: state.items.items,
    analyticsUserSelectedTimeFilter: state.analytics.analyticsUserSelectedTimeFilter,
    analyticsChartData: state.analytics.analyticsChartData,
});
export default connect(mapStateToProps, { queryReport, 
                                            getAllSavedSimulations, 
                                            getSimulationAllowedStatus, 
                                            getAnalyticsStatus })(Analytics);