import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Modal, Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import './NewMasterModal.css';

const NewMasterFileWarning = ({
  isWarningActive,
  closeWarning,
  uploadNewMaster
}) => {

  const { t } = useTranslation();

  return (
    <Modal
      className='newMasterModal'
      open={isWarningActive}>
      <Modal.Header>{t('dataImport.warning')}</Modal.Header>
      <Modal.Content className='ui warning message masterModal'>{t('dataImport.newMasterFileWarning')}</Modal.Content>
      <Modal.Actions>
        <Button
          className='newMasterModalCancelButton'
          active={true}
          onClick={closeWarning}>
          {t('cancel')}
        </Button>
        <Button
          className='newMasterModalEnterButton'
          active={true}
          onClick={uploadNewMaster}>
          {t('dataImport.uploadAnyway')}
        </Button>
      </Modal.Actions>
    </Modal>

  );
};

NewMasterFileWarning.propTypes = {
  isWarningActive: PropTypes.bool,
  closeWarning: PropTypes.func,
  uploadNewMaster: PropTypes.func
};

NewMasterFileWarning.defaultProps = {
  isWarningActive: false,
  closeWarning: _.noop,
  uploadNewMaster: _.noop
};

export default NewMasterFileWarning;
