import React, { useMemo } from "react"
import { connect } from "react-redux"
import { Grid, Form, Dropdown, Segment, Button} from "semantic-ui-react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import FiltersDropdown from "../FiltersDropdown/FiltersDropdown"
import { queryReport, setFieldFilterProperties } from "../../../../actions/analytics"
import { getSafeSelectedWorkspace } from "../../../../utils/mappers/user"
// import { toast } from "react-toastify";
// import ToastI18nWrapper from "../../../ToastI18nWrapper/ToastI18nWrapper"
import { changeAnalyticsMaterialData, 
        changeUserSelectedAnalyticsTimeFilter,
        changeToggleOption,
        changeAggregateTimeFilter,
        changeSingleItemTimeFilter,
        changeAllWorkspacesTimeFilter } from "../../../../actions/analytics"

import _ from "lodash"
import "./ChartModifier.css"
import { WasSingleWorkspaceSelected, TIME_FILTER_OPTIONS } from "../../../../utils/constants"
import useTimeFilterTranslator from "../../../../hooks/useTimeFilterTranslator"
import { getUserSelectedTimeFilterByCase } from "../../utils"
import { getCurrentWorkspaceName } from "../../../../utils/mappers/user"

const ChartModifier = 
({ analyticsToggleOptions, 
    currentWorkspaceId,
    // newGroupData,
    analyticsMaterialData,
    changeAnalyticsMaterialData,
    queryReport,
    materialDataList,
    user,
    disableAnalyticsContentInteraction,
    changeToggleOption,
    currentWorkspaceTimeFilter,
    allWorkspacesTimeFilter,
    byMaterialTimeFilter,
    analyticsUserSelectedTimeFilter,
    changeUserSelectedAnalyticsTimeFilter,
    // changeAggregateTimeFilter,
    // changeSingleItemTimeFilter,
    // changeAllWorkspacesTimeFilter,
    workspaces,
    currentWorkspaceFieldFilters,
    allWorkspacesFieldFilters, 
    setFieldFilterProperties,
    selectedCurrentWorkspaceFieldFilter,
    selectedAllWorkspacesFiedFilter
})=>{
    const { wasAggregateSelected, wasSingleWorkspaceSelected } = analyticsToggleOptions
    const { CURRENT_WORKSPACE, SINGLE_ITEM, ALL_WORKSPACES } = TIME_FILTER_OPTIONS;

    const { t } = useTranslation()
    const timeFilterTranslated = useTimeFilterTranslator(currentWorkspaceTimeFilter, allWorkspacesTimeFilter, byMaterialTimeFilter)
    const {currentWorkspaceTimeFilterTranslated, allWorkspacesTimeFilterTranslated, byMaterialTimeFilterTranslated} = timeFilterTranslated

    const wasAggregatedSelected = wasSingleWorkspaceSelected && wasAggregateSelected
    const wasSingleSkuSelected = (wasSingleWorkspaceSelected && !wasAggregateSelected) || (!wasSingleWorkspaceSelected && !wasAggregateSelected)
    const wasAllWorkspacesSelected = wasSingleSkuSelected || wasAggregatedSelected ? false : true

    const groupFields = wasSingleWorkspaceSelected ? currentWorkspaceFieldFilters : allWorkspacesFieldFilters

    const updateMarkedFields = (accessor, value) => {
        const newMappedGroupFields = groupFields.map((field) => {
            if (field.accessor === accessor) {
                return { ...field, isChecked: value };
            }
            return field;
        })
        setFieldFilterProperties(newMappedGroupFields, wasAllWorkspacesSelected);
    };

    const handleAnalyticsByMaterial = (materialData)=>{
        const currentMaterialIdData = materialData.value.split(':')[1]
        // Check if I have to send a correct param of no 
        // const fieldFilters = { ...newGroupData}
        changeAnalyticsMaterialData(materialData.value)
        const singleItemTimeFilter = getUserSelectedTimeFilterByCase(analyticsUserSelectedTimeFilter, SINGLE_ITEM)
        const fieldFilters = wasSingleWorkspaceSelected ? selectedCurrentWorkspaceFieldFilter : selectedAllWorkspacesFiedFilter
        queryReport({currentWorkspaceId, 
                    t, 
                    fieldFilters: fieldFilters, 
                    timeFilter: singleItemTimeFilter,
                    materialId: currentMaterialIdData,
                    allWorkspaces: false
                });
    }

    const materialOptions = useMemo(()=>{
        if(materialDataList){
            return materialDataList
                    .map(material=>{ 
                        // Remove last parenthesis, then slice from las indexof "(" to material.length
                        let locationCode = material.slice(0, material.length-1)
                        locationCode = material
                                            .slice(material.lastIndexOf("("), material.length)
                                            .replace(/[()]/g, "")

                        return {
                            key: material,
                            value: `${material}:${locationCode}`,
                            text: material,
                        }
                    })
        }
        return []
    }, [ materialDataList ])

    const toggleOptionsHandler = (buttonName)=>{
        if(buttonName === getCurrentWorkspaceName(t, workspaces, currentWorkspaceId)){
            changeToggleOption({...analyticsToggleOptions, [WasSingleWorkspaceSelected]: true})
            const timeFilter = getUserSelectedTimeFilterByCase(analyticsUserSelectedTimeFilter, CURRENT_WORKSPACE)
            const wasFetchedFieldFilters = _.isEmpty(currentWorkspaceFieldFilters) ? true : false
            queryReport({currentWorkspaceId, 
                t, 
                fieldFilters: selectedCurrentWorkspaceFieldFilter, 
                timeFilter: timeFilter,
                materialId: null,
                allWorkspaces: false,
                isFirstDataFetch: wasFetchedFieldFilters //This is needed only the first time that user click on current workspace
            });
            return
        }
        if(buttonName === t("analytics.allWorkspaces")){
            changeToggleOption({...analyticsToggleOptions, [WasSingleWorkspaceSelected]: false})
            const timeFilter = getUserSelectedTimeFilterByCase(analyticsUserSelectedTimeFilter, ALL_WORKSPACES)
            const wasFetchedFieldFilters = _.isEmpty(allWorkspacesFieldFilters) ? true : false
            queryReport({currentWorkspaceId, 
                t, 
                fieldFilters: selectedAllWorkspacesFiedFilter, 
                timeFilter: timeFilter,
                materialId: null,
                allWorkspaces: true,
                isFirstDataFetch: wasFetchedFieldFilters //This is needed only the first time that user click on all workspaces
            });
            return
        }
    }
    
    
    const showAdminToggle = user.isAdmin && wasAggregateSelected ? true : false


    // const addTimeFilter = (newFilterTime)=>{
    //     if(wasAggregateSelected){
    //         changeAggregateTimeFilter([newFilterTime, ...currentWorkspaceTimeFilter])
    //     }
    //     if(wasSingleSkuSelected){
    //         changeSingleItemTimeFilter([newFilterTime, ...byMaterialTimeFilter])
    //     }
    //     if(wasAllWorkspacesSelected){
    //         changeAllWorkspacesTimeFilter([newFilterTime, ...allWorkspacesTimeFilter])
    //     }
    // }

    const getTimeFilterOptions = ()=>{
        if(wasAggregatedSelected){
            return currentWorkspaceTimeFilterTranslated
        }
        if(wasSingleSkuSelected){
            return byMaterialTimeFilterTranslated
        }
        if(wasAllWorkspacesSelected){
            return allWorkspacesTimeFilterTranslated
        }
    }

    // const addItemHandler = (newTimeItem)=>{
    //     const monthNumber = parseInt(newTimeItem) 
    //     const currentTimeOption = getTimeFilterOptions()
    //     if(monthNumber){
    //         const lastMonthTextFromOptions = currentTimeOption[currentTimeOption.length-1].text
    //         const lasthMonthValueAsString = lastMonthTextFromOptions.split(" ")[2]
    //         const maxMonthAllowed = parseInt(lasthMonthValueAsString)
    //         if(monthNumber> 0 && monthNumber<maxMonthAllowed){
    //             const newFilterTime = {"key": `${t("analytics.last")} - ${monthNumber} ${t("analytics.months")}`, 
    //                                     "text": `${t("analytics.last")} - ${monthNumber} ${t("analytics.months")}`, 
    //                                     "value": monthNumber,
    //                                     "user_value": "true", 
    //                                     "content": (<span>{`${t("analytics.last")} - ${monthNumber} ${t("analytics.months")} `}<Icon name="user" /></span>)
    //                                 }
    //             addTimeFilter(newFilterTime)
    //             return
    //         }

    //         toast.warn(
    //             <ToastI18nWrapper 
    //                 translateKey={"analytics.monthNoExist"} 
    //                 />,
    //             { timeout: 300 }
    //             );
            
    //     }
    // }

    // const getAvailableTimeOptions = ()=>{
    //     if(wasAggregatedSelected){
    //         return !_.isEmpty(currentWorkspaceTimeFilterTranslated) && currentWorkspaceTimeFilterTranslated.length <= 1 ? false : true
    //     }
    //     if(wasSingleSkuSelected){
    //         return !_.isEmpty(byMaterialTimeFilterTranslated) && byMaterialTimeFilterTranslated.length <= 1 ? false : true
    //     }
    //     if(wasAllWorkspacesSelected){
    //         return !_.isEmpty(allWorkspacesTimeFilterTranslated) && allWorkspacesTimeFilterTranslated.length <= 1 ? false : true
    //     }
    // }

    const getValueForTimeOptions = ()=>{
        if(wasAggregatedSelected){
            return !_.isEmpty(analyticsUserSelectedTimeFilter[CURRENT_WORKSPACE]) 
                            ? analyticsUserSelectedTimeFilter[CURRENT_WORKSPACE]["value"]
                            : currentWorkspaceTimeFilter[0]['value']
        }
        if(wasSingleSkuSelected){
            return !_.isEmpty(analyticsUserSelectedTimeFilter[SINGLE_ITEM]) 
                            ? analyticsUserSelectedTimeFilter[SINGLE_ITEM]["value"]
                            : byMaterialTimeFilter[0]['value']
        }
        if(wasAllWorkspacesSelected){
            return !_.isEmpty(analyticsUserSelectedTimeFilter[ALL_WORKSPACES]) 
                            ? analyticsUserSelectedTimeFilter[ALL_WORKSPACES]["value"]
                            : allWorkspacesTimeFilter[0]['value']
        }
    }
    
    const disableTimeDropdown = ()=>{
        const currentOptionValues = getTimeFilterOptions()
        return (!analyticsMaterialData && !wasAggregateSelected) || _.isEmpty(currentOptionValues) ? true : false
    }

    const handleFilterTime = (data)=>{
        let value = data.value
        const currentOptionValues = getTimeFilterOptions()
        const selectedOptionText = currentOptionValues.filter((optionObject)=>(optionObject.value === value))
        const selectedOptionTypeTimeFilter = _.isEmpty(selectedOptionText) ? "user_value" : "system_value"
        const selectedValueFormat = {'value':value, [selectedOptionTypeTimeFilter]: true}
        let isAllWorkspacesSelected = false

        if(wasAggregatedSelected){
            changeUserSelectedAnalyticsTimeFilter({...analyticsUserSelectedTimeFilter, [CURRENT_WORKSPACE]: selectedValueFormat})
            isAllWorkspacesSelected = false
        }
        if(wasSingleSkuSelected){
            changeUserSelectedAnalyticsTimeFilter({...analyticsUserSelectedTimeFilter, [SINGLE_ITEM]: selectedValueFormat})
            isAllWorkspacesSelected = false
        }
        if(wasAllWorkspacesSelected){
            changeUserSelectedAnalyticsTimeFilter({...analyticsUserSelectedTimeFilter, [ALL_WORKSPACES]: {...selectedValueFormat, "diffmonth": selectedOptionText[0]['diffmonth']}})
            value = selectedOptionText[0]['diffmonth']
            isAllWorkspacesSelected = true
        }

        const materialData = analyticsMaterialData && !wasAggregateSelected ? analyticsMaterialData.split(':')[1] : null
        const fieldFilters = isAllWorkspacesSelected ? selectedAllWorkspacesFiedFilter : selectedCurrentWorkspaceFieldFilter

        queryReport({currentWorkspaceId, 
            t, 
            fieldFilters, 
            timeFilter: {[selectedOptionTypeTimeFilter]: value}, 
            materialId: materialData,
            allWorkspaces: isAllWorkspacesSelected
        });
    }

    
    return(
        <Segment>
            <Grid.Row className="filter-row">
            {
                wasAggregateSelected
                    ?   (
                            <Grid.Column>
                                    <Form.Field>
                                    <label>{t("groupSettings.filters")}</label>
                                    {groupFields && <FiltersDropdown
                                        groupFields={groupFields}
                                        markedGroupFields={groupFields
                                                            .map((field) => field.accessor)}
                                                            onChange={(accesor, isChecked)=> {updateMarkedFields(accesor, isChecked)}}
                                    ></FiltersDropdown>}
                                    </Form.Field>
                            </Grid.Column>
                        )
                    :   (
                            <Grid.Column>
                                <Form.Field className='material-section' required>
                                <label className="field-title">{t('simulation.materialTitle')}</label>
                                <Dropdown
                                    placeholder={t("simulation.itemDescription")}
                                    value={analyticsMaterialData}
                                    onChange={(event, selectedMaterial)=>{
                                        handleAnalyticsByMaterial(selectedMaterial);
                                    }}
                                    compact
                                    search 
                                    selection 
                                    disabled={materialOptions.length ? false : true}
                                    options={materialOptions}
                                />
                                </Form.Field>
                            </Grid.Column>
                        )
            }
            <Grid.Column className="time-filter-container">
                <Form.Field>
                    <label>{t("analytics.timeFilter")}</label>
                    <Dropdown
                        options={getTimeFilterOptions()}
                        disabled={disableTimeDropdown()}
                        value={getValueForTimeOptions()}
                        // allowAdditions={getAvailableTimeOptions()}
                        // onAddItem={(e, data)=>addItemHandler(data.value)}
                        onChange={(e, data)=>{handleFilterTime(data)}}
                        // wrapSelection={false}
                        // searchInput={{ type: 'number'}}
                        // search
                        selection
                        />
                </Form.Field>
            </Grid.Column>
            </Grid.Row>
            {
                showAdminToggle && (
                            <Grid.Column>
                                <Grid.Row className="analytics-admin-toggle">
                                    <label>{t("analytics.workspaceFilter")}</label>
                                    {/* TODO: disable when chart doesn't have data to show */}
                                    <Button.Group fluid>
                                        <Button 
                                            toggle 
                                            active={wasSingleWorkspaceSelected}
                                            onClick={(e, data)=>toggleOptionsHandler(data.children)}
                                            disabled={disableAnalyticsContentInteraction}
                                            >
                                            {getCurrentWorkspaceName(t, workspaces, currentWorkspaceId)}
                                        </Button>
                                        <Button 
                                            toggle 
                                            active={!wasSingleWorkspaceSelected}
                                            onClick={(e, data)=>toggleOptionsHandler(data.children)}
                                            disabled={disableAnalyticsContentInteraction}
                                            >
                                            {t("analytics.allWorkspaces")}
                                        </Button>
                                    </Button.Group>
                                </Grid.Row>
                            </Grid.Column>
                )
            }
        </Segment>
    )
}

ChartModifier.propTypes = {
    analyticsToggleOptions: PropTypes.object,
    currentWorkspaceId: PropTypes.number,
    // newGroupData: PropTypes.object,
    analyticsMaterialData: PropTypes.string,
    changeAnalyticsMaterialData: PropTypes.func,
    queryReport: PropTypes.func,
    materialDataList: PropTypes.array,
    user: PropTypes.object,
    disableAnalyticsContentInteraction: PropTypes.bool,
    changeToggleOption: PropTypes.func,
    currentWorkspaceTimeFilter: PropTypes.array,
    allWorkspacesTimeFilter: PropTypes.array,
    byMaterialTimeFilter: PropTypes.array,
    changeUserSelectedAnalyticsTimeFilter: PropTypes.func,
    analyticsUserSelectedTimeFilter: PropTypes.object,
    changeAggregateTimeFilter: PropTypes.func,
    changeSingleItemTimeFilter: PropTypes.func,
    changeAllWorkspacesTimeFilter: PropTypes.func,
    workspaces: PropTypes.array,
    currentWorkspaceFieldFilters: PropTypes.array,
    allWorkspacesFieldFilters: PropTypes.array,
    setFieldFilterProperties: PropTypes.func,
    selectedCurrentWorkspaceFieldFilter: PropTypes.object,
    selectedAllWorkspacesFiedFilter: PropTypes.object,
}

const mapStateToProps = (state)=>({
    analyticsToggleOptions: state.analytics.analyticsToggleOptions,
    currentWorkspaceId: getSafeSelectedWorkspace(state),
    // newGroupData: state.analytics.newGroupData,
    analyticsMaterialData: state.analytics.analyticsMaterialData,
    materialDataList: state.analytics.materialDataList,
    user: state.auth.user,
    disableAnalyticsContentInteraction: state.analytics.disableAnalyticsContentInteraction,
    currentWorkspaceTimeFilter: state.analytics.currentWorkspaceTimeFilter,
    allWorkspacesTimeFilter: state.analytics.allWorkspacesTimeFilter,
    byMaterialTimeFilter: state.analytics.byMaterialTimeFilter,
    analyticsUserSelectedTimeFilter: state.analytics.analyticsUserSelectedTimeFilter,
    workspaces: state.workspace.workspaces,
    currentWorkspaceFieldFilters: state.analytics.currentWorkspaceFieldFilters,
    allWorkspacesFieldFilters: state.analytics.allWorkspacesFieldFilters,
    selectedCurrentWorkspaceFieldFilter: state.analytics.selectedCurrentWorkspaceFieldFilter,
    selectedAllWorkspacesFiedFilter: state.analytics.selectedAllWorkspacesFiedFilter,
})

export default connect(mapStateToProps, { 
                                            queryReport, 
                                            changeAnalyticsMaterialData, 
                                            changeUserSelectedAnalyticsTimeFilter,
                                            changeToggleOption,
                                            changeAggregateTimeFilter,
                                            changeSingleItemTimeFilter,
                                            changeAllWorkspacesTimeFilter,
                                            setFieldFilterProperties
                                         })(ChartModifier)