import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { List } from "semantic-ui-react";

import { useTranslation } from "react-i18next";

import { mapItemsToOrderSummary } from "../../../utils/mappers/notifications";
import { toExcelNumberFormatWithoutDecimals } from "../../../utils/format";

import "./OrderSummary.css";

const OrderSummary = ({ orderSummaryData }) => {
  const { t } = useTranslation();

  const { listed, suggested, changed, unchanged } = orderSummaryData;

  const listItems = [
    {
      textKey: "statusPanel.orderSummary.listedItem",
      value: listed,
    },
    {
      textKey: "statusPanel.orderSummary.suggestedItem",
      value: suggested,
    },
    {
      color: "yellow",
      textKey: "statusPanel.orderSummary.changedItem",
      value: changed,
    },
    {
      color: "green",
      textKey: "statusPanel.orderSummary.unchangedItem",
      value: unchanged,
    },
  ];

  return (
    <List>
      {listItems.map((item) => (
        <List.Item key={item.textKey}>
          {item.color ? (
            <List.Icon
              verticalAlign="middle"
              className={item.color}
              name="circle"
              size="small"
            />
          ) : null}
          <List.Content verticalAlign="middle" className="order-summary-item">
            {t(item.textKey, { count: toExcelNumberFormatWithoutDecimals(item.value, {currency: "USD"}) })}
          </List.Content>
        </List.Item>
      ))}
    </List>
  );
};

OrderSummary.propTypes = {
  orderSummaryData: PropTypes.objectOf(PropTypes.number).isRequired,
};

const mapStateToProps = (state) => ({
  orderSummaryData: mapItemsToOrderSummary(state.items.items),
});

export default connect(mapStateToProps)(OrderSummary);
