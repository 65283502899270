import { useEffect, useState, useMemo } from "react"
import moment from "moment-timezone"

const useCurrentDateRange = (startDate, dateRange) =>{

    const [ currentDateRange, setCurrentDateRange ] = useState(dateRange)

    const dateToValidCalendarFormat = (date)=>{
        return date ? date.split('/').reverse().join('-') : ""
    }

    useEffect(()=>{
        const newStartDateRange =  dateToValidCalendarFormat(startDate)
        const addDayStartDateRange = moment(newStartDateRange).add(1, 'day').format('YYYY-MM-DD')
        setCurrentDateRange({...dateRange, startDateRange: addDayStartDateRange})
    }, [startDate, dateRange])


    const isStartDateSelected = useMemo(()=>{
        if(startDate){
            const startSelectedDate = moment(dateToValidCalendarFormat(startDate, 'YYYY-MM-DD')).valueOf()
            const lastDateValid = moment(dateRange.endDateRange, 'YYYY-MM-DD').valueOf()
            return startSelectedDate === lastDateValid ? true : false
        }
        return true
    }, [startDate, dateRange])


    return { currentDateRange, isStartDateSelected }
}

export default useCurrentDateRange