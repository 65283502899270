import React from 'react'
import classNames from 'classnames'
import "./BarChart.css"

export const Bar = ( bar )=>{

    const { width, height, x, y, color } = bar.drawData
    return(
        <rect
            className={classNames("buffer-details-chart-bar", color)}
            width={width}
            height={height}
            x={x}
            y={y}
            z={0}
        ></rect>
    )
}