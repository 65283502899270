import { useMemo } from "react";
import { mapTimeFilters } from "../utils/mappers/analytics";
import { useTranslation } from "react-i18next"

const useTimeFilterTranslator = (currentWorkspaceTimeFilter, allWorkspacesTimeFilter, byMaterialTimeFilter) => {
    const { t, i18n } = useTranslation()
    const translatedTimeFilters = useMemo(()=>{
        const translatedTimeFilters = {
            currentWorkspaceTimeFilterTranslated: mapTimeFilters(t, currentWorkspaceTimeFilter),
            allWorkspacesTimeFilterTranslated: mapTimeFilters(t, allWorkspacesTimeFilter),
            byMaterialTimeFilterTranslated: mapTimeFilters(t, byMaterialTimeFilter)
        }
        return translatedTimeFilters
        // This line was added because when language changed translation and mapper should be run
        // eslint-disable-next-line
    }, [t, i18n.language, currentWorkspaceTimeFilter, allWorkspacesTimeFilter, byMaterialTimeFilter])

    return translatedTimeFilters
}

export default useTimeFilterTranslator;