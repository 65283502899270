import React, { useState } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import moment from "moment";
import { calendarDateInputFormat } from "../../../utils/format";
import { mapBufferSettingsGroupsToOptions } from "../../../utils/mappers/calendar";
import { superGroupExists } from "../../../utils/mappers/groupSettings";
//import CustomCalendarInput from "../../CustomCalendarInput/CustomCalendarInput";

import {
  Modal,
  Form,
  Header,
  Icon,
  Button,
  Confirm,
  Dropdown,
  Radio,
  Grid,
} from "semantic-ui-react";

import { useTranslation } from "react-i18next";

import "./EventModal.css";

export const EventModal = ({ groups, event, onSubmit, onClose, onDelete, showGroupWarning, warningShowed }) => {
  const { t } = useTranslation();

  const isEditing = _.get(event, "id", null) !== null;

  const [start, setStart] = useState(calendarDateInputFormat(_.get(event, 'start', null)));
  const [end, setEnd] = useState(calendarDateInputFormat(_.get(event, 'end', null)));
  const [title, setTitle] = useState(_.get(event, "title", ""));
  const [showRemoveEventConfirm, setShowRemoveEventConfirm] = useState(false);
  //const [createDemand, setCreateDemand] = useState(false);
  const [disableQtyPerDay, setDisableQtyPerDay] = useState(
    isEditing && _.get(event, "extendedProps.quantity_per_day", null) === null
  );
  const [qtyPerDay, setQtyPerDay] = useState(
    _.get(event, "extendedProps.quantity_per_day", null)
  );
  const [adusPerDay, setAdusPerDay] = useState(
    _.get(event, "extendedProps.adus_per_day", null)
  );
  const [selectedGroups, setSelectedGroups] = useState(
    _.get(event, "extendedProps.groups", []).map((group) => group.id)
  );
  const [selectedGroupError, setSelectedGroupError] = useState(false);
  const [endDateError, setEndDateError] = useState(null);

  const onSave = (e) => {
    if (_.isEmpty(selectedGroups)) {
      setSelectedGroupError(true);
      return;
    }

    // to-do: "end date value is exclusive?"
    e.preventDefault();

    const startDate = moment(start, "YYYY-MM-DD").format();

    let endDate;

    if (_.isEmpty(end)) {
      endDate = moment(start, "YYYY-MM-DD")
        .add(1, "days")
        .format();
    } else {
      endDate = moment(end, "YYYY-MM-DD").format();
    }

    if (moment(endDate).isBefore(startDate)) {
      setEndDateError({
        content: t("calendar.events.endDateBeforeStartError"),
        pointing: "above",
      });
      return;
    }

    if (moment(endDate).isSame(startDate)) {
      setEndDateError({
        content: t("calendar.events.eventDurationError"),
        pointing: "above",
      });
      return;
    }

    if (disableQtyPerDay) {
      onSubmit(title, startDate, endDate, null, adusPerDay, selectedGroups);
    } else {

      onSubmit(title, startDate, endDate, qtyPerDay, null, selectedGroups);
    }

    onClose();
  };
  if (superGroupExists(selectedGroups, groups) && !warningShowed) {
    showGroupWarning()
  }

  return (
    <>
      <Confirm
        open={showRemoveEventConfirm}
        content={t("calendar.events.deleteConfirmMessage", {
          event: event.title,
        })}
        cancelButton={t("cancel")}
        confirmButton={t("confirm")}
        onCancel={() => setShowRemoveEventConfirm(false)}
        onClose={() => setShowRemoveEventConfirm(false)}
        onConfirm={() => {
          onDelete(event.id);
          onClose();
        }}
      />
      <Form onSubmit={onSave}>
        <Modal
          as={Form}
          className="event-modal"
          open
          size="small"
          onClose={onClose}
        >
          <Header
            icon="browser"
            content={
              isEditing ? t("calendar.events.edit") : t("calendar.events.add")
            }
          />
          <Modal.Content>
            <Form.Field required>
              <label>{t("calendar.events.titleLabel")}</label>
              <input
                className="event-title"
                placeholder={t("calendar.events.titleLabel")}
                name="title"
                required
                defaultValue={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </Form.Field>

            <Form.Field required>
              <label>{t("calendar.events.eventMaterial")}</label>

              <Dropdown
                required
                error={selectedGroupError}
                className="event-group-select"
                placeholder={t("calendar.events.eventMaterialPlaceholder")}
                fluid
                selection
                labeled
                multiple
                value={selectedGroups}
                options={
                  _.isEmpty(groups)
                    ? []
                    : mapBufferSettingsGroupsToOptions(
                      groups.filter(group => !group.rules.materialMethod.includes('rules')))
                }
                onChange={(e, data) => {
                  setSelectedGroups(data.value);
                  setSelectedGroupError(false);
                }}
              />
            </Form.Field>

            {/* <CustomCalendarInput
              className="event-start"
              title={t("calendar.events.startDateLabel")}
              required={true}
              onDateClick={(selectedDate) => setStart(selectedDate)}
              /> */}
            {/* TODO: add an icon to delete info easy, beacuase in this from doesn't need end date */}
            {/* <CustomCalendarInput
              className="event-end"
              title={t("calendar.events.endDateLabel")}
              error={endDateError}
              required={true}
              onDateClick={(selectedDate) => setEnd(selectedDate)}
              />  */}
            {/* TODO: Implement some features, like when user doesn't insert end date */}
            <Form.Field required>
              <label>{t("calendar.events.startDateLabel")}</label>
              <input
                className="event-start"
                type="date"
                name="start"
                required
                defaultValue={start}
                onChange={(e) => setStart(e.target.value)}
              />
            </Form.Field>
            <Form.Input
              error={endDateError}
              label={t("calendar.events.endDateLabel")}
            >
              <input
                className="event-end"
                type="date"
                name="end"
                defaultValue={end}
                onChange={(e) => setEnd(e.target.value)}
              />
            </Form.Input>

            <Grid columns={2}>
              <Grid.Column>
                <Form.Field>
                  <Radio
                    className="per-day-radio"
                    label={t("calendar.events.quantityPerDay")}
                    name="per-day-radio-group"
                    checked={!disableQtyPerDay}
                    onChange={() => setDisableQtyPerDay(false)}
                  />
                </Form.Field>
                <Form.Field
                  required={!disableQtyPerDay}
                  disabled={disableQtyPerDay}
                >
                  <input
                    type="number"
                    step="any"
                    min="0"
                    defaultValue={_.get(
                      event,
                      "extendedProps.quantity_per_day",
                      null
                    )}
                    className="per-day-input"
                    name="adusPerDay"
                    required={!disableQtyPerDay}
                    onChange={(e) => setQtyPerDay(e.target.value)}
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column>
                <Form.Field>
                  <Radio
                    className="per-day-radio"
                    label={t("calendar.events.adusPerDay")}
                    name="per-day-radio-group"
                    checked={disableQtyPerDay}
                    onChange={() => setDisableQtyPerDay(true)}
                  />
                </Form.Field>
                <Form.Field
                  required={disableQtyPerDay}
                  disabled={!disableQtyPerDay}
                >
                  <input
                    type="number"
                    step="any"
                    min="0"
                    className="per-day-input"
                    name="per-day-radio-group"
                    required={disableQtyPerDay}
                    defaultValue={_.get(
                      event,
                      "extendedProps.adus_per_day",
                      null
                    )}
                    onChange={(e) => setAdusPerDay(e.target.value)}
                  />
                </Form.Field>
              </Grid.Column>
            </Grid>
          </Modal.Content>
          <Modal.Actions>
            {isEditing && (
              <Button
                type="cancel"
                color="red"
                onClick={(e) => {
                  e.preventDefault();
                  setShowRemoveEventConfirm(true);
                }}
                floated="left"
              >
                <Icon name="trash" /> {t("calendar.events.remove")}
              </Button>
            )}
            <Button type="cancel" color="red" inverted onClick={onClose}>
              <Icon name="close" /> {t("cancel")}
            </Button>
            <Button color="green" inverted type="submit">
              <Icon name="checkmark" /> {t("save")}
            </Button>
          </Modal.Actions>
        </Modal>
      </Form>
    </>
  );
};

EventModal.propTypes = {
  event: PropTypes.object.isRequired,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  onDelete: PropTypes.func,
  groups: PropTypes.arrayOf(PropTypes.object),
  showGroupWarning: PropTypes.func,
  warningShowed: PropTypes.bool,
};

EventModal.defaultProps = {
  onSubmit: _.noop,
  onClose: _.noop,
  onDelete: _.noop,
};

export default EventModal;
