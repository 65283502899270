import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";

import { connect } from "react-redux";
import { Loader } from "semantic-ui-react";
import { LOGIN } from "../Navbar/routes";

const PrivateRoute = ({ auth, component: Component, ...rest }) => {
  return (
    <Route
      {...rest} // just spread any other options passed to the route
      render={props => {
        // protect the route while loading with spinner gif
        if (auth.loading) {
          return <Loader active size="large" />;
        }
        // if finished loading and user is not authenticated, redirect to login
        // else, render whatever component was passed in, with whatever props (where do these come from??)
        return auth.isAuthenticated ? (
          <Component {...props}></Component>
        ) : (
          <Redirect
            to={{
              pathname: LOGIN,
              // eslint-disable-next-line react/prop-types
              state: { from: props.location }
            }}
          ></Redirect>
        );
      }}
    ></Route>
  );
};

PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired,
  component: PropTypes.object
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, null)(PrivateRoute);
