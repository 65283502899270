import React, { useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { connect } from "react-redux";
import { Icon } from "semantic-ui-react";
import _ from "lodash";
import ValueFilter from "../../Filters/ValueFilter";
import { itemAccessors } from "../../../../utils/constants";
import { setOrderTableAndMenuFilters } from "../../../../actions/ordersTable";

const Header = ({ setFilter, tableWideFilteredRows, column, setOrderTableAndMenuFilters, notificationFilterArray }) => {

  const { preFilteredRows, filteredRows } = column

  //This use effect allow user to change color of notification filters
  //Here only send info to state 
  useEffect(()=>{
    if(column.id === "location_code_concatenation"){
      const filterValues = filteredRows.map((filtervalue)=>(filtervalue.values.location_code_concatenation))
      if(!_.isEqual(filterValues, notificationFilterArray)){
        setOrderTableAndMenuFilters({[column.id]: filteredRows})
      }
    }
  }, [filteredRows, column, setOrderTableAndMenuFilters, notificationFilterArray])

  const memoizedValueFilter = React.useMemo(() =>

    <ValueFilter
      columnId={column.id}
      setFilter={(values) => {
        setFilter(column.id, values)
      }}
      isFilterActive={preFilteredRows.length !== filteredRows.length}
      preFilteredRows={preFilteredRows}
      filteredRows={tableWideFilteredRows}
    />,
    [
      column.id,
      preFilteredRows,
      filteredRows,
      tableWideFilteredRows,
      setFilter
    ]
  )

  return (
    <th
      key={column.id}
      className={classNames("order-table-header", {
        location: column.id === itemAccessors.location,
        code: column.id === itemAccessors.code,
        description: column.id === itemAccessors.description,
        variance: column.id === itemAccessors.orderVariance,
        "actual-order": column.id === itemAccessors.actualOrder,
        "suggested-order": column.id === itemAccessors.suggestedOrder,
      })}
      {...column.getHeaderProps(column.getSortByToggleProps())}
    >
      {column.render("Header")}
      {column.isSorted &&
        (column.isSortedDesc ? (
          <Icon name="caret down"></Icon>
        ) : (
            <Icon name="caret up"></Icon>
          ))}
      <br />
      {memoizedValueFilter}
    </th>
  );
};

Header.propTypes = {
  column: PropTypes.object,
  setFilter: PropTypes.func,
  tableWideFilteredRows: PropTypes.array,
  setOrderTableAndMenuFilters: PropTypes.func,
  notificationFilterArray: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
  ]),
};

const mapStateToProps = (state) => {
  return {
    notificationFilterArray: state.ordersTable.notificationFilterArray,
  };
};

//export default Header;
export default connect(mapStateToProps, {setOrderTableAndMenuFilters})(Header);
