import React from "react";
import PropTypes from "prop-types";

import { itemAccessors, batchStatuses } from "../../../../utils/constants";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

const Row = ({
  prepareRow,
  onSelectRow,
  editable,
  row,
  ordersCalculationId,
  selected
}) => {
  prepareRow(row);

  // Extracted to separate variables because of react-hooks warning
  const actualOrder = row.values[itemAccessors.actualOrder];
  const suggestedOrder = row.values[itemAccessors.suggestedOrder];
  const reasonCode = row.values[itemAccessors.orderVariance].reasonCode;
  const delta = row.values[itemAccessors.orderVariance].delta;
  const { t } = useTranslation()

  const memoizedRow = React.useMemo(
    () => {
      return (
        // eslint-disable-next-line react/jsx-key
        <tr
          {...row.getRowProps()}
          className={classNames("order-table-row", {
            unassigned: row.values.group === "-",
            selected: selected
          })}
          onClick={() => {
            onSelectRow(row);
          }}
        >
          {row.cells.map((cell) => {
            return (
              <td
                {...cell.getCellProps()}
                key={`${row.id}-${cell.column.id}`}
                className={classNames("order-table-cell", {
                  location: cell.column.id === itemAccessors.location,
                  code: cell.column.id === itemAccessors.code,
                  description: cell.column.id === itemAccessors.description,

                  variance: cell.column.id === itemAccessors.orderVariance,
                  "worst-batch-status":
                    cell.column.id === itemAccessors.worstBatchStatus,
                  "actual-order": cell.column.id === itemAccessors.actualOrder,
                  "suggested-order":
                    cell.column.id === itemAccessors.suggestedOrder,
                  "variance-cell-surplus":
                    cell.column.id === itemAccessors.orderVariance &&
                    cell.value.delta > 0,
                  "variance-cell-deficit":
                    cell.column.id === itemAccessors.orderVariance &&
                    cell.value.delta < 0,
                  "variance-cell-none":
                    cell.column.id === itemAccessors.orderVariance &&
                    cell.value.delta === 0,
                  "disabled-cell":
                    !editable &&
                    (cell.column.id === itemAccessors.orderVariance ||
                      cell.column.id === itemAccessors.actualOrder)
                },
                // Status of materials, expired, expe....
                {
                  expired:
                    cell.column.id === itemAccessors.worstBatchStatus &&
                    cell.value === batchStatuses.expired,
                  expiry_this_week:
                    cell.column.id === itemAccessors.worstBatchStatus &&
                    cell.value === batchStatuses.expiry_this_week,
                  expiry_next_week:
                    cell.column.id === itemAccessors.worstBatchStatus &&
                    cell.value === batchStatuses.expiry_next_week,
                  expiry_two_weeks:
                    cell.column.id === itemAccessors.worstBatchStatus &&
                    cell.value === batchStatuses.expiry_two_weeks,
                  expiry_three_weeks:
                    cell.column.id === itemAccessors.worstBatchStatus &&
                    cell.value === batchStatuses.expiry_three_weeks,
                  expiry_three_plus_weeks:
                    cell.column.id === itemAccessors.worstBatchStatus &&
                    cell.value === batchStatuses.expiry_three_plus_weeks,
                },
                // To paint color of cells or words by on-hand stock zone
                // TODO: to solve this problem maybe is necessary to use columnId
                {
                  blue_background: cell.row.values.buffer_zone_color === t("ordersTable.columns.blue_zone") && 
                    (cell.column.id === itemAccessors.onHandStock || cell.column.id === itemAccessors.bufferZoneColor),
                  green_background: cell.row.values.buffer_zone_color === t("ordersTable.columns.green_zone") && 
                    (cell.column.id === itemAccessors.onHandStock || cell.column.id === itemAccessors.bufferZoneColor),
                  yellow_background: cell.row.values.buffer_zone_color === t("ordersTable.columns.yellow_zone") && 
                    (cell.column.id === itemAccessors.onHandStock || cell.column.id === itemAccessors.bufferZoneColor),
                  red_background: cell.row.values.buffer_zone_color === t("ordersTable.columns.red_zone") && 
                    (cell.column.id === itemAccessors.onHandStock || cell.column.id === itemAccessors.bufferZoneColor),
                  grey_background: cell.row.values.buffer_zone_color === t("ordersTable.columns.grey_zone") && 
                    (cell.column.id === itemAccessors.onHandStock || cell.column.id === itemAccessors.bufferZoneColor),
                  no_zone: cell.row.values.buffer_zone_color === t("ordersTable.columns.stock_out") && 
                    (cell.column.id === itemAccessors.onHandStock || cell.column.id === itemAccessors.bufferZoneColor),
                }
                
                )}
              >
                {cell.render("Cell")}
              </td>
            );
          })}
        </tr>
      );
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      // Ignoring exhaustive deps warnings because changes in tableInstance
      // are already accounted for in prepareRow
      prepareRow,
      ordersCalculationId,
      actualOrder,
      suggestedOrder,
      reasonCode,
      delta,
      editable,
      selected,
      row // Row was added to updated translation of buffer color zone cell
    ]
  );

  return <>{memoizedRow}</>
};


Row.propTypes = {
  prepareRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  editable: PropTypes.bool,
  row: PropTypes.object,
  ordersCalculationId: PropTypes.number,
  selected: PropTypes.bool
};

export default Row;
