import React from "react";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";

const ToastI18nWrapper = ({ translateKey, data, customMessage="" }) => {
  const { t } = useTranslation();
  return <div>{`${t(translateKey, { ...data })} ${customMessage}`}</div>;
};

ToastI18nWrapper.propTypes = {
  translateKey: PropTypes.string.isRequired,
  data: PropTypes.object,
  customMessage: PropTypes.string
};

export default ToastI18nWrapper;
