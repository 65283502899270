
import { TIME_FILTER_OPTIONS } from "../../utils/constants"

export const getUserSelectedTimeFilterByCase = (analyticsUserSelectedTimeFilter, optionType)=>{
    const { ALL_WORKSPACES } = TIME_FILTER_OPTIONS;
    const selectedValue = analyticsUserSelectedTimeFilter[optionType].value 
                                ? analyticsUserSelectedTimeFilter[optionType].value 
                                : 0
    const timeFilter = {}
    if(selectedValue && optionType === ALL_WORKSPACES){
        if(analyticsUserSelectedTimeFilter[optionType]['diffmonth']){
            timeFilter["system_value"] = analyticsUserSelectedTimeFilter[optionType]['diffmonth']
            return timeFilter
        }
    }

    if(selectedValue){
        analyticsUserSelectedTimeFilter[optionType]['user_value']
            ? timeFilter["user_value"] = selectedValue
            : timeFilter["system_value"]= selectedValue
    }
    return timeFilter
}