import {
  SET_ERROR,
  REMOVE_ERROR,
  CLEAR_ERRORS,
  SET_ERRORS
} from "../actions/types";

const initialState = [];

export default function(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_ERROR:
      return [...state, payload];
    case REMOVE_ERROR:
      return state.filter(error => error.id !== payload);
    case SET_ERRORS:
      return [...payload];
    case CLEAR_ERRORS:
      return [];
    default:
      return state;
  }
}
