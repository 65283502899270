import _ from "lodash";

import {
  GET_UPLOAD_HISTORY,
  GET_FILETYPE_LAST_UPDATED,
  BEGIN_FILETYPE_LAST_UPDATED_FETCH,
  ABORT_FILETYPE_LAST_UPDATED_FETCH,
  UPLOAD_FILE,
  UPDATE_FILE_STATUS,
  ABORT_FILE_UPLOAD,
  BEGIN_UPLOAD_HISTORY_FETCH,
  ABORT_UPLOAD_HISTORY_FETCH,
  UPDATE_UPLOAD_HISTORY,
} from "../actions/types";

import { dataImportFileTypes } from "../utils/constants";
import { sortDataImportLogDesc } from "../utils/format";

const initialState = {
  loadingHistory: true,
  loadingFileTypeInfo: true,
  uploadingFile: true,
  fileTypeInfo: { ...dataImportFileTypes },
  logEntries: [],
};

const applyDates = (fileTypes, dates) => {
  for (const key of Object.keys(dates)) {
    fileTypes[key].lastUpdated = dates[key];
  }

  return fileTypes;
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case BEGIN_UPLOAD_HISTORY_FETCH:
      return {
        ...state,
        loadingHistory: true,
      };
    case ABORT_UPLOAD_HISTORY_FETCH:
      return {
        ...state,
        loadingHistory: false,
        logEntries: [],
      };
    case UPDATE_UPLOAD_HISTORY:
      return {
        ...state,
        logEntries: _.includes(
          state.logEntries.map((entry) => entry.signed_url_id),
          payload.signed_url_id
        )
          ? state.logEntries.map((entry) =>
            String(entry.signed_url_id) === String(payload.signed_url_id)
              ? { ...payload }
              : entry
          )
          : [...state.logEntries, { ...payload }].sort(sortDataImportLogDesc),
      };
    case GET_UPLOAD_HISTORY:
      return {
        ...state,
        loadingHistory: false,
        logEntries: payload.sort(sortDataImportLogDesc),
      };
    case BEGIN_FILETYPE_LAST_UPDATED_FETCH:
      return {
        ...state,
        loadingFileTypeInfo: true,
      };
    case ABORT_FILETYPE_LAST_UPDATED_FETCH:
      return {
        ...state,
        loadingFileTypeInfo: false,
        fileTypeInfo: { ...dataImportFileTypes },
      };
    case GET_FILETYPE_LAST_UPDATED:
      return {
        ...state,
        fileTypeInfo: applyDates({ ...state.fileTypeInfo }, payload),
        loadingFileTypeInfo: false,
      };
    case UPLOAD_FILE:
      return {
        ...state,
        logEntries: [payload, ...state.logEntries],
        loadingHistory: false,
        loadingFileTypeInfo: false,
        uploadingFile: true,
      };
    case UPDATE_FILE_STATUS:
      return {
        ...state,
        logEntries: state.logEntries.map((entry) =>
          entry.id === payload.id ? { ...entry, ...payload } : entry
        ),
        loadingHistory: false,
        loadingFileTypeInfo: false,
        uploadingFile: true,
      };
    case ABORT_FILE_UPLOAD:
      return {
        ...state,
        loadingHistory: false,
        loadingFileTypeInfo: false,
        uploadingFile: false,
      };
    default:
      return state;
  }
}
