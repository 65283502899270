import { useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { history as historyShape } from "react-router-prop-types";
import { connect } from "react-redux";

import { clearErrors } from "../../actions/error";
import { setWorkspaceSelectorDisabled } from "../../actions/workspace";

const NavigationTracker = ({
  history,
  clearErrors,
  setWorkspaceSelectorDisabled,
}) => {

  useEffect(() => {
    clearErrors();
    const { pathname } = history.location;
 
    if (pathname.indexOf("admin-console") === -1) {
      setWorkspaceSelectorDisabled(false);
      return
    } 
    setWorkspaceSelectorDisabled(true);
  }, [
    history.location.key,
    clearErrors,
    history.action,
    history.location,
    setWorkspaceSelectorDisabled
  ]);

  return null;
};

NavigationTracker.propTypes = {
  history: historyShape,
  setWorkspaceSelectorDisabled: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired
};

export default connect(null, { clearErrors, setWorkspaceSelectorDisabled })(
  withRouter(NavigationTracker)
);
