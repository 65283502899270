import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import _ from "lodash";
import { connect } from "react-redux";
import { saveGroup, saveEventGroup, editGroup, editEventGroup, removeGroup } from "../../actions/groupSettings";
import { Grid, Header, Segment, Button, Loader } from "semantic-ui-react";

import { useTranslation } from "react-i18next";
import EditGroup from "./EditGroup/EditGroup";
import EditEventGroup from "./EditEventGroup/EditEventGroup";
import Group from "./Group/Group";
import SubGroupButtons from "./SubGroupsButtons/SubGroupButtons";

import { getSafeSelectedWorkspace } from "../../utils/mappers/user";
import { resolveEditPermission } from "../../utils/mappers/workspace";
import { mapChildToFatherMaterials, combineEventRulesMirrorSubgroups } from "../../utils/mappers/groupSettings";

import { warmupHighMemService } from "../../actions/warmup";

import "./GroupSettings.css";

export const GroupSettings = ({
  items,
  columns,
  groups,
  fatherGroups,
  saveGroup,
  saveEventGroup,
  editGroup,
  editEventGroup,
  removeGroup,
  currentWorkspaceId,
  canEditCurrentWorkspace,
  loadingFetch,
  loadingPutPost,
  loadingDataset,
  isOrdersTableCommitted,
  warmupHighMemService,
}) => {
  const { t } = useTranslation();
  const [showEditGroup, setShowEditGroup] = useState(false);
  const [showEditEventGroup, setShowEditEventGroup] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [showSubgroups, setShowSubgroups] = useState(false);
  const [selectedFatherGroup, setSelectedFatherGroup] = useState(null)

  useEffect(() => {
    warmupHighMemService();
  }, [warmupHighMemService]);

  useEffect(() => {
    setShowEditGroup(false);
    setSelectedGroup(null);
    setShowEditEventGroup(false);
    setShowSubgroups(false);
    setSelectedFatherGroup(null);
  }, [currentWorkspaceId]);

  if (!currentWorkspaceId) {
    return (
      <div className="ui container container-space">
        <Segment placeholder style={{ minHeight: "350px" }}>
          <h2>{t("workspaceRequired")}</h2>
        </Segment>
      </div>
    );
  }

  if (loadingFetch || loadingDataset) {
    return (
      <div className="ui container">
        <Loader active size="large" />
      </div>
    );
  }

  if (!loadingFetch && _.isEmpty(items)) {
    return (
      <div className="ui container container-space">
        <Segment placeholder style={{ minHeight: "350px" }}>
          <h2>{t("noItems")}</h2>
        </Segment>
      </div>
    );
  }

  const groupsDisplay = (groups, showSubgroups, fatherGroup = null) => {
    let selectedGroups = []
    // combine event groups and rules groups according their common materials

    if (showSubgroups) {
      groups = combineEventRulesMirrorSubgroups(groups)
      // combineEventRulesMirrorSubgroups
      selectedGroups = groups.filter(group => (
        group.rules.materialMethod.includes('event') || group.rules.materialMethod.includes('rules'))).filter(group =>
          group.rules.materialMethod.split('-')[1] === (fatherGroup.id).toString());
    }
    else {
      selectedGroups = groups.filter(group =>
        !group.rules.materialMethod.includes('event') && !group.rules.materialMethod.includes('rules'));
    }

    return (
      selectedGroups.map((group) =>
        !_.isEmpty(selectedGroup) &&
          selectedGroup.id === group.id ? null : (
          <Grid.Column width={5} key={group.id}>
            <Group
              editable={canEditCurrentWorkspace}
              onEditClick={() => modifyGroup(group)}
              onRemoveClick={() => deleteGroup(group)}
              groupProps={group}
              onEditEventGroup={() => handleEventGroup(group)}
              groups={groups}
            ></Group>
          </Grid.Column>
        )
      )
    )
  }

  const handleEventGroup = (fatherGroup) => {
    setShowSubgroups(true)
    setSelectedFatherGroup(fatherGroup)

  }

  const closeEventGroups = () => {
    setSelectedFatherGroup(null)
    setShowSubgroups(false)
    setSelectedGroup(null)
  }

  const groupEdition = (
    canEditCurrentWorkspace,
    showEditGroup,
    showEditEventGroup,
    showSubgroups,
    selectedFatherGroup,

  ) => {
    if (canEditCurrentWorkspace && showEditGroup) {
      return (
        <EditGroup
          groups={groups}
          loading={loadingPutPost}
          groupNames={groups.map((group) => group.name)}
          columns={columns}
          items={items}
          selectedGroup={selectedGroup}
          onSave={async (groupData) => {
            if (_.isEmpty(selectedGroup)) {
              await saveGroup(
                currentWorkspaceId,
                groupData,
                isOrdersTableCommitted
              );
            } else {
              await editGroup(
                currentWorkspaceId,
                groupData,
                isOrdersTableCommitted
              );
              setSelectedGroup(null);
              mapChildToFatherMaterials(
                groups,
                columns,
                fatherGroups,
                items,
                groupData,
                editEventGroup,
                currentWorkspaceId,
                isOrdersTableCommitted
              );
            }
            setShowEditGroup(false);
            setSelectedGroup(null);
          }}
          onCancel={() => {
            setShowEditGroup(false);
            setSelectedGroup(null);
          }}
        />
      )
    }
    if (canEditCurrentWorkspace && showEditEventGroup) {
      return (
        <EditEventGroup
          loading={loadingPutPost}
          groups={groups}
          groupNames={groups.map((group) => group.name)}
          columns={columns}
          items={items}
          selectedGroup={selectedGroup}
          selectedFatherGroup={selectedFatherGroup == null ? selectedGroup : selectedFatherGroup}
          onSave={async (groupData) => {

            if (selectedGroup == null) {
              await saveEventGroup(
                currentWorkspaceId,
                groupData,
                isOrdersTableCommitted
              );
            }
            else {
              await editEventGroup(
                currentWorkspaceId,
                groupData,
                isOrdersTableCommitted
              );
            }
            setShowEditEventGroup(false);
            setSelectedGroup(null)
          }}
          onCancel={() => {
            setShowEditEventGroup(false);
            setSelectedGroup(null);
          }}
        />
      )
    }
    if (showSubgroups && selectedFatherGroup != null) {
      return (
        <SubGroupButtons
          goBack={() => closeEventGroups()}
          newSubgroup={() => setShowEditEventGroup(true)}
        />
      )
    }
    else {
      return (
        <Grid centered className="add-button-container">
          <Button
            disabled={!canEditCurrentWorkspace}
            primary
            className={"add-group"}
            onClick={() => {
              if (!canEditCurrentWorkspace) {
                return;
              }
              setShowEditGroup(true);
            }}
          >
            {t("groupSettings.addNew")}
          </Button>
        </Grid>

      )
    }
  }
  const modifyGroup = (group) => {
    if (!canEditCurrentWorkspace) {
      return;
    }

    if (group.rules.materialMethod.includes("event") || group.rules.materialMethod.includes('rules')) {
      setShowEditEventGroup(true)
    }
    else {
      setShowEditGroup(true);
    }

    setSelectedGroup(group);
  }

  const deleteGroup = (group) => {
    if (!canEditCurrentWorkspace) {
      return;
    }
    removeGroup(
      currentWorkspaceId,
      group.id,
      isOrdersTableCommitted
    );
    setSelectedGroup(null);

  }

  return (
    <Segment basic secondary className="group-settings-container">
      <Grid relaxed stackable>
        <Grid.Row centered>
          <Grid.Column widescreen={13}>

            {_.isEmpty(groups) && !showEditGroup
              ? (
                <Grid.Row centered className="group-settings-add-button-container">
                  <Button
                    disabled={!canEditCurrentWorkspace}
                    primary
                    onClick={() => {
                      if (!canEditCurrentWorkspace) {
                        return;
                      }
                      setShowEditGroup(true);
                    }}
                  >
                    {t("groupSettings.addNew")}
                  </Button>
                </Grid.Row>
              )
              : null
            }
            <Grid.Row >
              {_.isEmpty(groups) && !showEditGroup ? (
                <Segment placeholder>
                  <Header icon> {t("groupSettings.noSettings")}</Header>
                </Segment>
              ) : (
                <Segment basic>
                  {groupEdition(
                    canEditCurrentWorkspace,
                    showEditGroup,
                    showEditEventGroup,
                    showSubgroups,
                    selectedFatherGroup
                  )}

                  <Segment>
                    {(selectedFatherGroup != null && showSubgroups) ?
                      (<Header
                        className='subgroups-header'
                        textAlign='center'
                      > {t("groupSettings.mainGroup") + selectedFatherGroup.name}
                      </Header>) : null}
                    <div className={"saved-groups"}>
                      <Grid stackable>
                        {groupsDisplay(groups, showSubgroups, selectedFatherGroup)}
                      </Grid>
                    </div>
                  </Segment>
                </Segment>
              )}
            </Grid.Row>

          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
};

GroupSettings.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  columns: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  groups: PropTypes.arrayOf(PropTypes.object),
  fatherGroups: PropTypes.array,
  saveGroup: PropTypes.func,
  saveEventGroup: PropTypes.func,
  editGroup: PropTypes.func,
  editEventGroup: PropTypes.func,
  removeGroup: PropTypes.func,
  currentWorkspaceId: PropTypes.number,
  loadingFetch: PropTypes.bool,
  loadingPutPost: PropTypes.bool,
  loadingDataset: PropTypes.bool,
  isOrdersTableCommitted: PropTypes.bool,
  canEditCurrentWorkspace: PropTypes.bool,
  warmupHighMemService: PropTypes.func,
};

GroupSettings.defaultProps = {
  items: [],
  groups: [],
  fatherGroups: [],
  saveGroup: _.noop,
  saveEventGroup: _.noop,
  editGroup: _.noop,
  editEventGroup: _.noop,
  removeGroup: _.noop,
  loadingFetch: false,
  loadingPutPost: false,
  loadingDataset: false,
  warmupHighMemService: _.noop,
};

const mapStateToProps = (state) => ({
  items: state.items.items,
  columns: state.dataset.columns,
  groups: state.groupSettings.groups,
  fatherGroups: state.groupSettings.fatherGroups,
  currentWorkspaceId: getSafeSelectedWorkspace(state),
  canEditCurrentWorkspace:
    state.auth.isConnected &&
    resolveEditPermission(state.auth.user, getSafeSelectedWorkspace(state)),
  loadingFetch: state.groupSettings.loadingFetch,
  loadingPutPost: state.groupSettings.loadingPutPost,
  loadingDataset: state.dataset.loading,
  isOrdersTableCommitted: state.ordersTable.isCommitted,
});

export default connect(mapStateToProps, {
  saveGroup,
  saveEventGroup,
  editGroup,
  editEventGroup,
  removeGroup,
  warmupHighMemService,
})(GroupSettings);
