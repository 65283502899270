import React, { useRef } from 'react'
import { connect } from 'react-redux'
import { Grid, Button, Menu, Checkbox } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { resolveEditPermission } from '../../../utils/mappers/workspace'
import { getSafeSelectedWorkspace } from '../../../utils/mappers/user'
import { setSelectedWorkspaceIds } from '../../../actions/calendar'
import LargeTextPopup from '../../LargeTextPopup/LargeTextPopup'
import useComponentSize from '../../../hooks/useComponentSize'
import './EventMenu.css'

const EventMenu = ({
    canEditCurrentWorkspace,
    workspaces,
    setSelectedWorkspaceIds,
    selectedWorkspaceIds,
    clickDate,
    openNewEventModal
    })=>{
    
    const { t } = useTranslation()
    const ref = useRef()
    const { componentWidth } = useComponentSize(ref)

    return(
      <Grid.Column className='event-menu-container'>
        <Grid.Row>
          <Button
            disabled={!canEditCurrentWorkspace}
            fluid
            positive
            onClick={() => {
                clickDate("")
                openNewEventModal(canEditCurrentWorkspace && true)
            //   setClickedDate("");
            //   setOpenNewEventModal(canEditCurrentWorkspace && true);
            }}
          >
            {t("calendar.events.createEvent")}
          </Button>
        </Grid.Row>

        <Grid.Row className='separator-calendar'>
          <div ref={ref}>
            <Menu vertical compact fluid className='calendar-menu-event'>
              <Menu.Item header >
                <p className='title-calendar-menu'>{t("calendar.workspacesListTitle")}</p>
              </Menu.Item>
              {workspaces.map((workspace) => (
                <Menu.Item key={workspace.id}>
                  <Checkbox
                    label={()=>(
                                  <LargeTextPopup 
                                    content={workspace.name}
                                    containerWidth={componentWidth}
                                    wordLength={0.07}
                                    />
                          )}
                    checked={_.includes(selectedWorkspaceIds, workspace.id)}
                    onChange={(event, data) => {
                      if (data.checked) {
                        setSelectedWorkspaceIds([
                          ...selectedWorkspaceIds,
                          workspace.id,
                        ]);
                      } else {
                        setSelectedWorkspaceIds(
                          selectedWorkspaceIds.filter(
                            (id) => id !== workspace.id
                          )
                        );
                      }
                    }}
                  />
                </Menu.Item>
              ))}
            </Menu>
          </div>
        </Grid.Row>
      </Grid.Column>
    )
}

EventMenu.propTypes = {
    canEditCurrentWorkspace: PropTypes.bool,
    workspaces: PropTypes.arrayOf(PropTypes.object), //TODO: check if this is correct
    setSelectedWorkspaceIds: PropTypes.func,
    selectedWorkspaceIds: PropTypes.arrayOf(PropTypes.number),
    clickDate: PropTypes.func,
    openNewEventModal: PropTypes.func,
}

EventMenu.defaultProps = {
    canEditCurrentWorkspace: false,
    workspaces: [],
    selectedWorkspaceIds: []
}

const mapStateToProps = (state) => ({
    canEditCurrentWorkspace:
        state.auth.isConnected &&
        resolveEditPermission(state.auth.user, getSafeSelectedWorkspace(state)),
    workspaces: state.workspace.workspaces,
    selectedWorkspaceIds: state.calendar.selectedWorkspaceIds
})

export default connect(mapStateToProps, { setSelectedWorkspaceIds })(EventMenu)