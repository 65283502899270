import _ from "lodash";

import { batchStatuses, itemAccessors } from "../constants";
import { round } from "../format";

let countNames = {
  black: [],
  grey: [],
  red: [],
  yellow: [],
  green: [],
  blue: [],
}

export const getSafeItemsCurrency = (items) => {
  if (_.isEmpty(items) || !_.has(items[0], itemAccessors.unitCostCurrency)) {
    return null;
  }
  return items[0][itemAccessors.unitCostCurrency];
};

export const mapItemsToBatchDataSummary = (items) => {
  const mapped = items.map((item) => item.worst_batch_status);

  const counts = {
    expired: 0,
    expiring_this_week: 0,
    expiring_next_week: 0,
    expiring_in_two_weeks: 0,
    expiring_in_three_weeks: 0,
  };

  for (const status of mapped) {
    switch (status) {
      case batchStatuses.expired:
        ++counts.expired;
        break;
      case batchStatuses.expiry_this_week:
        ++counts.expiring_this_week;
        break;
      case batchStatuses.expiry_next_week:
        ++counts.expiring_next_week;
        break;
      case batchStatuses.expiry_two_weeks:
        ++counts.expiring_in_two_weeks;
        break;
      case batchStatuses.expiry_three_weeks:
        ++counts.expiring_in_three_weeks;
        break;
      default:
        break;
    }
  }

  return counts;
};

export const mapItemsToOnHandStockDataSummary = (items) => {
  const counts = {
    black: 0,
    grey: 0,
    red: 0,
    yellow: 0,
    green: 0,
    blue: 0,
    total: items.length,
  };

  countNames = {
    black: [],
    grey: [],
    red: [],
    yellow: [],
    green: [],
    blue: [],
  }

  for (const item of items) {
    if (item.on_hand_stock <= 0) {
      ++counts.black;
      countNames.black.push(item.location_code_concatenation)
    } else if (item.on_hand_stock <= item.top_of_grey) {
      ++counts.grey;
      countNames.grey.push(item.location_code_concatenation)
    } else if (item.on_hand_stock <= item.top_of_red) {
      ++counts.red;
      countNames.red.push(item.location_code_concatenation)
    } else if (item.on_hand_stock <= item.top_of_yellow) {
      ++counts.yellow;
      countNames.yellow.push(item.location_code_concatenation)
    } else if (item.on_hand_stock <= item.top_of_green) {
      ++counts.green;
      countNames.green.push(item.location_code_concatenation)
    } else {
      ++counts.blue;
      countNames.blue.push(item.location_code_concatenation)
    }
  }

  return { counts, countNames };
};

export const mapItemsToOnHandStockDataSummaryCurrency = (items) => {
  const counts = {
    black: 0,
    grey: 0,
    red: 0,
    yellow: 0,
    green: 0,
    blue: 0,
    total: 0,
  };

  const toNumber = (value) => (value === "-" ? 0 : value);

  const mappedItems = items.map((item) => ({
    ...item,
    on_hand_stock: toNumber(item.on_hand_stock),
    grey_zone: toNumber(item.grey_zone),
    red_zone: toNumber(item.red_zone),
    yellow_zone: toNumber(item.yellow_zone),
    green_zone: toNumber(item.green_zone),
    top_of_grey: toNumber(item.top_of_grey),
    top_of_red: toNumber(item.top_of_red),
    top_of_yellow: toNumber(item.top_of_yellow),
    top_of_green: toNumber(item.top_of_green),
  }));

  for (const item of mappedItems) {
    if (item.material_method !== "buffered") {
      counts.blue += item.on_hand_stock * item.unit_cost;
      continue;
    }

    if (item.on_hand_stock <= 0) {
      continue;
    }

    if (item.top_of_grey > 0) {
      if (item.on_hand_stock > item.top_of_grey) {
        counts.grey += item.grey_zone * item.unit_cost;
      } else {
        counts.grey += item.on_hand_stock * item.unit_cost;
        continue;
      }
    }

    if (item.on_hand_stock > item.top_of_red) {
      counts.red += item.red_zone * item.unit_cost;
    } else {
      counts.red += (item.on_hand_stock - item.top_of_grey) * item.unit_cost;
      continue;
    }

    if (item.on_hand_stock > item.top_of_yellow) {
      counts.yellow += item.yellow_zone * item.unit_cost;
    } else {
      counts.yellow += (item.on_hand_stock - item.top_of_red) * item.unit_cost;
      continue;
    }

    if (item.on_hand_stock > item.top_of_green) {
      counts.green += item.green_zone * item.unit_cost;
      counts.blue += (item.on_hand_stock - item.top_of_green) * item.unit_cost;
    } else {
      counts.green +=
        (item.on_hand_stock - item.top_of_yellow) * item.unit_cost;
      continue;
    }
  }

  counts.total = round(
    counts.black +
      counts.grey +
      counts.red +
      counts.yellow +
      counts.green +
      counts.blue,
    2
  );

  counts.blue = round(counts.blue, 2);
  counts.grey = round(counts.grey, 2);
  counts.red = round(counts.red, 2);
  counts.yellow = round(counts.yellow, 2);
  counts.green = round(counts.green, 2);

  return { counts, countNames };
};

export const mapItemsToOrderSummary = (items) => {
  const counts = {
    listed: items.length,
    suggested: 0,
    changed: 0,
    unchanged: 0,
  };

  for (const item of items) {
    if (item.suggested_order > 0) {
      ++counts.suggested;
    }
    if (item.suggested_order !== item.actual_order) {
      ++counts.changed;
    }
    if (item.suggested_order === item.actual_order) {
      ++counts.unchanged;
    }
  }

  return counts;
};

export const mapItemsToProjectedStockOuts = (items) => {
  const counts = {
    today: 0,
    tomorrow: 0,
    within_7: 0,
    within_15: 0,
    within_30: 0,
  };

  for (const item of items) {
    if (!_.isNumber(item.projected_stock_out)) {
      continue;
    }
    if (item.projected_stock_out < 1) {
      ++counts.today;
    } else if (item.projected_stock_out < 2) {
      ++counts.tomorrow;
    } else if (item.projected_stock_out <= 7) {
      ++counts.within_7;
    } else if (item.projected_stock_out < 15) {
      ++counts.within_15;
    } else if (item.projected_stock_out <= 30) {
      ++counts.within_30;
    }
  }

  return counts;
};
