import React from 'react';
import { toWorkspaceTimezone } from '../../../utils/format';
import { Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { FILE_DATE_FORMAT } from '../../../utils/constants';

const ImportDates = ({
  dataImportStatus,
  workspaceTimezone,
  fileType
}) => {
  const { t } = useTranslation();
  const importDateDetail = () => {
    if (_.isNil(dataImportStatus[fileType].lastUpdated)){
      return 'N/A';
    }
    else{
      return toWorkspaceTimezone(
        dataImportStatus[fileType].lastUpdated,
        workspaceTimezone,
        FILE_DATE_FORMAT);
    }
  };
  const timeStatus = dataImportStatus[fileType].uploadTimeStatus.message;
  const uploadTimeStatusUpdated = ( timeStatus !== 'no_file_uploaded' && timeStatus !== 'file_up_to_date');

  const uploadMessageDetails = () => {
    if(!uploadTimeStatusUpdated){
      return null;
    }
    return (
      <div>
        <Icon
          className={
            dataImportStatus[fileType].uploadTimeStatus.color
          }
          name="circle"
          size="small"
        />
        {t(
          'dataImport.' +
                 dataImportStatus[fileType].uploadTimeStatus.message
        )}
      </div>
    );
  };

  return(
    <React.Fragment>
      <div>
        {t('dataImport.lastUpdated', {
          date: importDateDetail(),
        })}
      </div>
      {uploadMessageDetails()}
    </React.Fragment>
  );
};

ImportDates.propTypes = {
  dataImportStatus: PropTypes.object,
  workspaceTimezone: PropTypes.string,
  fileType: PropTypes.string
};

export default ImportDates;