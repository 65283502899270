import React, { useState } from "react";

import PropTypes from "prop-types";

import _ from "lodash";
import { Dropdown, Grid } from "semantic-ui-react";

import { useTranslation } from "react-i18next";

import "./FiltersDropdown.css";

const FiltersDropdown = ({
  markedGroupFields,
  groupFields,
  onChange,
  disabled
}) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState({}); //TODO CHECK THIS INIITIAL VALUE

  const updateChecked = (accessor, value) => {
    setChecked({ ...checked, [accessor]: value });
  };

  return (
    <Dropdown
      className="ui selection dropdown"
      upward={false}
      disabled={disabled}
      placeholder={
        _.isEmpty(groupFields)
          ? t("groupSettings.filtersDropdownEmpty")
          : t("groupSettings.filtersDropdownPlaceholder")
      }
      onClick={() => setOpen(true)}
      onBlur={() => {
        setOpen(false);
      }}
      closeOnChange={false}
      open={open}
    >
      {_.isEmpty(groupFields) || _.isEmpty(markedGroupFields) ? null : (
        <Dropdown.Menu className="group-fields-dropdown">
          {groupFields.map(groupField => (
            <Dropdown.Item
              key={groupField.accessor}
              className="group-fields-dropdown-item"
            >
              <Grid>
                <Grid.Row>
                  <div className="ui item checkbox">
                    <input
                      type="checkbox"
                      name={groupField.name}
                      onClick={e => {
                        //console.log(e.target.checked);
                        updateChecked(groupField.accessor, e.target.checked);
                        onChange(groupField.accessor, e.target.checked);
                      }}
                      value={checked[groupField.accessor]}
                      defaultChecked={_.includes(
                        markedGroupFields,
                        groupField.accessor
                      )}
                    />
                    <label>{groupField.name}</label>
                  </div>
                </Grid.Row>
              </Grid>
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      )}
    </Dropdown>
  );
};

FiltersDropdown.propTypes = {
  markedGroupFields: PropTypes.arrayOf(PropTypes.string),
  groupFields: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
  disabled: PropTypes.bool
};

FiltersDropdown.defaultProps = {
  markedGroupFields: [],
  groupFields: [],
  onChange: _.noop,
  disabled: false
};

export default FiltersDropdown;
