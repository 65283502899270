import React, { useState } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Modal, Button, Grid } from "semantic-ui-react";

import { getBatchInventoryModeValue } from "../../../utils/mappers/workspace";

import { useTranslation } from "react-i18next";

const DownloadTemplatesModal = ({ workspaces, currentWorkspaceId }) => {
  const { t, i18n } = useTranslation();
  const [showDownloadModal, setShowDownloadModal] = useState(false);

  const currentBatchInventoryMode = getBatchInventoryModeValue(
    workspaces,
    currentWorkspaceId
  );

  return (
    <Modal
      trigger={
        <Button
          positive
          onClick={() => {
            setShowDownloadModal(true);
          }}
          className="download-templates"
        >
          {t("dataImport.downloadTemplates")}
        </Button>
      }
      open={showDownloadModal}
      onClose={() => {
        setShowDownloadModal(false);
      }}
    >
      <Modal.Header>{t("dataImport.downloadTemplates")}</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Grid container columns={1} verticalAlign={"middle"}>
            <Grid.Row>
              <Button
                as="a"
                href={
                  // eslint-disable-next-line no-undef
                  `${process.env.PUBLIC_URL}/excel_templates/${
                    i18n.language
                  }/${t("dataImport.templateFilenames.master")}.xlsx`
                }
                download
              >
                {t("dataImport.fileSections.master")}
              </Button>
            </Grid.Row>
            <Grid.Row>
              <Button
                as="a"
                href={
                  // eslint-disable-next-line no-undef
                  `${process.env.PUBLIC_URL}/excel_templates/${
                    i18n.language
                  }/${t("dataImport.templateFilenames.historical_demand")}.xlsx`
                }
                download
              >
                {t("dataImport.fileSections.historical_demand")}
              </Button>
            </Grid.Row>
            {currentBatchInventoryMode ? (
              <Grid.Row>
                <Button
                  as="a"
                  href={
                    // eslint-disable-next-line no-undef
                    `${process.env.PUBLIC_URL}/excel_templates/${
                      i18n.language
                    }/${t("dataImport.templateFilenames.inventory_batch")}.xlsx`
                  }
                  download
                >
                  {t("dataImport.fileSections.inventory_batch")}
                </Button>
              </Grid.Row>
            ) : (
              <Grid.Row>
                <Button
                  as="a"
                  href={
                    // eslint-disable-next-line no-undef
                    `${process.env.PUBLIC_URL}/excel_templates/${
                      i18n.language
                    }/${t("dataImport.templateFilenames.inventory")}.xlsx`
                  }
                  download
                >
                  {t("dataImport.fileSections.inventory")}
                </Button>
              </Grid.Row>
            )}
          </Grid>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={() => {
            setShowDownloadModal(false);
          }}
        >
          {t("close")}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

DownloadTemplatesModal.propTypes = {
  workspaces: PropTypes.arrayOf(PropTypes.object),
  currentWorkspaceId: PropTypes.number,
};

const mapStateToProps = (state) => ({
  workspaces: state.workspace.workspaces,
  currentWorkspaceId: state.workspace.currentWorkspace,
});

export default connect(mapStateToProps)(DownloadTemplatesModal);
