import React, { useState } from "react";
import PropTypes from "prop-types";

import _ from "lodash";

import { useTranslation } from "react-i18next";

import { Button, Modal, Icon, Form, Message } from "semantic-ui-react";

const CustomLayoutModal = ({
  suggestedName,
  checkDuplicate,
  onSubmit,
  onCancel
}) => {
  const [name, setName] = useState(suggestedName);
  const [error, setError] = useState(false);
  const { t } = useTranslation();
  return (
    <Modal open>
      <Modal.Header>
        {t("ordersTable.layouts.newLayoutModal.header")}
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Form
            error={error}
            onSubmit={() => {
              if (!checkDuplicate(name)) {
                setError(false);
                onSubmit(name);
                onCancel();
              } else {
                setError(true);
              }
            }}
          >
            <Form.Field required>
              <label htmlFor="name">
                {t("ordersTable.layouts.newLayoutModal.input")}
              </label>
              <input
                name="name"
                type="text"
                value={name}
                required
                onChange={e => setName(e.target.value)}
              />
            </Form.Field>
            <Message error content={t("ordersTable.layouts.duplicateName")} />
            <Button color="red" onClick={onCancel}>
              <Icon name="remove" /> {t("cancel")}
            </Button>
            <Button color="green">
              <Icon name="checkmark" /> {t("save")}
            </Button>
          </Form>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
};

CustomLayoutModal.propTypes = {
  suggestedName: PropTypes.string,
  checkDuplicate: PropTypes.func,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
};

CustomLayoutModal.defaultProps = {
  suggestedName: "",
  checkDuplicate: _.noop,
  onSubmit: _.noop,
  onCancel: _.noop
};

export default CustomLayoutModal;
