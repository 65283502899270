import React from "react";

import _ from "lodash";

import axiosWrapper from "../utils/axiosWrapper";

import {
  GET_ITEMS,
  RESET_ORDER_CALCULATIONS_METADATA,
  UPDATE_ORDER_CALCULATIONS_METADATA,
  BEGIN_ITEMS_FETCH,
  ABORT_ITEMS_FETCH,
  SET_CURRENT_PAGE,
  SET_PAGE_SIZE,
  BEGIN_TABLE_UPDATE,
  END_TABLE_UPDATE,
  GET_BATCH_DATA,
  ABORT_TABLE_UPDATE
} from "./types";

import { toast } from "react-toastify";

import ToastI18nWrapper from "../components/ToastI18nWrapper/ToastI18nWrapper";

import {
  mapResultsRecordsToTableData,
  mapBatchData,
} from "../utils/mappers/ordersTable";
import { getWorkspaceTimezone } from "../utils/mappers/workspace";
import { setSelectedRow } from "./ordersTable";
import { recordsWithOnHandStockColors } from "../utils/mappers/ordersTable";

const showUnassignedGroupsWarning = (records) => {
  if (
    !_.isEmpty(
      records.map((record) => record.group).filter((group) => _.isNil(group))
    )
  ) {
    toast.warn(
      <ToastI18nWrapper
        translateKey={"ordersTable.unassignedMaterialsWarning"}
      />, {
      toastId: "unassignedMaterialsWarning",
    }
    );
  }
};

export const getItems = (current_workspace_id, inBackground = false) => async (
  dispatch, getState
) => {
  try {
    if (!inBackground) {
      dispatch({ type: BEGIN_ITEMS_FETCH });
    }
    const response = await axiosWrapper.get(
      `/order-table?current_workspace_id=${current_workspace_id}`
    );

    dispatch(setSelectedRow(null));
    // This condition only happend when response code is 204
    if(_.isEmpty(response.data)){
      dispatch(clearOrderTable())
      return
    }

    const workspaceTimezone = getWorkspaceTimezone(getState())

    dispatch({
      type: GET_ITEMS,
      payload: mapResultsRecordsToTableData(recordsWithOnHandStockColors(response.data.results.records), workspaceTimezone),
    });
    dispatch({
      type: GET_BATCH_DATA,
      payload: mapBatchData(response.data.batch_data, workspaceTimezone),
    });
    dispatch({
      type: UPDATE_ORDER_CALCULATIONS_METADATA,
      payload: {
        isCommitted: !_.isNil(response.data.committed_at),
        committedAt: response.data.committed_at,
        id: response.data.id,
        calendarEvents: response.data.events.records,
        groups: response.data.groups.records,
      },
    });
    dispatch({
      type: END_TABLE_UPDATE,
    });
    showUnassignedGroupsWarning(response.data.results.records);
  } catch (error) {

    dispatch(setSelectedRow(null));
    dispatch({ type: ABORT_TABLE_UPDATE });
    dispatch({ type: ABORT_ITEMS_FETCH });

    toast.error(<ToastI18nWrapper translateKey={"genericError"} />, {
      toastId: "genericError",
    })
  }
};

export const showSyncingSpinner = (show, showErrorToast = true) => async (
  dispatch
) => {
  if (show) {
    dispatch({
      type: BEGIN_TABLE_UPDATE,
    });
  } else {
    dispatch({
      type: END_TABLE_UPDATE,
    });
    if (showErrorToast) {
      toast.error(<ToastI18nWrapper translateKey={"errorSyncing"} />, {
        toastId: "errorSyncing",
      });
    }
  }
}

export const calculateOrderTable = (current_workspace_id) => async (
  dispatch, getState
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = JSON.stringify({
    current_workspace_id: current_workspace_id,
  });

  try {
    const workspaceTimezone = getWorkspaceTimezone(getState())

    dispatch({
      type: BEGIN_TABLE_UPDATE,
    });
    const response = await axiosWrapper.post(
      "/order-calculation",
      body,
      config
    );
    dispatch({
      type: RESET_ORDER_CALCULATIONS_METADATA,
    });
    dispatch({
      type: GET_ITEMS,
      payload: mapResultsRecordsToTableData(response.data.results.records, workspaceTimezone),
    });
    dispatch({
      type: UPDATE_ORDER_CALCULATIONS_METADATA,
      payload: {
        isCommitted: !_.isNil(response.data.committed_at),
        committedAt: response.data.committed_at,
        id: response.data.id,
      },
    });
    dispatch({
      type: END_TABLE_UPDATE,
    });
    showUnassignedGroupsWarning(response.data.results.records);
  } catch (error) {

    dispatch({ type: ABORT_TABLE_UPDATE });
    if (!_.isNil(error.response)) {
      switch (error.response.status) {
        case 412:
          dispatch({
            type: GET_ITEMS,
            payload: [],
          });
          break;
        default:
          toast.error(
            <ToastI18nWrapper
              translateKey={"ordersTable.genericCalculationError"}
            />,
            { toastId: "genericCalculationError" }
          );
      }
    } else {
      toast.error(
        <ToastI18nWrapper
          translateKey={"ordersTable.genericCalculationError"}
        />,
        { toastId: "genericCalculationError" }
      );
    }
  }
};

export const setCurrentPage = (pageIndex) => (dispatch) => {
  dispatch({
    type: SET_CURRENT_PAGE,
    payload: pageIndex,
  });
};

export const setPageSize = (pageSize) => (dispatch) => {
  dispatch({
    type: SET_PAGE_SIZE,
    payload: pageSize,
  });
};


export const clearOrderTable = () => dispatch => {
  dispatch(setSelectedRow(null));
  dispatch({
    type: GET_ITEMS,
    payload: [],
  });
  dispatch({
    type: GET_BATCH_DATA,
    payload: null,
  });
  dispatch({
    type: UPDATE_ORDER_CALCULATIONS_METADATA,
    payload: {
      isCommitted: false,
      committedAt: null,
      id: null,
      calendarEvents: null,
      groups: null,
    },
  });
}