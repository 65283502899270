import React, { useState } from "react"
import { Button, Icon, Input, Grid, Popup } from "semantic-ui-react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"
import "./EditName.css"

const EditName = ({ rowData, onEditClick, simulationsSavedList, disableComponent, cancelAction })=>{
    const [ inEditClicked, setIsEditClicked ] = useState(false)
    const [ newSimulationName, setNewSimulationName ] = useState(rowData.sim_name)
    const [ colorEditIcon, setColorEditIcon ] = useState("grey")
    const [ inputError, setInputError ] = useState(false)
    const [ wasSimNameChanged, setWasSimNameChanged ] = useState(false)
    const { t } = useTranslation()

    const editClickedAction = ()=>{
        setWasSimNameChanged(false)
        setIsEditClicked(true)
    }

    const positiveActionButton = () =>{
        // Check if inserted value is different from saved simulation list
        const findInsertedName = simulationsSavedList.find(value => newSimulationName === value.sim_name)

        if(findInsertedName){
            setInputError(true)
        }
        if(!findInsertedName){
            onEditClick({   
                            save_name: newSimulationName, 
                            sim_id: rowData.sim_id, 
                            material_id: rowData.material_id, 
                        })
            setColorEditIcon("red")
            setIsEditClicked(false)
            setInputError(false)
            setWasSimNameChanged(true)
        }
    }

    const renderEditComponent = () => {
        return(
            <Grid stackable>
                <Grid.Row>
                    <Popup 
                        content={t("simulation.nameExists")}
                        open={inputError}
                        className="sim-name-popup-error"
                        // popper is used here because of problem when user only have one simulation saved
                        popperModifiers={{ preventOverflow: { padding: {top: -100, right: -1000}} }}
                        trigger={
                                <Input
                                    error={inputError}
                                    value={newSimulationName}
                                    onChange={(e, data) => setNewSimulationName(data.value)}
                                />
                        }
                    />
                    
                    <Button 
                        size="mini" 
                        color="black" 
                        onClick={()=>cancelChanges()}>
                        {t("cancel")}
                    </Button>
                    
                    <Button 
                        size="mini" 
                        positive 
                        onClick={()=>positiveActionButton()}>
                        {t("confirm")}
                    </Button>
                </Grid.Row>
            </Grid>
        )
    }

    const deleteChanges = ()=>{
        setColorEditIcon("grey")
        setNewSimulationName(rowData.sim_name)
        onEditClick("")
        setWasSimNameChanged(false)
        cancelAction(false)
    }

    const cancelChanges = ()=>{
        setNewSimulationName(rowData.sim_name)
        setIsEditClicked(false); 
        setInputError(false)
        cancelAction(false)
    }

    return(
        <div>
            {
                inEditClicked
                    ?   renderEditComponent()
                    :   newSimulationName
            }
            {
                !inEditClicked
                    &&   <Button 
                            disabled={disableComponent}
                            positive 
                            icon
                            size="mini"
                            className="edit-simulation-name-button-background"
                            onClick={()=>editClickedAction()}>
                            <Icon 
                                name='pencil' 
                                color={colorEditIcon} 
                                className="icon-edit-simulation-name-button"/>
                        </Button>
            }
            {
                wasSimNameChanged
                    &&   <Button 
                            icon
                            size="mini"
                            basic
                            color="red"
                            onClick={()=>deleteChanges()}>
                            <Icon 
                                size="small"
                                name='close' 
                                />
                        </Button>
            }
            
        </div>
    )
}

EditName.propTypes = {
    rowData: PropTypes.object,
    onEditClick: PropTypes.func,
    simulationsSavedList: PropTypes.array,
    disableComponent: PropTypes.bool,
    cancelAction: PropTypes.func
}

const mapStateToProps = (state)=>({
    simulationsSavedList: state.analytics.simulationsSavedList,
})
export default connect(mapStateToProps, null)(EditName)