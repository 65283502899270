import moment from "moment-timezone";
import _ from "lodash";

export const mapFileDataToStatus = (fileTypeInfo, logEntries) => {
  let fileTypeInfoCopy = _.cloneDeep(fileTypeInfo);

  const now = moment.utc();
  const fileUpdateRules = {
    master: {
      red: 720,
      redCode: "overMonth",
      yellow: 336,
      yellowCode: "overTwoWeeks",
    },
    historical_demand: {
      red: 720,
      redCode: "overMonth",
      yellow: 336,
      yellowCode: "overTwoWeeks",
    },
    inventory: {
      red: 168,
      redCode: "overWeek",
      yellow: 96,
      yellowCode: "over4Days",
    },
  };

  for (const fileType in fileUpdateRules) {
    let fileUploadTime = moment.utc(fileTypeInfoCopy[fileType].lastUpdated);
    fileTypeInfoCopy[fileType].uploadTimeStatus = {};
    fileTypeInfoCopy[
      fileType
    ].uploadTimeStatus.hoursSinceUpdate = moment
      .duration(now.diff(fileUploadTime))
      .hours();

    if (!fileTypeInfoCopy[fileType].lastUpdated) {
      fileTypeInfoCopy[fileType].uploadTimeStatus.color = "red";
      fileTypeInfoCopy[fileType].uploadTimeStatus.message = "no_file_uploaded";
    } else if (
      fileTypeInfoCopy[fileType].uploadTimeStatus.hoursSinceUpdate >=
      fileUpdateRules[fileType].red
    ) {
      fileTypeInfoCopy[fileType].uploadTimeStatus.color = "red";
      fileTypeInfoCopy[fileType].uploadTimeStatus.message =
        fileUpdateRules[fileType].redCode;
    } else if (
      fileTypeInfoCopy[fileType].uploadTimeStatus.hoursSinceUpdate >=
      fileUpdateRules[fileType].yellow
    ) {
      fileTypeInfoCopy[fileType].uploadTimeStatus.color = "yellow";
      fileTypeInfoCopy[fileType].uploadTimeStatus.message =
        fileUpdateRules[fileType].yellowCode;
    } else {
      fileTypeInfoCopy[fileType].uploadTimeStatus.color = "green";
      fileTypeInfoCopy[fileType].uploadTimeStatus.message = "file_up_to_date";
    }

    const fileTypeLogEntry = logEntries.filter(
      (entry) => entry.file_section === fileType
    );
    if (fileTypeLogEntry.length > 0) {
      if (fileTypeLogEntry[0].status === "success") {
        fileTypeInfoCopy[fileType].lastUploadStatus = {
          color: "green",
          message: "latest_upload_success",
        };
      } else {
        fileTypeInfoCopy[fileType].lastUploadStatus = {
          color: "red",
          message: "latest_upload_failure",
        };
      }
    } else {
      fileTypeInfoCopy[fileType].lastUploadStatus = {
        color: "red",
        message: "no_file_uploaded",
      };
    }
  }
  return fileTypeInfoCopy;
};
