import React, { useState } from "react";
import { Slider, Rail, Handles, Tracks, Ticks } from "react-compound-slider";
import { SliderRail, Handle, Track, Tick } from "../../../../GroupSettings/Slider/SliderComponents";
import PropTypes from 'prop-types'
import { Grid, Input } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import './SinglePointSlider.css'
import { maskTextToExtractFloat } from "../../../../../utils/format";

const sliderStyle = {
  position: "relative",
  width: "100%"
};

export const SinglePointSlider = ({startInitialValue=0, endInitialValue=10, onChange}) => {
  const domain = [startInitialValue, 360];
  const { t } = useTranslation()
  const [ inputRangeValue, setInputRangeValue ] = useState(endInitialValue)

  const settings = {
    min: startInitialValue,
    max: domain[1],
    step: 5,
  };

  const handleInputChange = (e) => {
    let newInputValue = parseInt(maskTextToExtractFloat(e.target.value));

    if(!newInputValue){
      setInputRangeValue(settings.min)
      onChange(settings.min)
      return
    }
    setInputRangeValue(newInputValue)
    onChange(newInputValue)
  };


  const handleSliderInitialValue = () => {
    return inputRangeValue <= settings.min 
              ? settings.min 
              : (inputRangeValue ? inputRangeValue : 0)
  }



  return (
      <Grid>
        <Grid.Column width={16} className="slider-container">
        <div>
        <div style={{ margin: "15%", width: "80%"}}>
            <Slider
            mode={1}
            domain={domain}
            rootStyle={sliderStyle}
            values={[handleSliderInitialValue()]}
            onChange={(values)=>{
                                    setInputRangeValue(Math.ceil(values[0]))
                                    onChange(inputRangeValue); 
                                    
                                }}
            >
            <Rail>
                {({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}
            </Rail>
            <Handles>
                {({ handles, getHandleProps }) => (
                <div >
                    {handles.map(handle => (
                    <Handle
                        key={handle.id}
                        handle={handle}
                        domain={domain}
                        getHandleProps={getHandleProps}
                    />
                    ))}
                </div>
                )}
            </Handles>
            <Tracks right={false}>
                {({ tracks, getTrackProps }) => (
                <div >
                    {tracks.map(({ id, source, target }) => (
                    <Track
                        key={id}
                        source={source}
                        target={target}
                        getTrackProps={getTrackProps}
                    />
                    ))}
                </div>
                )}
            </Tracks>
            <Ticks count={5}>
                {({ ticks }) => (
                <div >
                    {ticks.map(tick => (
                    <Tick key={tick.id} tick={tick} count={ticks.length} />
                    ))}
                </div>
                )}
            </Ticks>
            </Slider>
        </div>
        </div>
        </Grid.Column>
      <Grid.Column width={16}>
        <Input
          label={t("simulation.days")}
          type="text"
          className="ui input adu-measurement-single-point-slider"
          value={inputRangeValue}
          fluid
          onChange={handleInputChange}
        />
      </Grid.Column>
    </Grid>
  );
};

SinglePointSlider.propTypes = {
    startInitialValue: PropTypes.number, 
    endInitialValue: PropTypes.number, 
    onChange: PropTypes.func
}

