import styled from "@emotion/styled";

//Use styled because this doesn't affect other full calendar components
export const CustomCalendarStyleWrapper = styled.div`
    .fc table{
        border-collapse: collapse !important;
    }

    .fc-row.fc-widget-header{
        margin-right: 0px !important;
    }

    .fc-unthemed .fc-row{
        border: none;
    }

    .fc table tbody td{
        padding: 0px !important;
    }

    .fc-dayGrid-view .fc-body .fc-row{
        min-height: 0px !important;
    }

    .fc-day-grid-event.fc-h-event.fc-event.fc-start.fc-end{
        height: 5px !important;
        background-color: #A8A9D9 !important;
        border-color: #A8A9D9 !important;
    }

    /* Selector to highlight selected day */
    .fc-day-top.fc-sun.fc-past:hover,
    .fc-day-top.fc-mon.fc-past:hover,
    .fc-day-top.fc-tue.fc-past:hover,
    .fc-day-top.fc-wed.fc-past:hover,
    .fc-day-top.fc-thu.fc-past:hover,
    .fc-day-top.fc-fri.fc-past:hover,
    .fc-day-top.fc-sat.fc-past:hover
    {
        background-color: #A8A9D9 !important;
        cursor: pointer;
    }

    td>.fc-day-number:hover{
        cursor: pointer;
    }
`