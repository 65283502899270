import React, { useState } from "react";
import PropTypes from "prop-types";

import _ from "lodash";

import { useTranslation } from "react-i18next";

import { Dropdown } from "semantic-ui-react";
import "./SubGroupDropdown.css"

const MultipleDropdown = ({ options, defaultValues, onChange, groupType }) => {
  const { t } = useTranslation();

  const optionAll = { key: "all", text: t("groupSettings.all"), value: "all" };
  const conventionalOptions = [optionAll, ...options]
  const eventOptions = [...options]
  
  const [values, setValues] = useState([...defaultValues]);
  let className = '';
  if (groupType === 'event'){
    className = "subgroup"
  }
  else{
    className = "multiple-dropdown"
  }

  return (
    <Dropdown
      className={className}
      value={values}
      options={groupType === "event" ? eventOptions: conventionalOptions}
      fluid
      multiple
      search
      selection
      onChange={(e, data) => {
        if (data.value.includes("all")) {
          setValues(["all"]);
          onChange(["all"]);
        } else {
          setValues(data.value);
          onChange(data.value);
        }
      }}
      closeOnChange={false}
    />
  );
};

MultipleDropdown.propTypes = {
  options: PropTypes.array,
  defaultValues: PropTypes.array,
  onChange: PropTypes.func,
  groupType: PropTypes.string,
};

MultipleDropdown.defaultProps = {
  options: [],
  defaultValues: [],
  onChange: _.noop,
  required: false,
  groupType: ""
};

export default MultipleDropdown;
