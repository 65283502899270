import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import common_en from "./translations/en/common.json";
import common_es from "./translations/es/common.json";
import common_it from "./translations/it/common.json";

i18n.use(initReactI18next).init({
  fallbackLng: ["en", "es", "it"],
  debug: false,
  interpolation: {
    escapeValue: false,
  },
  defaultNS: "common",
  lng: "en",
  resources: {
    en: {
      common: common_en,
    },
    es: {
      common: common_es,
    },
    it: {
      common: common_it,
    },
  },
});

export default i18n;
