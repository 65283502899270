import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import _ from "lodash";
import XLSX from "xlsx";
import { useTranslation } from "react-i18next";
import { requiredColumnsIdOrder } from "../../../utils/constants";

const getWithTranslatedKeys = (t, i18n, orderTableInstanceObject, visibleColumns) => {
  const keys = Object.keys(orderTableInstanceObject);

  const wordKeyRepresentation = ['material_location', 
                                  'material_code', 
                                  'location_code_concatenation', 
                                  'material_description',
                                  'material_unitof_measure',
                                  'material_type',
                                  'unit_cost_currency',
                                  'group',
                                  'subgroup',
                                ]
  const standardReasonCodes = ["supply", "demand", "internal", "other"];                              

  const newObj = {};
  for (const key of keys) {

    

    // Skip columns that are not visible
    if (visibleColumns) {
      if (!visibleColumns.includes(key)) continue;
    }
    
    let value = orderTableInstanceObject[key];
    if (value === "-") {
      value = "";
    }

    // Infinity is a numeric value that cause a problem generating xlsx
    // This is just in case that any field of order table taked an infinite value
    // to avoid errors in excel creation, check if order table and excel values are equal
    const isValueANumber = wordKeyRepresentation.includes(key) 
    const isValueInfinity = parseFloat(value) && !isFinite(value)

    if(!isValueANumber && isValueInfinity && requiredColumnsIdOrder.includes(key)){
      value = "Infinity"
    }

    const existColumnValue = i18n.exists(`ordersTable.columns.${key}`)
    
    if (key === "order_variance") {
      const reasonCode = orderTableInstanceObject[key]["reasonCode"];
      newObj[t(`ordersTable.columns.${key}`)] = orderTableInstanceObject[key]["delta"];
      newObj[t(`ordersTable.columns.reason_code`)] = _.isNil(reasonCode) || _.isEmpty(reasonCode)
        ? ""
        : standardReasonCodes.includes(reasonCode)? t(`ordersTable.reasonCodes.${reasonCode}`): reasonCode;
    } 
    if (key === "material_method") {
      const methodValue = !value ? "-" : value
      newObj[t(`ordersTable.columns.material_method`)] = t(
        `ordersTable.method.${methodValue}`
      );
    }
    // When user insert custom additional fields
    if(!existColumnValue && key !== "order_variance" && key !== "material_method"){
      newObj[key] = value;
    }
    // Used to other fields
    if(existColumnValue && key !== "order_variance" && key !== "material_method"){
      newObj[t(`ordersTable.columns.${key}`)] = value;
    }
  }
  return newObj;
};

const exportClickHandler = (t, i18n, tableInstance)=>{
  const visibleColumns = tableInstance.allColumns
    .filter(column => column.isVisible)
    .map(column => column.id);

  const dataToSheet = tableInstance.rows.map((row) => {
    const translated = getWithTranslatedKeys(t, i18n, row.values, visibleColumns);
    return translated;
  });
  
  const fileName = `Paqtana_${moment(new Date()).format(
    "DD_MM_YYYY"
  )}.xlsx`;

  const worksheet = XLSX.utils.json_to_sheet(dataToSheet);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(
    workbook,
    worksheet,
    t("ordersTable.exportedWorksheetName")
  );

  XLSX.writeFile(workbook, fileName);
}

const DataExportButton = ({ tableInstance }) => {
  const { t, i18n } = useTranslation();

  return (
    <input
      type="button"
      className="ui button positive"
      style={{height:"min-content"}}
      onClick={() => exportClickHandler(t, i18n, tableInstance)}
      value={t("ordersTable.exportTable")}
    />
  );
};

DataExportButton.propTypes = {
  tableInstance: PropTypes.object.isRequired,
};

export default DataExportButton;
