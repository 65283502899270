import React, { useState} from 'react';
import { Input } from 'semantic-ui-react';
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const SearchBar = ({
    setFilter,
    
}) => {
    const { t } = useTranslation();        
    const [filterInput, setFilterInput] = useState("");
    
    const handleFilterChange = e => {
        const value = e.target.value || undefined;
        setFilter(value);
        setFilterInput(value)
    };

    return (
        <Input
            icon="search"
            value={filterInput}
            onChange={handleFilterChange}
            placeholder={t("ordersTable.search")}
            size="small"
            />
    )
};

SearchBar.propTypes = {
    setFilter: PropTypes.func,
    
};

export default SearchBar;