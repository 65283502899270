import React, { useState, useEffect } from 'react'
import { Accordion, Icon} from 'semantic-ui-react'
import PropTypes from 'prop-types'
import './Inputs.css'
import { useTranslation } from 'react-i18next'
import { CustomTableStyle } from './CustomTableStyle'
import QuantityTable from './Tables/QuantityTable'
import GroupSettingTable from './Tables/GroupsSettingTable'
import GreyZonesTable from './Tables/GreyZonesTable'


export const Inputs = ({materialName, quantityModifier, groupSetting, greyZone})=>{
    const { t } = useTranslation()
    const [ activeSection, setActiveSection ] = useState({section1:'inactive', section2: 'inactive', section3: 'inactive'}) 
    useEffect(()=>{
        if(quantityModifier.length>0){
            setActiveSection((activeSection)=>({...activeSection, section1:"active"}))
        }
        if(Object.keys(groupSetting).length>0){
            setActiveSection((activeSection)=>({...activeSection, section2:"active"}))
        }
        if(greyZone.length>0){
            setActiveSection((activeSection)=>({...activeSection, section3:"active"}))
        }
    }, [quantityModifier, groupSetting, greyZone])

    const activeSectionAction = (sectionName)=>{
        activeSection[sectionName] === 'inactive' 
        ? setActiveSection((activeSection)=>({...activeSection, [sectionName]: 'active'}))
        : setActiveSection((activeSection)=>({...activeSection, [sectionName]: 'inactive'}))
    }

    return(
        <Accordion>

            <Accordion.Title
                active={activeSection['section1'] === 'active'}
                onClick={()=>activeSectionAction('section1')}
                >
                <Icon name="dropdown"/>
                {t("simulation.quantityModifiers")}
            </Accordion.Title>
            <Accordion.Content
                active={activeSection['section1'] === 'active'}
                >
                <CustomTableStyle>
                    <QuantityTable 
                            quantityModifier={quantityModifier} 
                            materialName={materialName}/>
                </CustomTableStyle>
            </Accordion.Content>


            <Accordion.Title
                active={activeSection['section2'] === 'active'}
                onClick={()=>activeSectionAction('section2')}
                >
                <Icon name="dropdown"/>
                {t("simulation.groupSettingTitle")}
            </Accordion.Title>
            <Accordion.Content
                active={activeSection['section2'] === 'active'}
                >
                <CustomTableStyle>
                    <GroupSettingTable 
                        groupSetting={groupSetting} 
                        materialName={materialName}
                        />
                </CustomTableStyle>
            </Accordion.Content>

        
            <Accordion.Title
                active={activeSection['section3'] === 'active'}
                onClick={()=>activeSectionAction('section3')}
                >
                <Icon name="dropdown"/>
                {t("simulation.greyZoneTitle")}
            </Accordion.Title>
            <Accordion.Content
                active={activeSection['section3'] === 'active'}
                >
                <CustomTableStyle>
                    <GreyZonesTable 
                        greyZone={greyZone}
                        materialName={materialName}/>
                </CustomTableStyle>
            </Accordion.Content>

        </Accordion>
    )
}

Inputs.propTypes = {
    quantityModifier: PropTypes.array,
    greyZone: PropTypes.array,
    groupSetting: PropTypes.object,
    materialName: PropTypes.string
}