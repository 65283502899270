import React from "react";
import _ from "lodash";
import { fileAccessors, FILE_DATE_FORMAT, FAILED_STATUS } from "../constants";
import FileStatusIndicator from "../../components/DataImport/FileStatusIndicator/FileStatusIndicator.jsx";
import { toWorkspaceTimezone } from "../format";
import { PROCESSING_STATUS, UPLOADING_STATUS } from "../constants";

export const mapAccessorsToColumns = (t, accessors, renderCell, workspaceTimezone) => {
  return accessors.map((accessor) => {
    const column = {
      Header: t(`dataImport.uploadLog.columns.${accessor}`),
      accessor: accessor,
    };

    if (column.accessor === fileAccessors.error_log) {
      column.Header = "";
      column.Cell = (columnData) => {
        let mappedErrors = [];
        if (!_.isNil(columnData.row.values[fileAccessors.error_log])) {
          const { errors } = columnData.row.values[fileAccessors.error_log];
          if (errors) {
            mappedErrors = errors.map((error) =>
              t(`dataImport.backendValidationErrors.${error.error_type}`, {
                duplicate: error.duplicate ? error.duplicate : undefined,

                // invalid_column: error.invalid_column ? t(`ordersTable.columns.${error.invalid_column}`) : undefined,
                invalid_column: error.invalid_column ? error.invalid_column : undefined,
                current_language_configuration: error.current_language_configuration ? error.current_language_configuration : undefined,
                column_names_error: error.column_names_error ? error.column_names_error : undefined,
                rows_exceeded_by: error.rows_exceeded_by ? error.rows_exceeded_by : undefined,
                columns_exceeded_by: error.columns_exceeded_by ? error.columns_exceeded_by : undefined,
                // column: error.missing_column ? t(`ordersTable.columns.${error.missing_column}`) : undefined,
                column: error.missing_column ? error.missing_column : undefined,
                formulas_in_cells_detected: error.formulas_in_cells_detected ? error.formulas_in_cells_detected : undefined,
                negative_nums_in_cells_detected: error.negative_nums_in_cells_detected ? error.negative_nums_in_cells_detected : undefined,
                empty_cells: error.empty_cells ? error.empty_cells : undefined,
                incoherent_cells: error.incoherent_cells ? error.incoherent_cells : undefined,
                duplicate_rows: error.duplicate_rows ? error.duplicate_rows : undefined,
                table_should_start_on: error.table_should_start_on ? error.table_should_start_on : undefined,
                conflicting_sheet: error.conflicting_sheet ? error.conflicting_sheet : undefined,
                inconsistent_batch_info: error.inconsistent_batch_info ? error.inconsistent_batch_info : undefined,
                field_required: error.field_required ? error.field_required : undefined,
                negative_nums: error.negative_nums ? error.negative_nums : undefined,
                duplicate_materials: error.duplicate_materials ? error.duplicate_materials : undefined,
                unmatched_data: error.unmatched_data ? error.unmatched_data : undefined,
                dates_format_error: error.dates_format_error ? error.dates_format_error : undefined,
                date_format_error: error.date_format_error ? error.date_format_error : undefined,
                reserved_word_found: error.reserved_word_found ? error.reserved_word_found : undefined, 
                empty_additional_columns: error.empty_additional_columns ? error.empty_additional_columns : undefined,
                
              })
            );
          }
        }

        return renderCell(
          columnData.row.values[fileAccessors.original_filename],
          mappedErrors
        );
      };
    } else if (column.accessor === fileAccessors.created_at) {
      column.Cell = (columnData) => {
        if (columnData.row.values[fileAccessors.created_at] === "...") {
          return "...";
        }

        return toWorkspaceTimezone(
          columnData.row.values[fileAccessors.created_at],
          workspaceTimezone,
          FILE_DATE_FORMAT
        );
      };
    } else if (column.accessor === fileAccessors.status) {
      // eslint-disable-next-line react/display-name
      column.Cell = (columnData) => {
        const status = columnData.row.values[fileAccessors.status];
        return (
          <FileStatusIndicator
            failed={status === FAILED_STATUS}
            text={t(`dataImport.status.${status}`)}
          />
        );
      };
    } else if (column.accessor === fileAccessors.file_section) {
      column.Cell = (columnData) => {
        return t(
          `dataImport.fileSections.${
          columnData.row.values[fileAccessors.file_section]
          }`
        );
      };
    }

    return column;
  });
};

export const isProcessingFileData = (state, file_name, )=>{
  return !_.isEmpty(state
                      .files
                      .logEntries
                      // here is master
                      .filter((entry) => (entry.file_section === file_name
                                              && (entry.status === PROCESSING_STATUS || entry.status === UPLOADING_STATUS)))
  )
}

export const checkMasterInLogEntries = (state)=>{
  return state
            .files
            .logEntries
            .map((entry) => (entry.status === FAILED_STATUS ? "" : entry.file_section))
            .includes("master")
}