import React, { useState, useEffect, useMemo } from 'react'
import { Grid, Form, Input } from 'semantic-ui-react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { mapDateRangeToDays } from '../../../../utils/mappers/analytics'
import { changeLoadSimulationData, changeSimAduFreqUpd } from '../../../../actions/analytics'
import { getSafeSelectedWorkspace } from '../../../../utils/mappers/user'
import _ from "lodash" 
import './AduFreqModifier.css'

const AduFreqMod = 
({ 
    changeSimAduFreqUpd,
    simulationAduFreqUpd,
    dateRange, 
    disabled,
    loadSimulationData,
    simulationMaterialData,
    currentWorkspaceId,
    changeLoadSimulationData 
})=>{


    const { t } = useTranslation()
    const { loaded_results="", sim_inputs="" } = loadSimulationData[0] || {}
    let freqUpd = useMemo(()=>{
        return sim_inputs.adu_freq ? sim_inputs.adu_freq : simulationAduFreqUpd
    }, [sim_inputs, simulationAduFreqUpd])

    const [ AduFreqUpd, setAduFreqUpd ] = useState(freqUpd);

    useEffect(()=>{
        setAduFreqUpd(freqUpd)
    }, [freqUpd])

    useEffect(()=>{
        setAduFreqUpd(1)
        changeSimAduFreqUpd(1)
    }, [simulationMaterialData, currentWorkspaceId, setAduFreqUpd, changeSimAduFreqUpd])

    const filterAduFreq = (freq) => {
        if(_.isEmpty(freq)){
            return ''
        }
        const maxFreq = mapDateRangeToDays(dateRange.startDateRange, dateRange.endDateRange);
        const allowedFreq = parseInt(freq.replace('.', '')) <= maxFreq ? parseInt(
            freq.replace('.', '')) : maxFreq; 
        return Math.abs(allowedFreq)
    }
    
    const setNewAduFreq = () => {
        if(AduFreqUpd === '' || AduFreqUpd === 0){
            setAduFreqUpd(1)
            changeSimAduFreqUpd(1)
            return
        }
        if(loadSimulationData.length){
            changeLoadSimulationData([{loaded_results,
                                        sim_inputs: {...sim_inputs, adu_freq: AduFreqUpd}}])
            return
        }
        changeSimAduFreqUpd(AduFreqUpd)
    }
    
    return(
    <Grid.Column mobile={8} widescreen={11} tablet={11}>
            <Grid columns={1} stackable>
            <Grid.Column>
            <Form.Field>
                <label 
                    className="field-title">
                    {t("simulation.aduFreqUpd")}
                </label>
                <Input
                    className='adu-freq-input'
                    disabled={disabled}
                    onBlur={() => setNewAduFreq()}
                    value={AduFreqUpd}
                    onChange={(e)=>{ setAduFreqUpd(filterAduFreq(e.target.value)) }}
                    />
            </Form.Field>
            </Grid.Column>
            </Grid>
    </Grid.Column>
    )
}

AduFreqMod.propTypes = {
    changeSimAduFreqUpd: PropTypes.func,
    simulationAduFreqUpd: PropTypes.number,
    dateRange: PropTypes.object,
    disabled: PropTypes.bool,
    loadSimulationData: PropTypes.array,
    simulationMaterialData: PropTypes.object,
    currentWorkspaceId: PropTypes.number,
    changeLoadSimulationData: PropTypes.func,
}

const mapStateToProps = (state)=>({
    simulationAduFreqUpd: state.analytics.simulationAduFreqUpd,
    loadSimulationData: state.analytics.loadSimulationData,
    simulationMaterialData: state.analytics.simulationMaterialData,
    currentWorkspaceId: getSafeSelectedWorkspace(state),
})

export default connect(mapStateToProps,{ changeSimAduFreqUpd, changeLoadSimulationData })(AduFreqMod)