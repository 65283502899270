import _ from "lodash";

export const equalsAny = (rows, ids, filterValues) => {
  return rows.filter(row => {
    return ids.some(id => {
      const rowValue = row.values[id];
      return _.includes(filterValues, rowValue);
    });
  });
};

//The way that iterate is:
//First go over every row and then over every column of this row
//And then check if the value of the cell is equal to filterValues
//Return a row or array of rows that match the filter
export const multipleOptions = (rows, ids, filterValues) => {
  if(Array.isArray(filterValues)){
    //Iterate over every row
    return rows.filter(row => {
      //Iterate over every column of this row
      return ids.some(id => {
        // Get the value of the cell
        const rowValue = row.values[id];
        return filterValues.some(filterValue => {
          return rowValue === filterValue;
        });
      })
    });
  }
}
