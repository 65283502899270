import _ from "lodash";
import moment from "moment";

import { mapBufferSettingGroup } from "../utils/mappers/groupSettings";

export const hasCalendarEventsChanged = (
  committedCalendarEvents,
  currentCalendarEvents,
  currentWorkspaceId
) => {
  if (
    _.isNil(currentWorkspaceId) ||
    _.isNil(currentCalendarEvents) ||
    _.isNil(committedCalendarEvents)
  ) {
    return false;
  }

  const filteredCurrentCalendarEvents = currentCalendarEvents.filter(
    (event) => String(event.workspace_id) === String(currentWorkspaceId)
  );

  if (
    (_.isEmpty(filteredCurrentCalendarEvents) &&
      !_.isEmpty(committedCalendarEvents)) ||
    (!_.isEmpty(filteredCurrentCalendarEvents) &&
      _.isEmpty(committedCalendarEvents))
  ) {
    return true;
  }

  const keys = _.isEmpty(committedCalendarEvents)
    ? []
    : Object.keys(committedCalendarEvents[0]);
  const maskedCurrentCalendarEvents = _.isEmpty(keys)
    ? []
    : filteredCurrentCalendarEvents.map((event) => ({
      ..._.pick(event, keys),
      groups: event.groups.map((group) => group.id),
    }));

  const mappedCommitedEvents = committedCalendarEvents.map((event) => ({
    ...event,
    groups: event.groups.map((group) => group.id),
  }));

  return !_.isEqual(mappedCommitedEvents, maskedCurrentCalendarEvents);
};

export const hasGroupsChanged = (committedGroups, currentGroups) => {
  if (_.isNil(committedGroups) || _.isNil(currentGroups)) {
    return false;
  }

  const mappedOrdersTableGroups = committedGroups.map(mapBufferSettingGroup);

  return !_.isEqual(mappedOrdersTableGroups, currentGroups);
};

export const hasHistoricalDemandChanged = (
  historicalDemandLastUpdate,
  ordersTableCommitDate
) => {
  if (_.isNil(ordersTableCommitDate) || _.isNil(historicalDemandLastUpdate)) {
    return false;
  }
  if (historicalDemandLastUpdate === "" || ordersTableCommitDate === "") {
    return false;
  }
  return moment(historicalDemandLastUpdate).isAfter(ordersTableCommitDate);
};

export const difference = function (object, base) {
  function changes(object, base) {
    return _.transform(object, function (result, value, key) {
      if (!_.isEqual(value, base[key])) {
        result[key] =
          _.isObject(value) && _.isObject(base[key])
            ? changes(value, base[key])
            : value;
      }
    });
  }
  return changes(object, base);
};
