import React from "react";
import _ from "lodash";

import { Dropdown } from "semantic-ui-react";

import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { mapItemsToRecordsObject } from "../../../utils/mappers/ordersTable"
import './OrdersErrors.css'

const OrdersErrorDropdown = ({
    items,
}) => {
        
    const errorRecords = mapItemsToRecordsObject(items).records.filter(
        (entry) => entry.variance !== 0 && entry.reason_code === ""
      )
    const errors = errorRecords === undefined ? []: errorRecords;
    const { t } = useTranslation();
    const errorTags = [];
    errors.forEach(
        error => errorTags.push(
            {text: error.location_code_concatenation + ' ('+ error.material_description + ')', 
            key: error.location_code_concatenation + ' ('+ error.material_description + ')'}))
    
    let placeholderTag = ''
     if( !_.isEmpty(errorTags)){
        placeholderTag = t("ordersTable.materialsWithIssues")
    }

    if( placeholderTag !== '' ){
        return <Dropdown
                className='errorsDropdown'
                basic={true}
                button={true}
                disabled={placeholderTag === ''}
                placeholder= {placeholderTag}
                options={errorTags}
                pointing={false}
            />        
    } else if(placeholderTag === ''){
        return null
    }
    

    
};

OrdersErrorDropdown.propTypes = {
    errorRecords: PropTypes.array,
    items: PropTypes.array,
};

OrdersErrorDropdown.defatultProps = {
    errorRecords: []
}


export default OrdersErrorDropdown