import React, { useState } from 'react'
import { Accordion, Icon } from 'semantic-ui-react'
import { ResultsTable } from './ResultsTable'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

export const Results = ({simulationData})=>{
    const {res1, res2} = simulationData
    const [ activeSection, setActiveSection ] = useState({section1:'inactive', section2:'inactive'})
    const { t } = useTranslation()
    return(
        <Accordion>
            <Accordion.Title
                active={activeSection['section1'] === 'active'}
                onClick={()=>
                        activeSection['section1'] === 'inactive' 
                        ? setActiveSection((activeSection)=>({...activeSection, section1: 'active'}))
                        : setActiveSection((activeSection)=>({...activeSection, section1: 'inactive'}))
                    }
                >
                <Icon name="dropdown"/>
                {t("simulation.variationTitle")}
            </Accordion.Title>
            <Accordion.Content
                active={activeSection['section1'] === 'active'}
                className="simulation-inputs"
                >
                <ResultsTable simulationData={res1}/>
            </Accordion.Content>

            <Accordion.Title
                active={activeSection['section2'] === 'active'}
                onClick={()=>
                        activeSection['section2'] === 'inactive' 
                        ? setActiveSection((activeSection)=>({...activeSection, section2: 'active'}))
                        : setActiveSection((activeSection)=>({...activeSection, section2: 'inactive'}))
                    }
                >
                <Icon name="dropdown"/>
                {t("simulation.performanceTitle")}
            </Accordion.Title>
            <Accordion.Content
                active={activeSection['section2'] === 'active'}
                className="simulation-results"
                >
                <ResultsTable simulationData={res2}/>
            </Accordion.Content>
        </Accordion>
    )
}

Results.propTypes = {
    simulationData: PropTypes.object
}