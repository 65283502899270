import React, { useState } from "react";
import PropTypes from "prop-types";

import _ from "lodash";
import classNames from "classnames"

import { Dropdown, Grid } from "semantic-ui-react";

import { useTranslation } from "react-i18next";


import { itemAccessors } from "../../../utils/constants";
import { round, sortAnyTypeAsc } from "../../../utils/format";
import SortByColorModal from "./SortByColor/SortByColorModal";

import "./ValueFilter.css";

const ValueFilter = ({ columnId, setFilter, preFilteredRows, filteredRows, isFilterActive }) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const initialValues = [
    ...new Set(preFilteredRows.map((row) => row.values[columnId])),
  ].sort(sortAnyTypeAsc)
  
  let filteredValues = [
    ...new Set(filteredRows.map((row) => row.values[columnId])),
  ].sort(sortAnyTypeAsc);

  React.useEffect(
    () => {
      if (open) {
        return
      }      

    },
    [filteredRows, columnId, open]
  )


  const updateChecked = (option, isBoxChecked) => {

    if (option === "all" && isBoxChecked) {
      setFilter(undefined)      
      return;
    } else if (option === "all" && !isBoxChecked) {
      setFilter([])
      return;
    }

    let selectedValues;
    if (isBoxChecked) {
      selectedValues = [...filteredValues, option];
    } else {
      selectedValues = filteredValues.filter((value) => value !== option)
    }
    setFilter(selectedValues);
  }


  const mapValue = (value) => {
    if (
      _.includes(
        [itemAccessors.netFlowPriority, itemAccessors.currentOnHandAlert],
        columnId
      )
    ) {
      return !_.isNaN(round(value * 100, 2))
        ? `${round(value * 100, 2)}%`
        : "-";
    } else if (columnId === itemAccessors.worstBatchStatus) {
      return t(`retail.batchStatus.${value}`);
    } else if (columnId === itemAccessors.materialMethod) {
      return t(`ordersTable.method.${value}`);
    }
    return value;
  };


  if (
    columnId === itemAccessors.actualOrder ||
    columnId === itemAccessors.orderVariance ||
    columnId === itemAccessors.suggestedOrder
  ) {
    return null;
  }

  return (
    <Dropdown
      upward={false}
      icon={"filter"}
      floating
      button
      className={classNames("icon", "mini", "basic", "toggle", "order-table-column-filter", { active: isFilterActive })}
      onClick={() => setOpen(true)}
      onBlur={() => {
        setOpen(false);
      }}
      closeOnChange={false}
      open={open}
      scrolling
    >
      <Dropdown.Menu className="order-table-column-filter-dropdown">

        {
          columnId === itemAccessors.bufferZoneColor && <SortByColorModal/>
        }

        <Dropdown.Item>
          <Grid>
            <Grid.Row>
              <div className="ui item checkbox">
                <input
                  type="checkbox"
                  name="all"
                  onChange={(e) => {
                    updateChecked("all", e.target.checked);
                  }}
                  checked={!isFilterActive}
                />
                <label>{t("ordersTable.filters.noFilter")}</label>
              </div>
            </Grid.Row>
          </Grid>
        </Dropdown.Item>

        {initialValues.map((value) => (
          <Dropdown.Item key={value}>
            <Grid>
              <Grid.Row>
                <div className="ui item checkbox">
                  <input
                    type="checkbox"
                    name={value}
                    onChange={(e) => updateChecked(value, e.target.checked)}
                    checked={_.includes(filteredValues, value)}
                  />
                  <label>{mapValue(value)}</label>
                </div>
              </Grid.Row>
            </Grid>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

ValueFilter.propTypes = {
  columnId: PropTypes.string,
  setFilter: PropTypes.func,
  preFilteredRows: PropTypes.array,
  filteredRows: PropTypes.array,
  isFilterActive: PropTypes.bool
};

ValueFilter.defaultProps = {
  columnId: itemAccessors.code,
  setFilter: _.noop,
  preFilteredRows: [],
  filteredRows: [],
  isFilterActive: false
};

export default ValueFilter;
