import v8n from 'v8n'
import moment from "moment-timezone";

// TODO: here maybe is necessary to implement moadiator design patterns
// https://refactoring.guru/design-patterns/mediator
// check the link above to see how to implement and watch an example of how to solve this problem
const dateToValidCalendarFormat = (date)=>{
    return date ? date.split('/').reverse().join('-') : ""
}


export const allowAddGreyZone = (reply, adusPerDay, qtyPerDay, datesData, disableQtyPerDay, setErrorFields)=>{

    const startDate= v8n().string().minLength(1).test(reply.start_date) 
    const endDate= v8n().string().minLength(1).test(reply.end_date)
    const adusPerDayData= v8n().string().minLength(1).test(adusPerDay) 
    const qtyPerDayData= v8n().string().minLength(1).test(qtyPerDay)

    const startSelectedDate = moment(dateToValidCalendarFormat(datesData.startDate), 'YYYY-MM-DD').valueOf()
    const endSelectedDate = moment(dateToValidCalendarFormat(datesData.endDate), 'YYYY-MM-DD').valueOf()

    //This is a case when user insert only adusPerDayData
    if(disableQtyPerDay && startDate && endDate && adusPerDayData && qtyPerDayData===false && startSelectedDate<endSelectedDate){
            // updateAndResetData(reply)
            return true
    }

    //This is a case when user insert only qtyPerDayData
    if(disableQtyPerDay===false && startDate && endDate && adusPerDayData===false && qtyPerDayData && startSelectedDate<endSelectedDate){
            // updateAndResetData(reply)
            return true
    }

    //This is a case when user insert both adusPerDayData and qtyPerDayData but only one of them is valid
    if(parseFloat(adusPerDay) && parseFloat(qtyPerDay) && startSelectedDate<endSelectedDate){
            // updateAndResetData(reply)
            return true
    }
    

    if(disableQtyPerDay){
        setErrorFields({
            startDate: !startDate,
            endDate: !endDate,
            adusPerDay: !adusPerDayData,
            qtyPerDay: false
        })
        return false
    }

    if(disableQtyPerDay === false){
        setErrorFields({
            startDate: !startDate,
            endDate: !endDate,
            adusPerDay: false,
            qtyPerDay: !qtyPerDayData
        })
        return false
    }
    if(startSelectedDate === endSelectedDate || startSelectedDate > endSelectedDate){
        setErrorFields({
            startDate: true,
            endDate: true,
            adusPerDay: false,
            qtyPerDay: false
        })
        return false
    }   
}