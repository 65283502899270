import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import { useTranslation } from "react-i18next";

import { Message } from "semantic-ui-react";

const Error = ({ errors }) => {
  const { t } = useTranslation();

  return (
    errors !== null &&
    errors.length > 0 &&
    errors.map(error => (
      <Message key={error.id} negative>
        {t(`backendMessages.${error.message}`)}
      </Message>
    ))
  );
};

Error.propTypes = {
  errors: PropTypes.array.isRequired
};

const mapStateToProps = state => ({ errors: state.error });
export default connect(mapStateToProps, null)(Error);
