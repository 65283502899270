import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { Card } from "semantic-ui-react"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"
import _ from "lodash"
import ModalSimulationMenu from "../ModalSimulationMenu/ModalSimulationMenu"
import { saveSimulationData } from "../../../../actions/analytics"
import { loadSavedSimulationData, deleteSavedSimulationData, renameSavedSimulation } from "../../../../actions/analytics"
import { getSafeSelectedWorkspace } from "../../../../utils/mappers/user"
import "./CardBar.css"

const CardBar = 
({  
    simulationButtonDisabledStatus,

    currentWorkspaceId,
    simulationMaterialData,
    simulationQuantityModifiers,
    simulationGreyZone,
    simulationGroupSettings,
    simulationAduFreqUpd,
    simOrdDays,

    loadSavedSimulationData,
    deleteSavedSimulationData,
    renameSavedSimulation,
    simulationsSavedList,
    saveSimulationData,

    loadedSelectedSimulationData,
    items,
    loadingSimulation,
})=>{
    const { t } = useTranslation()
    const saveSimulation = (saveNameData)=>{
        saveSimulationData( currentWorkspaceId,
            simulationMaterialData.data.split(':')[1],
            simulationQuantityModifiers,
            simulationGreyZone,
            simulationGroupSettings,
            simulationAduFreqUpd,
            simOrdDays,
            saveNameData)
    }

    const modifySimulation = (actionType, sim_id, material_id, save_name)=>{
        if(actionType==="delete"){
            deleteSavedSimulationData(currentWorkspaceId, sim_id)
            return
        }
        if(actionType==="edit"){
            renameSavedSimulation(currentWorkspaceId,  save_name, sim_id, material_id)
            return
        }
    }

    let disabledSaveSimulationButton = true

    if( simulationQuantityModifiers.length 
        || simulationGreyZone.length 
        || Object.keys(simulationGroupSettings).length 
        || simulationAduFreqUpd !== 1
        || !_.isEqual(simOrdDays, [0, 1, 2, 3, 4, 5, 6])
        ){

        disabledSaveSimulationButton = simulationButtonDisabledStatus ? false : true
    }

    const disableSimulationActionButtons = useMemo(()=>{
        return simulationsSavedList.length ? false : true
    }, [ simulationsSavedList ])

    const simulationNameInfo = useMemo(()=>{
        const materialInfo = items.find((materialData)=> materialData.location_code_concatenation === loadedSelectedSimulationData.material_id)

        return materialInfo ? `    ${materialInfo.material_description} (${materialInfo.location_code_concatenation})` : ""
    }, [ items, loadedSelectedSimulationData ])

    return(
        <Card.Content className="simulation-card-header">
                        <ModalSimulationMenu 
                            headerName = {t("save")} 
                            positiveButtonName = {t("save")} 
                            iconName= "save"
                            currentWorkspaceId={currentWorkspaceId}
                            disabled={disabledSaveSimulationButton || loadingSimulation}
                            positiveButtonAction={
                                (saveNameData)=>{ saveSimulation(saveNameData) }}
                            />
                        <ModalSimulationMenu 
                            headerName = {t("simulation.load")} 
                            positiveButtonName = {t("simulation.load")} 
                            iconName= "upload"
                            currentWorkspaceId={currentWorkspaceId}
                            disabled={disableSimulationActionButtons || loadingSimulation}
                            positiveButtonAction={
                                (material_id, save_name)=>{loadSavedSimulationData(currentWorkspaceId, 
                                                                                    material_id, save_name);
                                            
                            }}
                            selectRowsTable={true}
                            />
                        <ModalSimulationMenu
                            headerName = {t("simulation.modify")} 
                            positiveButtonName = {t("confirm")}
                            iconName= "edit"
                            currentWorkspaceId={currentWorkspaceId}
                            disabled={disableSimulationActionButtons || loadingSimulation}
                            positiveButtonAction={(actionType, sim_id, material_id, save_name)=>{
                                modifySimulation(actionType, sim_id, material_id, save_name)
                            }}
                            />
                        
                        {   loadedSelectedSimulationData.sim_id 
                                    ?   <p className="simulate-label-title">
                                            <span>{`${loadedSelectedSimulationData.sim_name}`}</span>
                                            <span className="simulate-label-title-add-info">{`${simulationNameInfo}`}</span>
                                        </p> 
                                      
                                    : null}
                        
        </Card.Content>
    )
}
CardBar.propTypes = {
    simulationButtonDisabledStatus: PropTypes.bool,
    currentWorkspaceId: PropTypes.number,
    simulationMaterialData: PropTypes.object,
    simulationQuantityModifiers: PropTypes.array,
    simulationGreyZone: PropTypes.array,
    simulationGroupSettings: PropTypes.object,
    simulationAduFreqUpd: PropTypes.number,
    simOrdDays: PropTypes.array,
    loadSavedSimulationData: PropTypes.func,
    deleteSavedSimulationData: PropTypes.func,
    renameSavedSimulation: PropTypes.func,
    simulationsSavedList: PropTypes.array,
    saveSimulationData: PropTypes.func,
    loadedSelectedSimulationData: PropTypes.object,
    items: PropTypes.arrayOf(PropTypes.object),
    loadingSimulation: PropTypes.bool
}

const mapStateToProps = (state)=>({
    simulationMaterialData: state.analytics.simulationMaterialData,
    simulationQuantityModifiers: state.analytics.simulationQuantityModifiers,
    simulationGroupSettings: state.analytics.simulationGroupSettings,
    simulationGreyZone:state.analytics.simulationGreyZone,
    currentWorkspaceId: getSafeSelectedWorkspace(state),
    simulationAduFreqUpd:state.analytics.simulationAduFreqUpd,
    simOrdDays: state.analytics.simOrdDays,
    simulationsSavedList: state.analytics.simulationsSavedList,
    loadedSelectedSimulationData: state.analytics.loadedSelectedSimulationData,
    items: state.items.items,
    loadingSimulation: state.analytics.loadingSimulation
})

export default connect(mapStateToProps, {  loadSavedSimulationData, 
                                            deleteSavedSimulationData, 
                                            renameSavedSimulation,
                                            saveSimulationData 
                                        })(CardBar)