import React, { useEffect, useMemo, useState } from "react"
import { Grid, Segment, Button, Form, Loader, Card } from "semantic-ui-react"
import PropTypes from "prop-types"
import "./Simulation.css"
import { useTranslation } from 'react-i18next'
import Chart from "react-apexcharts";
import { simulateData, 
        setSimulationDateRange,
        changeInputSimulationDataStatus,
        saveSimulationInputChanges,
        changeSimulationResultData,
        } from "../../../actions/analytics"
import { connect } from "react-redux"
import { mapChartOneCodesToStringsSimulation } from "../../../utils/mappers/analytics"
import _ from "lodash"
import AccordionData from './Accordion/AccordionData'
import MaterialSection from "./MaterialSection/MaterialSection"
import QuantityModifier from "./QuantityModifier/QuantityModifier"
import { abbreviateNumber } from "../../../utils/format"
import { getSafeSelectedWorkspace } from "../../../utils/mappers/user"
import AduFreqModifier from "./AduFreqModifier/AduFreqModifier"
import OrdDaysSelector from "./OrdDaysSelector/OrdDaysSelector"
import CardBar from "./CardBar/CardBar"
import { calculateCustomMaxYAxis, calculateCustomMinYAxis } from "../../../utils/mappers/analytics"
import { toExcelNumberFormatWithoutDecimals } from "../../../utils/format"

const Simulation = 
({  chartOneOptions,
    chartOneHeight, 
    simulationChartData, 
    currentWorkspaceId, 
    simulateData,
    loadingSimulation,
    simulationMaterialData,
    simulationQuantityModifiers,
    simulationGroupSettings,
    simulationGreyZone,
    simulationAduFreqUpd,
    simOrdDays,
    previousSimData,
    setSimulationDateRange,
    simulationDateRange,
    loadSimulationData,
    isInputLoadedSimulationDataChange,
    changeInputSimulationDataStatus,
    loadedSelectedSimulationData,
    saveSimulationInputChanges,
    changeSimulationResultData,
    previousLoadedSimInputData,
    loadingFetch,
    disableAnalyticsContentInteraction
})=>{

    const { t } = useTranslation();

    const [currentMaterialId, setCurrentMaterialId] = useState();

    useEffect(()=>{
        if(simulationChartData.chartOne){
            const arrayLabels = simulationChartData.chartOne.dates
            const startDateRange = arrayLabels[0].split('/').reverse().join('-')
            const endDateRange = arrayLabels[arrayLabels.length-1].split('/').reverse().join('-')
            setSimulationDateRange({ startDateRange, endDateRange })
        }
    }, [simulationChartData, setSimulationDateRange])

    useEffect(()=>{
        if(loadSimulationData.length){
            changeSimulationResultData(loadSimulationData[0].loaded_results)
        }
    }, [ loadSimulationData, changeSimulationResultData ])

    useEffect(()=>{
        if(loadSimulationData.length && _.isEqual(loadSimulationData[0].sim_inputs, previousSimData)){
            changeInputSimulationDataStatus(false)
        }
    }, [ loadSimulationData, previousSimData,  changeInputSimulationDataStatus])

    const disabledSimulationSection = useMemo(()=>{
        if(!loadSimulationData.length){
            return loadingSimulation || (simulationMaterialData.data ? false : true);
        }
        return false
    }, [ loadingSimulation, simulationMaterialData, loadSimulationData ])

    const convertingDate = (datesArray)=>{
        return datesArray.map((date)=>(date.split('/').reverse().join('-')))
    }

    const getOptionsToSimChart = ()=>{
        const exportFilename = currentMaterialId ? currentMaterialId.replace(/\s+/g, '-') : null;
        const chartOneOptionsChartWithToolbar = {
            ...chartOneOptions.chart,
            toolbar: {
                export: {
                    csv: {
                        filename: exportFilename
                    },
                    svg: {
                        filename: exportFilename
                    },
                    png: {
                        filename: exportFilename
                    }
                }
            }
        }

        // TODO: i18n.language to change the language of the chart options
        if(simulationChartData.chartOne){
            const chartDataSerie = getSeriesToSimChart()
            const newChartOneOptions = {
                ...chartOneOptions,
                // In chart property could change tooltip language, check apexchart specification
                chart:{...chartOneOptionsChartWithToolbar },
                stroke: { width: 2, curve: "smooth" },
                yaxis: { 
                        min: calculateCustomMinYAxis(chartDataSerie), 
                        max: calculateCustomMaxYAxis(simulationChartData.chartOne),
                        // Label to remove many decimals from labels
                        labels :{
                            formatter: (value) => (toExcelNumberFormatWithoutDecimals(abbreviateNumber(value)))
                        }
                    },
                xaxis:{ 
                    type:'datetime', 
                    categories: convertingDate(simulationChartData.chartOne.dates),
                },
                legend: {
                    ...chartOneOptions.legend,
                    // To hide white line (this line hava array of zeros)
                    showForZeroSeries: false
                },
                tooltip:{
                    ...chartOneOptions.tooltip,
                    // Remove white line data from tooltip, because is unnecessary
                    y: {
                        formatter: function(value, { seriesIndex }) {
                            return seriesIndex === 0 ? "" : toExcelNumberFormatWithoutDecimals(value)
                        },
                        title: {
                            formatter: (seriesName) => {
                                return seriesName === "line" ? "" : seriesName
                            },
                        },
                      }
                },
                // Add white line like base to solve problems of disable grey label
                colors: [ "#FFFFFF", ...chartOneOptions.colors ],
            }
            return newChartOneOptions
        }

        chartOneOptions.chart = chartOneOptionsChartWithToolbar;
        return chartOneOptions;
    }

    const getSeriesToSimChart = ()=>{
        return simulationChartData.chartOne ? mapChartOneCodesToStringsSimulation(t, simulationChartData.chartOne) : []
    }

    const simButtonDisabled = useMemo(()=>{
        const currentSimData = {
            current_workspace_id: currentWorkspaceId,
            material_id: simulationMaterialData.data.split(':')[1],
            sim_dataset: simulationQuantityModifiers,
            g_zone_input: simulationGreyZone,
            group_info: simulationGroupSettings,
            adu_freq: simulationAduFreqUpd,
            ordering_days: simOrdDays
        }

        //When simulation data is not loaded
        if((!loadSimulationData.length 
            && !previousSimData.current_workspace_id 
            && !previousSimData.material_id) || _.isEqual(previousSimData, currentSimData)){
            return true
        }

        if(!loadSimulationData.length && previousSimData.material_id && !_.isEqual(previousSimData, currentSimData)){
            return false || loadingSimulation
        }

        // When simulation data is loaded
        // current workspace_id and material_id aren't neccesary to compare
        delete previousSimData['current_workspace_id']
        delete previousSimData['material_id']
        if((loadSimulationData.length 
            && _.isEqual(loadSimulationData[0].sim_inputs, previousLoadedSimInputData)) || _.isEqual(loadSimulationData[0].sim_inputs, previousSimData)){
            return true
        }

        if(loadSimulationData.length && !_.isEqual(loadSimulationData[0].sim_inputs, previousLoadedSimInputData)){
            return false || loadingSimulation
        }

    }, [ currentWorkspaceId,
        simulationMaterialData.data,
        simulationQuantityModifiers,
        simulationGreyZone,
        simulationGroupSettings,
        simulationAduFreqUpd,
        simOrdDays,
        previousSimData,
        loadingSimulation,
        loadSimulationData,
        previousLoadedSimInputData ])

    
    const runSimulationWithData = ()=>{
        if(loadSimulationData.length){
            const { sim_dataset, g_zone_input, group_info, adu_freq, ordering_days } = loadSimulationData[0].sim_inputs
            simulateData(
                currentWorkspaceId,
                loadedSelectedSimulationData.material_id,
                sim_dataset, //simulationQuantityModifiers,
                g_zone_input, //simulationGreyZone,
                group_info, //simulationGroupSettings,
                adu_freq, //simulationAduFreqUpd,
                ordering_days, //simOrdDays,
            )
            return
        }

        simulateData(
            currentWorkspaceId,
            simulationMaterialData.data.split(':')[1],
            simulationQuantityModifiers,
            simulationGreyZone,
            simulationGroupSettings,
            simulationAduFreqUpd,
            simOrdDays,
        )
    }

    const saveSimulationChanges = ()=>{
        if(loadSimulationData.length){
            const { sim_dataset, g_zone_input, group_info, adu_freq, ordering_days } = loadSimulationData[0].sim_inputs
            saveSimulationInputChanges(
                currentWorkspaceId,
                loadedSelectedSimulationData.material_id,
                sim_dataset,
                g_zone_input,
                group_info,
                adu_freq,
                ordering_days,
                loadedSelectedSimulationData.sim_name,
                loadedSelectedSimulationData.sim_id
            )
            changeInputSimulationDataStatus(true)
        }
    }

    return(
        <Segment basic>
            <Grid.Row centered stretched className="separator">
            <Grid.Column>
                <Card className="simulation-card">
                    <CardBar 
                        simulationButtonDisabledStatus={simButtonDisabled}/>

                    <Card.Content>
                    <Form>
                        <Segment disabled={disableAnalyticsContentInteraction} basic>
                            <Grid columns={2} stackable>
                            <Grid.Row stretched>
                            <MaterialSection 
                                currentWorkspaceId={currentWorkspaceId}
                                dateRange={simulationDateRange}
                                disabled={disabledSimulationSection}
                                onMaterialSelected={id => setCurrentMaterialId(id)}
                                />
                            <Grid.Column width={11}>
                                <Grid.Row>
                                    <QuantityModifier 
                                        dateRange={simulationDateRange}
                                        disabled={disabledSimulationSection}
                                        />
                                </Grid.Row>
                                <Grid.Row>
                                <Segment  disabled={disabledSimulationSection}>
                                    <Grid.Row>
                                        <label 
                                            className="segment-title">
                                            {t("simulation.timeVars")}
                                        </label>
                                    </Grid.Row>
                                    <Grid columns={2} stackable>
                                        <Grid.Column width={6}>
                                        <AduFreqModifier
                                                dateRange={simulationDateRange}
                                                disabled={disabledSimulationSection}
                                            />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <OrdDaysSelector
                                            disabled={disabledSimulationSection}
                                            />
                                            
                                        </Grid.Column>
                                    </Grid>
                                    </Segment>
                                </Grid.Row>
                            </Grid.Column>
                            </Grid.Row>
                            {
                                simulationChartData.chartOne || loadSimulationData.length
                                ?   <Grid.Row>
                                        <Grid.Column widescreen={16}>
                                            <AccordionData />
                                        </Grid.Column>
                                    </Grid.Row>
                                :   null
                            }
                            <Grid.Row>
                                <Grid.Column floated='left' width={15}>
                                    {/* Loading fecth, in items loading status used to captume skus of workspace */}
                                    <Loader size="big" active={loadingSimulation || loadingFetch} />
                                </Grid.Column>
                                <Grid.Column floated='right' width={5}>
                                    <Button 
                                        floated="right" 
                                        size="small"
                                        compact
                                        color="green"
                                        disabled={simButtonDisabled}
                                        onClick={() => runSimulationWithData()}>
                                        <p className="simulate-label">{t("simulation.runSimulation")}</p>
                                    </Button>
                                    {
                                        loadSimulationData.length ? <Button 
                                                                        floated="right" 
                                                                        size="small"
                                                                        compact
                                                                        className="save-changes-button"
                                                                        disabled={isInputLoadedSimulationDataChange}
                                                                        onClick={()=> saveSimulationChanges()}
                                                                        >
                                                                        <p className="simulate-label">{t("orderScreen.saveChangesToLayout")}</p>
                                                                    </Button>  
                                                                  : null
                                    }   
                                </Grid.Column>
                            </Grid.Row>
                            </Grid>
                        </Segment>
                    </Form>
                    </Card.Content>
                </Card>
            </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                <Segment>
                    <Chart
                        options={getOptionsToSimChart()}
                        series={getSeriesToSimChart()}
                        height={chartOneHeight}
                    />
                </Segment>
                </Grid.Column>
            </Grid.Row>
        </Segment>
    )
}

Simulation.propTypes = {
    materials : PropTypes.array,
    currentWorkspaceId : PropTypes.number,
    simulationChartData : PropTypes.object,
    simulateData: PropTypes.func,
    chartOneOptions: PropTypes.object,
    chartOneHeight: PropTypes.number,
    loadingSimulation: PropTypes.bool,
    simulationMaterialData: PropTypes.object,
    simulationQuantityModifiers: PropTypes.array,
    simulationGroupSettings: PropTypes.object,
    simulationGreyZone: PropTypes.array,
    simulationAduFreqUpd: PropTypes.number,
    simOrdDays: PropTypes.array,
    previousSimData: PropTypes.object,
    setSimulationDateRange: PropTypes.func,
    simulationDateRange: PropTypes.object,
    loadSimulationData: PropTypes.array,
    isInputLoadedSimulationDataChange: PropTypes.bool,
    changeInputSimulationDataStatus: PropTypes.func,
    loadedSelectedSimulationData: PropTypes.object,
    saveSimulationInputChanges: PropTypes.func,
    changeSimulationResultData: PropTypes.func,
    previousLoadedSimInputData: PropTypes.object,
    loadingFetch: PropTypes.bool,
    disableAnalyticsContentInteraction: PropTypes.bool
}

const mapStateToProps = (state)=>({
    simulationChartData: state.analytics.simulationChartData,
    chartOneOptions: state.analytics.chartOneOptions,
    chartOneHeight: state.analytics.chartOneHeight,
    loadingSimulation: state.analytics.loadingSimulation,
    simulationMaterialData: state.analytics.simulationMaterialData,
    simulationQuantityModifiers: state.analytics.simulationQuantityModifiers,
    simulationGroupSettings: state.analytics.simulationGroupSettings,
    simulationGreyZone:state.analytics.simulationGreyZone,
    currentWorkspaceId: getSafeSelectedWorkspace(state),
    simulationAduFreqUpd:state.analytics.simulationAduFreqUpd,
    simOrdDays: state.analytics.simOrdDays,
    previousSimData:state.analytics.previousSimData,
    simulationDateRange: state.analytics.simulationDateRange,
    loadSimulationData: state.analytics.loadSimulationData,
    isInputLoadedSimulationDataChange: state.analytics.isInputLoadedSimulationDataChange,
    loadedSelectedSimulationData: state.analytics.loadedSelectedSimulationData,
    previousLoadedSimInputData: state.analytics.previousLoadedSimInputData,
    loadingFetch: state.analytics.loadingFetch,
    disableAnalyticsContentInteraction: state.analytics.disableAnalyticsContentInteraction
})

export default connect( mapStateToProps, 
                    {   simulateData,  
                        setSimulationDateRange,
                        changeInputSimulationDataStatus,
                        saveSimulationInputChanges,
                        changeSimulationResultData
                    })(Simulation)