import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { List } from "semantic-ui-react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { round, abbreviateNumber } from "../../../utils/format";
import {
  mapItemsToOnHandStockDataSummary,
  mapItemsToOnHandStockDataSummaryCurrency,
} from "../../../utils/mappers/notifications";

import { setNotificationFilter } from "../../../actions/ordersTable";
import { toExcelNumberFormatWithoutDecimals } from "../../../utils/format";
import "./OnHandStockSummary.css";

const OnHandStockSummary = ({ items, inCurrency=false, setNotificationFilter, categories, selectFilter }) => {
  const { t } = useTranslation();

  const onHandStockDataSummary = useMemo(()=>{
    return inCurrency
                ? mapItemsToOnHandStockDataSummaryCurrency(items)
                : mapItemsToOnHandStockDataSummary(items);
  }, [ items, inCurrency ]);

  const { counts, countNames } = onHandStockDataSummary;
  
  const {
    black,
    total,
  } = counts;

  categories = categories
                .map((category) => ({...category, 
                                        value: counts[category.color]})) 
  const checkBlackCategory = categories
                                .filter((category) => 
                                    category.color === "black");

  if (!inCurrency && checkBlackCategory.length === 0) {
      categories.push({
        color: "black",
        value: black,
        filterColor: "grey"
      });
  }

  const getPercentage = (value) => {
    return `${total !== 0 ? round((value * 100) / total, 0) : 0}%`;
  };

  const handleColorFilter = (value) => {
    //TODO: change color if it is necessary
    const selectFilterColor = "violet"
    const filterZoneSelected = value.currentTarget.classList[6].split('selectedColor:')[1];
    const isCurrency = value.currentTarget.classList[7].split('currencyPanel:')[1]
    let filterArray = ''
    const newCategoryFilterColors = categories.map((category) => { 
      if(category.color === filterZoneSelected){
        const { filterColor } = category;
        const newFilterColor = filterColor === "grey" ? selectFilterColor : "grey";
        filterArray = newFilterColor === "grey" ? '' : countNames[category.color]
        return {
          ...category,
          filterColor: newFilterColor
          }
      }
      return {...category, filterColor: "grey"}
    })
    setNotificationFilter(filterArray)
    selectFilter({isCurrency, newCategoryFilterColors})
  }

  return (
    <List>
      {categories.map((category) => (
        <List.Item key={category.color}>
          <List.Icon
            verticalAlign="middle"
            className={category.color}
            name="circle"
            size="small"
          />
          <List.Icon
            verticalAlign="middle"
            className={classNames(category.filterColor, 
                                  'selectedColor:'+category.color,
                                  "currencyPanel:"+inCurrency)}
            name="filter"
            size="small"
            onClick={(value) => handleColorFilter(value)}
          />
          
          <List.Content
            verticalAlign="middle"
            className="on-hand-stock-summary-item"
          >
            {inCurrency
              ? `${toExcelNumberFormatWithoutDecimals(abbreviateNumber(category.value, 2), {currency: "USD"})} (${getPercentage(
                category.value
              )})`
              : `${t("statusPanel.onHandStockByZone.item", {
                count: toExcelNumberFormatWithoutDecimals(category.value, {currency: "USD"}),
              })} (${getPercentage(category.value)})`}
          </List.Content>
        </List.Item>
      ))}
      <List.Item>
        <List.Header className="on-hand-stock-summary-total">
          {inCurrency
            ? `${t("statusPanel.onHandStockByZone.total")}: 
          ${toExcelNumberFormatWithoutDecimals(abbreviateNumber(total, 2), {currency: "USD"})}`
            : `${t("statusPanel.onHandStockByZone.total")}: 
          ${t("statusPanel.onHandStockByZone.item", {
              count: toExcelNumberFormatWithoutDecimals(total, {currency: "USD"}),
            })}`}
        </List.Header>
      </List.Item>
    </List>
  );
};

OnHandStockSummary.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  inCurrency: PropTypes.bool,
  setNotificationFilter: PropTypes.func,
  categories: PropTypes.array,
  selectFilter: PropTypes.func
};

const mapStateToProps = (state) => ({
  items: state.items.items,
});

export default connect(mapStateToProps, { setNotificationFilter })(OnHandStockSummary);
