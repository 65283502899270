import React from "react"
import PropTypes from "prop-types";
import { PYTZ_COMMON_TIMEZONES } from "../../../../utils/constants"
import { Dropdown } from "semantic-ui-react"
import { useTranslation } from "react-i18next"

const TimeZonePicker = ({ value, onChange }) => {

    const { t } = useTranslation()
    return <Dropdown
        search
        value={value}
        onChange={onChange}
        selection options={PYTZ_COMMON_TIMEZONES.map((tz) => ({
            key: tz,
            text: t(`timezones.${tz}`),
            value: tz,
        }))}></Dropdown>
}

TimeZonePicker.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func
}

export default TimeZonePicker
