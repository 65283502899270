import React, { useMemo, useState } from "react";
import usePrevious from "../../../hooks/usePrevious";
import { connect } from "react-redux";
import _ from "lodash";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Chart from "react-apexcharts";
import { Button, Grid, Segment, Loader, Dropdown, Divider } from "semantic-ui-react";
import { getSafeSelectedWorkspace } from "../../../utils/mappers/user";
import { queryReport } from "../../../actions/analytics"; 
import ToggleSection from "./ToggleSection/ToggleSection";
import "./AnalyticsSection.css"
import ChartModifier from "./ChartModifier/ChartModifier";
import { WasAggregateSelected } from "../../../utils/constants";
import useAnalyticsChartDataConvertor from "../../../hooks/useAnalyticsChartDataConvertor";
import {
    ANALYTICS_CHART_OPTIONS,
    isCurrentChartOneOrChartTwo,
    getGraphOptionsByChart,
    transformComboChartDataWithLineFix
} from "./utils";
import { getUserSelectedTimeFilterByCase } from "../utils";
import { TIME_FILTER_OPTIONS } from "../../../utils/constants";
import { setFieldFilterProperties } from "../../../actions/analytics";
import { mappedFiledFiltersWithNewDefaultValues } from "../../../utils/mappers/analytics";

const AnalyticsSection = ( {
    chartOneHeight,
    currentWorkspaceId,
    loadingAnalyticsChartData,
    queryReport,
    loadingDataset,
    analyticsToggleOptions,
    analyticsUserSelectedTimeFilter,
    analyticsByMaterialChartData,
    analyticsChartData,
    analyticsAllWorkspacesChartData,
    currentWorkspaceFieldFilters,
    allWorkspacesFieldFilters,
    selectedCurrentWorkspaceFieldFilter,
    selectedAllWorkspacesFiedFilter,
    setFieldFilterProperties,
    loadingAnalyticsFilters
} ) => {
    const { t } = useTranslation();
    const { CURRENT_WORKSPACE, ALL_WORKSPACES } = TIME_FILTER_OPTIONS;
    const analyticCharts = {analyticsChartData, analyticsByMaterialChartData, analyticsAllWorkspacesChartData}
    const analyticsChartDataFormatObject = useAnalyticsChartDataConvertor(analyticCharts)
    const { wasSingleWorkspaceSelected } = analyticsToggleOptions
    const fieldFilter = wasSingleWorkspaceSelected ? currentWorkspaceFieldFilters : allWorkspacesFieldFilters
    const optionAll = { key: "all", text: t("groupSettings.all"), value: "all" };
    const [currentFilterFieldsData, setCurrentFilterFieldsData] = useState();

    const wasAllWorkspacesSelected = !wasSingleWorkspaceSelected;
    const typeFilterTime = wasSingleWorkspaceSelected ? CURRENT_WORKSPACE : ALL_WORKSPACES;
    const currentSelectedTimeFilter = getUserSelectedTimeFilterByCase(analyticsUserSelectedTimeFilter, typeFilterTime);
    const oldSelectedFieldData = wasSingleWorkspaceSelected ? selectedCurrentWorkspaceFieldFilter : selectedAllWorkspacesFiedFilter;
    const temporalFieldFilters = { ...oldSelectedFieldData, ...currentFilterFieldsData }

    const previousSelectedTimeFilter = usePrevious(currentSelectedTimeFilter);
    const previousTemporalFieldFilters = usePrevious(temporalFieldFilters);
    const previousWasAllWorkspacesSelected = usePrevious(wasAllWorkspacesSelected);

    let currentAnalyticsChartData = { 
        options: ANALYTICS_CHART_OPTIONS,
        series: [], 
    }

    const currentChartData = isCurrentChartOneOrChartTwo(analyticsChartDataFormatObject, analyticsToggleOptions)
    const optionsByChart= getGraphOptionsByChart(analyticCharts, analyticsChartDataFormatObject, analyticsToggleOptions)

    currentAnalyticsChartData['series'] = _.isEmpty(currentChartData) ? [] : transformComboChartDataWithLineFix(currentChartData, analyticsToggleOptions);
    currentAnalyticsChartData['options'] = optionsByChart

    const haveFiltersChanged = useMemo(() => {
        return !_.isEqual(previousTemporalFieldFilters, temporalFieldFilters) ||
            !_.isEqual(previousSelectedTimeFilter, currentSelectedTimeFilter) ||
            !_.isEqual(previousWasAllWorkspacesSelected, wasAllWorkspacesSelected);
    }, [
        previousTemporalFieldFilters,
        previousSelectedTimeFilter,
        previousWasAllWorkspacesSelected,
        temporalFieldFilters,
        currentSelectedTimeFilter,
        wasAllWorkspacesSelected,
    ]);

    const handleMultipleDropdownChange = (data, field) => {
        const selectedValue = data.value;
        if (!_.isEmpty(selectedValue)) {
            const currentFieldData = {
                [field.accessor]: { name: field.name, values: selectedValue },
            };
            setCurrentFilterFieldsData(currentFieldData);
        }

        const wasAllWorkspacesSelected = !wasSingleWorkspaceSelected ? true : false
        const mappedFieldFitlers = mappedFiledFiltersWithNewDefaultValues(fieldFilter, field, selectedValue);
        setFieldFilterProperties(mappedFieldFitlers, wasAllWorkspacesSelected);
    }


    const handleApplyFilterClick = () => {
        if (!haveFiltersChanged) {
            // early return to avoid sending multiple unnnecessary requests if none of the filters have changed
            return;
        }

        queryReport({currentWorkspaceId, 
            t, 
            fieldFilters: temporalFieldFilters, 
            timeFilter: currentSelectedTimeFilter, 
            allWorkspaces: wasAllWorkspacesSelected,
        });
    }
    

    return ( 
        <Segment basic>
            <Segment>
                <Grid stackable >
                    <Grid.Row columns={2}>
                        <Grid.Column stretched width={5}>
                            <ToggleSection/>
                        </Grid.Column>
                        <Grid.Column stretched width={11}>
                            <ChartModifier />
                        </Grid.Column>
                    </Grid.Row>
                    {
                        analyticsToggleOptions[WasAggregateSelected]
                            && (<Grid.Row>
                                    <Grid.Column>
                                        <Segment>
                                            <Grid stackable>
                                                {fieldFilter && fieldFilter
                                                .filter((field) => field.isChecked === true)
                                                .map((field) => (
                                                    <Grid.Column
                                                    stretched
                                                    width={4}
                                                    key={field.accessor}
                                                    >
                                                        <label className="dropdown">{field.name}</label>
                                                        <Dropdown
                                                            className={"multiple-dropdown"}
                                                            value={field.defaultValues}
                                                            options={[...field.options, optionAll]}
                                                            fluid
                                                            multiple
                                                            search
                                                            selection
                                                            onChange={(e, data) => {
                                                                handleMultipleDropdownChange(data, field)
                                                            }}
                                                            closeOnChange={false}
                                                        />
                                                    </Grid.Column>
                                                ))}
                                            </Grid>
                                            <Divider inverted />
                                            <Segment
                                                basic
                                                textAlign={"center"}>
                                                <Button
                                                    active
                                                    disabled={!haveFiltersChanged}
                                                    className="toggle"
                                                    onClick={()=>handleApplyFilterClick()}>
                                                    {t("analytics.apply")}
                                                </Button>
                                            </Segment>
                                        </Segment>
                                    </Grid.Column>
                                </Grid.Row>
                            )

                    }
                    <Grid.Row>
                        <Loader active={loadingDataset || loadingAnalyticsChartData || loadingDataset || loadingAnalyticsFilters} size="big"/>
                    </Grid.Row>
                    </Grid>
            </Segment> 
            <Grid.Row>
                <Grid.Column>
                    <Segment>
                        <Chart
                            options={currentAnalyticsChartData.options}
                            series={currentAnalyticsChartData.series}
                            height={chartOneHeight}
                            type="area"
                        />
                    </Segment>
                </Grid.Column>
            </Grid.Row>
        </Segment>
    
    )
}

AnalyticsSection.propTypes = {
    chartOneHeight: PropTypes.number,
    analyticsPass: PropTypes.string,
    currentWorkspaceId: PropTypes.number,
    loadingAnalyticsChartData: PropTypes.bool,
    queryReport: PropTypes.func,
    loadingDataset: PropTypes.bool,
    analyticsToggleOptions: PropTypes.object,
    analyticsUserSelectedTimeFilter: PropTypes.object,
    analyticsByMaterialChartData: PropTypes.object,
    analyticsChartData: PropTypes.object,
    analyticsAllWorkspacesChartData: PropTypes.object,
    currentWorkspaceFieldFilters: PropTypes.array,
    allWorkspacesFieldFilters: PropTypes.array,
    selectedCurrentWorkspaceFieldFilter: PropTypes.object,
    selectedAllWorkspacesFiedFilter: PropTypes.object,
    setFieldFilterProperties: PropTypes.func,
    loadingAnalyticsFilters: PropTypes.bool
  };

const mapStateToProps = (state) => ({
    /* pull pieces of the state */
    chartOneHeight: state.analytics.chartOneHeight,
    currentWorkspaceId: getSafeSelectedWorkspace(state),
    loadingAnalyticsChartData: state.analytics.loadingAnalyticsChartData,
    loadingDataset: state.dataset.loading,
    analyticsToggleOptions: state.analytics.analyticsToggleOptions,
    analyticsByMaterialChartData: state.analytics.analyticsByMaterialChartData,
    analyticsChartData: state.analytics.analyticsChartData,
    analyticsAllWorkspacesChartData: state.analytics.analyticsAllWorkspacesChartData,
    analyticsUserSelectedTimeFilter: state.analytics.analyticsUserSelectedTimeFilter,
    currentWorkspaceFieldFilters: state.analytics.currentWorkspaceFieldFilters,
    allWorkspacesFieldFilters: state.analytics.allWorkspacesFieldFilters,
    selectedCurrentWorkspaceFieldFilter: state.analytics.selectedCurrentWorkspaceFieldFilter,
    selectedAllWorkspacesFiedFilter: state.analytics.selectedAllWorkspacesFiedFilter,
    loadingAnalyticsFilters: state.analytics.loadingAnalyticsFilters,
});

export default connect(mapStateToProps, { queryReport, setFieldFilterProperties })( AnalyticsSection );