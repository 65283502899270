import React from "react";
import PropTypes from "prop-types";
import { Button, Icon} from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import _ from "lodash";

export const DeactivateTokenButton = ({
    deactivateToken,
    tokenId,
}) => {
    const { t } = useTranslation();
    const deactivate = t("account.adminConsole.revoke");
    const deactivationOp = (tokenId) => {
        deactivateToken(tokenId)
    }
    
    return (
        <Button
            className="revokeTokenButton"
            disabled={false}        
            onClick={() => deactivationOp(tokenId)}            
            > {deactivate} <Icon name='close'/>
        </Button>)   
}

DeactivateTokenButton.propTypes = {
    deactivateToken: PropTypes.func,
    getTokensInfo: PropTypes.func,
    isTokenDeactivated: PropTypes.bool,
    tokenId: PropTypes.number,
  };

  DeactivateTokenButton.defaultProps = {
    deactivateToken: _.noop,
    getTokensInfo: _.noop,
    isTokenDeactivated: false,
};
export default DeactivateTokenButton;
