import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import { Segment, Message } from "semantic-ui-react";

import { detectOrderTableParameterChanges } from "../../actions/ordersTable";

import { useTranslation } from "react-i18next";

const ChangeWarning = ({
  currentCalendarEvents,
  currentGroups,
  isCurrentOrderTableCommitted,
  fileTypeInfo,
  currentWorkspaceId,
  calendarEventsChanged,
  groupsChanged,
  historicalDemandChanged,
  detectOrderTableParameterChanges,
  loadingOrderTable,
  loadingOrderTableUpdate,
  loadingGroups,
  loadingCalendarEvents,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (
      loadingOrderTable ||
      loadingOrderTableUpdate ||
      loadingGroups ||
      loadingCalendarEvents
    ) {
      return;
    } else if (isCurrentOrderTableCommitted && !_.isNil(currentWorkspaceId)) {
      detectOrderTableParameterChanges();
    }
  }, [
    loadingOrderTable,
    loadingOrderTableUpdate,
    loadingGroups,
    loadingCalendarEvents,
    isCurrentOrderTableCommitted,
    currentWorkspaceId,
    fileTypeInfo,
    currentCalendarEvents,
    currentGroups,
    detectOrderTableParameterChanges,
  ]);

  if (
    !isCurrentOrderTableCommitted ||
    loadingOrderTable ||
    loadingOrderTableUpdate ||
    loadingGroups ||
    loadingCalendarEvents
  ) {
    return null;
  }

  return (
    <>
      {calendarEventsChanged && (
        <Segment as={Message} warning className="change-warning">
          {t("ordersTable.calendarEventsChangeWarning")}
        </Segment>
      )}
      {groupsChanged && (
        <Segment as={Message} warning className="change-warning">
          {t("ordersTable.groupSettingsChangeWarning")}
        </Segment>
      )}
      {historicalDemandChanged && (
        <Segment as={Message} warning className="change-warning">
          {t("ordersTable.historicalDemandChangeWarning")}
        </Segment>
      )}
    </>
  );
};

ChangeWarning.propTypes = {
  currentCalendarEvents: PropTypes.arrayOf(PropTypes.object),
  currentGroups: PropTypes.arrayOf(PropTypes.object),
  isCurrentOrderTableCommitted: PropTypes.bool,
  fileTypeInfo: PropTypes.object,
  currentWorkspaceId: PropTypes.number,
  calendarEventsChanged: PropTypes.bool,
  groupsChanged: PropTypes.bool,
  historicalDemandChanged: PropTypes.bool,
  loadingOrderTable: PropTypes.bool,
  loadingOrderTableUpdate: PropTypes.bool,
  loadingGroups: PropTypes.bool,
  loadingCalendarEvents: PropTypes.bool,
  detectOrderTableParameterChanges: PropTypes.func,
};

const mapStateToProps = (state) => ({
  currentCalendarEvents: state.calendar.events,
  currentGroups: state.groupSettings.groups,
  isCurrentOrderTableCommitted: state.ordersTable.isCommitted,
  fileTypeInfo: state.files.fileTypeInfo,
  currentWorkspaceId: state.workspace.currentWorkspace,
  calendarEventsChanged: state.ordersTable.calendarEventsChanged,
  groupsChanged: state.ordersTable.groupsChanged,
  historicalDemandChanged: state.ordersTable.historicalDemandChanged,
  loadingOrderTable: state.ordersTable.loading,
  loadingOrderTableUpdate: state.ordersTable.loadingUpdate,
  loadingGroups: state.groupSettings.loadingFetch,
  loadingCalendarEvents: state.calendar.loadingFetch,
});

export default connect(mapStateToProps, { detectOrderTableParameterChanges })(
  ChangeWarning
);
