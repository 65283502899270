import React from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";

import { connect } from "react-redux";
import { Loader } from "semantic-ui-react";
import { LOGIN, ORDER_SCREEN } from "../Navbar/routes";

const Default = ({ auth }) => {
  if (auth.loading) {
    return <Loader active size="large" />;
  }

  return auth.isAuthenticated ? (
    <Redirect
      to={{
        pathname: ORDER_SCREEN,
      }}
    ></Redirect>
  ) : (
    <Redirect
      to={{
        pathname: LOGIN,
      }}
    ></Redirect>
  );
};

Default.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(Default);
