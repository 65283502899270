import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import { Grid, Icon, Header, Segment } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

import { mapFileDataToStatus } from "../../utils/mappers/fileStatus";
import { getSafeItemsCurrency } from "../../utils/mappers/notifications";

import BatchStatusSummary from "./BatchStatusSummary/BatchStatusSummary";
import OnHandStockSummary from "./OnHandStockSummary/OnHandStockSummary";
import OrderSummary from "./OrderSummary/OrderSummary";
import ProjectedStockOuts from "./ProjectedStockOuts/ProjectedStockOuts";

import "./NotificationsPanel.css";

export const NotificationsPanel = ({
  dataImportStatus,
  loadingFileTypeInfo,
  loadingItems,
  hasItems,
  hasBatchData,
  itemsCurrency,
  orderTableFilters,
}) => {

  //TODO: maybe this constant have to move to categoriesData when when we added filter in Project Stock Outs
  const categoriesData = [
    {
      color: "blue",
      filterColor: "grey"
    },
    {
      color: "green",
      filterColor: "grey"
    },
    {
      color: "yellow",
      filterColor: "grey"
    },
    {
      color: "red",
      filterColor: "grey"
    },
    {
      color: "grey",
      filterColor: "grey"
    },
  ];
  const [ categoriesItem, setCategoriesItem ] = useState(categoriesData);
  const [ categoriesCurrency, setCategoriesCurrency ] = useState(categoriesData);

  //TODO: check to change this to useMemo
  useEffect(()=>{
    setCategoriesItem(categoriesData)
    setCategoriesCurrency(categoriesData)
    // eslint-disable-next-line
  }, [loadingItems, orderTableFilters])

  const { t } = useTranslation();
  if (loadingFileTypeInfo || loadingItems || !dataImportStatus) {
    return <Grid></Grid>;
  }

  let dataImportStatusColor = "green";
  for (const file in dataImportStatus) {
    if (
      dataImportStatusColor === "green" &&
      dataImportStatus[file].uploadTimeStatus.color === "yellow"
    ) {
      dataImportStatusColor = "yellow";
    } else if (dataImportStatus[file].uploadTimeStatus.color === "red") {
      dataImportStatusColor = "red";
    }
  }

  for (const file in dataImportStatus) {
    if (dataImportStatus[file].lastUploadStatus.color === "red") {
      dataImportStatusColor = "red";
    }
  }

  const controlFilters = (filterInfo)=>{
    const { isCurrency, newCategoryFilterColors } = filterInfo

    if(isCurrency === "false"){
      setCategoriesCurrency(categoriesData)
      setCategoriesItem(newCategoryFilterColors)
    }
    if(isCurrency === "true"){  
      setCategoriesItem(categoriesData)
      setCategoriesCurrency(newCategoryFilterColors)
    }
  }

  return (
    <Grid className="notifications-panel" textAlign="center">
      <Grid.Row>
        <Segment>
          <Header size="small">{t("statusPanel.menuNotifications")}</Header>
          <div>
            <Icon
              className={dataImportStatusColor}
              name="circle"
              size="small"
            />
            {t("dataImport.title")}
          </div>
        </Segment>
      </Grid.Row>

      <Grid.Row>
        <Segment>
          <Grid.Row>
            <Header size="small">{t("statusPanel.orderSummary.title")}</Header>
          </Grid.Row>
          <OrderSummary />
        </Segment>
      </Grid.Row>

      {hasItems && (
        <>
          <Grid.Row>
            <Segment>
              <Header size="small">
                {t("statusPanel.onHandStockByZone.title")}
                <br />
                <span className="secondary">
                  {t("statusPanel.onHandStockByZone.itemsUnit")}
                </span>
              </Header>
        {/* Items */}
              <OnHandStockSummary 
                  categories={categoriesItem} 
                  selectFilter={(filterInfo)=> controlFilters(filterInfo)}
                  />
            </Segment>
          </Grid.Row>

          <Grid.Row>
            <Segment>
              <Header size="small">
                {t("statusPanel.onHandStockByZone.title")}
                <br />
                <span className="secondary">
                  {_.isNil(itemsCurrency)
                    ? t("statusPanel.onHandStockByZone.currencyUnit")
                    : `(${itemsCurrency})`}
                </span>
              </Header>
        {/* Categories */}
              <OnHandStockSummary 
                  inCurrency 
                  categories={categoriesCurrency}
                  selectFilter={(filterInfo)=> controlFilters(filterInfo)}
                  />
            </Segment>
          </Grid.Row>

          <Grid.Row>
            <Segment>
              <Header size="small">
                {t("retail.projectedStockOuts.title")}
                <br />
                <span className="secondary">
                  {t("retail.projectedStockOuts.itemsUnit")}
                </span>
              </Header>

              <ProjectedStockOuts />
            </Segment>
          </Grid.Row>
        </>
      )}

      {hasBatchData && (
        <Grid.Row>
          <Segment>
            <Header size="small">
              {t("retail.batchStatus.leftPanel.title")}
              <br />
                <span className="secondary">
                  {t("retail.batchStatus.leftPanel.itemsUnit")}
                </span>
            </Header>

            <BatchStatusSummary />
          </Segment>
        </Grid.Row>
      )}
    </Grid>
  );
};

NotificationsPanel.propTypes = {
  dataImportStatus: PropTypes.object,
  loadingFileTypeInfo: PropTypes.bool,
  loadingItems: PropTypes.bool,
  hasItems: PropTypes.bool,
  hasBatchData: PropTypes.bool,
  itemsCurrency: PropTypes.string,
  orderTableFilters: PropTypes.object,
};

NotificationsPanel.defaultProps = {
  dataImportStatus: {},
  loadingFileTypeInfo: false,
};

const mapStateToProps = (state) => ({
  dataImportStatus: mapFileDataToStatus(
    state.files.fileTypeInfo,
    state.files.logEntries
  ),
  loadingFileTypeInfo: state.files.loadingFileTypeInfo,
  loadingItems: state.items.loadingFetch,
  hasItems: !_.isEmpty(state.items.items),
  hasBatchData: !_.isNil(state.items.batchData),
  itemsCurrency: getSafeItemsCurrency(state.items.items),
  orderTableFilters: state.ordersTable.orderTableFilters,
});

export default connect(mapStateToProps, {})(NotificationsPanel);
