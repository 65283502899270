import React from 'react'
import { Popup } from 'semantic-ui-react';
import { shortenNumber } from '../../../../utils/format';
import { ExtraMetricsLineXOffset, barWidth } from '../../../../utils/constants';
import _ from "lodash";
import "./BarChart.css"

export const ExtraMetrics = ( metricData ) => {

    const { metric, shortNumberLength, extraMetricsTextXCoordinate, centerLine } = metricData

    const {
      value,
      label,
      icon,
      details,
      header,
      relativeOffset,
      hideLine,
      color,
    } = metric;
    
    const splitLabel = label.split(':')
    const newLabel = splitLabel[0] + ': ' + shortenNumber(parseFloat(splitLabel[1]), shortNumberLength+0.5)
    
    return (
      <Popup
        on="hover"
        disabled={_.isEmpty(header)}
        position="left center"
        trigger={
          <g>
            {!hideLine && (
              <line
                className={"extraMetricsLine"}
                x1={centerLine - 30}
                y1={value + relativeOffset}
                x2={centerLine + barWidth / 2}
                y2={value}
                z={1}
              />
            )}
            {!hideLine && icon}
            <text
              textAnchor="end"
              x={extraMetricsTextXCoordinate - ExtraMetricsLineXOffset}
              y={value + 5 + relativeOffset}
              z={1}
              className={"extraMetricsLabel"}
              fill={hideLine ? color : null}
              strokeWidth="1"
              textDecoration={hideLine ? "underline" : null}
            >
              {newLabel}
            </text>
          </g>
        }
      >
        <Popup.Header> {header}</Popup.Header>
        <Popup.Content className="chart-popup-content">{details}</Popup.Content>
      </Popup>
    );
  };