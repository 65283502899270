import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import classNames from "classnames";

import _ from "lodash";

import { getSelectedRowBatchData } from "../../../../utils/mappers/detailsPanel";

import { batchStatuses } from "../../../../utils/constants";

import { Table, Accordion, Icon } from "semantic-ui-react";

import { useTranslation } from "react-i18next";

import { abbreviateNumber, shortenNumber } from "../../../../utils/format";

import { Popup } from "semantic-ui-react";
import { toExcelNumberFormatWithDecimals } from "../../../../utils/format";

const OnHandStockBatchesTable = ({ active, index, onClick, batches }) => {
  
  const ref = useRef(null)
  const [ longQuantityStock, setLongQuantityStock ] = useState({containerWidth: 0, shortNumber: 0}) 

  const { t } = useTranslation();

  useEffect(()=>{
    window.addEventListener('resize', ()=>{
      setLongQuantityStock((longQuantityStock)=>({...longQuantityStock, containerWidth: window.innerWidth}))
    })
  }, [])

  useEffect(()=>{
    if(ref.current!==null){
      setLongQuantityStock((longQuantityStock)=>({...longQuantityStock, shortNumber: (abbreviateNumber((ref.current.offsetWidth-65)*0.03)+5)}))
    }
  }, [longQuantityStock.containerWidth, longQuantityStock.shortNumber])

  if (_.isEmpty(batches)) {
    return null;
  }

  return (
    <div ref={ref}>
      <Accordion.Title active={active} index={index} onClick={onClick}>
        <Icon name="dropdown" />
        {t("detailsPanel.numericalDetails.onHandStock.title")}
      </Accordion.Title>
      <Accordion.Content active={active}>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.Cell>
                {t("detailsPanel.numericalDetails.onHandStock.quantity")}
              </Table.Cell>
              <Table.Cell>
                {t("detailsPanel.numericalDetails.onHandStock.batchIdentifier")}
              </Table.Cell>
              <Table.Cell>
                {t("detailsPanel.numericalDetails.onHandStock.batchExpiryDate")}
              </Table.Cell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {batches.map((batch) => (
              <Table.Row
                key={`${batch.location_code_concatenation}-${batch.batch_identifier}`}
              >
                {[
                  shortenNumber(batch.on_hand_stock, longQuantityStock.shortNumber),
                  batch.batch_identifier,
                  batch.batch_expiry_date,
                ].map((value) => (
                  <Table.Cell
                    key={value}
                    className={classNames({
                      expired: batch.status === batchStatuses.expired,
                      expiry_this_week:
                        batch.status === batchStatuses.expiry_this_week,
                      expiry_next_week:
                        batch.status === batchStatuses.expiry_next_week,
                      expiry_two_weeks:
                        batch.status === batchStatuses.expiry_two_weeks,
                      expiry_three_weeks:
                        batch.status === batchStatuses.expiry_three_weeks,
                      expiry_three_plus_weeks:
                        batch.status === batchStatuses.expiry_three_plus_weeks,
                    })}
                  >
                    {
                      typeof(value) === 'string' && value.indexOf('...') > 0
                          ? <Popup 
                                on='hover'
                                position='left center'
                                content={toExcelNumberFormatWithDecimals(batch.on_hand_stock)}
                                trigger={<p>{value}</p>}
                                />
                          : value 
                    }
                  </Table.Cell>
                ))}
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Accordion.Content>
    </div>
  );
};

OnHandStockBatchesTable.propTypes = {
  active: PropTypes.bool,
  index: PropTypes.number,
  onClick: PropTypes.func,
  batches: PropTypes.arrayOf(PropTypes.object),
};

const mapStateToProps = (state) => ({
  batches: getSelectedRowBatchData(state.ordersTable.selectedRow, state.items),
});

export default connect(mapStateToProps)(OnHandStockBatchesTable);
