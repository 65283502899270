import React from "react";
import PropTypes from "prop-types";

import _ from "lodash";

import { Button, Icon } from "semantic-ui-react";

const UploadButton = ({
  disabled,
  text,
  fileInputRef,
  onFileAdded,
  openFileDialog
}) => {
  return (
    <React.Fragment>
      <input
        //TODO: change the logic for this button later, solved problem for matter 
        disabled={disabled}
        ref={fileInputRef}
        type="file"
        style={{ display: "none" }}
        onChange={onFileAdded}
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      />
      <Button
        positive
        //TODO: change the logic for this button later, solved problem for matter 
        disabled={disabled}
        floated="right"
        type="button"
        className="btn btn-primary"
        onClick={openFileDialog}
      >
        {text} &nbsp;&nbsp; <Icon name='upload' />
      </Button>
    </React.Fragment>
  );
};

UploadButton.propTypes = {
  text: PropTypes.string,
  fileInputRef: PropTypes.object,
  onFileAdded: PropTypes.func,
  openFileDialog: PropTypes.func,
  disabled: PropTypes.bool
};

UploadButton.defaultProps = {
  text: "",
  fileInputRef: null,
  onFileAdded: _.noop,
  openFileDialog: _.noop
};
export default UploadButton;
