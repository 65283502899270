import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { List } from "semantic-ui-react";

import { useTranslation } from "react-i18next";

import { mapItemsToBatchDataSummary } from "../../../utils/mappers/notifications";

import "./BatchStatusSummary.css";

const BatchStatusSummary = ({ batchDataSummary }) => {
  const { t } = useTranslation();

  const {
    expired,
    expiring_this_week,
    expiring_next_week,
    expiring_in_two_weeks,
    expiring_in_three_weeks,
  } = batchDataSummary;

  const listItems = [
    {
      color: "black",
      textKey: "retail.batchStatus.leftPanel.expired",
      value: expired,
    },
    {
      color: "red",
      textKey: "retail.batchStatus.leftPanel.expiry_this_week",
      value: expiring_this_week,
    },
    {
      color: "yellow",
      textKey: "retail.batchStatus.leftPanel.expiry_next_week",
      value: expiring_next_week,
    },
    {
      color: "green",
      textKey: "retail.batchStatus.leftPanel.expiry_two_weeks",
      value: expiring_in_two_weeks,
    },
    {
      color: "blue",
      textKey: "retail.batchStatus.leftPanel.expiry_three_weeks",
      value: expiring_in_three_weeks,
    },
  ];

  return (
    <List>
      {listItems.map((item) => (
        <List.Item key={item.textKey}>
          <List.Icon
            verticalAlign="middle"
            className={item.color}
            name="circle"
            size="small"
          />
          <List.Content
            verticalAlign="middle"
            className="batch-status-summary-item "
          >
            {`${t(item.textKey)}: ${t("retail.item", { count: item.value })}`}
          </List.Content>
        </List.Item>
      ))}
    </List>
  );
};

BatchStatusSummary.propTypes = {
  batchDataSummary: PropTypes.objectOf(PropTypes.number).isRequired,
};

const mapStateToProps = (state) => ({
  batchDataSummary: mapItemsToBatchDataSummary(state.items.items),
});

export default connect(mapStateToProps)(BatchStatusSummary);
