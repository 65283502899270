import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Grid, Input} from 'semantic-ui-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next';
import SimpleRangeSlider from '../../../GroupSettings/SimpleRangeSlider/SimpleRangeSlider';
import { SinglePointSlider } from './SinglePointSlider/SinglePointSlider';
import { changeLoadSimulationData } from '../../../../actions/analytics';

import {
    variabilityFactorMin,
    variabilityFactorMax,
    variabilityFactorStep,
    leadTimeFactorMin,
    leadTimeFactorMax,
    leadTimeFactorStep,
  } from '../../../../utils/constants';

import { round } from '../../../../utils/format'; 
import './ModalGroupSetting.css'
import { connect } from 'react-redux';
import classNames from 'classnames';

const ModalGroupSetting = 
({ 
  isModal=false,
  disabled, 
  changeGroupSetting, 
  materialGroupSetting,
  loadSimulationData,
  changeLoadSimulationData
})=>{
    const { t } = useTranslation()
    const [ open, setOpen ] = useState(false)
    const [ruleData, setRuleData] = useState(materialGroupSetting);
    const [ aduLengthError, setAduLengthError ] = useState(false)
    const { loaded_results="", sim_inputs="" } = loadSimulationData[0] || {}

    useEffect(()=>{
      setRuleData(materialGroupSetting)
    }, [materialGroupSetting])

    const getLeadTimeTag = (value) => {
        let text;
        if (value > 0.9) {
        text = t("groupSettings.rules.leadTime.tags.extremelyShort");
        } else if (value > 0.7) {
        text = t("groupSettings.rules.leadTime.tags.veryShort");
        } else if (value > 0.5) {
        text = t("groupSettings.rules.leadTime.tags.short");
        } else if (value > 0.3) {
        text = t("groupSettings.rules.leadTime.tags.medium");
        } else if (value > 0.1) {
        text = t("groupSettings.rules.leadTime.tags.long");
        } else {
        text = t("groupSettings.rules.leadTime.tags.veryLong");
        }
        return `${text} (${value})`;
    };

    const decreaseLeadTimeFactor = () => {
        const newValue = round(
          parseFloat(ruleData.leadTimeFactor) - leadTimeFactorStep,
          2
        );
        setRuleData({
          ...ruleData,
          leadTimeFactor: Math.max(newValue, leadTimeFactorMin),
        });
      };
    
    const incrementLeadTimeFactor = () => {
        const newValue = round(
            parseFloat(ruleData.leadTimeFactor) + leadTimeFactorStep,
            2
        );
        setRuleData({
            ...ruleData,
            leadTimeFactor: Math.min(newValue, leadTimeFactorMax),
        });
    };

    const getVariabilityTag = (value) => {
        let text;
        if (value > 0.9) {
          text = t("groupSettings.rules.variability.tags.extremelyHigh");
        } else if (value > 0.7) {
          text = t("groupSettings.rules.variability.tags.veryHigh");
        } else if (value > 0.5) {
          text = t("groupSettings.rules.variability.tags.high");
        } else if (value > 0.3) {
          text = t("groupSettings.rules.variability.tags.medium");
        } else if (value > 0.1) {
          text = t("groupSettings.rules.variability.tags.low");
        } else {
          text = t("groupSettings.rules.variability.tags.veryLow");
        }
        return `${text} (${value})`;
      };
    
    const decreaseVariabilityFactor = () => {
    const newValue = round(
        parseFloat(ruleData.variabilityFactor) - variabilityFactorStep,
        2
    );
    setRuleData({
        ...ruleData,
        variabilityFactor: Math.max(newValue, variabilityFactorMin),
    });
    };

    const incrementVariabilityFactor = () => {
        const newValue = round(
          parseFloat(ruleData.variabilityFactor) + variabilityFactorStep,
          2
        );
        setRuleData({
          ...ruleData,
          variabilityFactor: Math.min(newValue, variabilityFactorMax),
        });
      };

    const updateMaterialGroupSetting = ()=>{
      const reply = {
          adu_measurement_start: ruleData.aduMeasurementStart,
          adu_measurement_end: ruleData.aduMeasurementEnd,
          lead_time_factor: ruleData.leadTimeFactor,
          variability_factor: ruleData.variabilityFactor
      }
      
      //Refactor this conditional later
      if(ruleData.aduMeasurementStart === ruleData.aduMeasurementEnd || ruleData.aduMeasurementEnd < ruleData.aduMeasurementStart ){
        setAduLengthError(true)
      }else{
        if(loadSimulationData.length){

          changeLoadSimulationData([{loaded_results, sim_inputs: {...sim_inputs, group_info: reply}}])
          setAduLengthError(false)
          setOpen(false);
          return 
        }
        changeGroupSetting(reply)
        setAduLengthError(false)
        setOpen(false);
      }
    }

    return(
        <Modal
            onClose={()=>setOpen(false)}
            onOpen={()=>setOpen(true)}
            open={open}
            trigger={
                <Button 
                  fluid={!isModal} 
                  disabled={disabled} 
                  positive={isModal}
                  icon={isModal ? "pencil" : null}
                  className={classNames({"button-color": !isModal})}
                  >
                  {isModal ? null : t("simulation.changeMaterialGroupSettings")}
                </Button>
                }
            >
            <Modal.Header>{t("simulation.changeMaterialGroupSettings")}</Modal.Header>
            
            <Modal.Content>
            <Grid stackable>
                <Grid.Row columns={3}>
                    <Grid.Column>
                      <Form>
                        <Form.Field className="adu-length-settings" error={aduLengthError}>
                          <label>{t("simulation.aduMeasuramentLength")}</label>
                          <SinglePointSlider 
                              startInitialValue={ruleData.aduMeasurementStart}
                              endInitialValue={ruleData.aduMeasurementEnd}
                              onChange={(high)=>setRuleData({...ruleData, aduMeasurementEnd:high})}
                            />
                        </Form.Field>
                        </Form>
                    </Grid.Column>

                    <Grid.Column>
                        <Form.Field className="lead-time-slider">
                          <label>
                            {t("groupSettings.rules.leadTime.title")}
                          </label>

                          <Grid>
                            <Grid.Column width={16}>
                              <SimpleRangeSlider
                                step={leadTimeFactorStep}
                                min={leadTimeFactorMin}
                                max={leadTimeFactorMax}
                                onChange={(value) => {
                                  setRuleData({
                                    ...ruleData,
                                    leadTimeFactor: round(parseFloat(value), 1),
                                  });
                                }}
                                initialValue={ruleData.leadTimeFactor}
                              />
                            </Grid.Column>
                            <Grid.Column textAlign={"center"} width={16}>
                              <Input
                                className="lead-time-controls"
                                value={getLeadTimeTag(ruleData.leadTimeFactor)}
                              >
                                <Button
                                  positive
                                  icon="minus"
                                  size="mini"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    decreaseLeadTimeFactor();
                                  }}
                                />
                                <input type="text" />
                                <Button
                                  positive
                                  icon="plus"
                                  size="mini"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    incrementLeadTimeFactor();
                                  }}
                                />
                              </Input>
                            </Grid.Column>
                          </Grid>
                        </Form.Field>

                    </Grid.Column>

                    <Grid.Column>
                        <Form.Field className="variability-slider">
                          <label>
                            {t("groupSettings.rules.variability.title")}
                          </label>
                          <Grid>
                            <Grid.Column width={16}>
                              <SimpleRangeSlider
                                step={variabilityFactorStep}
                                min={variabilityFactorMin}
                                max={variabilityFactorMax}
                                onChange={(value) => {
                                  setRuleData({
                                    ...ruleData,
                                    variabilityFactor: round(
                                      parseFloat(value),
                                      1
                                    ),
                                  });
                                }}
                                initialValue={ruleData.variabilityFactor}
                              />
                            </Grid.Column>
                            <Grid.Column textAlign={"center"} width={16}>
                              <Input
                                className="lead-time-controls"
                                value={getVariabilityTag(
                                  ruleData.variabilityFactor
                                )}
                              >
                                <Button
                                  positive
                                  icon="minus"
                                  size="mini"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    decreaseVariabilityFactor();
                                  }}
                                />
                                <input type="text" />
                                <Button
                                  positive
                                  icon="plus"
                                  size="mini"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    incrementVariabilityFactor();
                                  }}
                                />
                              </Input>
                            </Grid.Column>
                          </Grid>
                        </Form.Field>

                        </Grid.Column>
                  </Grid.Row>
                </Grid> 
            </Modal.Content>
            <Modal.Actions>
                <Button color='black' onClick={() => setOpen(false)}>
                    {t("cancel")}
                </Button>
                <Button
                    onClick={() => { updateMaterialGroupSetting()}}
                    positive>
                    {t("confirm")}
                </Button>
            </Modal.Actions>
            
        </Modal>
    )
}

ModalGroupSetting.propTypes = {
  isModal: PropTypes.bool,
  disabled: PropTypes.bool,
  changeGroupSetting: PropTypes.func,
  currentWorkspaceId: PropTypes.number,
  materialId: PropTypes.string,
  materialGroupSetting: PropTypes.object,
  loadSimulationData: PropTypes.array,
  changeLoadSimulationData: PropTypes.func
}

const mapStateToProps = (state) =>({
  materialGroupSetting: state.analytics.materialGroupSetting,
  loadSimulationData: state.analytics.loadSimulationData
})

export default connect(mapStateToProps, { changeLoadSimulationData })(ModalGroupSetting)
