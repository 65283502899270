import React from "react";
import PropTypes from "prop-types";
import { Table } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

export const PathsContainer = ({ workspaces }) => {
    const { t } = useTranslation();
    const workspaceHeader = t("account.autoUploadConfig.workspaces");
    const savingPathsHeader = t("account.autoUploadConfig.savingPaths"); 

    return(
        <Table celled>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>{workspaceHeader}</Table.HeaderCell>
                    <Table.HeaderCell>{savingPathsHeader}</Table.HeaderCell>
                </Table.Row>
            </Table.Header>

            <Table.Body>
                {workspaces
                    .map(   workspace => (
                        <Table.Row key={workspace.name}>
                            <Table.Cell key={workspace.name}>{workspace.name}</Table.Cell>
                            <Table.Cell>
                                <br/> 
                                workspace-{workspace.id}/master/<br/> <br/>
                                workspace-{workspace.id}/historical_demand/ <br/> <br/>
                                workspace-{workspace.id}/inventory/ <br/> <br/>
                            </Table.Cell>
                        </Table.Row>
                ))}               
                
            </Table.Body>        
        </Table>
    )
    
    
}

PathsContainer.propTypes = {    
    workspaces: PropTypes.array,   
  };

PathsContainer.defaultProps = {
    workspaces: [],    
};

export default PathsContainer;