import { combineReducers } from "redux";

import { LOGOUT, SELECT_WORKSPACE } from "../actions/types";

import items from "./items";
import ordersTable from "./ordersTable";
import dynamicFields from "./dynamicFields";
import error from "./error";
import calendar from "./calendar";
import analytics from "./analytics";
import files from "./files";
import groupSettings from "./groupSettings";
import auth from "./auth";
import adminConsole from "./adminConsole";
import workspace from "./workspace";
import dataset from "./dataset";

const appReducer = combineReducers({
  items,
  ordersTable,
  dynamicFields,
  error,
  calendar,
  analytics,
  files,
  groupSettings,
  auth,
  adminConsole,
  workspace,
  dataset,
});

const rootReducer = (state, action) => {
  if (action.type === SELECT_WORKSPACE) {
    const { auth, workspace, adminConsole } = state;
    state = { auth, workspace, adminConsole };
  } else if (action.type === LOGOUT) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
