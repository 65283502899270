import React, { useEffect } from "react";
import PropTypes from "prop-types";

import _ from "lodash";
import { connect } from "react-redux";

import { getSafeSelectedWorkspace } from "../../utils/mappers/user";

import {
  subscribeToWorkspaceChannel,
  subscribeToOrganizationChannel,
} from "../../actions/workspace";

import { setConnectionStatus } from "../../actions/auth";

export const PusherProvider = ({
  pusherInstance,
  currentWorkspaceId,
  currentOrganizationId,
  subscribeToWorkspaceChannel,
  subscribeToOrganizationChannel,
  isAuthenticated,
  setConnectionStatus,
  ...props
}) => {
  useEffect(
    () => {
      //pusher connection states: connected, connecting, unavailable.
      const pollConnectionState = setInterval(() => {
        setConnectionStatus(pusherInstance.connection.state);
      }, 8000);
      return () => clearInterval(pollConnectionState);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (!_.isNil(currentOrganizationId) && isAuthenticated) {
      const unsubscribe = subscribeToOrganizationChannel(
        pusherInstance,
        currentOrganizationId
      );
      return unsubscribe;
    }
  }, [
    pusherInstance,
    subscribeToOrganizationChannel,
    currentOrganizationId,
    isAuthenticated,
  ]);

  useEffect(() => {
    if (!_.isNil(currentWorkspaceId) && isAuthenticated) {
      const unsubscribe = subscribeToWorkspaceChannel(
        pusherInstance,
        currentWorkspaceId
      );
      return unsubscribe;
    }
  }, [
    pusherInstance,
    currentWorkspaceId,
    subscribeToWorkspaceChannel,
    isAuthenticated,
  ]);

  // eslint-disable-next-line react/prop-types
  return <>{props.children}</>;
};

PusherProvider.propTypes = {
  pusherInstance: PropTypes.object,
  currentWorkspaceId: PropTypes.number,
  currentOrganizationId: PropTypes.number,
  subscribeToWorkspaceChannel: PropTypes.func,
  subscribeToOrganizationChannel: PropTypes.func,
  isAuthenticated: PropTypes.bool,
  setConnectionStatus: PropTypes.func,
};

const mapStateToProps = (state) => ({
  currentWorkspaceId: getSafeSelectedWorkspace(state),
  isAuthenticated: state.auth.isAuthenticated,
  currentOrganizationId: _.isNil(state.auth.user)
    ? null
    : state.auth.user.organization_id,
});

export default connect(mapStateToProps, {
  subscribeToWorkspaceChannel,
  subscribeToOrganizationChannel,
  setConnectionStatus,
})(PusherProvider);
