import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { List } from "semantic-ui-react";

import { useTranslation } from "react-i18next";

import { mapItemsToProjectedStockOuts } from "../../../utils/mappers/notifications";

import "./ProjectedStockOuts.css";

const ProjectedStockOuts = ({ stockOutsData }) => {
  const { t } = useTranslation();

  const { today, tomorrow, within_7, within_15, within_30 } = stockOutsData;

  const listItems = [
    {
      color: "black",
      textKey: "retail.projectedStockOuts.today",
      value: today,
    },
    {
      color: "red",
      textKey: "retail.projectedStockOuts.tomorrow",
      value: tomorrow,
    },
    {
      color: "yellow",
      textKey: "retail.projectedStockOuts.within_7",
      value: within_7,
    },
    {
      color: "green",
      textKey: "retail.projectedStockOuts.within_15",
      value: within_15,
    },
    {
      color: "blue",
      textKey: "retail.projectedStockOuts.within_30",
      value: within_30,
    },
  ];

  return (
    <List>
      {listItems.map((item) => (
        <List.Item key={item.textKey}>
          <List.Icon
            verticalAlign="middle"
            className={item.color}
            name="circle"
            size="small"
          />
          <List.Content
            verticalAlign="middle"
            className="projected-stock-outs-item"
          >
            {`${t(item.textKey)}: ${t("retail.item", { count: item.value })}`}
          </List.Content>
        </List.Item>
      ))}
    </List>
  );
};

ProjectedStockOuts.propTypes = {
  stockOutsData: PropTypes.objectOf(PropTypes.number).isRequired,
};

const mapStateToProps = (state) => ({
  stockOutsData: mapItemsToProjectedStockOuts(state.items.items),
});

export default connect(mapStateToProps)(ProjectedStockOuts);
