import React, { useState } from "react";
import PropTypes from "prop-types";

import _ from "lodash";

import { debounce } from "throttle-debounce";

import { useTranslation } from "react-i18next";

import { mapColumnsToGroupFields, fatherItems } from "../../../utils/mappers/groupSettings";

import {
  Segment,
  Form,
  Grid,
  Button,
  Loader,
  Dimmer,
  GridColumn,
  Radio,
} from "semantic-ui-react";

import SubgroupDropdown from "../SubgroupDropdown/SubgroupMaterials";
import RulesSettings from "../RulesSettings/RulesSettings";

import "./EditEventGroup.css";

const EditEventGroup = ({
  groups,
  groupNames,
  selectedGroup,
  selectedFatherGroup,
  columns,
  items,
  onSave,
  onCancel,
  loading,
}) => {
  const { t } = useTranslation();
  const setGroupFields = (selectedGroup, groups) => {
    if (selectedGroup == null) {
      return mapColumnsToGroupFields(t, columns, fatherItems(selectedGroup, items, columns), 'event');
    }
    if (selectedGroup.rules.materialMethod.includes("event")) {
      const sourceGroup = groups.filter(
        group => group.id === selectedGroup.rules.materialMethod.split('-')[1])
      return mapColumnsToGroupFields(t, columns, fatherItems(sourceGroup[0], items, columns), 'event');
    }
    return mapColumnsToGroupFields(t, columns, fatherItems(selectedGroup, items, columns), 'event');
  }
  const groupFields = setGroupFields(selectedFatherGroup, groups)

  const def = {};
  for (let field of groupFields) {
    def[[field.accessor]] = {
      name: field.name,
      accessor: field.accessor,
      values: [],
    };
  }

  const [duplicateNameError, setDuplicateNameError] = useState(null);

  const initialGroupData = _.isNil(selectedGroup)
    ? { ...selectedGroup }
    : { ...selectedGroup };

  const [newGroupData, setNewGroupData] = useState(initialGroupData);

  const updateNewGroupData = (fieldData) => {
    setNewGroupData({ ...newGroupData, ...fieldData });
  };
  const subgroupRulesEditActive = () => {
    if (_.isEmpty(initialGroupData.rules)) {
      return true
    }
    if (initialGroupData.rules.materialMethod.split('-')[0] === 'event') {
      return true
    }
    return false
  }
  const [rulesDisabled, setRulesDisabled] = useState(subgroupRulesEditActive());
  const setMaterialMethod = () => {
    ruleData.materialMethod.split('-')[0] === 'event' ?
      ruleData.materialMethod = 'rules-subgroup' :
      ruleData.materialMethod = 'event-subgroup'
  }


  const initialRuleData = _.isEmpty(initialGroupData.rules)
    ? {
      materialMethod: rulesDisabled ? 'event-subgroup' : 'rules-subgroup',
      aduMeasurementStart: 0,
      aduMeasurementEnd: 90,
      variabilityFactor: 0.5,
      leadTimeFactor: 0.5,
    }
    : { ...initialGroupData.rules };
  const [ruleData, setRuleData] = useState(initialRuleData);
  const materialMethodOptions = [{
    text: t(
      "groupSettings.rules.materialMethod.rulesSubgroup"
    ),
    value: "rules-subgroup",
  }]


  const initialMarked = {};
  groupFields.forEach((field) => (initialMarked[field.accessor] = true));
  const debouncedGroupData = debounce(500, (fieldData) => {
    updateNewGroupData({ ...newGroupData, ...fieldData });
  });
  return (
    <Segment basic className={"edit-group"}>
      <Dimmer.Dimmable dimmed={loading}>
        <Dimmer inverted active={loading}>
          {loading && (
            <div className="ui container">
              <Loader active size="large" />
            </div>
          )}
        </Dimmer>

        <Form
          autoComplete="off"
          onSubmit={() => {
            const filtered = {
              ...newGroupData,
              rules: { ...ruleData },
            };
            Object.keys(filtered).forEach(
              (key) => filtered[key] === undefined && delete filtered[key]
            );

            if (
              !_.has(newGroupData, "id") &&
              _.includes(groupNames, newGroupData.name.trim())
            ) {
              setDuplicateNameError({
                content: t("groupSettings.duplicateGroup"),
                pointing: "above",
              });
              return;
            }
            setDuplicateNameError(null);
            onSave(filtered);
          }}
        >
          <Grid columns={2} stackable>
            <GridColumn stretched mobile={16} largeScreen={8} widescreen={8}>
              <Segment className="name-filters">
                <Grid.Row>
                  <Form.Group className="top-labels" widths={2}>
                    <Form.Input
                      className="group-name-input"
                      required
                      error={duplicateNameError}
                      placeholder="Add a name to this group"
                      name="name"
                      defaultValue={_.get(selectedGroup, "name", "")}
                      onChange={(e) => {
                        debouncedGroupData({ name: e.target.value.trim() });
                      }}
                      label={t("groupSettings.nameLabel")}
                    />
                    <Form.Field className="rules-toggle">
                      <label>{t("groupSettings.modifyRules")}</label>
                      <Radio
                        disabled={!_.isNil(newGroupData.mirrorEventSubgroup)}
                        checked={!rulesDisabled}
                        toggle
                        onChange={() => {
                          setRulesDisabled(!rulesDisabled)
                          setMaterialMethod()
                        }}
                      />
                    </Form.Field>
                  </Form.Group>
                </Grid.Row>

                <Grid columns={2} >
                  {groupFields
                    .map((field) => (
                      <Grid.Column
                        stretched
                        width={10}
                        key={field.accessor}
                      >
                        <label className="filter-tittle" >{field.name}</label>
                        <SubgroupDropdown
                          options={field.options}
                          defaultValues={
                            _.get(selectedGroup, field.accessor, {}).values ||
                            _.get(newGroupData, field.accessor, {}).values
                          }
                          onChange={(options) =>
                            updateNewGroupData({
                              [field.accessor]: _.isEmpty(options)
                                ? {
                                  name: field.name,
                                  values: ["all"],
                                }
                                : {
                                  name: field.name,
                                  values: options,
                                },
                            })
                          }
                          groupType={'event'}
                        />
                      </Grid.Column>
                    ))}
                  <Grid.Column floated="right" verticalAlign="bottom">
                    <div className={"action-buttons"}>

                      <Button disabled={!rulesDisabled} onClick={onCancel}>{t("cancel")}</Button>
                      <Button disabled={!rulesDisabled} positive className="submit-group" type="submit">
                        {t("save")}
                      </Button>
                    </div>
                  </Grid.Column>
                </Grid>
              </Segment>
            </GridColumn>
            <GridColumn mobile={16} largeScreen={8} widescreen={8}>
              <RulesSettings
                selectedGroup={selectedGroup}
                columns={columns}
                items={items}
                ruleData={ruleData}
                setRuleData={setRuleData}
                onCancel={onCancel}
                rulesDisabled={rulesDisabled}
                materialMethodOptions={materialMethodOptions}
              />
            </GridColumn>
          </Grid>
        </Form>
      </Dimmer.Dimmable>
    </Segment>
  );
};

EditEventGroup.propTypes = {
  selectedGroup: PropTypes.object,
  selectedFatherGroup: PropTypes.object,
  columns: PropTypes.object,
  items: PropTypes.array,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  groupNames: PropTypes.arrayOf(PropTypes.string),
  groups: PropTypes.array,
  loading: PropTypes.bool,
};

EditEventGroup.defaultProps = {
  selectedGroup: null,
  selectedFatherGroup: null,
  columns: [],
  items: [],
  groupNames: [],
  onSave: _.noop,
  onCancel: _.noop,
  loading: false,
  groups: [],
};

export default EditEventGroup;
