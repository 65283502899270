import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useTable } from 'react-table'
import PropTypes from 'prop-types'
import Table from '../../../../../DataImport/Table/Table'
import { mapColumnHeaderToReactTableFormat } from '../../../../../../utils/mappers/analytics'
import ModifyActionCell from './ModifyActionCell/ModifyActionCell'


const QuantityTable = ({quantityModifier, materialName})=>{
    const { t } = useTranslation()
    const translateMapper = useMemo(()=>
                                {
                                return({
                                    material_name: t("simulation.materialTitle"),
                                    start_date: t("simulation.startDate"),
                                    end_date: t("simulation.endDate"),
                                    multiplier: t("simulation.quantityMultiplier")})
                                }, [t])

    const columns = useMemo(()=>{
        if(quantityModifier.length){
            const quantityModifierOrder = {start_date:"",end_date: "", multiplier: ""}
            const columns = mapColumnHeaderToReactTableFormat(quantityModifierOrder, translateMapper, "")
            const nameHeader = [{Header: translateMapper.material_name, accessor: "material_name",}]
            return nameHeader.concat(columns)
        }
        return []
    }, [quantityModifier, translateMapper])

    const data = useMemo(()=>{
        return quantityModifier.map((quantityData)=>({...quantityData, 
                                                    material_name: materialName,
                                                    modify: <ModifyActionCell
                                                                data={quantityData}
                                                                type="QuantityTable"
                                                                />
                                                }))
 
    }, [quantityModifier, materialName])

    //It's impossible to have different names of colums and data because of the way react-table works
    const quantityModifierTable = useTable({columns, data})

    return(
        <Table instance={quantityModifierTable}/>
    )
}

QuantityTable.propTypes = {
    quantityModifier: PropTypes.array,
    materialName: PropTypes.string
}


export default QuantityTable;