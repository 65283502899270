import React from "react";
import PropTypes from "prop-types";
import { Button, Icon} from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import _ from "lodash";

export const NewKeyButton = ({
    generateNewKey,
    keyExists,
    configExists,
    
}) => {
    const { t } = useTranslation();
    const buttonMessage = t("account.autoUploadConfig.generateKey");    
    
    return (
        <Button
            className="autoUploadConfigButton"
            disabled={keyExists || !configExists}        
            onClick={() => generateNewKey()}            
            > {buttonMessage} <Icon name='plus'/>
        </Button>)   
}

NewKeyButton.propTypes = {
    generateNewKey: PropTypes.func,
    keyExists: PropTypes.bool,
    configExists: PropTypes.bool,    
  };

  NewKeyButton.defaultProps = {
    generateNewKey: _.noop,
    keyExists: false,
    configExists: false,
};
export default NewKeyButton;