import React from "react"

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, counter: 0 };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }
    componentDidCatch() {
        // reload app in rare case of error in component render
        window.location.reload(false)
    }
    render() {
        if (this.state.hasError) {
            return <></>;
        }
        // eslint-disable-next-line react/prop-types
        return this.props.children;
    }
}

export default ErrorBoundary