import React, { useState } from "react";
import PropTypes from "prop-types";
import { Dropdown } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import "./SuggestedOrderFilter.css"

const SuggestedOrderFilter = ({ column }) => {
  const { t } = useTranslation();
  const { setFilter } = column;
  const [text, setText] = useState(undefined);
  // const color = text && (text.toLowerCase().includes("not") ? "blue" : "green");

  return (
    <Dropdown
      icon={"filter"}
      text={text || t("ordersTable.filters.noFilter")}
      floating
      labeled
      button
      className="icon suggested-order-filter button-content"
    >
      <Dropdown.Menu>
        <Dropdown.Item
          onClick={() => {
            setFilter(undefined);
            setText(null);
          }}
          text={t("ordersTable.filters.noFilter")}
        />
        <Dropdown.Item
          onClick={() => {
            setFilter({ type: "globalFilter", threshold: 1 });
            setText(t("ordersTable.filters.orderRequired"));
          }}
          text={t("ordersTable.filters.orderRequired")}
        />
        <Dropdown.Item
          onClick={() => {
            setFilter({ type: "globalFilter", threshold: 0 });
            setText(t("ordersTable.filters.orderNotRequired"));
          }}
          text={t("ordersTable.filters.orderNotRequired")}
        />
      </Dropdown.Menu>
    </Dropdown>
  );
};

SuggestedOrderFilter.propTypes = {
  column: PropTypes.object,
};

SuggestedOrderFilter.defaultProps = {
  column: {},
};

export default SuggestedOrderFilter;
