import {
  GET_WORKSPACES,
  SELECT_WORKSPACE,
  RESET_LOADING_WORKSPACES_FLAG,
  SET_SELECTOR_DISABLED,
  CREATE_WORKSPACE,
  EDIT_WORKSPACE,
  DELETE_WORKSPACE,
  UPDATE_WORKSPACE_SETTING,
} from "../actions/types";

const initialState = {
  loadingWorkspaces: true,
  workspaces: [],
  currentWorkspace: null,
  workspaceSelectorDisabled: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_WORKSPACE:
      return {
        ...state,
        workspaces: [...state.workspaces, payload],
        loadingWorkspaces: false,
      };
    case EDIT_WORKSPACE:
      return {
        ...state,
        workspaces: state.workspaces.map((workspace) =>
          workspace.id === payload.id ? payload : workspace
        ),
      };
    case DELETE_WORKSPACE:
      if (!payload) {
        return {
          ...state,
        };
      }
      return {
        ...state,
        currentWorkspace: null,
        workspaces: state.workspaces.filter(
          (workspace) => workspace.id !== payload.id
        ),
      };
    case GET_WORKSPACES:
      return {
        ...state,
        workspaces: payload,
        loadingWorkspaces: false,
      };
    case SELECT_WORKSPACE:
      return {
        ...state,
        currentWorkspace: payload,
      };
    case RESET_LOADING_WORKSPACES_FLAG:
      return {
        ...state,
        loadingWorkspaces: true,
      };
    case SET_SELECTOR_DISABLED:
      return {
        ...state,
        workspaceSelectorDisabled: payload,
      };
    case UPDATE_WORKSPACE_SETTING:
      return {
        ...state,
        workspaces: state.workspaces.map((workspace) =>
          workspace.id === payload.id ? { ...payload } : workspace
        ),
        loadingWorkspaces: false,
      };
    default:
      return state;
  }
}
