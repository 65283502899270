import React, { useState, useEffect } from "react"
import { Form, Header, Button, Grid, Input } from "semantic-ui-react"
import { accountRecovery } from "../../../actions/auth"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import "./AccountRecovery.css"
//history is already deprecated in react router v6.
import { useHistory } from "react-router-dom";
import { LOGIN } from "../../Navbar/routes"
import { useTranslation } from "react-i18next"
import { LANGUAGES_ABREVIATIONS } from "../../../utils/constants"


const AccountRecovery = ({ accountRecovery })=>{
    const { spanish, english, italian } = LANGUAGES_ABREVIATIONS
    const { t, i18n } = useTranslation()
    const history = useHistory()
    const [ userEmail, setUserEmail ] = useState("")
    const [ errorEmail, setErrorEmail ] = useState(false)
    const userLanguage = navigator.language.split("-")[0] // navigator.language --> es-Es

    useEffect(()=>{
        if(userLanguage === spanish || userLanguage === english || userLanguage === italian){
            i18n.changeLanguage(userLanguage)
            return
        }
        i18n.changeLanguage(spanish)
        // ignore because spanish, english and italian are constants
        // eslint-disable-next-line
    }, [i18n, userLanguage])


    let regex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/

    const submmitHandler = (e)=>{
        e.preventDefault();
        const isEmail = regex.test(userEmail)
        if(isEmail) {
            accountRecovery(userEmail, userLanguage)
            setErrorEmail(false)
            // history is already deprecated in react router v6.
            history.push(LOGIN)
        }
        if(!isEmail){
            setErrorEmail(true)
        }
    }

    return(
        <Grid verticalAlign='middle' centered className="account-recovery-image">
            <Grid.Row centered>
                <Grid.Column className="account-recovery-container">
                    <Form onSubmit={(e)=>submmitHandler(e)}>
                        <Header as="h2" className="first-header">{t("account.passwordRecovery.message1")}</Header>
                        <Header as="h3" className="second-header">{t("account.passwordRecovery.message2")}</Header>
                        <Form.Field width={6} error={errorEmail}>
                            <label className="account-recovery-input-label">{t("account.emailAddress")}</label>
                            <Input 
                                placeholder={t("account.emailPlaceholder")}
                                onChange={(e)=>setUserEmail(e.target.value)}
                                />
                        </Form.Field>
                        <Button type='submit' className="account-recovery-submit-button">
                                {t("account.passwordRecovery.submit")}
                        </Button>
                    </Form>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}

AccountRecovery.propTypes = {
    accountRecovery: PropTypes.func
}


export default connect(null, { accountRecovery })(AccountRecovery)