import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import _ from "lodash";

import {
    generateAutoUploadConfig,
    getBucketName,
    generateNewJsonKey,       
    updateWorkspaceToAutoConfig,
  } from "../../../actions/adminConsole";
import { getWorkspaces } from "../../../actions/workspace"

import { Grid, Container } from "semantic-ui-react";
import CreateConfigButton from "./subComponents/CreateConfigButton";
import PathsContainer from "./subComponents/PathsContainer";
import NewKeyButton from "./subComponents/NewKeyButton";
import DownloadKeyButton from "./subComponents/DownloadKeyButton";

import { getSafeSelectedWorkspace } from"../../../utils/mappers/user"
import { useTranslation } from "react-i18next";

export const AutoUploadConfig = ({
    currentJsonKey,    
    currentWorkspaceId,    
    generateAutoUploadConfig,
    workspaces,
    getBucketName,
    currentBucketName,
    generateNewJsonKey,    
    configIsProcessing,
    updateWorkspaceToAutoConfig,
    keyName,    
}) => {   
        
    const { t } = useTranslation();    

    let configExists = currentBucketName !== null
    
    const createConfig = (workspace) => {
        generateAutoUploadConfig(workspace)        
    };

    const keyExists = () =>{
        return (Object.keys(currentJsonKey).length !== 0 )        
    };

    const setBucketTag = () => {
        getBucketName()
        
        if (currentBucketName === null){            
            return (
            <Grid.Row>
                <Container>{t("account.autoUploadConfig.noBucket")}</Container>
            </Grid.Row>                
                )
        }        
        return (
            <>
            <Grid.Row>
                <Container>{t("account.autoUploadConfig.bucketName")+': '}</Container>
            </Grid.Row>
            <Grid.Row>
                <Container>{currentBucketName}</Container>
            </Grid.Row>
            </>
              
            )
    }      
    const updateAllWorkspaces = (existingWorkspaces) => {
        existingWorkspaces.map( workspace => updateWorkspaceToAutoConfig(workspace.id))
    }
    if (configExists){
        updateAllWorkspaces(workspaces)
    }    

    return(        
            <Grid celled>
                <Grid.Column width={5}>
                    {setBucketTag()}
                    <Grid.Row>
                        <CreateConfigButton
                                currentWorkspaceId={currentWorkspaceId}
                                createConfig={createConfig}                                
                                configExists={configExists}
                                loaderActive={configIsProcessing} 
                                workspaces={workspaces}                               
                            />
                    </Grid.Row>                  
                    <Grid.Row>
                        <DownloadKeyButton
                                jsonKey={currentJsonKey}
                                keyExists={keyExists()}  
                                keyName={keyName}                                                                        
                            />
                    </Grid.Row>
                    <Grid.Row>
                        <NewKeyButton
                            generateNewKey={generateNewJsonKey}
                            keyExists={keyExists()}
                            configExists={configExists}                            
                        />
                    </Grid.Row>
                    
                </Grid.Column>
                <Grid.Column width={11}>
                    <Grid.Row>
                        <PathsContainer                                
                                workspaces={workspaces}
                            />
                    </Grid.Row>             
                </Grid.Column>
            </Grid>
    )

}

AutoUploadConfig.propTypes = {
    currentJsonKey: PropTypes.object.isRequired,
    firstConfig: PropTypes.bool,
    currentWorkspaceId: PropTypes.number,
    canEditCurrentWorkspace: PropTypes.bool,
    generateAutoUploadConfig: PropTypes.func.isRequired,
    workspaces: PropTypes.array,
    getWorkspaces: PropTypes.func.isRequired,
    currentBucketName: PropTypes.string,
    getBucketName: PropTypes.func.isRequired,
    generateNewJsonKey: PropTypes.func,    
    configIsProcessing: PropTypes.bool,       
    updateWorkspaceToAutoConfig: PropTypes.func,
    keyName: PropTypes.string,
    
  };

  AutoUploadConfig.defaultProps = {
    currentJsonKey: {},
    firstConfig: true,
    currentWorkspaceId: _.noop,
    canEditCurrentWorkspace: false,
    generateAutoUploadConfig: _.noop,    
};

const mapStateToProps = (state) => ({
    currentJsonKey: state.adminConsole.jsonKey,    
    currentBucketName: state.adminConsole.bucketName,
    currentWorkspaceId: getSafeSelectedWorkspace(state),
    workspaces: state.workspace.workspaces,
    configIsProcessing: state.adminConsole.configProcessing,
    keyName: state.adminConsole.keyName,
});

export default connect(mapStateToProps, {
    generateAutoUploadConfig,  
    getBucketName,  
    generateNewJsonKey,
    getWorkspaces,
    updateWorkspaceToAutoConfig,
  })(AutoUploadConfig);