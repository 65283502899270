import React from 'react'
import { Table } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

export const ResultsTable = ({simulationData})=>{
    const { t } = useTranslation()
    const getTranslateKeysFirstTable = {
        "ADU": "detailsPanel.numericalDetails.bufferParameters.average_daily_usage",
        "oh_inv": "simulation.ohInventory",
        "num_open_orders": "simulation.numberOpenOrders",
        "open_orders_quantity": "simulation.openOrdersQuantity",
        "order_frequency": "simulation.orderFrecuency"
    }

    const getTranslateKeysSecondTable = {
        "total_sales_orders": "simulation.totalSalesOrders",
        "SOWD": "simulation.stockOutWithDemand",
        "service_level": "simulation.serviceLevel"
    }

    return(
        simulationData.length > 0
            ? <Table definition>
                <Table.Header>
                    {
                        Object.keys(simulationData[0]).length>1
                        ? (
                            <Table.Row>
                                <Table.HeaderCell>{t("simulation.average")}</Table.HeaderCell>
                                <Table.HeaderCell>{t("simulation.planned")}</Table.HeaderCell>
                                <Table.HeaderCell>{t("simulation.actual")}</Table.HeaderCell>
                                <Table.HeaderCell>%</Table.HeaderCell>
                            </Table.Row>
                        )
                        : null
                    }
                </Table.Header>
                <Table.Body>
                    {
                        Object.keys(simulationData[0]).length>1
                            ? simulationData.map((objectData)=>
                                <Table.Row key={`${objectData.definition}`}>
                                    <Table.Cell>
                                        {t(getTranslateKeysFirstTable[objectData.definition])}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {objectData.planned}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {objectData.actual}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {objectData.percentage}
                                    </Table.Cell>
                                </Table.Row>
                            )
                            : 
                            (Object
                                .values(simulationData)
                                .map((objectData)=>
                                    (<Table.Row key={`${Object.keys(objectData)[0]}`}>
                                        <Table.Cell>
                                            {t(getTranslateKeysSecondTable[Object.keys(objectData)[0]])}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {Object.values(objectData)[0]}
                                        </Table.Cell>
                                    </Table.Row>)
                                )
                            )
                    }
                </Table.Body>
            </Table>
            
            : <p>{t("simulation.noResults")}</p>
    )
}

ResultsTable.propTypes = {
    simulationData: PropTypes.array
}