import React from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";

import { setBatchInventoryMode } from "../../../actions/workspace";
import {
  getBatchInventoryModeValue,
  resolveEditPermission,
} from "../../../utils/mappers/workspace";

import { Modal, Button, Radio } from "semantic-ui-react";

import { useTranslation } from "react-i18next";
import { getSafeSelectedWorkspace } from "../../../utils/mappers/user";

const WorkspaceSettings = ({
  open,
  onClose,
  workspaces,
  currentWorkspaceId,
  setBatchInventoryMode,
  canEditCurrentWorkspace,
}) => {
  const { t } = useTranslation();

  const currentBatchInventoryMode = getBatchInventoryModeValue(
    workspaces,
    currentWorkspaceId
  );

  return (
    <Modal open={open}>
      <Modal.Header>{t("account.workspace.settingsHeader")}</Modal.Header>
      <Modal.Content>
        <Radio
          disabled={!canEditCurrentWorkspace}
          checked={currentBatchInventoryMode}
          toggle
          onChange={(e, data) => {
            setBatchInventoryMode(data.checked);
          }}
          label={t("account.workspace.batchInventoryMode")}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose}>{t("close")}</Button>
      </Modal.Actions>
    </Modal>
  );
};

WorkspaceSettings.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  workspaces: PropTypes.arrayOf(PropTypes.object),
  currentWorkspaceId: PropTypes.number,
  setBatchInventoryMode: PropTypes.func,
  canEditCurrentWorkspace: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  workspaces: state.workspace.workspaces,
  currentWorkspaceId: state.workspace.currentWorkspace,
  canEditCurrentWorkspace:
    state.auth.isConnected &&
    resolveEditPermission(state.auth.user, getSafeSelectedWorkspace(state)),
});

export default connect(mapStateToProps, { setBatchInventoryMode })(
  WorkspaceSettings
);
