import React from "react";
import _ from "lodash";
import axiosWrapper from "../utils/axiosWrapper";
import {
    AUTH_ERROR,
    BEGIN_SINGLE_CALCULATION,
    END_SINGLE_CALCULATION,
    FAILED_SINGLE_CALCULATION,
    BEGIN_SINGLE_CALCULATION_FOR_TABLE_CELL,
    END_SINGLE_CALCULATION_FOR_TABLE_CELL,
    FAILED_SINGLE_CALCULATION_FOR_TABLE_CELL,
    BEGIN_LOAD_DYNAMIC_FIELDS_METADATA,
    END_LOAD_DYNAMIC_FIELDS_METADATA,
    FAILED_LOAD_DYNAMIC_FIELDS_METADATA,
    BEGIN_DYNAMIC_FIELDS_REQUEST,
    FAILED_DYNAMIC_FIELDS_REQUEST,
    BEGIN_GET_DYNAMIC_FIELDS,
    FAILED_GET_DYNAMIC_FIELDS,
    GET_DYNAMIC_FIELDS,
    SAVE_DYNAMIC_FIELD,
    EDIT_DYNAMIC_FIELD,
    DELETE_DYNAMIC_FIELD,
    SET_DRAFT_DYNAMIC_FIELD,
    UPDATE_DRAFT_DYNAMIC_FIELD,
    RESET_DRAFT_DYNAMIC_FIELD,
    CLEAR_SINGLE_CALCULATION,
} from "./types"
import { getItems } from "./items";
import { toast } from "react-toastify";
import ToastI18nWrapper from "../components/ToastI18nWrapper/ToastI18nWrapper";

export const doSingleCalc = (
    currentWorkspaceId,
    formula,
    currentRowId
) => async(dispatch) => {
    const config = {
        headers: {
          "Content-Type": "application/json",
        },
    };

    const body = JSON.stringify({
        current_workspace_id: currentWorkspaceId,
        formula: formula,
        current_row_id: currentRowId
    });

    try {
        dispatch({type: BEGIN_SINGLE_CALCULATION});
        const response = await axiosWrapper.post("/dynamic-fields/single-calc", body, config);
        dispatch({
          type: END_SINGLE_CALCULATION,
          payload: response.data.result
        });
    } catch (error) {
        dispatch({ type: FAILED_SINGLE_CALCULATION, payload: error.message ? error.message : error })
    }
}

export const doSingleCalcForTableCell = (
    currentWorkspaceId,
    formula,
    currentRowId,
    rowIndex,
    columnId
) => async(dispatch) => {
    const config = {
        headers: {
          "Content-Type": "application/json",
        },
    };

    const body = JSON.stringify({
        current_workspace_id: currentWorkspaceId,
        formula: formula,
        current_row_id: currentRowId
    });

    try {
        dispatch({
            type: BEGIN_SINGLE_CALCULATION_FOR_TABLE_CELL, 
            payload: {
                rowIndex: rowIndex,
                columnId: columnId
            }
        });
        const response = await axiosWrapper.post("/dynamic-fields/single-calc", body, config);
        dispatch({
          type: END_SINGLE_CALCULATION_FOR_TABLE_CELL,
          payload: {
            result: response.data.result,
            rowIndex: rowIndex,
            columnId: columnId
          }
        });
    } catch (error) {
        dispatch({ type: FAILED_SINGLE_CALCULATION_FOR_TABLE_CELL, payload: error.message ? error.message : error })
    }
}

export const doLoadMetadata = (
    currentWorkspaceId
) => async(dispatch) => {
    try {
        dispatch({type: BEGIN_LOAD_DYNAMIC_FIELDS_METADATA});
        const response = await axiosWrapper.get(`/dynamic-fields/metadata?current_workspace_id=${currentWorkspaceId}`);
        dispatch({
          type: END_LOAD_DYNAMIC_FIELDS_METADATA,
          payload: response.data.result
        });
    } catch (error) {
        dispatch({ type: FAILED_LOAD_DYNAMIC_FIELDS_METADATA, payload: error.message ? error.message : error })
    }
}

export const doGetDynamicFields = (
    currentWorkspaceId
) => async(dispatch) => {
    if (!currentWorkspaceId) {
        dispatch({
            type: GET_DYNAMIC_FIELDS,
            payload: [],
        });
        return;
    }

    try {
        dispatch({ type: BEGIN_GET_DYNAMIC_FIELDS });
        const response = await axiosWrapper.get(`/dynamic-fields?current_workspace_id=${currentWorkspaceId}`);
        dispatch({
            type: GET_DYNAMIC_FIELDS,
            payload: response.data,
        });
    } catch (error) {
        if (!_.isNil(error.response) && error.response.status === 401) {
            dispatch({ type: AUTH_ERROR });
        }
        dispatch({ type: FAILED_GET_DYNAMIC_FIELDS });
    }
}

export const doSaveDynamicField = (
    currentWorkspaceId,
) => async(dispatch, getState) => {
    const config = {
        headers: {
          "Content-Type": "application/json",
        },
    };

    let dynamicField = getState().dynamicFields.draftDynamicField;
    const newField = {
        current_workspace_id: currentWorkspaceId,
        title: dynamicField.title,
        name: dynamicField.name,
        formula: dynamicField.formula,
    };
    if (dynamicField.singleRowOnly) {
        newField.row_id = dynamicField.row_id;
    }

    const body = JSON.stringify(newField);

    try {
        dispatch({ type: BEGIN_DYNAMIC_FIELDS_REQUEST });
        await axiosWrapper.post("/dynamic-fields", body, config);
        dispatch({ type: SAVE_DYNAMIC_FIELD });

        dispatch(getItems(currentWorkspaceId));
    } catch (error) {
        dispatch({ type: FAILED_DYNAMIC_FIELDS_REQUEST });
        toast.error(<ToastI18nWrapper translateKey={"genericError"} />, {
            toastId: "genericError",
        });
    }
}

export const doUpdateDynamicField = (
    currentWorkspaceId
) => async(dispatch, getState) => {
    const config = {
        headers: {
          "Content-Type": "application/json",
        },
    };

    let dynamicField = getState().dynamicFields.draftDynamicField;
    const updatedField = {
        dynamic_field_id: dynamicField.dynamic_field_id,
        current_workspace_id: currentWorkspaceId,
        title: dynamicField.title,
        name: dynamicField.name,
        formula: dynamicField.formula,
    };
    if (dynamicField.singleRowOnly) {
        updatedField.row_id = dynamicField.row_id;
    }

    const body = JSON.stringify(updatedField);

    try {
        dispatch({ type: BEGIN_DYNAMIC_FIELDS_REQUEST });
        await axiosWrapper.put("/dynamic-fields", body, config);
        dispatch({ type: EDIT_DYNAMIC_FIELD, payload: updatedField });

        dispatch(getItems(currentWorkspaceId));
    } catch (error) {
        dispatch({ type: FAILED_DYNAMIC_FIELDS_REQUEST });
        toast.error(<ToastI18nWrapper translateKey={"genericError"} />, {
            toastId: "genericError",
        });
    }
}

export const doDeleteDynamicField = (
    currentWorkspaceId, dynamicFieldId
) => async(dispatch) => {
    const config = {
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
            current_workspace_id: currentWorkspaceId,
            id: dynamicFieldId
        })
    };

    try {
        dispatch({ type: BEGIN_DYNAMIC_FIELDS_REQUEST });
        const response = await axiosWrapper.delete("/dynamic-fields", config);
        dispatch({ type: DELETE_DYNAMIC_FIELD, payload: response.data });

        dispatch(getItems(currentWorkspaceId));
    } catch (error) {
        dispatch({ type: FAILED_DYNAMIC_FIELDS_REQUEST });    
        toast.error(<ToastI18nWrapper translateKey={"genericError"} />, {
            toastId: "genericError",
        });
    }
}

export const doSetDraftDynamicField = (dynamicField) => async(dispatch) => {
    dispatch({
        type: SET_DRAFT_DYNAMIC_FIELD,
        payload: _.extend(dynamicField, {singleRowOnly: dynamicField.row_id && dynamicField.row_id.trim().length > 0})
    });
}

export const doUpdateDraftDynamicField = (field, value) => async(dispatch) => {
    dispatch({
        type: UPDATE_DRAFT_DYNAMIC_FIELD,
        payload: {
            field: field,
            value: value
        }
    });
}

export const doResetDraftDynamicField = () => async(dispatch) => {
    dispatch({ type: RESET_DRAFT_DYNAMIC_FIELD });
}

export const doClearSingleCalculation = () => async(dispatch) => {
    dispatch({ type: CLEAR_SINGLE_CALCULATION });
}
