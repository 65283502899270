import React from "react"
import PropTypes from "prop-types"
import ModalDeleteData from "./ModalDeleteData/ModalDeleteData"
import ModalQuantityModifier from "./ModalQuantityModifier/ModalQuantityModifier"
import ModalGroupSetting from "../../../../ModalGroupSetting/ModalGroupSetting"
import ModalGreyZone from "../../../../ModalGreyZone/ModalGreyZone"
import { changeSimulationMaterialGroupSetting, changeSimulationGreyZoneArray } from "../../../../../../../actions/analytics"
import { connect } from "react-redux"
import "./ModifyActionCell.css"

const ModifyActionCell = 
({  type, 
    data, 
    changeSimulationMaterialGroupSetting, 
    changeSimulationGreyZoneArray,
    simulationDateRange,
    loadingSimulation,
})=>{

    const renderEditButtons = ()=>{
        if(type==="QuantityTable"){
            return <ModalQuantityModifier data={data}/>
        }

        if(type==="GroupSettingsTable"){
            return <ModalGroupSetting 
                        isModal={true}  
                        disabled={loadingSimulation} 
                        changeGroupSetting={(newGroupSettings)=>{changeSimulationMaterialGroupSetting(newGroupSettings)}}
                        />
        }

        if(type==="GreyZonesTable"){
            return <ModalGreyZone
                        modalData={data} 
                        disabled={loadingSimulation} 
                        changeGreyZone={(newGreyZoneArray)=>{changeSimulationGreyZoneArray(newGreyZoneArray)}}
                        dateRange={simulationDateRange} 
                        />
        }
    }

    return(
        <div className="modifier-table-cell">
            {   
                renderEditButtons()
            } 
            <ModalDeleteData type={type} data={data}/>
        </div>
    )
}

ModifyActionCell.propTypes = {
    type: PropTypes.string,
    data: PropTypes.object,
    changeSimulationMaterialGroupSetting: PropTypes.func,
    changeSimulationGreyZoneArray: PropTypes.func,
    simulationDateRange: PropTypes.object,
    loadingSimulation: PropTypes.bool
}

const mapStateToProps = (state) => ({
    simulationDateRange: state.analytics.simulationDateRange,
    loadingSimulation: state.analytics.loadingSimulation
})

export default connect(mapStateToProps, {changeSimulationMaterialGroupSetting, changeSimulationGreyZoneArray})(ModifyActionCell)