import React from "react";

import axiosWrapper from "../utils/axiosWrapper";
import _ from "lodash";

import { toast } from "react-toastify";

import ToastI18nWrapper from "../components/ToastI18nWrapper/ToastI18nWrapper";

import {
  GET_EVENTS,
  ADD_EVENT,
  EDIT_EVENT,
  REMOVE_EVENT,
  AUTH_ERROR,
  BEGIN_EVENT_FETCH,
  ABORT_EVENTS_REQUEST,
  BEGIN_EVENT_REQUEST,
  SELECT_VISIBLE_WORKSPACE_EVENTS,
} from "./types";

import { mapToEventsArray, mapCalendarEvent } from "../utils/mappers/calendar";

import { toISOStringWithoutTimezone } from "../utils/format"

/*
const getColor = () => {
  const eventColors = ["#8e7fef", "#3788D8", "#2fc44f", "#c93c3c", "#69931c"];
  const getRandomInt = (min, max) => {
    return Math.floor(Math.random() * (max - min)) + min;
  };

  return eventColors[getRandomInt(0, eventColors.length - 1)];
};
*/

export const getEvents = (currentWorkspaceId, inBackground = false) => async (
  dispatch
) => {
  if (!currentWorkspaceId) {
    dispatch({
      type: GET_EVENTS,
      payload: [],
    });
    return;
  }

  try {
    if (!inBackground) {
      dispatch({ type: BEGIN_EVENT_FETCH });
      dispatch({ type: BEGIN_EVENT_REQUEST });
    }
    const response = await axiosWrapper.get(
      `/calendar?current_workspace_id=${currentWorkspaceId}`
    );

    dispatch({
      type: GET_EVENTS,
      payload: mapToEventsArray(response.data, currentWorkspaceId),
    });
  } catch (error) {
    if (!_.isNil(error.response) && error.response.status === 401) {
      toast.error(<ToastI18nWrapper translateKey={"genericError"} />, {
        toastId: "genericError",
      });
    }
    dispatch({ type: ABORT_EVENTS_REQUEST });
  }
};

export const addEvent = (
  currentWorkspaceId,
  name,
  start,
  end,
  qtyPerDay,
  adusPerDay,
  selectedGroups,
  isCurrentOrderTableCommitted
) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({
    current_workspace_id: currentWorkspaceId,
    name,
    start: toISOStringWithoutTimezone(start),
    end: toISOStringWithoutTimezone(end, true),
    allDay: true,
    editable: true,
    //backgroundColor: getColor(),
    create_demand: false,
    create_zone: true,
    quantity_per_day: qtyPerDay,
    adus_per_day: adusPerDay,
    group_id: selectedGroups,
    calculation_required: !isCurrentOrderTableCommitted
  });

  try {
    dispatch({ type: BEGIN_EVENT_REQUEST });
    const response = await axiosWrapper.post("/calendar", body, config);
    dispatch({
      type: ADD_EVENT,
      payload: mapCalendarEvent(response.data),
    });
  } catch (error) {
    dispatch({ type: ABORT_EVENTS_REQUEST });
    if (!_.isNil(error.response) && error.response.status === 401) {
      dispatch({ type: AUTH_ERROR });
    } else {
      toast.error(<ToastI18nWrapper translateKey={"genericError"} />, {
        toastId: "genericError",
      });
    }
  }
};

// todo: refactor to use less parameters
export const editEvent = (
  currentWorkspaceId,
  oldEvent,
  name,
  start,
  end,
  qtyPerDay,
  adusPerDay,
  selectedGroups,
  isCurrentOrderTableCommitted
) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({
    current_workspace_id: currentWorkspaceId,
    event_id: _.get(oldEvent, "id"),
    name,
    start: toISOStringWithoutTimezone(start),
    end: toISOStringWithoutTimezone(end),
    create_demand: false,
    create_zone: true,
    quantity_per_day: qtyPerDay,
    adus_per_day: adusPerDay,
    group_id: selectedGroups,
    calculation_required: !isCurrentOrderTableCommitted
  });

  try {
    dispatch({ type: BEGIN_EVENT_REQUEST });
    const response = await axiosWrapper.put("/calendar", body, config);

    dispatch({
      type: EDIT_EVENT,
      payload: mapCalendarEvent(response.data),
    });
  } catch (error) {
    dispatch({ type: ABORT_EVENTS_REQUEST });
    if (!_.isNil(error.response) && error.response.status === 401) {
      dispatch({ type: AUTH_ERROR });
    } else {
      toast.error(<ToastI18nWrapper translateKey={"genericError"} />, {
        toastId: "genericError",
      });
    }
  }
};

export const removeEvent = (
  currentWorkspaceId,
  eventId,
  isCurrentOrderTableCommitted
) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify({
      event_id: eventId,
      current_workspace_id: currentWorkspaceId,
      calculation_required: !isCurrentOrderTableCommitted
    }),
  };
  try {
    dispatch({
      type: REMOVE_EVENT,
      payload: parseInt(eventId),
    });
    const response = await axiosWrapper.delete("/calendar", config);
    dispatch({
      type: REMOVE_EVENT,
      payload: response.data.id,
    });
  } catch (error) {
    dispatch({ type: ABORT_EVENTS_REQUEST });

    if (!_.isNil(error.response)) {
      if (error.response.status === 401) {
        dispatch({ type: AUTH_ERROR });
      } else if (error.response.status === 404) {
        return;
      } else {
        toast.error(<ToastI18nWrapper translateKey={"genericError"} />, {
          toastId: "genericError",
        });
      }
    } else {
      toast.error(<ToastI18nWrapper translateKey={"genericError"} />, {
        toastId: "genericError",
      });
    }
  }
};


export const setSelectedWorkspaceIds = workspaceIds => dispatch => {

  dispatch({
    type: SELECT_VISIBLE_WORKSPACE_EVENTS,
    payload: workspaceIds
  })
}