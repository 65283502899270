import React, { useEffect, useState } from "react";

import _ from "lodash";

import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  Grid,
  Button,
  Table,
  Checkbox,
  Confirm,
  Loader,
} from "semantic-ui-react";

import {
  getOrganization,
  getRoles,
  addRolesToUser,
  removeRolesFromUser,
  setOpenCalendars,
  deleteUser,
  getInviteLink,
} from "../../../actions/adminConsole";

import { mapRolesToColumProperties } from "../../../utils/mappers/role";
import { getMarkedWorkspaces, hasAdminRole } from "../../../utils/mappers/user";

import WorkspacesDropdown from "./WorkspacesDropdown/WorkspacesDropdown";
import UserInviteModal from "../UserInviteModal/UserInviteModal";

import { useTranslation } from "react-i18next";

import "./UserAccessControl.css";

export const UserAccessControl = ({
  organization,
  workspaces,
  users,
  roleColumnProps,
  getOrganization,
  setOpenCalendars,
  getRoles,
  currentUser,
  addRolesToUser,
  removeRolesFromUser,
  deleteUser,
  getInviteLink,
  loadingOrganization,
  isConnected,
}) => {
  const { t } = useTranslation();

  const [changes, setChanges] = useState({});
  const [showConfirmDeleteUserModal, setShowConfirmDeleteUserModal] = useState(
    false
  );
  const [showInviteLinkModal, setShowInviteLinkModal] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);

  useEffect(() => {
    getOrganization();
    getRoles();
  }, [getOrganization, getRoles]);

  const renderRoleColumn = (user, columnProps) => {
    const { role, type } = columnProps;
    if (type === "checkbox") {
      return (
        <Checkbox
          disabled={!isConnected || currentUser.id === user.id}
          className="admin-toggle"
          onChange={(e, data) =>
            onUserAccessChange(user.id, role, data.checked)
          }
          defaultChecked={hasAdminRole(user)}
        />
      );
    } else if (type === "dropdown") {
      // internally manages checking workspaces where user has this role
      const markedWorkspaces = getMarkedWorkspaces(user.roles, role);
      return (
        <WorkspacesDropdown
          disabled={!isConnected || currentUser.id === user.id}
          className="workspace-dropdown"
          markedWorkspaces={markedWorkspaces}
          workspaces={workspaces}
          onChange={(workspace_id, value) =>
            onUserAccessChange(user.id, role, value, workspace_id)
          }
        />
      );
    }
  };

  const onOrganizationPropertyChange = (propertyName, value) => {
    //console.log(user_id, role.title, value, workspace_id);
    setChanges({
      ...changes,
      [`${propertyName}`]: {
        user_id: null,
        propertyName,
        value,
      },
    });
  };

  const onUserAccessChange = (user_id, role, value, workspace_id = null) => {
    //console.log(user_id, role.title, value, workspace_id);
    setChanges({
      ...changes,
      [`${user_id}-${role.title}-${workspace_id}`]: {
        user_id,
        role_id: role.id,
        value,
        workspace_id,
      },
    });
  };

  const dispatchUpdates = () => {
    const changesGroupedByUserId = _.groupBy(changes, "user_id");

    // if user_id = null -> organization update
    const openCalendarsChange = _.find(changesGroupedByUserId[null], {
      propertyName: "open_calendars",
    });

    if (!_.isEmpty(openCalendarsChange)) {
      setOpenCalendars(openCalendarsChange.value);
    }

    // each key of changesGroupedByUserId holds array of role_assignments
    // classify as add /remove, i.e. 2 lists for every key

    users.forEach((user) => {
      if (!_.isEmpty(changesGroupedByUserId[user.id])) {
        const roleAdditionsList = changesGroupedByUserId[user.id].filter(
          (roleAssignment) => roleAssignment.value === true
        );
        const roleRemovalsList = changesGroupedByUserId[user.id].filter(
          (roleAssignment) => roleAssignment.value === false
        );

        // call appropriate action for each list: {user_id, role_assignment_list}
        if (!_.isEmpty(roleAdditionsList)) {
          addRolesToUser(user.id, roleAdditionsList);
        }
        if (!_.isEmpty(roleRemovalsList)) {
          removeRolesFromUser(user.id, roleRemovalsList);
        }
      }
    });

    // TODO: skip remove if role non existent in first place (compare with users from state)
    setChanges({});
  };

  const shouldDisableAddUsers = () => {
    if (
      !isConnected ||
      _.isEmpty(organization) ||
      _.isEmpty(organization.users)
    ) {
      return true;
    }

    return organization.users.length >= organization.max_users;
  };

  if (loadingOrganization) {
    return (
      <div className="ui container">
        <Loader active size="large" />
      </div>
    );
  }

  return (
    <Grid className="user-access-control">
      {!_.isEmpty(userToDelete) && (
        <Confirm
          open={showConfirmDeleteUserModal}
          onCancel={() => setShowConfirmDeleteUserModal(false)}
          onConfirm={() => {
            deleteUser(userToDelete.id);
            setUserToDelete(null);
            setShowConfirmDeleteUserModal(false);
          }}
          content={`Delete user ${userToDelete.name}?`}
          confirmButton={t("account.adminConsole.deleteUser")}
          cancelButton={t("cancel")}
        />
      )}
      <Grid.Row>
        <div className="user-access-top-buttons">
          {showInviteLinkModal && (
            <UserInviteModal onClose={() => setShowInviteLinkModal(false)} />
          )}
          <Button
            disabled={shouldDisableAddUsers()}
            className="add-user"
            onClick={() => {
              getInviteLink();
              setShowInviteLinkModal(true);
            }}
          >
            {t("account.adminConsole.addUser")}
          </Button>

          <Button
            floated="right"
            className="save-changes"
            disabled={!isConnected || Object.keys(changes).length < 1}
            onClick={dispatchUpdates}
          >
            {t("account.adminConsole.saveChanges")}
          </Button>
        </div>
      </Grid.Row>

      <Grid.Row>
        <Table celled className="user-access-control-table">
          <Table.Header>
            <Table.Row textAlign="center">
              <Table.HeaderCell>{t("account.adminConsole.user")}</Table.HeaderCell>
              {roleColumnProps.map((columnProps) => (
                <Table.HeaderCell key={columnProps.titleKey}>
                  {t(columnProps.titleKey)}
                </Table.HeaderCell>
              ))}
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {users.map((user) => (
              <Table.Row
                textAlign="center"
                key={user.id}
                className="user-access-control-row"
              >
                <Table.Cell>{user.name}</Table.Cell>
                {roleColumnProps.map((columnProps) => (
                  <Table.Cell key={`${user.id}-${columnProps.titleKey}`}>
                    {renderRoleColumn(user, columnProps)}
                  </Table.Cell>
                ))}
                <Table.Cell>
                  <Button
                    disabled={!isConnected || currentUser.id === user.id}
                    size="tiny"
                    className="delete-user"
                    onClick={() => {
                      setUserToDelete(user);
                      setShowConfirmDeleteUserModal(true);
                    }}
                  >
                    {t("account.adminConsole.deleteUser")}
                  </Button>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>

          <Table.Footer>
            <Table.Row textAlign="left" verticalAlign="middle">
              <Table.HeaderCell colSpan={roleColumnProps.length + 2}>
                <Checkbox
                  disabled={!isConnected}
                  className="open-calendars-checkbox"
                  onChange={(e, data) =>
                    onOrganizationPropertyChange("open_calendars", data.checked)
                  }
                  defaultChecked={organization.open_calendars}
                  label={t("account.adminConsole.openCalendars")}
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </Grid.Row>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  organization: state.adminConsole.organization,
  workspaces: state.adminConsole.loadingOrganization
    ? []
    : state.adminConsole.organization.workspaces,
  users: state.adminConsole.loadingOrganization
    ? []
    : state.adminConsole.organization.users,
  currentUser: state.auth.user,
  roleColumnProps: mapRolesToColumProperties(state.adminConsole.roles),
  loadingOrganization: state.adminConsole.loadingOrganization,
  isConnected: state.auth.isConnected,
});

UserAccessControl.propTypes = {
  organization: PropTypes.object.isRequired,
  workspaces: PropTypes.arrayOf(PropTypes.object).isRequired,
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  getOrganization: PropTypes.func.isRequired,
  setOpenCalendars: PropTypes.func.isRequired,
  getRoles: PropTypes.func.isRequired,
  addRolesToUser: PropTypes.func.isRequired,
  removeRolesFromUser: PropTypes.func.isRequired,
  deleteUser: PropTypes.func.isRequired,
  roleColumnProps: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentUser: PropTypes.object.isRequired,
  getInviteLink: PropTypes.func.isRequired,
  loadingOrganization: PropTypes.bool.isRequired,
  isConnected: PropTypes.bool,
};

export default connect(mapStateToProps, {
  getOrganization,
  setOpenCalendars,
  getRoles,
  addRolesToUser,
  removeRolesFromUser,
  deleteUser,
  getInviteLink,
})(UserAccessControl);
