import {
  SET_ALERT,
  REMOVE_ALERT,
  SET_ERROR,
  SET_ERRORS,
  CLEAR_ERRORS
} from "./types";

import uuid from "uuid";

import _ from "lodash";

// requires thunk midleware

export const setAlert = (
  msg,
  alertType = "info",
  timeout = 5000
) => dispatch => {
  const id = uuid.v4();
  dispatch({
    type: SET_ALERT,
    payload: { id, alertType, msg }
  });

  // automatically clear the alert after some time (default 5 secs)
  // remove alert action requires only the alert's id as the payload
  setTimeout(() => dispatch({ type: REMOVE_ALERT, payload: id }), timeout);
};

export const setError = (message, type = "negative") => dispatch => {
  const id = uuid.v4();
  dispatch({
    type: SET_ERROR,
    payload: { id, type, message }
  });
};

export const setErrors = (errors, type = "negative") => dispatch => {
  dispatch({
    type: SET_ERRORS,
    payload: _.isArray(errors)
      ? errors.map(error => ({
          id: uuid.v4(),
          type,
          message: error.message || "<<error.message>>"
        }))
      : [
          {
            id: uuid.v4(),
            type,
            message: "<<error.message>>"
          }
        ]
  });
};

export const clearErrors = () => dispatch => {
  dispatch({
    type: CLEAR_ERRORS,
    payload: null
  });
};
