import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { getSafeSelectedWorkspace } from "../../utils/mappers/user";
import { Loader, Segment, GridColumn, Grid } from "semantic-ui-react";

import OrdersTable from "../OrdersTable/OrdersTable";
import NotificationsPanel from "../NotificationsPanel/NotificationsPanel";
import DetailsPanel from "../DetailsPanel/DetailsPanel";
import ChangeWarning from "../ChangeWarning/ChangeWarning";
import classNames from "classnames";

import { withRouter } from "react-router-dom";
import { history as historyShape } from "react-router-prop-types";

import "./Dashboard.css";
import { ORDER_SCREEN } from "../Navbar/routes";

export const Dashboard = ({
  user,
  loadingItems,
  hasSelectedRow,
  items,
  currentWorkspaceId,
  loadingDataset,
  loadingUpdate,
  history,
}) => {
  const { pathname } = history.location;

  const { t } = useTranslation();

  const cW = {
    notif: { mobile: 4, largeScreen: 2 },
    orders: hasSelectedRow ? { mobile: 12, largeScreen: 10 } : { mobile: 12, largeScreen: 12 },
    details: hasSelectedRow ? { mobile: 16, largeScreen: 4 } : { mobile: 16, largeScreen: 2 },
  };

  const renderLoaderOrTable = () => {
    if (loadingItems || loadingDataset || (_.isEmpty(items) && loadingUpdate)) {
      return pathname === ORDER_SCREEN ? <Loader active size="large" /> : null;
    }

    if (_.isEmpty(items)) {
      return pathname === ORDER_SCREEN ? (
        <Segment placeholder>
          <h3>{t("noItems")}</h3>
        </Segment>
      ) : null;
    }

    return <OrdersTable />;
  };

  if (_.isNil(user)) {
    return null;
  }

  if (!currentWorkspaceId) {
    return pathname === ORDER_SCREEN ? (
      <div className="ui container container-space">
        <Segment placeholder style={{ minHeight: "350px" }}>
          <h2>{t("workspaceRequired")}</h2>
        </Segment>
      </div>
    ) : null;
  }

  return (

    // Have to be explicit and not just next key breakpoints for some reason.
    <div
      className={classNames({
        "dashboard-container-hidden": pathname !== ORDER_SCREEN
      }, "grid", "top-dashboard-grid")}
    >
      <Grid relaxed stackable className="dashboard">
        <GridColumn mobile={cW.notif.mobile} tablet={cW.notif.mobile} computer={cW.notif.mobile} largeScreen={cW.notif.largeScreen} widescreen={cW.notif.largeScreen} >
          <ChangeWarning />
          <NotificationsPanel />
        </GridColumn>
        <GridColumn mobile={cW.orders.mobile} tablet={cW.orders.mobile} computer={cW.orders.mobile} largeScreen={cW.orders.largeScreen} widescreen={cW.orders.largeScreen} className="orders-table-container">
          {renderLoaderOrTable()}
        </GridColumn>
        <GridColumn className="details-panel-column" mobile={cW.details.mobile} tablet={cW.details.mobile} computer={cW.details.mobile} largeScreen={cW.details.largeScreen} widescreen={cW.details.largeScreen}>
          <DetailsPanel />
        </GridColumn>
      </Grid>
    </div>
  );
};

Dashboard.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  loadingItems: PropTypes.bool,
  loadingDataset: PropTypes.bool,
  loadingUpdate: PropTypes.bool,
  hasSelectedRow: PropTypes.bool,
  currentWorkspaceId: PropTypes.number,
  history: historyShape,
  user: PropTypes.object,
};

Dashboard.defaultProps = {
  items: [],
  loadingItems: false,
  hasSelectedRow: false,
  loadingUpdate: false,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  items: state.items.items,
  loadingItems: state.items.loadingFetch,
  hasSelectedRow:
    !_.isEmpty(state.items.items) && !_.isNil(state.ordersTable.selectedRow),
  currentWorkspaceId: getSafeSelectedWorkspace(state),
  loadingDataset: state.dataset.loading,
  loadingUpdate: state.ordersTable.loadingUpdate
});

export default connect(mapStateToProps, null )(withRouter(Dashboard));
