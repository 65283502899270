import React, { useRef, useEffect, useState } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";

import classNames from "classnames";

import { Dropdown, Popup } from "semantic-ui-react";

import { setCurrentWorkspace } from "../../actions/workspace";

import { mapCurrentUserToWorkspaceOptions } from "../../utils/mappers/workspace";

import { useTranslation } from "react-i18next";

import "./WorkspaceSelector.css";
import { abbreviateNumber } from "../../utils/format";

export const WorkspaceSelector = ({
  workspaceSelectorDisabled,
  setCurrentWorkspace,
  currentWorkspace,
  workspaces,
  loadingWorkspaces,
  loadingUser,
  isConnected,
}) => {
  
  const { t } = useTranslation();
  const ref = useRef(null) 
  const [ dataWidth, setDataWidth ] = useState({widthWindow: 0, widthContainer: 0, selectedItem: ""})

  useEffect(() => {
    window.addEventListener('resize', () => {
      setDataWidth((dataWidth)=>({...dataWidth, widthWindow: window.innerWidth}))
    });
  }, []);

  useEffect(()=>{
    setDataWidth((dataWidth)=>({...dataWidth, widthContainer: ref.current.offsetWidth}))
  }, [dataWidth.widthWindow, dataWidth.widthContainer])

  const analizeLongNames = ()=>{
    let resultArray = []
    for(let i=0; i< workspaces.length; i++){
      const workspaceNames = workspaces[i].text
      const indexToCut = abbreviateNumber(dataWidth.widthContainer * 0.073)
      const newName = workspaceNames.length > indexToCut ? (workspaceNames.slice(0, indexToCut)+'...') : workspaceNames
      resultArray.push({key: workspaces[i].value, text: newName, value: workspaces[i].value, longValue: workspaceNames})
    }
    return resultArray
  }

  const currentName = ()=>{
    if(!dataWidth.selectedItem){
      const currentWorkspaceData = analizeLongNames().filter((wData)=> wData.key === currentWorkspace)[0]
      const currentWorkspaceName = typeof(currentWorkspaceData) === 'object' 
                                      ? currentWorkspaceData.text 
                                      : t("account.adminConsole.noWorkspaceFoundShort")
                                      
      return currentWorkspaceName
    }
    return  dataWidth.selectedItem
  }
  
  const renderMenuItem = (shortName)=>{
    return <Dropdown.Item 
        key={`${shortName.text} +-:-+ ${shortName.key}`} 
        value={`${shortName.text} +-:-+ ${shortName.key}`} 
        onClick={(e, data)=>{
          const selectedWorkspaceData = data.value.split(' +-:-+ ')
          const selectedWorkspaceName = selectedWorkspaceData[0]
          const selectedWorkspaceId = parseInt(selectedWorkspaceData[1])
          setDataWidth((dataWidth)=>({...dataWidth, selectedItem: selectedWorkspaceName}))
          if(selectedWorkspaceId !== currentWorkspace){
             setCurrentWorkspace(parseInt(selectedWorkspaceData[1]))
          }
        }}
        active={currentName() === shortName.text ? true: false}
        >
            {shortName.text}
    </Dropdown.Item>
  }

  const disableWorkspaceDropdown = !isConnected ||
                          workspaceSelectorDisabled ||
                          currentWorkspace === null ||
                          loadingUser ||
                          loadingWorkspaces

  return (
    <div className="workspace-selector" ref={ref}>
      <div
        className={classNames("workspace-selector-label", {
          disabled: workspaceSelectorDisabled,
        })}
      >
        {t("currentWorkspace")}
      </div>

      <Dropdown
        loading={loadingWorkspaces || loadingUser}
        button
        fluid
        className="medium selection"
        disabled={disableWorkspaceDropdown}
        text={currentName()}
        scrolling
        placeholder={t("account.adminConsole.noWorkspaceFoundShort")}
      >
          <Dropdown.Menu
            className="menu_box"
            >
          {
            analizeLongNames().map((shortName)=>{
                return(
                    shortName.text.indexOf('...')>0
                    ? (
                        <Popup on='hover' 
                          key={shortName.longValue} 
                          basic
                          //popperModifiers allow you to modify popup placement when you have near components
                          popperModifiers={{ preventOverflow: { padding: {top: -100, right: -1000}} }}
                          content={shortName.longValue}
                          trigger={renderMenuItem(shortName)} />
                      )
                    : (renderMenuItem(shortName))
                )
            })
          }
        </Dropdown.Menu>
      </Dropdown> 
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentWorkspace: state.workspace.currentWorkspace, //getSafeSelectedWorkspace(state),
  workspaceSelectorDisabled: state.workspace.workspaceSelectorDisabled,
  user: state.auth.user,
  loadingUser: state.auth.loading,
  workspaces: mapCurrentUserToWorkspaceOptions(
    state.workspace.workspaces,
    state.auth.user
  ),
  loadingWorkspaces: state.workspace.loadingWorkspaces,
  isConnected: state.auth.isConnected,
});

WorkspaceSelector.propTypes = {
  disabled: PropTypes.bool,
  workspaceSelectorDisabled: PropTypes.bool,
  setCurrentWorkspace: PropTypes.func.isRequired,
  currentWorkspace: PropTypes.number,
  user: PropTypes.object,
  loadingUser: PropTypes.bool,
  workspaces: PropTypes.arrayOf(PropTypes.object),
  loadingWorkspaces: PropTypes.bool,
  isConnected: PropTypes.bool,
};

WorkspaceSelector.defaultProps = {
  workspaceSelectorDisabled: false,
};

export default connect(mapStateToProps, { setCurrentWorkspace })(
  WorkspaceSelector
);
