import React from "react";
import PropTypes from "prop-types";
import { Button, Loader } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
export const CreateConfigButton = ({
    currentWorkspaceId,
    createConfig,
    configExists,
    loaderActive,
    workspaces,

}) => {
    const { t } = useTranslation();
    const buttonMessage = t("account.autoUploadConfig.createNewConfig");
    const workspacesExist = () =>{
        return workspaces.length !== 0
    }       
    
    return (
        <Button
            className={'autoUploadConfigButton'}
            disabled={configExists || !workspacesExist()}            
            onClick={() => createConfig(currentWorkspaceId)}            
            > {buttonMessage} <Loader active={loaderActive} size={"tiny"} inline={true}/>  
        </Button>
    )   
}

CreateConfigButton.propTypes = {
    currentWorkspaceId: PropTypes.number,
    createConfig: PropTypes.func,
    configExists: PropTypes.bool,
    loaderActive: PropTypes.bool,
    workspaces: PropTypes.array,
    
  };

CreateConfigButton.defaultProps = {
    currentWorkspaceId: _.noop,
    createConfig: _.noop,
    configExists: _.noop,
    loaderActive: false,
};
export default CreateConfigButton;  