import React, { useState } from "react";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";

import { Modal, Header, Button, List, Segment } from "semantic-ui-react";

const UploadErrorsModal = ({ filename, errors }) => {
  const { t } = useTranslation();
  const [showUploadErrorsModal, setShowUploadErrorsModal] = useState(false);
  return (
    <Modal
      trigger={<Button onClick={() => {setShowUploadErrorsModal(true)}}> {t("dataImport.uploadLog.errors")} </Button>}
      open={showUploadErrorsModal}
      onClose={() => {setShowUploadErrorsModal(false)}}
    >
      <Header>
        {t("dataImport.uploadLog.errors")}: {filename}
      </Header>
      <Modal.Content>
        <Modal.Description>
          <List relaxed>
            {errors.map(error => (
              <List.Item key={error}>
                <Segment>
                  <List.Content>{error}</List.Content>
                </Segment>
              </List.Item>
            ))}
          </List>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => {setShowUploadErrorsModal(false)}}>{t("close")}</Button>
      </Modal.Actions>
    </Modal>
  );
};

UploadErrorsModal.propTypes = {
  filename: PropTypes.string.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default UploadErrorsModal;
