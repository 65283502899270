import React, { useRef, useState } from 'react';
import UploadButton from './UploadButton';
import NewMasterFileWarning from '../NewMasterModal/NewMasterModal';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const MasterUploadButton = ({
  fileType,
  hasMasterData,
  // canEditCurrentWorkspace,
  // isProcessingFileData,
  onFileAdded
}) => {

  const [showMasterUploadWarning, setShowMasterUploadWarning] = useState(true);
  const [masterUploadFlag, setMasterUploadFlag] = useState(false);
  const { t } = useTranslation();
  const fileInputReferences = { master: useRef() };

  const openFileChooser = (fileType) => {
    if (hasMasterData && !masterUploadFlag){
      setMasterUploadFlag(true);
    }
    else if (hasMasterData && masterUploadFlag){
      setShowMasterUploadWarning(true);
    }
    else if (!hasMasterData){
      fileInputReferences[fileType].current.click();
    }
  };

  const uploadNewMaster = (fileType) => {
    setShowMasterUploadWarning(false);
    fileInputReferences[fileType].current.click();
  };

  const activateNewMasterWarning = (fileType) =>{
    if (hasMasterData && masterUploadFlag) {
      return (<NewMasterFileWarning
        isWarningActive={showMasterUploadWarning}
        closeWarning={() => setShowMasterUploadWarning(false)}
        uploadNewMaster={()=> {uploadNewMaster(fileType);}}/>);
    }
    return null;
  };

  // let buttonDisabled = true
  // if(canEditCurrentWorkspace && !isProcessingFileData){
  //   buttonDisabled = false
  // }
  // if(canEditCurrentWorkspace && isProcessingFileData){
  //   buttonDisabled = true
  // }

  return (
    <>
      {activateNewMasterWarning(fileType)}
      <UploadButton
        disabled={false}
        text={t('dataImport.uploadNew')}
        fileInputRef={fileInputReferences[fileType]}
        onFileAdded={onFileAdded}
        openFileDialog={() => openFileChooser(fileType)}
      />
    </>
  );
};

MasterUploadButton.propTypes = {
  fileType: PropTypes.string,
  hasMasterData: PropTypes.bool,
  canEditCurrentWorkspace: PropTypes.bool,
  isProcessingFileData: PropTypes.bool,
  onFileAdded: PropTypes.func
};

export default MasterUploadButton;