import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Button, Icon, Popup, Segment, Container} from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import "./TokenContainer.css";
import { visibleToken } from "./utils";

export const NewTokenContainer = (
    newToken,
) => {
    
    const { t } = useTranslation();
    const tokenValue = !_.isNil(newToken.newToken)  ? newToken.newToken: null
    const copyToken = () => {
            if (tokenValue !== null){
            navigator.clipboard.writeText(tokenValue)
        }
    }
    return (
        <div >
            <Segment className="token-container" clearing  content={tokenValue}>
                <div>
                <Container className="token-container" textAlign='left'>
                    {_.isNil(tokenValue) ? '' : visibleToken(tokenValue).map(
                        tokenSlice => 
                        <div key={visibleToken(tokenValue).indexOf(tokenSlice)} >{tokenSlice}</div>)}
                <Popup content={"Token Copied"} trigger={
                    <Button floated="right"
                        disabled={_.isNil(newToken.newToken)}
                        onClick={() => {copyToken()}}
                        >{t("account.adminConsole.copy")}
                    <Icon name="copy"/>
                    </Button>} 
                />
            </Container>
            </div>
            </Segment>
         </div>
      )
      
}

NewTokenContainer.propTypes = {
    newToken: PropTypes.string,
  };

  NewTokenContainer.defaultProps = {
    newToken: _.noop,
};
export default NewTokenContainer;

