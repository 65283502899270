import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
//import moment from "moment";
import { connect } from "react-redux";
import { addEvent, editEvent, removeEvent, setSelectedWorkspaceIds } from "../../actions/calendar";
import { getWorkspaces } from "../../actions/workspace";
//import { dateInputFormat } from "../../utils/format";
import { warmupHighMemService } from "../../actions/warmup";
import { getSafeSelectedWorkspace } from "../../utils/mappers/user";
import { resolveEditPermission } from "../../utils/mappers/workspace";
import { useTranslation } from "react-i18next";
//import EventCalendar from "./EventModal/EventCalendar/EventCalendar";
//import EventMenu from "./EventModal/EventMenu/EventMenu";
import EventCalendar from "./EventCalendar/EventCalendar";
import EventMenu from "./EventMenu/EventMenu";

// import FullCalendar from "@fullcalendar/react";
// import allLocales from "@fullcalendar/core/locales-all";
// import dayGridPlugin from "@fullcalendar/daygrid";
// import timeGridPlugin from "@fullcalendar/timegrid";
// import interactionPlugin from "@fullcalendar/interaction";

import {
  //Button,
  Segment,
  Grid,
  //Checkbox,
  //Menu,
  //Loader,
  //Dimmer,
} from "semantic-ui-react";

import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";

import EventModal from "./EventModal/EventModal";
import GroupsWarning from "./GroupsWarning/GroupsWarning";

import "./CalendarSettings.css";

// const defaultWorkingDays = [2, 3, 4, 5];
// const defaultOrderingDays = [1, 6];

export const CalendarSettings = ({
  currentWorkspaceId,
  canEditCurrentWorkspace,
  //events,
  //loadingPutPost,
  //loadingFetch,
  addEvent,
  editEvent,
  removeEvent,
  workspaces,
  groups,
  isOrdersTableCommitted,
  warmupHighMemService,
  //selectedWorkspaceIds,
  //setSelectedWorkspaceIds
}) => {
  const { t } = useTranslation();

  const [openNewEventModal, setOpenNewEventModal] = useState(false);
  const [openEditEventModal, setOpenEditEventModal] = useState(false);
  const [clickedDate, setClickedDate] = useState(null);
  const [clickedEvent, setClickedEvent] = useState(null);
  const [showWarning, setShowWarning] = useState(false)
  const [warningShowed,  setWarningShowed] = useState(false)

  useEffect(() => {
    warmupHighMemService();
  }, [warmupHighMemService]);


  // const customDayRender = (dayRenderInfo) => {
  //   if (_.includes(defaultWorkingDays, dayRenderInfo.date.getDay())) {
  //     dayRenderInfo.el.classList.add("working-day");
  //   } else if (_.includes(defaultOrderingDays, dayRenderInfo.date.getDay())) {
  //     dayRenderInfo.el.classList.add("ordering-day");
  //   }
  // };

  // const getFixedEnd = (start, end) => {
  //   if (_.isObject(end)) {
  //     return moment(
  //       dateInputFormat(end),
  //       "YYYY-MM-DD"
  //     ).format();
  //   }

  //   return moment(dateInputFormat(start), "YYYY-MM-DD")
  //     .add(1, "days")
  //     .format();
  // };

  if (!currentWorkspaceId || workspaces.length < 1) {
    return (
      <div className="ui container container-space">
        <Segment placeholder style={{ minHeight: "350px" }}>
          <h2>{t("workspaceRequired")}</h2>
        </Segment>
      </div>
    );
  }

  // const filterEvents = () => {
  //   const filtered = [];
  //   selectedWorkspaceIds.forEach((id) => {
  //     filtered.push(...events.filter((event) => event.workspace_id === id));
  //   });

  //   return filtered;
  // };

  return (
    <>
      {canEditCurrentWorkspace && openNewEventModal && (
        <EventModal
          groups={groups}
          event={{ start: clickedDate }}
          onSubmit={(
            title,
            start,
            end,
            qtyPerDay,
            adusPerDay,
            selectedGroups
          ) => {
            addEvent(
              currentWorkspaceId,
              title,
              start,
              end,
              qtyPerDay,
              adusPerDay,
              selectedGroups,
              isOrdersTableCommitted
            );
            setOpenNewEventModal(false);
          }}
          onClose={() => {
            setOpenNewEventModal(false)
            setWarningShowed(false)
          }}
          showGroupWarning={()=> setShowWarning(true)}
          warningShowed={warningShowed}
        />
      )}

      {canEditCurrentWorkspace && openEditEventModal && (
        <EventModal
          groups={groups}
          event={clickedEvent}
          onSubmit={(
            title,
            start,
            end,
            qtyPerDay,
            adusPerDay,
            selectedGroups
          ) => {
            editEvent(
              currentWorkspaceId,
              clickedEvent,
              title,
              start,
              end,
              qtyPerDay,
              adusPerDay,
              selectedGroups,
              isOrdersTableCommitted
            );
          }}
          onClose={() => {
            setOpenEditEventModal(false)
            setWarningShowed(false)
           }}
          onDelete={(id) =>
            removeEvent(currentWorkspaceId, id, isOrdersTableCommitted)
          }
          showGroupWarning={()=> setShowWarning(true)}
          warningShowed={warningShowed}
        />
      )}
      <GroupsWarning
        isWarningActive={showWarning && !warningShowed}
        closeWarning={() => {
          setShowWarning(false)
          setWarningShowed(true)}}      
      />
      <Segment basic secondary className="segement-calendar-container">
        <Grid stackable relaxed>
          <Grid.Row centered>
            <Grid.Column widescreen={13}>
              <Grid.Row centered columns={2} className="calendar-settings-container">
                <EventMenu 
                  clickDate={(date)=> setClickedDate(date)}
                  openNewEventModal={(open)=> setOpenNewEventModal(open)}
                  />

                <EventCalendar
                  clickDate={(date) => setClickedDate(date)}
                  openNewEventModal={(open)=> setOpenNewEventModal(open)}
                  clickEvent={(event) => setClickedEvent(event)}
                  openEditEventModal={(open)=> setOpenEditEventModal(open)}
                  />  
              </Grid.Row>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </>
  );
};

CalendarSettings.propTypes = {
  events: PropTypes.arrayOf(PropTypes.object),
  addEvent: PropTypes.func,
  editEvent: PropTypes.func,
  removeEvent: PropTypes.func,
  getWorkspaces: PropTypes.func,
  currentWorkspaceId: PropTypes.number,
  workspaces: PropTypes.arrayOf(PropTypes.object),
  groups: PropTypes.arrayOf(PropTypes.object),
  loadingPutPost: PropTypes.bool,
  loadingFetch: PropTypes.bool,
  isOrdersTableCommitted: PropTypes.bool,
  canEditCurrentWorkspace: PropTypes.bool,
  warmupHighMemService: PropTypes.func,
  selectedWorkspaceIds: PropTypes.arrayOf(PropTypes.number),
  setSelectedWorkspaceIds: PropTypes.func
};

CalendarSettings.defaultProps = {
  events: [],
  workspaces: [],
  groups: [],
  getEvents: _.noop,
  getGroups: _.noop,
  addEvent: _.noop,
  editEvent: _.noop,
  removeEvent: _.noop,
  loadingPutPost: false,
  loadingFetch: false,
  canEditCurrentWorkspace: false,
  warmupHighMemService: _.noop,
  selectedWorkspaceIds: []
};

const mapStateToProps = (state) => ({
  events: state.calendar.events,
  currentWorkspaceId: getSafeSelectedWorkspace(state),
  canEditCurrentWorkspace:
    state.auth.isConnected &&
    resolveEditPermission(state.auth.user, getSafeSelectedWorkspace(state)),
  workspaces: state.workspace.workspaces,
  groups: state.groupSettings.groups,
  loadingPutPost: state.calendar.loadingPutPost,
  loadingFetch: state.calendar.loadingFetch,
  isOrdersTableCommitted: state.ordersTable.isCommitted,
  selectedWorkspaceIds: state.calendar.selectedWorkspaceIds
});

export default connect(mapStateToProps, {
  addEvent,
  editEvent,
  removeEvent,
  getWorkspaces,
  warmupHighMemService,
  setSelectedWorkspaceIds
})(CalendarSettings);
