import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Icon} from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import "./button.css"

export const DownloadKeyButton = ({
    jsonKey,
    keyExists,
    keyName,   
        
}) => {
    const { t } = useTranslation();
    const buttonMessage = t("account.autoUploadConfig.downloadKey");
    const [singleDownload, setSingleDownload] = useState(true) 
       
    return (
        <Button
            className="autoUploadConfigButton"
            disabled={!(keyExists && singleDownload)}
            href={`data:text/json;charset=utf-8,${encodeURIComponent(
                JSON.stringify(jsonKey)
              )}`}            
            download={keyName}
            onClick={ () => setSingleDownload(false) }                    
            > {buttonMessage} <Icon name='download'/> 
        </Button>
        )  
}

DownloadKeyButton.propTypes = {
    jsonKey: PropTypes.object,
    keyExists: PropTypes.bool,
    keyName: PropTypes.string,      
  };

DownloadKeyButton.defaultProps = {
    jsonKey: _.noop,
    keyExists: _.noop,    
};
export default DownloadKeyButton;  