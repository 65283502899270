import React, { useState } from "react";
import PropTypes from "prop-types";

import _ from "lodash";

import { useTranslation } from "react-i18next";

import { Confirm, Card, Button, Popup, Divider, List } from "semantic-ui-react";

import "./Group.css";

const Group = ({
  editable,
  groupProps,
  onEditClick,
  onRemoveClick,
  onEditEventGroup,


}) => {
  const { t } = useTranslation();

  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const computedName = groupProps.name;

  const eventGroupButton = (groupInfo) => {
    if (!groupInfo.rules.materialMethod.includes("event") && !groupInfo.rules.materialMethod.includes("rules")) {
      return (
        <Popup
          trigger={
            <Button
              basic
              icon="sitemap"
              onClick={onEditEventGroup}
            />
          }
          content={t("groupSettings.createEventGroup")}
        />
      )
    }
    return
  }

  return (
    <Card>
      <Confirm
        open={showConfirmDelete}
        onCancel={() => setShowConfirmDelete(false)}
        onConfirm={() => {
          setShowConfirmDelete(false);
          onRemoveClick();
        }}
        content={`Delete ${computedName}?`}
      />
      <Card.Content>
        <Card.Header>{computedName}</Card.Header>
        <br />
        {!_.isEmpty(groupProps.description) && (
          <>
            <span>{groupProps.description}</span>
            <Divider />
          </>
        )}
        <List divided relaxed className={"group-props"}>
          {Object.entries(groupProps)
            .filter(
              (entry) =>
                entry[0] !== "id" &&
                entry[0] !== "name" &&
                entry[0] !== "description" &&
                entry[0] !== "number" &&
                entry[0] !== "rules"
            )
            .map((entry) => {
              const key = entry[0];
              const propertyData = entry[1];
              let values;
              if (_.isArray(propertyData.values)) {
                values = propertyData.values
                  .map((value) =>
                    value === "All" || value === "all"
                      ? t("groupSettings.all")
                      : value
                  )
                  .join(", ");
              } else {
                values =
                  propertyData.values === "All" || propertyData.values === "all"
                    ? t("groupSettings.all")
                    : propertyData.values;
              }

              const name = _.includes(
                [
                  "material_location",
                  "material_unitof_measure",
                  "material_type",
                ],
                key
              )
                ? t(`ordersTable.columns.${key}`)
                : propertyData.name;

              return (
                <List.Item key={key}>
                  <strong>{name}</strong>: {values}
                </List.Item>
              );
            })}

          {Object.entries(groupProps.rules).map((entry) => {
            const key = entry[0];
            let value = entry[1];
            let name = t(`groupSettings.rules.${key}`);
            let optionalUnits = null;
            if (typeof (value) == "string") {
              if (value.includes('event')) {
                value = 'event'
              }
              if (value.includes('rules')) {
                value = 'rules'
              }
            }

            if (key === "materialMethod") {
              value = t(`groupSettings.rules.materialMethod.${value}`);
              name = t("groupSettings.rules.materialMethod.title");
            } else if (key === "variabilityFactor") {
              name = t("groupSettings.rules.variability.title");
            } else if (key === "leadTimeFactor") {
              name = t("groupSettings.rules.leadTime.title");
            } else if (
              key === "aduMeasurementEnd" ||
              key === "aduMeasurementStart"
            ) {
              name = `${t("groupSettings.rules.aduGroupDisplayTitle")} ${name}`;
              optionalUnits = t("groupSettings.rules.aduMeasurementUnit");
            }

            return (
              <List.Item key={key}>
                <strong>{name}</strong>: {value} {optionalUnits}
              </List.Item>
            );
          })}
        </List>
      </Card.Content>
      <Card.Content extra>
        <div className="group-card-footer">
          {eventGroupButton(groupProps)}
          <Popup
            trigger={
              <Button
                disabled={!editable}
                basic
                icon="pencil"
                onClick={onEditClick}
              />
            }
            content={t("groupSettings.editGroup")}
          />
          <Popup
            trigger={
              <Button
                disabled={!editable}
                basic
                icon="close"
                onClick={() => setShowConfirmDelete(true)}
              />
            }
            content={t("groupSettings.removeGroup")}
          />
        </div>
      </Card.Content>
    </Card>
  );
};

Group.propTypes = {
  editable: PropTypes.bool,
  groupProps: PropTypes.object,
  onEditClick: PropTypes.func,
  onRemoveClick: PropTypes.func,
  onEditEventGroup: PropTypes.func,
  groups: PropTypes.array,
};

Group.defaultProps = {
  editable: true,
  groupProps: {},
  onEditClick: _.noop,
  onRemoveClick: _.noop,
  onEditEventGroup: _.noop,
  groups: [],
};

export default Group;
