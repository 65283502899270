import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const BatchStatusCell = ({ cell: { value } }) => {
  const { t } = useTranslation();
  return <>{t(`retail.batchStatus.${value}`)}</>;
};

BatchStatusCell.propTypes = {
  cell: PropTypes.object,
};

export default BatchStatusCell;
