import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Modal, Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import './SideEffectsModal.css';

const SideEffectsModal = ({
  isWarningActive,
  closeWarning,
  
}) => {

  const { t } = useTranslation();

  return (
    <Modal
      className='sideEffectsModal'
      open={isWarningActive}>
      <Modal.Header>{t('dataImport.warning')}</Modal.Header>
      <Modal.Content className='ui warning message sideEffectsModal'>{t('groupSettings.sideEffectsWarning')}</Modal.Content>
      <Modal.Actions>
        <Button
          className='sideEffectsModalContinueButton'
          active={true}
          onClick={closeWarning}>
          {t('confirm')}
        </Button>        
      </Modal.Actions>
    </Modal>

  );
};

SideEffectsModal.propTypes = {
  isWarningActive: PropTypes.bool,
  closeWarning: PropTypes.func,
  uploadNewMaster: PropTypes.func
};

SideEffectsModal.defaultProps = {
  isWarningActive: false,
  closeWarning: _.noop,
  uploadNewMaster: _.noop
};

export default SideEffectsModal;
