import _ from "lodash"
import queryString from 'query-string'

export const getJWTokenFromUrl = (urlQueryParameters)=>{
    const parameterObject = queryString.parse(urlQueryParameters);
    if(_.isEmpty(parameterObject) || _.isEmpty(parameterObject["code"])){
        return ''
    }
    if(parameterObject["code"]){
        return parameterObject["code"]
    }
}