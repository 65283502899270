import React from 'react'
import { Button, Modal } from 'semantic-ui-react'
import { connect } from 'react-redux'
import PropTypes from "prop-types"
import QuantityModifier from '../../../../../QuantityModifier/QuantityModifier'
import { useTranslation } from 'react-i18next'
import "./ModalQuantityModifier.css"

const ModalQuantityModifier = 
({ 
    simulationDateRange, 
    data,
    loadingSimulation,
})=>{

    const [openModal, setOpenModal] = React.useState(false)
    const { t } = useTranslation()      
    const buttonValueFormat = JSON.stringify(data)

    const triggerComponent = ()=>{
        return  <Button 
                    icon="pencil" 
                    positive
                    disabled={loadingSimulation}
                    value={buttonValueFormat}
                    />
    }

    return (
        <Modal
            onClose={() => setOpenModal(false)}
            onOpen={() => setOpenModal(true)}
            open={openModal}
            trigger={triggerComponent()}
        >
        <Modal.Header>{t("simulation.quantityModifiers")}</Modal.Header>
        <Modal.Content className='quantity-modifier'>
            <Modal.Description>
                <QuantityModifier 
                        modalData={data}
                        dateRange={simulationDateRange}
                        // disabled={false}
                        closeModal={()=>setOpenModal(false)}
                        />
            </Modal.Description>
        </Modal.Content>
        </Modal>
    )
}

ModalQuantityModifier.propTypes = {
    simulationDateRange: PropTypes.object,
    data: PropTypes.object,
    loadingSimulation: PropTypes.bool,
}

const mapStateToProps = (state)=>({
    simulationDateRange: state.analytics.simulationDateRange,
    loadingSimulation: state.analytics.loadingSimulation
})

export default connect(mapStateToProps, {})(ModalQuantityModifier)
