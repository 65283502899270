import React from "react";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";

import { Card } from "semantic-ui-react";
import { toExcelNumberFormatWithDecimals } from "../../../utils/format";
import "./OrderCard.css";

const OrderCard = ({ item }) => {
  const { t } = useTranslation();

  return (
    <Card centered>
      <Card.Content>
        <Card.Header className={"order-card-header"}>
          {t("detailsPanel.suggestedOrder.header")}: {item.material_code}
        </Card.Header>
        <Card.Meta className={"order-card-meta"}>
          {t("ordersTable.columns.material_location")}: <span>{item.material_location}</span>
        </Card.Meta>
        <Card.Meta className={"order-card-meta"}>
          {t("ordersTable.columns.material_description")}:<span>{" "}
          {item.material_description}</span>
        </Card.Meta>
        <Card.Meta className={"order-card-meta"}>
          {t("ordersTable.columns.material_unitof_measure")}:<span>{" "}
          {item.material_unitof_measure}</span>
        </Card.Meta>
        <Card.Meta className={"order-card-meta"}>
          {t("detailsPanel.suggestedOrder.group")}: <span>{item.group}</span>
        </Card.Meta>
        {item.events.length > 0 && (
          <Card.Meta className={"order-card-meta"}>
            {t("detailsPanel.suggestedOrder.events")}: <span>{item.events.join(", ")}</span>
          </Card.Meta>
        )}
        <span className={"card-order-recommended-qty"}>
          {t("detailsPanel.suggestedOrder.order")}: {toExcelNumberFormatWithDecimals(item.suggested_order)}
        </span>
      </Card.Content>
    </Card>
  );
};

OrderCard.propTypes = {
  item: PropTypes.object.isRequired,
};

export default OrderCard;
