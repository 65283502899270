import React, { useState, useEffect, useMemo } from 'react'
import { Button, Modal, Dropdown, Grid } from 'semantic-ui-react'
import { Table } from './BufferColorTable/Table'
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { useTranslation } from 'react-i18next'
import { updateOrderTableByBufferZoneColor } from '../../../../actions/ordersTable'
import "./SortByColorModal.css"

export const SortByColorModal = ({items, updateOrderTableByBufferZoneColor}) => {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()

  const columns2 = useMemo(
    () => [
      {
        Header: t("ordersTable.bufferZoneOrder"),
        accessor: "bufferZoneColor"
      }
    ],
    [t]
  );

  const [data, setData] = useState([])

  useEffect(()=>{
    if(items[0].buffer_zone_color !== "confirm_to_calculate"){
      const bufferZoneArray = items.map((item)=>(item.buffer_zone_color))
      const setBufferZoneArray = [...new Set(bufferZoneArray)]       
      const newData = setBufferZoneArray
                          .map((bufferZoneColor)=> {return {id:bufferZoneColor, 
                                                    bufferZoneColor: t("ordersTable.columns."+bufferZoneColor)}}) 
      setData(newData)
    }
    // eslint-disable-next-line
  }, [t])

  const disableInteraction = items[0].buffer_zone_color === "confirm_to_calculate" || data.length === 1 ? true : false

  const confirmationHanddler = ()=>{
    setOpen(false)
    updateOrderTableByBufferZoneColor(items, data, t)
  }

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size="mini"
      trigger={<Dropdown.Item disabled={disableInteraction}>
                    <Grid>
                    <Grid.Row>
                        <label>{t("ordersTable.sortByColor")}</label>
                    </Grid.Row>
                    </Grid>
                </Dropdown.Item>}
    >
      <Modal.Header>{t("ordersTable.sortByColor")}</Modal.Header>
      <Modal.Content className="sort-by-color-modal-content">
        {/* Code of table was extracted from here: https://codesandbox.io/s/react-table-drag-and-drop-sort-rows-with-dnd-kit-forked-00khw1?file=/src/App.jsx:1766-1815 */}
        <Table columns={columns2} data={data} setData={setData} />
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => setOpen(false)}>
          {t("cancel")}
        </Button>
        <Button
          onClick={() => confirmationHanddler()}
          positive
        >
          {t("confirm")}
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

const mapStateToProps = (state)=>({
  items: state.items.items
})

SortByColorModal.propTypes = {
  items: PropTypes.array,
  updateOrderTableByBufferZoneColor: PropTypes.func
}

// export default SortByColorModal
export default connect( mapStateToProps, { updateOrderTableByBufferZoneColor })(SortByColorModal)