import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import _ from "lodash";

import {
    createIntAPIToken,
    getIntAPITokensInfo,
    deactivateAPIToken

  } from "../../../actions/adminConsole";
import { Grid} from "semantic-ui-react";
import CreateTokenButton from "./subComponents/CreateTokenButton";
import TokensInfoContainer from "./subComponents/TokensInfoContainer";
import NewTokenContainer from "./subComponents/NewTokenContainer";

export const IntegtationAPIConfig = ({
    createIntAPIToken,
    getIntAPITokensInfo,  
    apiToken,
    tokensInfo,
    deactivateAPIToken, 
}) => {   
    const createToken = () => {
        createIntAPIToken()
    };
    
    useEffect(() => {
        getIntAPITokensInfo()
    }, [ apiToken, getIntAPITokensInfo ])

    return(        
            <Grid celled>
                <Grid.Column width={15}>
                    <Grid.Row>
                        <CreateTokenButton
                                createToken={createToken}
                                configExists={!_.isNil(apiToken)}
                            />
                    <Grid.Row>
                        <NewTokenContainer
                            newToken={ apiToken }
                        />
                    </Grid.Row>
                    </Grid.Row>  
                    <Grid.Row>
                        <TokensInfoContainer                                
                                tokensInfo={tokensInfo}
                                deactivateToken={deactivateAPIToken}
                                getTokensInfo={getIntAPITokensInfo}
                            />
                    </Grid.Row>             
                </Grid.Column>
            </Grid>
    )

}

IntegtationAPIConfig.propTypes = {
    createIntAPIToken: PropTypes.func,
    getIntAPITokensInfo: PropTypes.func,
    apiToken: PropTypes.string,
    tokensInfo: PropTypes.array,
    deactivateAPIToken: PropTypes.func,
  };

  IntegtationAPIConfig.defaultProps = {
    createIntAPIToken: _.noop,
    getIntAPITokensInfo: _.noop,
    deactivateAPIToken: _.noop,
    apiToken: null,
    tokensInfo: []
};

const mapStateToProps = (state) => ({
    apiToken: state.adminConsole.apiToken,
    creatingAPIToken: state.adminConsole.creatingAPIToken,
    tokensInfo: state.adminConsole.tokensInfo,
});
export default connect(mapStateToProps, {
    createIntAPIToken,
    getIntAPITokensInfo,
    deactivateAPIToken,
  })(IntegtationAPIConfig);