import React from "react";
import PropTypes from "prop-types";
import { Button, Loader } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
export const CreateTokenButton = ({
    createToken,
    configExists,
    loaderActive,
}) => {
    const { t } = useTranslation();
    const buttonMessage = t("account.adminConsole.generateToken");
    return (
        <Button
            className={'autoUploadConfigButton'}
            disabled={configExists}            
            onClick={() => createToken()}
            > {buttonMessage} <Loader active={loaderActive} size={"tiny"} inline={true}/>  
        </Button>
    )   
}

CreateTokenButton.propTypes = {
    createToken: PropTypes.func,
    configExists: PropTypes.bool,
    loaderActive: PropTypes.bool,
  };

CreateTokenButton.defaultProps = {
    currentWorkspaceId: _.noop,
    createConfig: _.noop,
    configExists: _.noop,
    loaderActive: false,
};
export default CreateTokenButton;  