import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { round, nanToNum, abbreviateNumber } from "../../../utils/format";
import "./SvgBufferDetailsChart.css";
import { useTranslation } from "react-i18next";
import { Bar } from "./BarChart/Bar";
import { TopOfZone } from "./BarChart/TopOfZone";
import { ExtraMetrics } from "./BarChart/ExtraMetrics"
import { BarValues } from "./BarChart/BarValues"
import { chartHeight, overalYOffset, barWidth } from "../../../utils/constants"
import { toExcelNumberFormatWithoutDecimals } from "../../../utils/format";

const getHeights = (
  available,
  rawGrey,
  rawRed,
  rawYellow,
  rawGreen,
  ...extra
) => {
  const rawTotal = rawGrey + rawRed + rawYellow + rawGreen;
  if (rawTotal === 0) {
    return {
      grey: 0,
      red: 0,
      yellow: 0,
      green: 0,
      extra: 0,
    };
  }

  const mappedGrey = (rawGrey * available) / rawTotal;
  const mappedRed = (rawRed * available) / rawTotal;
  const mappedYellow = (rawYellow * available) / rawTotal;
  const mappedGreen = (rawGreen * available) / rawTotal;

  const mappedExtra = extra.map((value) => _.isNil(value) ? null : (value * available) / rawTotal);

  return {
    grey: mappedGrey,
    red: mappedRed,
    yellow: mappedYellow,
    green: mappedGreen,
    extra: mappedExtra,
  };
};

const SvgBufferDetailsChart = ({ bufferData }) => {

  const [dataWidth, setDataWidth] = useState({window: 0, chart: 0, shortNumber: 10})
  const ref = useRef(null)

  useEffect(() => {
    window.addEventListener('resize', () => {
      setDataWidth((dataWidth)=>({...dataWidth, window: window.innerWidth}))
    });
  }, []);

  useEffect(()=>{
    setDataWidth((dataWidth)=>({...dataWidth, chart: ref.current.offsetWidth}))

    if(dataWidth.window >= 0){
      setDataWidth((dataWidth)=>({...dataWidth, shortNumber: (abbreviateNumber((dataWidth.chart-barWidth)*0.03)-3)})) 
    }

  }, [dataWidth.window, dataWidth.chart, dataWidth.shortNumber])

  const { t } = useTranslation();

  const { zones } = bufferData;

  const red = nanToNum(zones.red.units);
  const yellow = nanToNum(zones.yellow.units);
  const green = nanToNum(zones.green.units);
  const grey = nanToNum(zones.grey.units);

  const computedHeights = getHeights(
    chartHeight - overalYOffset,
    grey,
    red,
    yellow,
    green,
    bufferData.onHandStock,
    bufferData.analytics.avg_on_hand_stock,
    bufferData.netFlow.net_flow_eq
  );
  
  const extraMetricsOffset =
    overalYOffset +
    computedHeights.grey +
    computedHeights.yellow +
    computedHeights.green +
    computedHeights.red;

  const centerLine = dataWidth.chart / 2 - barWidth / 2;
  const topOfZoneTextXCoordinate = centerLine + barWidth / 2 + 60;
  const extraMetricsTextXCoordinate = centerLine + barWidth / 2 - 65;

  const topOfGreen = round(red + yellow + green + grey, 1);
  const topOfYellow = round(red + yellow + grey, 1);
  const topOfRed = round(red + grey, 1);
  const topOfGrey = round(grey, 1);

  let mapped_on_hand_stock =
    bufferData.onHandStock < 0
      ? chartHeight - 10
      : extraMetricsOffset - computedHeights.extra[0];
  mapped_on_hand_stock =
    bufferData.onHandStock > topOfGreen
      ? overalYOffset + 10
      : mapped_on_hand_stock;

  const mapped_avg_on_hand_stock = _.isNil(computedHeights.extra[1]) ? null : extraMetricsOffset - computedHeights.extra[1];

  let mapped_net_flow_eq =
    bufferData.netFlow.net_flow_eq < 0
      ? chartHeight
      : extraMetricsOffset - computedHeights.extra[2];
  mapped_net_flow_eq =
    bufferData.netFlow.net_flow_eq > topOfGreen
      ? overalYOffset
      : mapped_net_flow_eq;

  const bars = [
    {
      color: "grey",
      x: centerLine,
      y:
        overalYOffset +
        computedHeights.red +
        computedHeights.yellow +
        computedHeights.green,
      width: barWidth,
      height: computedHeights.grey,
      value: grey,
      topOf: `${t("detailsPanel.numericalDetails.bufferZones.top_of_grey")}: ${abbreviateNumber(topOfGrey)}`,
      relativeOffset:
        topOfRed - topOfGrey > 0.25 * topOfGreen ? 0 : 0.05 * chartHeight,
    },
    {
      color: "red",
      x: centerLine,
      y: overalYOffset + computedHeights.yellow + computedHeights.green,
      width: barWidth,
      height: computedHeights.red,
      value: red,
      topOf: `${t("detailsPanel.numericalDetails.bufferZones.top_of_red")}: ${abbreviateNumber(topOfRed)}`,
      relativeOffset:
        topOfYellow - topOfRed > 0.25 * topOfGreen ? 0 : 0.015 * chartHeight,
    },
    {
      color: "yellow",
      x: centerLine,
      y: overalYOffset + computedHeights.green,
      width: barWidth,
      height: computedHeights.yellow,
      value: yellow,
      topOf: `${t("detailsPanel.numericalDetails.bufferZones.top_of_yellow")}: ${abbreviateNumber(topOfYellow)}`,
      relativeOffset:
        topOfYellow - topOfRed > 0.25 * topOfGreen ? 0 : -0.015 * chartHeight,
    },
    {
      color: "green",
      x: centerLine,
      y: overalYOffset,
      width: barWidth,
      height: computedHeights.green,
      value: green,
      topOf: `${t("detailsPanel.numericalDetails.bufferZones.top_of_green")}: ${abbreviateNumber(topOfGreen)}`,
      relativeOffset:
        topOfGreen - topOfYellow > 0.25 * topOfGreen ? 0 : -0.05 * chartHeight,
    },
  ];

  let extraMetrics = [
    {
      value: mapped_on_hand_stock,
      hideLine:
        bufferData.onHandStock < 0 || bufferData.onHandStock > topOfGreen,
      color:
        bufferData.onHandStock < 0
          ? "#E76F51"
          : bufferData.onHandStock > topOfGreen
            ? "#B6B6E0"
            : null,
      label: `${t(
        "detailsPanel.numericalDetails.onHandStockAbbreviation"
      )}: ${abbreviateNumber(round(bufferData.onHandStock, 1))}`,
      icon: (
        <circle
          opacity="0.7"
          className="circle-marker"
          cx={centerLine + barWidth / 2}
          cy={mapped_on_hand_stock}
          r="6"
        />
      ),
      header: t("detailsPanel.numericalDetails.ohTittle"),
      details: toExcelNumberFormatWithoutDecimals(bufferData.onHandStock, {currendy: "USD"})
    },
    {
      value: mapped_avg_on_hand_stock,
      label: `${t(
        "detailsPanel.numericalDetails.aohAbbreviation"
      )}: ${abbreviateNumber(
        // Average on-hand stock == Available stock
        round(bufferData.analytics.avg_on_hand_stock, 1)
      )}`,
      icon: (
        <circle
          opacity="0.7"
          className="circle-marker"
          cx={centerLine + barWidth / 2}
          cy={mapped_avg_on_hand_stock}
          r="6"
        />
      ),
      header: t("detailsPanel.numericalDetails.aohTitle"),
      details: `${t("detailsPanel.numericalDetails.aohFormula")} = ${toExcelNumberFormatWithoutDecimals(round(
        bufferData.analytics.avg_on_hand_stock,
        2
      ))}`,
    },
    {
      value: mapped_net_flow_eq,
      hideLine:
        bufferData.netFlow.net_flow_eq < 0 ||
        bufferData.netFlow.net_flow_eq > topOfGreen,
      color:
        bufferData.netFlow.net_flow_eq < 0
          ? "#E76F51"
          : bufferData.netFlow.net_flow_eq > topOfGreen
            ? "#B6B6E0"
            : null,
      label: `${t(
        "detailsPanel.numericalDetails.asAbbreviation"
      )}: ${abbreviateNumber(round(bufferData.netFlow.net_flow_eq, 1))}`,
      icon: (
        <polygon
          opacity="0.7"
          className="triangle-marker"
          points={`${centerLine + barWidth / 2 - 8},${mapped_net_flow_eq + 5} ${
            centerLine + barWidth / 2 + 8
            },${mapped_net_flow_eq + 5} ${centerLine + barWidth / 2},${
            mapped_net_flow_eq - 10
            }`}
        />
      ),
      header: t("detailsPanel.numericalDetails.asTitle"),
      details: `${t("detailsPanel.numericalDetails.asFormula")} = ${toExcelNumberFormatWithoutDecimals(round(
        bufferData.netFlow.net_flow_eq,
        2
      ))}`,
    },
  ];

  const relativeExtraMetricOffsets = [
    -0.05 * chartHeight,
    0,
    0.05 * chartHeight,
  ];
  extraMetrics = extraMetrics.sort((a, b) => {
    if (a.value > b.value) {
      return 1
    } else if (a.value < b.value) {
      return -1
    } else { return 0 }
  });
  for (let i = 0; i < extraMetrics.length; i++) {
    extraMetrics[i]["relativeOffset"] = relativeExtraMetricOffsets[i];
  }

  return (
    <div className="svg-buffer-details-chart-container" ref={ref}>
      <svg height={chartHeight} width="100%" className="svg-buffer-details-chart" role="img">
        {bars.map((bar) =>
          bar.height > 0 ? (
            <g key={`${bufferData.partId}-${bar.color}`}>
              {<Bar drawData={bar}/>}
              {<TopOfZone drawData={bar} 
                          shortNumberLength={dataWidth.shortNumber} 
                          topOfZoneTextXCoordinate={topOfZoneTextXCoordinate}/>}
            </g>
          ) : null
        )}

        {/* Extra metrics*/}
        {extraMetrics.map((metric) =>
          _.isNil(metric.value) ?
            null :

            (
              <g key={`${bufferData.partId}-${metric.header}`}>
                {<ExtraMetrics  metric={metric} 
                                shortNumberLength={dataWidth.shortNumber} 
                                extraMetricsTextXCoordinate={extraMetricsTextXCoordinate} 
                                centerLine={centerLine}/>}
              </g>
            )
        )}

        {bars.map((bar) =>
          bar.height > 0 ? (
            <g key={`${bufferData.partId}-${bar.color}`}>
              {<BarValues drawData={bar}/>}
            </g>
          ) : null
        )}
      </svg>
    </div>
  );
};

SvgBufferDetailsChart.propTypes = {
  bufferData: PropTypes.object.isRequired,
};

export default SvgBufferDetailsChart;
