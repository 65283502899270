import _ from "lodash";
import uuid from "uuid";
import { ANALYTICS_ALLOWED } from "../constants";
import i18n from 'i18next';

export const mapItemsToUniqueValues = (items, key) => {
    const mapped = items.map((item) => item[key]);
  
    return _.uniq(mapped);
  };

export const mapItemsToDropdownOptions = (items, key) => {
    return mapItemsToUniqueValues(items, key)
      .filter((value) => !_.isNil(value))
      .map((value) => ({
        key: uuid.v4(),
        text: value,
        value: value,
      }));
  };

export const mapColumnsToGroupFields = (t, filterOptions) => {
  const groupFields = [];
  for (const property in filterOptions) {
    let mappedColumns = filterOptions[property].map((singleColumn) => {
        return {
            accessor: singleColumn.columnCode,
            name: (property === "standardColumns" ? t("ordersTable.columns." + singleColumn.columnCode) : singleColumn.columnTitle),
            options: singleColumn.columnOptions.map(columnValue => {
                return {
                    key: uuid.v4(),
                    text: columnValue,
                    value: columnValue
                }
            }),
            defaultValues: ["all"],
            isChecked: true,
        }
    });
    groupFields.push(...mappedColumns);
  }

  return groupFields;
};

export const mapTimeFilters = (t, timeFilterArray) => {
  if(timeFilterArray.length){
    const getTextData = (text)=>{
      if(text.includes("-")){
        return text.split(" ")[2]
      }
      return text
    }
      const firstValue = timeFilterArray[0]
      // when filters doesn't has options
      if(timeFilterArray.length === 1 && firstValue.text === "no_months"){
        return [{text:i18n.t("analytics.noMonths"),value: 1}]
      }
      // when filter has only one option
      if(timeFilterArray.length === 1 && (firstValue.text === "last_months" || firstValue.text === t("analytics.lastMonths"))){
        return [{...firstValue, "text": t("analytics.lastMonths")}]
      }
      // when filter has more than one option and it wasn't translated
      if(timeFilterArray.length > 1){
        const newTimeOptions = timeFilterArray
                                  .map((objectTemp, index)=>{
                                    if(index === (timeFilterArray.length - 1)){
                                      return {...objectTemp, "text":`${t("analytics.last")} - ${getTextData(objectTemp.text)} ${t("analytics.months")} (${t("analytics.allMonths")})`}
                                    }
                                    return {...objectTemp, "text":`${t("analytics.last")} - ${getTextData(objectTemp.text)} ${t("analytics.months")}`}
                                  })
        return newTimeOptions
      }
  }
  return []
}

export const mapDateRangeToDays = (start, end) => {
  const dateStart = Date(start) <= Date(end) ? new Date(start) : new Date(end)
  const dateEnd = Date(start) <= Date(end) ? new Date(end) : new Date(start)
  const milSecondsInDay = 86400000
  return Math.abs(dateEnd - dateStart) / milSecondsInDay
}

export const mapDaytagToNum = (day) => {
  const dayToNum = {
    'Mon': 0,
    'Tue': 1,
    'Wed': 2,
    'Thu': 3,
    'Fri': 4,
    'Sat': 5,
    'Sun': 6
  }
  return dayToNum[day]
}

// TODO: this could accept empty chartOne with chartTwo with data, or viseversa
export const mapChartCodesToStringsAnalytics = ({chartOne={}, chartTwo={}}) => {
  if(_.isEmpty(chartOne) && _.isEmpty(chartTwo)) {
    return {chartOneData: [], chartTwoData: []}
  }
  const mapperChartObject = {}

  const getDefaultZoneValues = (chartType)=>{
    if(_.isEmpty(chartType)){
      return []
    }
    return [
      {
        "name": i18n.t("detailsPanel.numericalDetails.bufferZones.grey"),
        "type": "area",
        "data": chartType.GREY,
      },
      {
        "name": i18n.t("detailsPanel.numericalDetails.bufferZones.red"),
        "type": "area",
        "data": chartType.RED,
      },
      {
        "name": i18n.t("detailsPanel.numericalDetails.bufferZones.yellow"),
        "type": "area",
        "data": chartType.YELLOW,
      },
      {
        "name": i18n.t("detailsPanel.numericalDetails.bufferZones.green"),
        "type": "area",
        "data": chartType.GREEN,
      }, 
    ]
  }

  mapperChartObject["chartOneData"] = getDefaultZoneValues(chartOne)
  mapperChartObject["chartTwoData"] = getDefaultZoneValues(chartTwo)

  const addBlueZone = (chartType, toAddArray)=>{
    if(_.isEmpty(chartType) || _.isEmpty(toAddArray)){
      return []
    }
    if(chartType.BLUE){
      toAddArray.push({
        "name": i18n.t("analytics.blue"),
        "type": "area",
        "data": chartType.BLUE,
      })
    }
    return toAddArray
  }

  mapperChartObject["chartOneData"] = addBlueZone(chartOne, mapperChartObject["chartOneData"])
  mapperChartObject["chartTwoData"] = addBlueZone(chartTwo, mapperChartObject["chartTwoData"])

  const addAditionalZoneValues = (chartType, toAddArray) =>{
    if(_.isEmpty(chartType) || _.isEmpty(toAddArray)){
      return []
    }
    if(chartType.NFE){
      toAddArray.push({ 
        "name": i18n.t("detailsPanel.numericalDetails.asTitle"), //Net Flow Equation
        "type": "line",
        "data": chartType.NFE ? chartType.NFE : []
      })
    }
    if(chartType.OHS){
      toAddArray.push({ 
        "name": i18n.t("analytics.OHS"), //On Hand Stock
        "type": "line",
        "data": chartType.OHS ? chartType.OHS : []
      })
    }
    return toAddArray
  }

  if(chartTwo["STOCK_OUT"]){
    const stockOut = [{
      "name": i18n.t("analytics.STOCK_OUT"),
      "type": "area",
      "data": chartTwo.STOCK_OUT,
    }]
    mapperChartObject["chartTwoData"] = stockOut.concat(mapperChartObject["chartTwoData"])
  }

  mapperChartObject["chartOneData"] = addAditionalZoneValues(chartOne, mapperChartObject["chartOneData"])
  mapperChartObject["chartTwoData"] = addAditionalZoneValues(chartTwo, mapperChartObject["chartTwoData"])


  return mapperChartObject
}

export const mapChartCodesToStrings = (t, chartOne, chartTwo) => {  
  if(_.isEmpty(chartOne) || _.isEmpty(chartTwo)) {
    return {chartOneData: [], chartTwoData: []}
  }
  return {
        chartOneData: [
          {
            "name": t("analytics.SGreyZ"),
            "data": chartOne.GREY,
          },
          {
            "name": t("analytics.SRZ"),
            "data": chartOne.RED,
          },
          {
            "name": t("analytics.SYZ"),
            "data": chartOne.YELLOW,
          },
          {
            "name": t("analytics.SGreenZ"),
            "data": chartOne.GREEN,
          },
        ],
        chartTwoData: [
            {
              "name": t("analytics.STOCK_OUT"),
              "type": "area",
              "data": chartTwo.STOCK_OUT,
            },
            {
              "name": t("detailsPanel.numericalDetails.bufferZones.grey"),
              "type": "area",
              "data": chartTwo.GREY,
            },
            {
              "name": t("detailsPanel.numericalDetails.bufferZones.red"),
              "type": "area",
              "data": chartTwo.RED,
            },
            {
              "name": t("detailsPanel.numericalDetails.bufferZones.yellow"),
              "type": "area",
              "data": chartTwo.YELLOW,
            },
            {
              "name": t("detailsPanel.numericalDetails.bufferZones.green"),
              "type": "area",
              "data": chartTwo.GREEN,
            },
            {
              "name":  t("analytics.blue"),
              "type": "area",
              "data": chartTwo.BLUE,
            },                                                       
          ],        
  }
}

export const mapChartOneCodesToStringsSimulation = (t, chartOne)=>{
  return(
    [
      //This line is necessary to solve problems with black line (ohs)
      //and click in grey zone
      {
        "name": "line", //Grey Zone
        "type": "area",
        "data": new Array(chartOne.GREY.length).fill(0),
      },
      {
        "name": t("detailsPanel.numericalDetails.bufferZones.grey"), //Grey Zone
        "type": "area",
        "data": chartOne.GREY,
      },
      {
        "name": t("detailsPanel.numericalDetails.bufferZones.red"), //Red Zone
        "type": "area",
        "data": chartOne.RED,
      },
      {
        "name": t("detailsPanel.numericalDetails.bufferZones.yellow"), //Yellow Zone
        "type": "area",
        "data": chartOne.YELLOW,
      },
      {
        "name": t("detailsPanel.numericalDetails.bufferZones.green"), //Green Zone 
        "type": "area",
        "data": chartOne.GREEN,
      },
      { 
        "name": t("detailsPanel.numericalDetails.asTitle"), //Net Flow Equation
        "type": "line",
        "data": chartOne.NFE ? chartOne.NFE : [],
      },
      { 
        "name": t("analytics.OHS"), //On Hand Stock
        "type": "line",
        "data": chartOne.OHS ? chartOne.OHS : [],
      }
    ]
  )
}

export const mapChartOneCodesToStringsAnalytics = (t, chartOne)=>{
  if(_.isEmpty(chartOne)) {
    return []
  }
  return(
    [
      {
        "name": t("detailsPanel.numericalDetails.bufferZones.grey"), //Grey Zone
        "type": "area",
        "data": chartOne.SGreyZ,
      },
      {
        "name": t("detailsPanel.numericalDetails.bufferZones.red"), //Red Zone
        "type": "area",
        "data": chartOne.SRZ,
      },
      {
        "name": t("detailsPanel.numericalDetails.bufferZones.yellow"), //Yellow Zone
        "type": "area",
        "data": chartOne.SYZ,
      },
      {
        "name": t("detailsPanel.numericalDetails.bufferZones.green"), //Green Zone 
        "type": "area",
        "data": chartOne.SGreenZ,
      },
      { 
        "name": t("detailsPanel.numericalDetails.asTitle"), //Net Flow Equation
        "type": "line",
        "data": chartOne.NFE ? chartOne.NFE : [],
      },
      { 
        "name": t("analytics.OHS"), //On Hand Stock
        "type": "line",
        "data": chartOne.OHS ? chartOne.OHS : [],
      }
    ]
  )
}

export const mapColumnHeaderToReactTableFormat = (principalObject, translationHeader, keyNoNeccesary="", modify=true) => {
  const columnDataFormat = Object
                                .keys(principalObject)
                                .filter(key => key !== keyNoNeccesary)
                                .map((keyName)=>{
                                  return {
                                    Header: translationHeader[keyName],
                                    accessor: keyName,
                                  }
                            })
  if(modify){
    columnDataFormat.push({
      Header: "          ",
      accessor: "modify",
    })
  }
  return columnDataFormat;
}

export const mapAnalyticsContentDisableStatus = (statusMessage)=>{
  return statusMessage === ANALYTICS_ALLOWED ? false : true
}

const sumAreaAndLineFromChart = (chartType, lineToSum)=>{
  const globalLengthArr = chartType['GREEN'].length
  const sumArea = [], sumLine = []
  for(let i=0; i< globalLengthArr; i++){
    if(chartType['GREEN']){
      let blueZone = !_.isEmpty(chartType['BLUE']) ? chartType['BLUE'][i] : 0 
      sumArea[i] = chartType['GREEN'][i] + chartType['GREY'][i] 
                      + chartType['RED'][i] + chartType['YELLOW'][i]
                      + blueZone

      sumLine[i] = chartType['GREEN'][i] + chartType[lineToSum][i]
    }
  }
  return {sumArea, sumLine}
}


// chartType could be chart one or chart two
export const calculateCustomMaxYAxis = (chartType)=>{
  let maxOHS=0, maxNFE=0

  if(chartType["GREEN"]){
    maxOHS = _.max(chartType['OHS'])
    maxNFE = _.max(chartType['NFE'])
  }

  const lineToSum = maxOHS > maxNFE ? 'OHS' : 'NFE'
  const {sumArea, sumLine} = sumAreaAndLineFromChart(chartType, lineToSum)

  const maxAreaSum = _.max(sumArea)
  const maxLineSum = _.max(sumLine)

  const maxYAxis = maxAreaSum > maxLineSum ? maxAreaSum : maxLineSum
  const addYAxis = maxYAxis * 0.005
  const resultYAxis = maxYAxis + addYAxis
  return resultYAxis
}

export const calculateCustomMinYAxis = (arraySeries)=>{
  if(_.isEmpty(arraySeries)){
    return 0
  }

  const firstDataElement = arraySeries[0]["data"][0]
  if(!_.isEmpty(firstDataElement) && firstDataElement.length > 1){
    const newDataArraySeries = arraySeries.map((objectInfo)=>{
      return objectInfo["data"].map((arrayZoneData)=> (arrayZoneData[1]))
    })
    const minZoneLocal = newDataArraySeries.map((valueArray)=>(_.min(valueArray)))
    return _.min(minZoneLocal)
  }

  const minimumLocals = arraySeries.map((zone)=>(_.min(zone.data)))
  return _.min(minimumLocals)
}

export const setDefaultMarkedField = (fieldFilterDataArray)=>{
  const initialMarkedFormat = {};
  fieldFilterDataArray.forEach((field) => (initialMarkedFormat[field.accessor] = true));
  return initialMarkedFormat
}

export const mappedFiledFiltersWithNewDefaultValues = (groupField, currentField, selectedValue)=>{
  return groupField.map((field) => {
    if (field.accessor === currentField.accessor) {
        return { ...field, defaultValues: selectedValue};
    }
    return field;
  })
}