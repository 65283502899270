import React from "react";

import { toast } from "react-toastify";

import ToastI18nWrapper from "../components/ToastI18nWrapper/ToastI18nWrapper";

import axiosWrapper from "../utils/axiosWrapper";

export const setLanguage = (language) => async (dispatch) => {
  const body = JSON.stringify({
    language,
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const response = await axiosWrapper.put("/user/language", body, config);
    dispatch({ type: "LANGUAGE_CHANGED", payload: response.data });
  } catch (error) {
     
    toast.error(<ToastI18nWrapper translateKey={"genericError"} />, {
      toastId: "genericError",
    });
  }
};
