import {
  SAVE_GROUP,
  GET_GROUPS,
  GET_FATHER_GROUPS,
  EDIT_GROUP,
  REMOVE_GROUP,
  BEGIN_GROUPS_REQUEST,
  ABORT_GROUPS_REQUEST,
  BEGIN_GROUP_FETCH,
} from "../actions/types";

const initialState = {
  loadingFetch: true,
  loadingPutPost: true,
  groups: [],
  fatherGroups: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_GROUPS:
      return {
        ...state,
        loadingFetch: false,
        loadingPutPost: false,
        groups: payload,
      };
    case GET_FATHER_GROUPS:
      return{
        ...state,
        fatherGroups: payload,        
      }
    case SAVE_GROUP:
      return {
        ...state,
        loadingPutPost: false,
      };
    case EDIT_GROUP:
      return {
        ...state,
        loadingPutPost: false,
        groups: state.groups.map((group) =>
          group.id === payload.id ? { ...payload } : group
        ),
      };
    case REMOVE_GROUP:
      return {
        ...state,
        loadingFetch: false,
        loadingPutPost: false,
        groups: state.groups.filter((group) => group.id !== payload),
      };
    case BEGIN_GROUP_FETCH:
      return {
        ...state,
        loadingFetch: true,
      };
    case BEGIN_GROUPS_REQUEST:
      return {
        ...state,
        loadingPutPost: true,
      };
    case ABORT_GROUPS_REQUEST:
      return {
        ...state,
        loadingPutPost: false,
        loadingFetch: false,
      };
    default:
      return state;
  }
}
