import React, { useRef } from 'react';
import UploadButton from './UploadButton';

import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const HistoricalUploadButton = ({
  fileType,
  // hasMasterData,
  // canEditCurrentWorkspace,
  // isProcessingFileData,
  onFileAdded
}) => {

  const { t } = useTranslation();
  const fileInputReferences = { historical_demand: useRef() };
  const openFileChooser = (fileType) => {
    fileInputReferences[fileType].current.click();
  };

  //TODO: add an variable that capture Processing inventory to disabe button when paqtana is uploading file 
  // let buttonDisabled = true
  // if(canEditCurrentWorkspace && hasMasterData && !isProcessingFileData){
  //   buttonDisabled = false
  // }
  // if(canEditCurrentWorkspace && hasMasterData && isProcessingFileData){
  //   buttonDisabled = true
  // }


  return (
    <UploadButton
      disabled={false}
      text={t('dataImport.uploadNew')}
      fileInputRef={fileInputReferences[fileType]}
      onFileAdded={onFileAdded}
      openFileDialog={() => openFileChooser(fileType)}
    />);
};

HistoricalUploadButton.propTypes = {
  fileType: PropTypes.string,
  hasMasterData: PropTypes.bool,
  canEditCurrentWorkspace: PropTypes.bool,
  isProcessingFileData: PropTypes.bool,
  onFileAdded: PropTypes.func
};



export default HistoricalUploadButton;