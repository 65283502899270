import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useTable } from 'react-table'
import PropTypes from 'prop-types'
import Table from '../../../../../DataImport/Table/Table'
import { mapColumnHeaderToReactTableFormat } from '../../../../../../utils/mappers/analytics'
import ModifyActionCell from './ModifyActionCell/ModifyActionCell'

const GreyZonesTable = ({greyZone, materialName})=> {
    const { t } = useTranslation()
    const translateMapper = useMemo(()=>
                                ({
                                    material_name: t("simulation.materialTitle"),
                                    start_date: t("simulation.startDate"),
                                    end_date: t("simulation.endDate"),
                                    adus_per_day: t("calendar.events.adusPerDay"),
                                    quantity_per_day: t("detailsPanel.numericalDetails.onHandStock.quantity"),
                                }), [t])

    const columns = useMemo(()=>{
        if(greyZone.length){
            const greyZoneOrder = {start_date:"",end_date: "", adus_per_day: "", quantity_per_day: ""}
            const columns = mapColumnHeaderToReactTableFormat(greyZoneOrder, translateMapper, "")
            const nameHeader = [{Header: translateMapper.material_name, accessor: "material_name",}]
            return nameHeader.concat(columns)
        }
        return []
    }, [greyZone, translateMapper])


    const data = useMemo(()=>{
        return greyZone.map((greyZoneData)=>({...greyZoneData, 
                                                material_name: materialName,
                                                modify: <ModifyActionCell
                                                            data={greyZoneData}
                                                            type="GreyZonesTable"
                                                            />
                                            }))
 
    }, [greyZone, materialName])

    //It's impossible to have different names of colums and data because of the way react-table works
    const greyZonesTable = useTable({columns, data})

    return(
        <Table instance={greyZonesTable}/>
    )
}

GreyZonesTable.propTypes = {
    greyZone: PropTypes.array,
    materialName: PropTypes.string,
}

export default GreyZonesTable;