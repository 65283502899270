import React, { useState } from "react";
import PropTypes from "prop-types";
import TimeZonePicker from './TimeZonePicker/TimeZonePicker';
import _ from "lodash";

import { debounce } from "throttle-debounce";

import { connect } from "react-redux";
import {
  Form,
  Table,
  Button,
  Grid,
  Segment,
  Header,
  Modal,
  Radio,
} from "semantic-ui-react";

import {
  deleteWorkspace,
  createWorkspace,
  editWorkspace,
  checkDeleteWorkspacePermission,
  setWorkspaceToDelete,
} from "../../../actions/adminConsole";

import { getWorkspaces } from "../../../actions/workspace";

import { useTranslation } from "react-i18next";

import "./WorkspaceEditor.css";
import { getClientTimezone } from "../../../utils/format";

export const WorkspaceEditor = ({
  organizationCode,
  deleteWorkspace,
  createWorkspace,
  editWorkspace,
  checkDeleteWorkspacePermission,
  needsWorkspaceDeleteRefresh,
  checkingWorkspaceDeleteFreshness,
  workspaces,
  setWorkspaceToDelete,
  workspaceToDelete,
  isConnected,
}) => {
  const { t } = useTranslation();

  const [editableWorkspace, setEditableWorkspace] = useState(null);

  const [showCreateForm, setShowCreateForm] = useState(false);

  const [workspaceNameError, setWorkspaceNameError] = useState(null);

  const [newWorkspaceTimeZone, setNewWorkspaceTimeZone] = useState(getClientTimezone())

  const [fillWithDemoData, setFillWithDemoData] = useState(false)

  const submitWorkspace = (workspace = editableWorkspace) => {
    workspace = { ...workspace, name: workspace.name.trim(), workspace_timezone: workspace.timezone };
    if (
      _.includes(
        workspaces
          .filter((existingWorskpace) => existingWorskpace.id !== workspace.id)
          .map((existingWorskpace) =>
            existingWorskpace.name.trim().replace(/ /g, "")
          ),
        workspace.name.trim().replace(/ /g, "")
      )
    ) {
      setWorkspaceNameError({
        content: t("account.adminConsole.duplicateWorkspaceNameError"),
        pointing: "below",
      });
      return;
    } else if (workspace.name === "") {
      setWorkspaceNameError({
        content: t("account.adminConsole.emptyWorkspaceNameError"),
        pointing: "below",
      });
      return;
    }
    _.has(workspace, "id")
      ? editWorkspace(workspace)
      : createWorkspace(workspace, fillWithDemoData);

    setNewWorkspaceTimeZone(getClientTimezone())
    setEditableWorkspace(null);
    setShowCreateForm(false);
    setFillWithDemoData(false)

  };

  const removeWithConfirmation = async (workspace) => {
    setWorkspaceToDelete(workspace);
    // Permission pre-flight check
    checkDeleteWorkspacePermission();
  };


  const debouncedWorkspaceData = debounce(500, (fieldData) => {
    setEditableWorkspace(fieldData)
  });

  const renderDeleteModal = () => {
    if (
      checkingWorkspaceDeleteFreshness ||
      needsWorkspaceDeleteRefresh ||
      _.isEmpty(workspaceToDelete)
    ) {
      return null;
    }

    return (
      <Modal open>
        <Modal.Header>
          Delete workspace {`"${workspaceToDelete.name}"`} ?
        </Modal.Header>
        <Modal.Content>
          <Form
            onSubmit={(e) => {
              e.preventDefault();

              if (e.target["workspace-name"].value === workspaceToDelete.name) {
                deleteWorkspace(workspaceToDelete.id);
                setWorkspaceToDelete(null);
              } else {
                setWorkspaceNameError({
                  content: t(
                    "account.adminConsole.workspaceNameDoesNotMatchError"
                  ),
                  pointing: "above",
                });
              }
              return;
            }}
          >
            <Form.Input
              required
              error={workspaceNameError}
              label={t("account.adminConsole.confirmWorkspaceToDelete")}
              placeholder={t("account.adminConsole.workspaceNameColumn")}
              name="workspace-name"
            />
            <div className="create-workspace-form-buttons">
              <Button disabled={!isConnected} type="submit" negative>
                {t("account.adminConsole.deleteWorkspace")}
              </Button>
              <Button
                disabled={!isConnected}
                onClick={() => {
                  setWorkspaceToDelete(null);
                }}
              >
                {t("cancel")}
              </Button>
            </div>
          </Form>
        </Modal.Content>
      </Modal>
    );
  };

  return (
    <Grid className="workspace-editor">
      {renderDeleteModal()}

      <Grid.Row>
        <Grid.Column width={8}>
          {showCreateForm ? (
            <Segment className="create-workspace-container">
             <Header className="create-workspace-header">{t("account.adminConsole.newWorkspace")}</Header>
              <Form
                className="create-workspace-container"
                onSubmit={(e) => {
                  e.preventDefault();
                  submitWorkspace(
                    {
                      name: e.target["workspace-name"].value,
                      timezone: newWorkspaceTimeZone
                    }
                  );
                }}
              >

                <Form.Input
                  label={t("account.adminConsole.workspaceNameLabel")}
                  error={workspaceNameError}
                  placeholder="Workspace Name"
                  name="workspace-name"
                />

                <Form.Field>
                  <label >{t("account.adminConsole.workspaceTimezoneLabel")}</label>
                  <TimeZonePicker
                    value={newWorkspaceTimeZone}
                    onChange={(e, data) => setNewWorkspaceTimeZone(data.value)} />
                </Form.Field>
                <Form.Field>
                <label >{t("account.adminConsole.fillWithDemoData")}</label>
                    <Radio
                    toggle
                    onChange={() => {
                      setFillWithDemoData(!fillWithDemoData)
                    console.log(fillWithDemoData)}}
                  />
                </Form.Field>
                  

                <div className="create-workspace-form-buttons">
                  <Button disabled={!isConnected} type="submit" positive>
                    {t("save")}
                  </Button>
                  <Button
                    onClick={() => {
                      setShowCreateForm(false);
                      setWorkspaceNameError(null);
                    }}
                  >
                    {t("cancel")}
                  </Button>
                </div>

              </Form>
            </Segment>
          ) : (
              <Button
                disabled={!isConnected}
                floated="left"
                primary
                className="create-workspace-button"
                onClick={() => {
                  setWorkspaceNameError(null);
                  setEditableWorkspace(null);
                  setShowCreateForm(true);
                }}
              >
                {t("account.adminConsole.createWorkspace")}
              </Button>
            )}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <div className="org-code-container">{t("account.adminConsole.organizationCode").concat(organizationCode)}</div>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={12}>
          {_.isEmpty(workspaces) ? (
            <Segment placeholder>
              <Header icon> {t("account.adminConsole.noWorkspaces")}</Header>
            </Segment>
          ) : (
              <Table celled>
                <Table.Header>
                  <Table.Row textAlign="center">
                    <Table.HeaderCell>
                      {t("account.adminConsole.workspaceNameLabel")}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      {t("account.adminConsole.workspaceCode")}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      {t("account.adminConsole.workspaceTimezoneLabel")}
                    </Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {workspaces.map((workspace) =>
                    editableWorkspace && editableWorkspace.id === workspace.id ? (
                      <Table.Row key={workspace.id} className="workspace-row">
                        <Table.Cell>
                          <Form.Input
                            fluid
                            error={workspaceNameError}
                            defaultValue={editableWorkspace.name}
                            onChange={(e) =>
                              debouncedWorkspaceData({
                                ...editableWorkspace,
                                name: e.target.value,
                              })
                            }
                          />
                        </Table.Cell>

                        <Table.Cell>
                          <TimeZonePicker
                            value={editableWorkspace.timezone}
                            onChange={(e, data) => setEditableWorkspace({
                              ...editableWorkspace,
                              timezone: data.value,
                            })} />
                        </Table.Cell>

                        <Table.Cell textAlign="center" collapsing>
                          <Button
                            disabled={!isConnected}
                            positive
                            size="small"
                            onClick={() => submitWorkspace(editableWorkspace)}
                            className="workspace-row-left-button"
                          >
                            {t("save")}
                          </Button>
                          <Button
                            disabled={!isConnected}
                            size="small"
                            onClick={() => setEditableWorkspace(null)}
                          >
                            {t("cancel")}
                          </Button>
                        </Table.Cell>
                      </Table.Row>
                    ) : (
                        <Table.Row key={workspace.id} className="workspace-row">
                          <Table.Cell>{workspace.name}</Table.Cell>
                          <Table.Cell>{workspace.workspace_code}</Table.Cell>
                          <Table.Cell>{t(`timezones.${workspace.timezone}`)}</Table.Cell>
                          <Table.Cell textAlign="center" collapsing>
                            <Button
                              disabled={!isConnected}
                              className="workspace-row-left-button"
                              icon="pencil"
                              size="tiny"
                              color="teal"
                              onClick={() => {
                                setShowCreateForm(false);
                                setWorkspaceNameError(null);
                                setEditableWorkspace(workspace);
                              }}
                            ></Button>
                            <Button
                              disabled={!isConnected}
                              size="tiny"
                              icon="trash"
                              negative
                              onClick={() => {
                                setWorkspaceNameError(null);
                                removeWithConfirmation(workspace);
                              }}
                            ></Button>
                          </Table.Cell>
                        </Table.Row>
                      )
                  )}
                </Table.Body>
              </Table>
            )}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  checkingWorkspaceDeleteFreshness:
    state.adminConsole.checkingWorkspaceDeleteFreshness,
  needsWorkspaceDeleteRefresh: state.adminConsole.needsWorkspaceDeleteRefresh,
  workspaceToDelete: state.adminConsole.workspaceToDelete,
  workspaces: _.isEmpty(state.adminConsole.organization)
    ? []
    : state.adminConsole.organization.workspaces,
  isConnected: state.auth.isConnected,
});

WorkspaceEditor.propTypes = {
  needsWorkspaceDeleteRefresh: PropTypes.bool.isRequired,
  organizationCode: PropTypes.string,
  getWorkspaces: PropTypes.func.isRequired,
  deleteWorkspace: PropTypes.func.isRequired,
  createWorkspace: PropTypes.func.isRequired,
  editWorkspace: PropTypes.func.isRequired,
  checkDeleteWorkspacePermission: PropTypes.func.isRequired,
  workspaces: PropTypes.arrayOf(PropTypes.object).isRequired,
  workspaceToDelete: PropTypes.object,
  setWorkspaceToDelete: PropTypes.func.isRequired,
  checkingWorkspaceDeleteFreshness: PropTypes.bool.isRequired,
  isConnected: PropTypes.bool,
};

export default connect(mapStateToProps, {
  getWorkspaces,
  deleteWorkspace,
  createWorkspace,
  editWorkspace,
  checkDeleteWorkspacePermission,
  setWorkspaceToDelete,
})(WorkspaceEditor);
