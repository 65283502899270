import styled from "@emotion/styled";
//TODO: check if changing language is necessary to change class property name
export const CustomTableStyle = styled.div`
.td-class-column.sim_name{
    width: 56%;
    text-align: left;
    padding-left: 2%;
}
.th-class-column.sim_name{
    padding-left: 2%;
}
.td-class-column{
    padding-left: 0.5%;
    padding: 10px;
}
.th-class-column{
    padding: 50px
}

.data-import-table{
    min-height: auto !important;
}

`