import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import { useTranslation } from "react-i18next";

const DynamicFieldsButton = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <input
      type="button"
      className="ui button primary dynamic-fields-trigger"
      style={{height:"min-content"}}
      onClick={onClick}
      value={t("ordersTable.dynamicFields.title")}
    />
  );
};

DynamicFieldsButton.propTypes = {
    onClick: PropTypes.func
};

DynamicFieldsButton.defaultProps = {
    onClick: _.noop
};

export default DynamicFieldsButton;
