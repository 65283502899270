import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  Loader,
  Message,
  Button,
  Form,
  Grid,
  Header,
  Segment,
} from "semantic-ui-react";

import { recoverPassword, checkJWToken } from "../../actions/auth";

import { LOGIN } from "../Navbar/routes";

import Error from "../Error/Error";

import "./PasswordRecovery.css";
import { getJWTokenFromUrl } from "../../utils/mappers/auth";

export const PasswordRecovery = ({
  recoverPassword,
  loading,
  checkJWToken,
  messageRecoverPassword,
  // use props if you need to extract some information from url
  ...props
}) => {

  const { t } = useTranslation();
  const history = useHistory()
  // eslint-disable-next-line react/prop-types
  const urlQueryParameters = _.isNil(props.location) ? "" : props.location.search;
  const jwtTokenValue = getJWTokenFromUrl(urlQueryParameters)

  useEffect(()=>{
    if(!_.isEmpty(jwtTokenValue)){
      checkJWToken(jwtTokenValue)
    }
  }, [checkJWToken, jwtTokenValue])

  const [formData, setFormData] = useState({
    password: "",
    password2: "",
    jwt: jwtTokenValue
  });

  const { password, password2 } = formData;
  const [formErrors, setFormErrors] = useState([]);

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const getSignupFormErrors = () => {
    const errors = [];
    if (password.length < 8) {
      errors.push({
        header: "account.passwordTooShort.header",
        content: "account.passwordTooShort.content",
      });
    } else if (password !== password2) {
      errors.push({
        header: "account.passwordsDoNotMatch.header",
        content: "account.passwordsDoNotMatch.content",
      });
    }
    return errors;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const errors = getSignupFormErrors();
    setFormErrors(errors);
    if (!_.isEmpty(errors)) {
      return;
    }
    recoverPassword(formData.password, formData.jwt);
    // TODO: history is already deprecated in react router v6.
    history.push(LOGIN)
  };

  if (loading) {
    return <Loader active size="large" />;
  }

  if(_.isEmpty(jwtTokenValue) || _.isEmpty(messageRecoverPassword)){
    return(
      <div className="error_screen">
        <div>
          <h1>404</h1>
          <h3>{t("account.notFound")}</h3>
        </div>
      </div>
    )
  }

  if(messageRecoverPassword === "invalid_token" || messageRecoverPassword === "token_expired"){
    return(
      <div className="error_screen">
        <div>
          <h1>401</h1>
          <h3>{t("account.unauthorized")}</h3>
        </div>
      </div>
    )
  }

  return (
    <Grid
      textAlign="center"
      container
      verticalAlign="middle"
      className="password-recovery-container"
    >
      <Grid.Column className="password-recovery-column">
        <Header as="h2" textAlign="center" className="account-recovery-message">
          {t("account.passwordRecoveryMessage")}
        </Header>
        <Form size="large" onSubmit={onSubmit} error={!_.isEmpty(formErrors)}>
          <Segment stacked>
            <Form.Input
              fluid
              required
              type="password"
              name="password"
              label={t("account.newPassword")}
              className="input-label-password-recovery"
              placeholder={t("account.newPassword")}
              value={password}
              onChange={(e) => onChange(e)}
            />
            <Form.Input
              fluid
              required
              type="password"
              name="password2"
              placeholder={t("account.passwordConfirm")}
              label={t("account.passwordConfirm")}
              className="input-label-password-recovery"
              value={password2}
              onChange={(e) => onChange(e)}
            />

            {formErrors.map((error) => (
              <Message
                key={error.header}
                error
                header={t(error.header)}
                content={t(error.content)}
              />
            ))}

            <Error />

            <Button fluid size="large" type="submit" className="send-changes-button">
              {t("account.sendChanges")}
            </Button> 

          </Segment>
        </Form>
      </Grid.Column>
    </Grid>
  );
};

PasswordRecovery.propTypes = {
  recoverPassword: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  checkJWToken: PropTypes.func,
  messageRecoverPassword: PropTypes.string
};

const mapStateToProps = (state) => ({
  loading: state.auth.loading,
  messageRecoverPassword: state.auth.messageRecoverPassword
});

export default connect(mapStateToProps, { recoverPassword, checkJWToken })(PasswordRecovery);
