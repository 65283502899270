import React, { useState } from "react"
import { Button, Icon, Grid } from "semantic-ui-react"
import PropTypes from "prop-types"

const DeleteButton = ({ rowData, onDeleteClick, disableComponent, cancelAction})=>{
    const [ buttonSelected, setButtonSelected ] = useState(false)

    const buttonAction = (data)=>{
        onDeleteClick(data.value)
        setButtonSelected(true)
    }

    const cancelChanges = ()=>{
        setButtonSelected(false)
        onDeleteClick("")
        cancelAction(false)
    }

    return(
        <Grid>
            <Grid.Row columns={2}>
                <Grid.Column>
                    <Button 
                        disabled={disableComponent}
                        value={rowData.sim_id} 
                        icon 
                        negative
                        basic={!buttonSelected}
                        size="tiny"
                        onClick={(e, data)=> buttonAction(data)}>
                        <Icon name='trash' />
                    </Button>
                </Grid.Column>
                {
                    buttonSelected  ?   <Grid.Column>
                                                <Button 
                                                    icon
                                                    size="mini"
                                                    basic
                                                    color="red"
                                                    onClick={()=>cancelChanges()}>
                                                    <Icon 
                                                        size="small"
                                                        name='close' 
                                                        />
                                                </Button>
                                        </Grid.Column>
                                    :   null
                }
            </Grid.Row>
        </Grid>
    )
}

DeleteButton.propTypes = {
    rowData: PropTypes.object,
    onDeleteClick: PropTypes.func,
    disableComponent: PropTypes.bool,
    cancelAction: PropTypes.func,
}

export default DeleteButton