import React, { useState } from 'react'
import { Button, Modal } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'

import { deleteSingleQuantityData,
        deleteGroupSettingData,
        changeSimulationGreyZoneArray,
        changeLoadSimulationData } from '../../../../../../../../actions/analytics' 

const  ModalDeleteData = 
({  data,
    type, 
    simulationQuantityModifiers, 
    deleteSingleQuantityData,
    deleteGroupSettingData,
    simulationGreyZone,
    changeSimulationGreyZoneArray,
    loadSimulationData,
    changeLoadSimulationData,
    simulationGroupSettings,
    loadingSimulation
}) => {
    const { t } = useTranslation()   
    const [ open, setOpen ] = useState(false)
    const [ valueToChange, setValueToChange ] = useState({})
    const inputValue = JSON.stringify(data)
    const { loaded_results="", sim_inputs="" } = loadSimulationData[0] || {}


    const dataFilter = (typeOfArray)=>{
        return typeOfArray
                        .filter((objectData)=> 
                                    !_.isEqual(objectData, valueToChange))
    }

    const positiveActionButton = ()=>{
             
        if(type==="QuantityTable"){ 
            const typeOfArray = simulationQuantityModifiers.length 
                                            ? simulationQuantityModifiers 
                                            : sim_inputs.sim_dataset
            
            const newQuantityArray = dataFilter(typeOfArray)

            if(simulationQuantityModifiers.length){
                deleteSingleQuantityData(newQuantityArray)
                setOpen(false)
                return 
            }

            changeLoadSimulationData([{ loaded_results, 
                                        sim_inputs: {...sim_inputs, sim_dataset: newQuantityArray}}])
            setOpen(false)
        }

        if(type==="GroupSettingsTable"){
            if(Object.keys(simulationGroupSettings).length){
                deleteGroupSettingData({})
                setOpen(false)
                return 
            }

            changeLoadSimulationData([{ loaded_results, 
                                        sim_inputs: {...sim_inputs, group_info: {} }}])
            setOpen(false)
        }

        if(type==="GreyZonesTable"){
            const typeOfArray = simulationGreyZone.length 
                                                ? simulationGreyZone 
                                                : sim_inputs.g_zone_input

            const newGreyZoneArray = dataFilter(typeOfArray)

            if(simulationGreyZone.length){
                changeSimulationGreyZoneArray(newGreyZoneArray)
                setOpen(false)
                return
            }

            changeLoadSimulationData([{ loaded_results, 
                                        sim_inputs: {...sim_inputs, g_zone_input: newGreyZoneArray }}])
            setOpen(false)

        }

    }

    const negativeActionButton = ()=>{
        setOpen(false)
    }

    const triggerComponent = ()=>{
        return  <div className="modifier-table-cell"> 
                    <Button 
                        icon="trash" 
                        negative
                        value={inputValue}
                        disabled={loadingSimulation}
                        onClick={(e, data)=> {setValueToChange(JSON.parse(data.value))}}
                        />
                </div>
    }

    return (
        <div>
        <Modal
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        trigger={triggerComponent()}
        >
        <Modal.Header>{t("simulation.areYouSureRemoveTitle")}</Modal.Header>
        <Modal.Content image>
            <Modal.Description>
            <p>{t("simulation.areYouSureRemoveMessage")}</p>
            </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
            <Button color='black' onClick={() => negativeActionButton()}>
            {t("cancel")}
            </Button>
            <Button
                onClick={() => positiveActionButton()}
                positive
                > 
                {t("confirm")}
            </Button>
        </Modal.Actions>
        </Modal>
        </div>
    )
}

ModalDeleteData.propTypes = {
    data: PropTypes.object,
    type: PropTypes.string,
    setCheckboxStatus: PropTypes.func,
    checkboxStatus: PropTypes.bool,
    simulationQuantityModifiers: PropTypes.array,
    deleteSingleQuantityData: PropTypes.func,
    deleteGroupSettingData: PropTypes.func,
    simulationGreyZone: PropTypes.array,
    deleteGreyZoneData: PropTypes.func,
    changeSimulationGreyZoneArray: PropTypes.func,
    loadSimulationData: PropTypes.array,
    changeLoadSimulationData: PropTypes.func,
    simulationGroupSettings: PropTypes.object,
    loadingSimulation: PropTypes.bool
}

const mapStateToProps = (state)=>({
    simulationQuantityModifiers: state.analytics.simulationQuantityModifiers,
    simulationGreyZone: state.analytics.simulationGreyZone,
    loadSimulationData: state.analytics.loadSimulationData,
    simulationGroupSettings: state.analytics.simulationGroupSettings,
    loadingSimulation: state.analytics.loadingSimulation
})


export default connect(mapStateToProps, 
                        {   deleteSingleQuantityData, 
                            deleteGroupSettingData, 
                            changeSimulationGreyZoneArray,
                            changeLoadSimulationData, 
                        })(ModalDeleteData)
