export const ADVANCED_LAYOUT = {
  id: "advanced",
  name: "advanced",
  isDeletable: false,
  isOverwriteable: false,
  translationKey: "ordersTable.layouts.advanced",
  initialState: { hiddenColumns: [] },
};

export const BASIC_LAYOUT = {
  id: "basic",
  name: "basic",
  translationKey: "ordersTable.layouts.basic",
  isDeletable: false,
  isOverwriteable: false,
  initialState: {
    hiddenColumns: [
      "unit_cost",
      "minimum_order_quantity",
      "multiple_order_quantity",
      "unit_cost_currency",
      "location_code_concatenation",
      "supply_lead_time",
      "lead_time",
      "on_hand_stock",
      "subgroup",
      "in_transit_inventory",
      "material_type",
      "buffer_type",
      "reason_code",
      "average_daily_usage",
      "lead_time_factor",
      "variability_factor",
      "additional_buffer_zone",
      "grey_zone",
      "red_zone",
      "yellow_zone",
      "green_zone",
      "top_of_grey",
      "top_of_red",
      "top_of_yellow",
    ],
  },
};

export const pusherEvents = Object.freeze({
  subscription_succeeded: "pusher:subscription_succeeded",
  file_parse_success: "file_parse_success",
  file_parse_error: "file_parse_error",
  new_calculation: "new_calculation",
  group_settings_change: "group_settings_change",
  calendar_events_change: "calendar_events_change",
  order_table_change: "order_table_change",
  order_table_row_change: "order_table_row_change",
  organization_setting_change: "organization_setting_change",
  workspaces_change: "workspaces_change",
  syncing_started: "syncing_started",
  syncing_interrupted: "syncing_interrupted"
});

export const priorityCodes = Object.freeze({
  blue: 0,
  green: 1,
  yellow: 2,
  red: 3,
});

// Data Import
export const dataImportFileTypes = Object.freeze({
  master: {
    translateKey: "dataImport.fileSections.master",
    lastUpdated: null,
  },
  historical_demand: {
    translateKey: "dataImport.fileSections.historical_demand",
    lastUpdated: null,
  },
  inventory: {
    translateKey: "dataImport.fileSections.inventory",
    lastUpdated: null,
  },
});

// Order Table
export const itemAccessors = Object.freeze({
  code: "material_code",
  location: "material_location",
  description: "material_description",
  bufferData: "buffer_data",
  priority: "priority",
  additional: "additional",
  actualOrder: "actual_order",
  suggestedOrder: "suggested_order",
  orderVariance: "order_variance",
  reasonCode: "reason_code",
  variance: "variance",
  materialMethod: "material_method",
  netFlowPriority: "net_flow_priority",
  currentOnHandAlert: "current_on_hand_alert",
  worstBatchStatus: "worst_batch_status",
  worstBatchExpiryDate: "worst_batch_expiry_date",
  unitCostCurrency: "unit_cost_currency",
  onHandStock: "on_hand_stock",
  bufferZoneColor: "buffer_zone_color"
});

export const batchStatuses = Object.freeze({
  expired: "expired",
  expiry_this_week: "expiry_this_week",
  expiry_next_week: "expiry_next_week",
  expiry_two_weeks: "expiry_two_weeks",
  expiry_three_weeks: "expiry_three_weeks",
  expiry_three_plus_weeks: "expiry_three_plus_weeks",
});

export const permanentColumns = [
  "material_code",
  "material_location",
  "material_description",
  "actual_order",
  "suggested_order",
  "order_variance",
];

export const requiredColumnsIdOrder = [
  "material_location",
  "material_code",
  "material_description",
  "location_code_concatenation",
  "material_unitof_measure",
  "material_type",
  "unit_cost",
  "unit_cost_currency",
  "supply_lead_time",
  "minimum_order_quantity",
  "multiple_order_quantity",
  "on_hand_stock",
  "transit_stock",
  "open_demand_orders",
  "group",
  "subgroup",
  "material_method",
  "average_daily_usage",
  "lead_time_factor",
  "variability_factor",
  "additional_buffer_zone",
  "grey_zone",
  "red_zone",
  "yellow_zone",
  "green_zone",
  "top_of_grey",
  "top_of_red",
  "top_of_yellow",
  "top_of_green",
  "net_flow_equation",
  "net_flow_priority",
  "current_on_hand_alert",
  "projected_stock_out",
];
export const batchColumnsIdOrder = [
  "worst_batch_identifier",
  "worst_batch_expiry_date",
  "worst_batch_status",
];

export const editableColumnsIdOrder = [
  "suggested_order",
  "actual_order",
  "order_variance",
];

export const FILE_DATE_FORMAT = "HH:mm - DD/MM/YYYY";

export const FAILED_STATUS = "failed";
export const SUCCESS_STATUS = "success";
export const PROCESSING_STATUS = "processing";
export const UPLOADING_STATUS = "uploading";

export const fileAccessors = Object.freeze({
  original_filename: "original_filename",
  file_section: "file_section",
  created_at: "created_at",
  status: "status",
  error_log: "error_log",
});

export const DATA_IMPORT_MAX_FILE_SIZE = 2e7;

// Admin console & permissions
export const ORGANIZATION_LEVEL_PERMISSIONS_KEY = null;
export const ACCESS_ADMIN_CONSOLE_PERMISSION = "access_admin_console";
export const VIEW_PERMISSION = "view";
export const EDIT_PERMISSION = "edit";
export const VIEWER_ROLE = "viewer";
export const EDITOR_ROLE = "editor";
export const ADMIN_ROLE = "admin";

// Buffer Settings
export const variabilityFactorMin = 0.1;
export const variabilityFactorMax = 1.2;
export const variabilityFactorStep = 0.1;
export const leadTimeFactorMin = 0.1;
export const leadTimeFactorMax = 1.2;
export const leadTimeFactorStep = 0.1;
export const defaultIgnoredColumnCodes = [
  "unit_cost",
  "material_code",
  "minimum_order_quantity",
  "multiple_order_quantity",
  "material_description",
  "on_hand_stock",
  "transit_stock",
  "open_demand_orders",
  "batch_expiry_date",
  "batch_identifier",
  "unit_cost_currency",
  "location_code_concatenation",
  "supply_lead_time",
];

// Batch statuses
export const expired = "expired";
export const expiry_this_week = "expiry_this_week";
export const expiry_next_week = "expiry_next_week";
export const expiry_two_weeks = "expiry_two_weeks";
export const expiry_three_weeks = "expiry_three_weeks";
export const expiry_three_plus_weeks = "expiry_three_plus_weeks";


export const chartHeight = 400;
export const overalYOffset = 50;
export const barWidth = 65;
export const topOfZoneLineYOffset = 1.3;
export const topOfZoneLineXOffset = 2;
export const ExtraMetricsLineXOffset = 5;

// Analytics
export const WasAggregateSelected = "wasAggregateSelected"
export const WasCurrencyUnitsSelected = "wasCurrencyUnitsSelected"
export const WasSingleWorkspaceSelected = "wasSingleWorkspaceSelected"

export const TIME_FILTER_OPTIONS = {
    CURRENT_WORKSPACE: "CURRENT_WORKSPACE_AGGREGATE_ITEMS",
    SINGLE_ITEM: "CURRENT_WORKSPACE_SINGLE_ITEM",
    ALL_WORKSPACES: "ALL_WORKSPACES",
}


export const ANALYTICS_CHARTS = {
  "single_material" : "QUERY_ANALYTICS_BY_MATERIAL_REPORT",
  "aggregate_materials": "QUERY_ANALYTICS_REPORT",
  "query_report_analytics_all_workspaces" : "QUERY_ANALYTICS_ALL_WORKSPACES_REPORT",
}

export const SYMBOLS = {
  "USD": "$",
  "PERCENTAGE": "%",
}

export const ANALYTICS_COLOR_CODES =  {
  blue:"#6563BB",
  green:"#2ec4b6", 
  yellow:"#fcd757",
  red: "#E76F51", 
  gray: "#CECECE",
  black:"#000000",
}

export const COMBO_CHART_ORDER_COLORS = [ANALYTICS_COLOR_CODES.gray, 
  ANALYTICS_COLOR_CODES.red, 
  ANALYTICS_COLOR_CODES.yellow, 
  ANALYTICS_COLOR_CODES.green, 
  ANALYTICS_COLOR_CODES.blue, 
  ANALYTICS_COLOR_CODES.black]

export const CHART_TWO_ORDER_COLORS = [ANALYTICS_COLOR_CODES.black,
  ANALYTICS_COLOR_CODES.gray, 
  ANALYTICS_COLOR_CODES.red, 
  ANALYTICS_COLOR_CODES.yellow, 
  ANALYTICS_COLOR_CODES.green, 
  ANALYTICS_COLOR_CODES.blue]

export const CHART_ONE_ORDER_COLORS =  [ANALYTICS_COLOR_CODES.gray, 
  ANALYTICS_COLOR_CODES.red, 
  ANALYTICS_COLOR_CODES.yellow, 
  ANALYTICS_COLOR_CODES.green, 
  ANALYTICS_COLOR_CODES.blue]

export const CHART_OPTIONS_GLOBAL = {
  // noData key was not added because of translation
  chart: 
  {
    type: "area",
    stacked: true,
  },
  colors: COMBO_CHART_ORDER_COLORS,
  dataLabels: {
    enabled: false,
    formatter: function (val) {
      return val ? val.toFixed(1) + "%" : "";
    },  
  },
  stroke: {
    curve: "smooth",
  },
  fill: {
    type: "solid",
    gradient: {
      opacityFrom: 0.6,
      opacityTo: 0.8,
    },
  },
  legend: {
    position: "top",
    horizontalAlign: "left",      
  },
  // Markers is only used to solve problem of tooltip point colors
  markers: {      
    size: .001      
  },
  tooltip:{
    enabled: true,
    shared:true,
    inverseOrder:true,
    fillSeriesColor:false,          
  },
  xaxis: {
    type: "datetime",
  },
}

// Simulation Allowed message
export const ANALYTICS_ALLOWED = "simulation allowed"

export const LANGUAGES_ABREVIATIONS = {
  spanish: "es",
  english: "en",
  italian: "it"
}

// pytz timezones
export const PYTZ_COMMON_TIMEZONES = [
  "Asia/Kabul",
  "Europe/Tirane",
  "Europe/Berlin",
  "Europe/Busingen",
  "Europe/Andorra",
  "Africa/Luanda",
  "America/Anguilla",
  "America/Antigua",
  "Antarctica/Casey",
  "Antarctica/Davis",
  "Antarctica/DumontDUrville",
  "Antarctica/Mawson",
  "Antarctica/McMurdo",
  "Antarctica/Palmer",
  "Antarctica/Rothera",
  "Antarctica/Syowa",
  "Antarctica/Vostok",
  "Asia/Riyadh",
  "Africa/Algiers",
  "America/Argentina/Buenos_Aires",
  "America/Argentina/Catamarca",
  "America/Argentina/Cordoba",
  "America/Argentina/Jujuy",
  "America/Argentina/La_Rioja",
  "America/Argentina/Mendoza",
  "America/Argentina/Rio_Gallegos",
  "America/Argentina/Salta",
  "America/Argentina/San_Juan",
  "America/Argentina/San_Luis",
  "America/Argentina/Tucuman",
  "America/Argentina/Ushuaia",
  "Asia/Yerevan",
  "America/Aruba",
  "Australia/Adelaide",
  "Australia/Brisbane",
  "Australia/Broken_Hill",
  "Australia/Currie",
  "Australia/Darwin",
  "Australia/Eucla",
  "Australia/Hobart",
  "Australia/Lindeman",
  "Australia/Lord_Howe",
  "Antarctica/Macquarie",
  "Australia/Melbourne",
  "Australia/Perth",
  "Australia/Sydney",
  "Europe/Vienna",
  "Asia/Baku",
  "America/Nassau",
  "Asia/Dhaka",
  "America/Barbados",
  "Asia/Bahrain",
  "America/Belize",
  "Africa/Porto-Novo",
  "Atlantic/Bermuda",
  "Europe/Minsk",
  "America/La_Paz",
  "Europe/Sarajevo",
  "Africa/Gaborone",
  "America/Araguaina",
  "America/Bahia",
  "America/Belem",
  "America/Boa_Vista",
  "America/Campo_Grande",
  "America/Cuiaba",
  "America/Eirunepe",
  "America/Fortaleza",
  "America/Maceio",
  "America/Manaus",
  "America/Noronha",
  "America/Porto_Velho",
  "America/Recife",
  "America/Rio_Branco",
  "America/Santarem",
  "America/Sao_Paulo",
  "Asia/Brunei",
  "Europe/Sofia",
  "Africa/Ouagadougou",
  "Africa/Bujumbura",
  "Asia/Thimphu",
  "Europe/Brussels",
  "Atlantic/Cape_Verde",
  "Asia/Phnom_Penh",
  "Africa/Douala",
  "America/Atikokan",
  "America/Blanc-Sablon",
  "America/Cambridge_Bay",
  "America/Creston",
  "America/Dawson_Creek",
  "America/Dawson",
  "America/Edmonton",
  "Canada/Mountain",
  "America/Fort_Nelson",
  "America/Glace_Bay",
  "America/Goose_Bay",
  "America/Halifax",
  "Canada/Atlantic",
  "America/Inuvik",
  "America/Iqaluit",
  "America/Moncton",
  "America/Nipigon",
  "America/Pangnirtung",
  "America/Rainy_River",
  "America/Rankin_Inlet",
  "America/Regina",
  "America/Resolute",
  "America/St_Johns",
  "Canada/Newfoundland",
  "America/Swift_Current",
  "America/Thunder_Bay",
  "America/Toronto",
  "Canada/Eastern",
  "America/Vancouver",
  "Canada/Pacific",
  "America/Whitehorse",
  "America/Winnipeg",
  "Canada/Central",
  "America/Yellowknife",
  "America/Kralendijk",
  "Asia/Qatar",
  "Africa/Ndjamena",
  "Europe/Prague",
  "Pacific/Easter",
  "America/Punta_Arenas",
  "America/Santiago",
  "Asia/Shanghai",
  "Asia/Urumqi",
  "Asia/Famagusta",
  "Asia/Nicosia",
  "Europe/Vatican",
  "America/Bogota",
  "Indian/Comoro",
  "Africa/Brazzaville",
  "Asia/Pyongyang",
  "Asia/Seoul",
  "America/Costa_Rica",
  "Europe/Zagreb",
  "America/Havana",
  "America/Curacao",
  "Africa/Abidjan",
  "Europe/Copenhagen",
  "America/Dominica",
  "Pacific/Galapagos",
  "America/Guayaquil",
  "Africa/Cairo",
  "America/El_Salvador",
  "Asia/Dubai",
  "Africa/Asmara",
  "Europe/Bratislava",
  "Europe/Ljubljana",
  "Atlantic/Canary",
  "Africa/Ceuta",
  "Europe/Madrid",
  "America/Adak",
  "America/Anchorage",
  "US/Alaska",
  "America/North_Dakota/Beulah",
  "America/Boise",
  "America/North_Dakota/Center",
  "America/Chicago",
  "US/Central",
  "America/Denver",
  "US/Mountain",
  "America/Detroit",
  "Pacific/Honolulu",
  "US/Hawaii",
  "America/Indiana/Indianapolis",
  "America/Juneau",
  "America/Indiana/Knox",
  "America/Los_Angeles",
  "US/Pacific",
  "America/Kentucky/Louisville",
  "America/Indiana/Marengo",
  "America/Menominee",
  "America/Metlakatla",
  "America/Kentucky/Monticello",
  "America/North_Dakota/New_Salem",
  "America/Nome",
  "America/New_York",
  "US/Eastern",
  "America/Indiana/Petersburg",
  "America/Phoenix",
  "US/Arizona",
  "America/Sitka",
  "America/Indiana/Tell_City",
  "America/Indiana/Vevay",
  "America/Indiana/Vincennes",
  "America/Indiana/Winamac",
  "America/Yakutat",
  "Europe/Tallinn",
  "Africa/Mbabane",
  "Africa/Addis_Ababa",
  "Asia/Manila",
  "Europe/Helsinki",
  "Pacific/Fiji",
  "Europe/Paris",
  "Africa/Libreville",
  "Africa/Banjul",
  "Asia/Tbilisi",
  "Africa/Accra",
  "Europe/Gibraltar",
  "America/Grenada",
  "Europe/Athens",
  "America/Danmarkshavn",
  "America/Scoresbysund",
  "America/Thule",
  "America/Guadeloupe",
  "Pacific/Guam",
  "America/Guatemala",
  "America/Cayenne",
  "Europe/Guernsey",
  "Africa/Conakry",
  "Africa/Malabo",
  "Africa/Bissau",
  "America/Guyana",
  "America/Port-au-Prince",
  "America/Tegucigalpa",
  "Europe/Budapest",
  "Asia/Kolkata",
  "Asia/Jayapura",
  "Asia/Makassar",
  "Asia/Pontianak",
  "Asia/Jakarta",
  "Asia/Baghdad",
  "Europe/Dublin",
  "Asia/Tehran",
  "Europe/Isle_of_Man",
  "Indian/Christmas",
  "Pacific/Norfolk",
  "Atlantic/Reykjavik",
  "America/Cayman",
  "Indian/Cocos",
  "Pacific/Rarotonga",
  "Atlantic/Faroe",
  "Atlantic/South_Georgia",
  "Atlantic/Stanley",
  "Pacific/Saipan",
  "Pacific/Kwajalein",
  "Pacific/Majuro",
  "Pacific/Midway",
  "Pacific/Wake",
  "Pacific/Pitcairn",
  "Pacific/Guadalcanal",
  "America/Grand_Turk",
  "America/Tortola",
  "America/St_Thomas",
  "Europe/Mariehamn",
  "Asia/Jerusalem",
  "Europe/Rome",
  "America/Jamaica",
  "Asia/Tokyo",
  "Europe/Jersey",
  "Asia/Amman",
  "Asia/Aqtau",
  "Asia/Aqtobe",
  "Asia/Almaty",
  "Asia/Atyrau",
  "Asia/Qostanay",
  "Asia/Qyzylorda",
  "Asia/Oral",
  "Africa/Nairobi",
  "Asia/Bishkek",
  "Pacific/Enderbury",
  "Pacific/Kiritimati",
  "Pacific/Tarawa",
  "Asia/Kuwait",
  "Asia/Vientiane",
  "Africa/Maseru",
  "Europe/Riga",
  "Africa/Monrovia",
  "Africa/Tripoli",
  "Europe/Vaduz",
  "Europe/Vilnius",
  "Europe/Luxembourg",
  "Asia/Beirut",
  "Europe/Skopje",
  "Indian/Antananarivo",
  "Asia/Kuala_Lumpur",
  "Asia/Kuching",
  "Africa/Blantyre",
  "Indian/Maldives",
  "Africa/Bamako",
  "Europe/Malta",
  "Africa/Casablanca",
  "America/Martinique",
  "Indian/Mauritius",
  "Africa/Nouakchott",
  "Indian/Mayotte",
  "Pacific/Chuuk",
  "Pacific/Kosrae",
  "Pacific/Pohnpei",
  "Europe/Chisinau",
  "Asia/Choibalsan",
  "Asia/Hovd",
  "Asia/Ulaanbaatar",
  "Europe/Podgorica",
  "America/Montserrat",
  "Africa/Maputo",
  "Antarctica/Troll",
  "Asia/Yangon",
  "America/Bahia_Banderas",
  "America/Cancun",
  "America/Chihuahua",
  "America/Mexico_City",
  "America/Hermosillo",
  "America/Matamoros",
  "America/Mazatlan",
  "America/Monterrey",
  "America/Merida",
  "America/Ojinaga",
  "America/Tijuana",
  "Europe/Monaco",
  "Africa/Windhoek",
  "Pacific/Nauru",
  "Asia/Kathmandu",
  "America/Managua",
  "Africa/Lagos",
  "Pacific/Niue",
  "Europe/Oslo",
  "Pacific/Noumea",
  "Pacific/Auckland",
  "Pacific/Chatham",
  "Africa/Niamey",
  "Asia/Muscat",
  "Asia/Karachi",
  "Pacific/Palau",
  "America/Panama",
  "Pacific/Bougainville",
  "Pacific/Port_Moresby",
  "America/Asuncion",
  "Europe/Amsterdam",
  "America/Lima",
  "Pacific/Gambier",
  "Pacific/Marquesas",
  "Pacific/Tahiti",
  "Europe/Warsaw",
  "Atlantic/Azores",
  "Europe/Lisbon",
  "Atlantic/Madeira",
  "America/Puerto_Rico",
  "Asia/Hong_Kong",
  "Asia/Macau",
  "GMT",
  "America/Nuuk",
  "UTC",
  "Europe/London",
  "Africa/Bangui",
  "Africa/Kinshasa",
  "Africa/Lubumbashi",
  "America/Santo_Domingo",
  "Indian/Reunion",
  "Africa/Kigali",
  "Europe/Bucharest",
  "Asia/Anadyr",
  "Europe/Astrakhan",
  "Asia/Barnaul",
  "Asia/Chita",
  "Asia/Yekaterinburg",
  "Asia/Irkutsk",
  "Europe/Kaliningrad",
  "Asia/Kamchatka",
  "Asia/Khandyga",
  "Asia/Krasnoyarsk",
  "Europe/Kirov",
  "Asia/Magadan",
  "Europe/Moscow",
  "Asia/Novokuznetsk",
  "Asia/Novosibirsk",
  "Asia/Omsk",
  "Asia/Sakhalin",
  "Europe/Samara",
  "Europe/Saratov",
  "Asia/Srednekolymsk",
  "Asia/Tomsk",
  "Europe/Ulyanovsk",
  "Asia/Ust-Nera",
  "Asia/Vladivostok",
  "Europe/Volgograd",
  "Asia/Yakutsk",
  "Pacific/Apia",
  "Pacific/Pago_Pago",
  "America/St_Barthelemy",
  "America/St_Kitts",
  "Europe/San_Marino",
  "America/Marigot",
  "America/Miquelon",
  "America/St_Vincent",
  "Atlantic/St_Helena",
  "America/St_Lucia",
  "Africa/Sao_Tome",
  "Africa/Dakar",
  "Europe/Belgrade",
  "Indian/Mahe",
  "Africa/Freetown",
  "Asia/Singapore",
  "America/Lower_Princes",
  "Asia/Damascus",
  "Africa/Mogadishu",
  "Asia/Colombo",
  "Africa/Johannesburg",
  "Africa/Khartoum",
  "Africa/Juba",
  "Europe/Stockholm",
  "Europe/Zurich",
  "America/Paramaribo",
  "Arctic/Longyearbyen",
  "Africa/El_Aaiun",
  "Asia/Bangkok",
  "Asia/Taipei",
  "Africa/Dar_es_Salaam",
  "Asia/Dushanbe",
  "Indian/Chagos",
  "Indian/Kerguelen",
  "Asia/Gaza",
  "Asia/Hebron",
  "Asia/Dili",
  "Africa/Lome",
  "Pacific/Fakaofo",
  "Pacific/Tongatapu",
  "America/Port_of_Spain",
  "Asia/Ashgabat",
  "Europe/Istanbul",
  "Pacific/Funafuti",
  "Africa/Tunis",
  "Europe/Kiev",
  "Europe/Simferopol",
  "Europe/Zaporozhye",
  "Europe/Uzhgorod",
  "Africa/Kampala",
  "America/Montevideo",
  "Asia/Samarkand",
  "Asia/Tashkent",
  "Pacific/Efate",
  "America/Caracas",
  "Asia/Ho_Chi_Minh",
  "Pacific/Wallis",
  "Asia/Aden",
  "Africa/Djibouti",
  "Africa/Lusaka",
  "Africa/Harare",
  "Etc/UTC"
]