import React from 'react'
import { abbreviateNumber, round, shortenNumber } from '../../../../utils/format';
import { Popup } from 'semantic-ui-react';
import { chartHeight } from "../../../../utils/constants"

export const BarValues = ( bar ) => {

    const { x, y, width, height, value } = bar.drawData
    const longNumber = abbreviateNumber(round(value, 1))
    const shortNumber = shortenNumber(longNumber, 4)

    const renderText = ()=> {
      return(
              <text
                x={x + width / 2}
                y={y + 5 + height / 2}
                textAnchor="middle"
                alignmentBaseline="central"
                z={1}
                className={"zoneValue"}
              >
                {height > (1 / 12) * chartHeight
                  ? shortNumber
                  : null}
              </text>
      )
    }

    return(
      typeof(shortNumber) === 'string' && shortNumber.indexOf('...') > 0
        ?  <Popup 
              on='hover'
              position="right center"
              content={longNumber}
              trigger={renderText()}
            />
        : renderText()

    )
  };
