import React, { useState, useEffect, useMemo } from 'react'
import { Accordion, Icon } from 'semantic-ui-react'
import { Inputs } from './Input/Inputs'
import PropTypes from 'prop-types'
import './AccordionData.css'
import { Results } from './Results/Results'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import _ from "lodash"

const AccordionData = 
({ 
    simulationChartData, 
    simulationMaterialData, 
    simulationQuantityModifiers, 
    simulationGroupSettings, 
    simulationGreyZone,

    loadSimulationData,
    loadedSelectedSimulationData,
    previousSimData
})=>{

    const { t } = useTranslation()
    const [ activeSection, setActiveSection ] = useState({
                                                    section1: 'inactive', 
                                                    section2: 'inactive'
                                                })

    useEffect(()=>{
        if( simulationQuantityModifiers.length >0 
            || Object.keys(simulationGroupSettings).length >0 
            || simulationGreyZone.length >0){
            setActiveSection((activeSection)=>({...activeSection, section1: 'active'}))
            return
        }
        setActiveSection((activeSection)=>({...activeSection, section1: 'inactive'}))

    }, [ simulationQuantityModifiers, simulationGroupSettings, simulationGreyZone ])

    useEffect(()=>{
        if(loadSimulationData.length && _.isEqual(loadSimulationData[0].sim_inputs, previousSimData)){
            setActiveSection((activeSection)=>({...activeSection, section1: 'active'}))
        }
    }, [ loadSimulationData, previousSimData,  ])


    useEffect(()=>{
        if(loadSimulationData.length){
            setActiveSection({section2:"active", section1: 'active'})
        }
    }, [loadSimulationData])


    const activeSectionAction = (sectionName)=>{
        activeSection[sectionName] === 'inactive' 
        ? setActiveSection((activeSection)=>({...activeSection, [sectionName]: 'active'}))
        : setActiveSection((activeSection)=>({...activeSection, [sectionName]: 'inactive'}))
    }

    const resultData = useMemo(()=>{
        if(simulationChartData.table1Info && simulationChartData.table2Info){
            return  {res1: simulationChartData.table1Info, 
                    res2: simulationChartData.table2Info}
        }
        if(loadSimulationData[0].loaded_results){
            return  {res1: loadSimulationData[0].loaded_results.table1Info, 
                    res2: loadSimulationData[0].loaded_results.table2Info}
        }
        return {res1: {}, res2: {}}
    }, [simulationChartData, loadSimulationData])


    const inputData = useMemo(()=>{
        if(simulationMaterialData.name){
            return  {
                materialName: simulationMaterialData.name,
                quantityModifier: simulationQuantityModifiers,
                groupSetting: simulationGroupSettings,
                greyZone: simulationGreyZone
            }
        }
        if(loadSimulationData.length){
            const input_data = loadSimulationData[0].sim_inputs
            return {
                materialName: loadedSelectedSimulationData.material_id,
                quantityModifier: input_data.sim_dataset,
                groupSetting: input_data.group_info,
                greyZone: input_data.g_zone_input
            }
        }

        return {
            materialName: "no name",
            quantityModifier: [],
            groupSetting: {},
            greyZone: []
        }

    }, [simulationMaterialData, 
        loadSimulationData, 
        simulationGreyZone, 
        simulationGroupSettings, 
        simulationQuantityModifiers,
        loadedSelectedSimulationData])

    useEffect(()=>{
        if(Object.keys(simulationChartData).length  && resultData.res1 && resultData.res2){
            setActiveSection((activeSection)=>({...activeSection, section2: 'active'}))
        }
    }, [ resultData, simulationChartData ])

    return(
        <Accordion>
            <Accordion.Title
                active={activeSection['section1'] === 'active'}
                onClick={()=>activeSectionAction('section1')}
                >
                <Icon name="dropdown"/>
                {t("simulation.inputs")}
            </Accordion.Title>
            <Accordion.Content
                active={activeSection['section1'] === 'active'}
                className="accordion-content">
                <Inputs 
                    materialName={inputData.materialName}
                    quantityModifier={inputData.quantityModifier}
                    groupSetting={inputData.groupSetting}
                    greyZone={inputData.greyZone}
                    />
            </Accordion.Content>

            <Accordion.Title
                active={activeSection['section2'] === 'active'}
                onClick={()=>activeSectionAction('section2')}>
                <Icon name="dropdown"/>
                {t("simulation.simulationResults")}
            </Accordion.Title>
            <Accordion.Content
                active={activeSection['section2'] === 'active'}
                className="accordion-content"
                >
                <Results simulationData={resultData}/>
            </Accordion.Content>
        </Accordion>
    )
}

AccordionData.propTypes = {
    simulationChartData: PropTypes.object,
    simulationMaterialData: PropTypes.object,
    simulationQuantityModifiers: PropTypes.array,
    simulationGroupSettings: PropTypes.object,
    simulationGreyZone: PropTypes.array,
    loadSimulationData: PropTypes.array,
    loadedSelectedSimulationData: PropTypes.object,
    previousSimData: PropTypes.object,
}

const mapStateToProps = (state) => ({
    simulationChartData: state.analytics.simulationChartData,
    simulationMaterialData: state.analytics.simulationMaterialData,
    simulationQuantityModifiers: state.analytics.simulationQuantityModifiers,
    simulationGroupSettings: state.analytics.simulationGroupSettings,
    simulationGreyZone: state.analytics.simulationGreyZone,
    loadSimulationData: state.analytics.loadSimulationData,
    loadedSelectedSimulationData: state.analytics.loadedSelectedSimulationData,
    previousSimData: state.analytics.previousSimData
})

export default connect(mapStateToProps,null)(AccordionData)