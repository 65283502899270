import React, { Suspense, useEffect } from "react";
import { Provider } from "react-redux";
import store from "../../store";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Navbar from "../Navbar/Navbar";
import PrivateRoute from "../Routing/PrivateRoute";
import Dashboard from "../Dashboard/Dashboard";
import DataImport from "../DataImport/DataImport";
import CalendarSettings from "../CalendarSettings/CalendarSettings";
import Analytics from "../Analytics/Analytics";
import GroupSettings from "../GroupSettings/GroupSettings";
import Login from "../Login/Login";
import Register from "../Register/Register";
import AdminConsole from "../AdminConsole/AdminConsole";
import PusherProvider from "../PusherProvider/PusherProvider";
import NavigationTracker from "../NavigationTracker/NavigationTracker";
import Default from "../404/Default";
import PasswordRecovery from "../PasswordRecovery/PasswordRecovery";
import AccountRecovery from "../PasswordRecovery/AccountRecovery/AccountRecovery";

import { Loader } from "semantic-ui-react";
import { LANGUAGES_ABREVIATIONS } from "../../utils/constants";
import { useTranslation } from "react-i18next";

import {
  DATA_IMPORT,
  CALENDAR,
  ANALYTICS,
  LOGIN,
  BUFFER_SETTINGS,
  REGISTER,
  ADMIN_CONSOLE,
  REGISTER_NON_ADMIN,
  ACCOUNT_RECOVERY,
  PASSWORD_RECOVERY,
} from "../Navbar/routes";

import { loadUser } from "../../actions/auth";
import { warmupHighMemService } from "../../actions/warmup";

import { baseOptions } from "../../utils/toastify";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// import "semantic-ui-css/semantic.min.css";
import "./App.css";

import { pusherInstance } from "../../utils/pusher";

const App = () => {
  const { i18n } = useTranslation()
  const { spanish, english, italian } = LANGUAGES_ABREVIATIONS
  // the language of the page are going to be the language of the browser, not the language of the recover url produced
  const userLanguage = navigator.language.split("-")[0] // navigator.language --> es-Es

  useEffect(() => {
    store.dispatch(loadUser());
    store.dispatch(warmupHighMemService());
  }, []);

  useEffect(()=>{
    if(userLanguage === spanish || userLanguage === english || userLanguage === italian){
        i18n.changeLanguage(userLanguage)
        return
    }
    i18n.changeLanguage(english)
    // ignore because spanish, english and italian are constants
    // eslint-disable-next-line
}, [i18n, userLanguage])

  return (
    <Provider store={store}>
      <Suspense fallback={<Loader active inline="centered" />}>
        <PusherProvider pusherInstance={pusherInstance}>
          <div className="App">
            <Router>
              <ToastContainer {...baseOptions} />
              <NavigationTracker />
              <Navbar />
              <Dashboard />
              <Switch>
                <Route exact path={LOGIN} component={Login}></Route>
                <Route
                  exact
                  path={ACCOUNT_RECOVERY}
                  component={AccountRecovery}
                >
                </Route>
                <Route
                  exact
                  path={PASSWORD_RECOVERY + "/:code?"}
                  component={PasswordRecovery}
                ></Route>
                <Route
                  exact
                  path={REGISTER + "/:registrationCode?"}
                  component={Register}
                ></Route>
                <Route
                  exact
                  path={REGISTER_NON_ADMIN}
                  component={Register}
                ></Route>
                <PrivateRoute
                  exact
                  path={ADMIN_CONSOLE}
                  component={AdminConsole}
                ></PrivateRoute>
                <PrivateRoute
                  exact
                  path={DATA_IMPORT}
                  component={DataImport}
                ></PrivateRoute>
                <PrivateRoute
                  exact
                  path={CALENDAR}
                  component={CalendarSettings}
                ></PrivateRoute>
                <PrivateRoute
                  exact
                  path={ANALYTICS}
                  component={Analytics}
                ></PrivateRoute>
                <PrivateRoute
                  exact
                  path={BUFFER_SETTINGS}
                  component={GroupSettings}
                ></PrivateRoute>
                <Route component={Default}></Route>
              </Switch>
            </Router>
          </div>
        </PusherProvider>
      </Suspense>
    </Provider>
  );
};

export default App;
